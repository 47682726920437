// import { uploadBrandBanner } from "src/services/Brand"

import { uploadSingleFile } from "api/uploadSingleFile";

export const dataURLtoFile = (dataurl: any, filename: any = "ok") => {
  var arr = dataurl?.split(","),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  const result = new File([u8arr], filename, { type: mime });
  // console.log("result", result);

  return result;
};

// export const uploadImage = async (file: any, category: string) => {
//   // console.log("file=>>>>>>>", file);
//   let formData = new FormData()
//   formData.append("file", file)
//   console.log("formData=>>>>>>>", formData)

//   formData.append("category", category)
//   // let resp = await singleUpload(formData)
//   let resp: any = await uploadBrandBanner(formData)

//   // console.log("uploadImage=>>>>>>>", resp)
//   if (resp) {
//     // console.log("Location =>>>>>>>>", resp?.Location);
//     return resp?.Location
//   }
// }

export const uploadImage = async (file: File) => {
  const formData = new FormData();
  formData.append("file", file);
  const resp: any = await uploadSingleFile(formData);
  if (resp?.imageUrl) {
    return resp?.imageUrl;
  } else {
    return undefined;
  }
};

export const uploadFileFirst = async (content: any) => {
  var div = document.createElement("div");
  div.innerHTML = content.trim();
  var thumb;
  let dataImg: any = div.querySelectorAll("img");
  // console.log("length:", dataImg.length);
  for (let i = 0; i < dataImg.length; i++) {
    // console.log("img check>>>:", dataImg[i]);
    var io = div.querySelectorAll("img")[i].src.toString().indexOf("data:");
    // console.log("=>>>>>>>>>>>>>io", io);

    if (io === 0) {
      // console.log("upload file index", i);
      let dataUrl = dataImg[i].src;
      //convert base64 to file
      let file: File = dataURLtoFile(dataUrl);
      const formData = new FormData();
      formData.append("file", file);
      //form data dut o cho nay nay
      const res: any = await uploadSingleFile(formData);
      // console.log("upload success :", res);
      dataImg[i].src = res.imageUrl;
      if (i === 0) {
        thumb = dataImg[i].src;
      }
      // console.log("item.src", dataImg[i].src);
    } else {
      // console.log("không cần upload");
      if (i === 0) {
        thumb = dataImg[i].src;
      }
    }
  }
  return { html: div.innerHTML, thumbnail: thumb };
};
