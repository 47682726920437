import { DefaultOptionType } from "antd/es/select";
import BrandLogo from "assets/images/brandLogo.png";
import VoucherLogo from "assets/images/voucherLogo.png";

export const data: any[] = [
  {
    campaignId: "2131311",
    brandName: "7-Eleven Korea",
    brandCategory: "Food & Beverage",
    brandLogo: BrandLogo,
    voucherLogo: VoucherLogo,
    voucherName: "Golden voucher - Special holiday",
    voucherAmount: 50000,
    voucherExp: "2023/09/30",
    voucherTargetPrice: 200000,
    voucherTotalUsage: 280000,
    progress: 10,
    status: "In Progress",
    startTime: "2023/06/23 07:00:00",
    endTime: "2023/06/24 07:00:00",
    maximumAmount: 500,
    maximumTime: 3,
    branchInfo: "Myeongdong, Busan, Kangnam, Daegu, Daejeon",
    descrition:
      "Jorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc vulputate libero et velit interdum, ac aliquet odio mattis.",
  },
  {
    campaignId: "2131312",
    brandName: "7-Eleven Korea",
    brandCategory: "Food & Beverage",
    brandLogo: BrandLogo,
    voucherLogo: VoucherLogo,
    voucherName: "Golden voucher - Special holiday",
    voucherAmount: 50000,
    voucherExp: "2023/09/30",
    voucherTargetPrice: 200000,
    voucherTotalUsage: 280000,
    progress: 10,
    status: "In Progress",
    startTime: "2023/06/23 07:00:00",
    endTime: "2023/06/24 07:00:00",
    maximumAmount: 500,
    maximumTime: 3,
    branchInfo: "Myeongdong, Busan, Kangnam, Daegu, Daejeon",
    descrition:
      "Jorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc vulputate libero et velit interdum, ac aliquet odio mattis.",
  },
  {
    campaignId: "2131313",
    brandName: "7-Eleven Korea",
    brandCategory: "Food & Beverage",
    brandLogo: BrandLogo,
    voucherLogo: VoucherLogo,
    voucherName: "Golden voucher - Special holiday",
    voucherAmount: 50000,
    voucherExp: "2023/09/30",
    voucherTargetPrice: 200000,
    voucherTotalUsage: 280000,
    progress: 10,
    status: "In Progress",
    startTime: "2023/06/23 07:00:00",
    endTime: "2023/06/24 07:00:00",
    maximumAmount: 500,
    maximumTime: 3,
    branchInfo: "Myeongdong, Busan, Kangnam, Daegu, Daejeon",
    descrition:
      "Jorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc vulputate libero et velit interdum, ac aliquet odio mattis.",
  },
  {
    campaignId: "2131314",
    brandName: "7-Eleven Korea",
    brandCategory: "Food & Beverage",
    brandLogo: BrandLogo,
    voucherLogo: VoucherLogo,
    voucherName: "Golden voucher - Special holiday",
    voucherAmount: 50000,
    voucherExp: "2023/09/30",
    voucherTargetPrice: 200000,
    voucherTotalUsage: 280000,
    progress: 10,
    status: "In Progress",
    startTime: "2023/06/23 07:00:00",
    endTime: "2023/06/24 07:00:00",
    maximumAmount: 500,
    maximumTime: 3,
    branchInfo: "Myeongdong, Busan, Kangnam, Daegu, Daejeon",
    descrition:
      "Jorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc vulputate libero et velit interdum, ac aliquet odio mattis.",
  },
  {
    campaignId: "2131315",
    brandName: "7-Eleven Korea",
    brandCategory: "Food & Beverage",
    brandLogo: BrandLogo,
    voucherLogo: VoucherLogo,
    voucherName: "Golden voucher - Special holiday",
    voucherAmount: 50000,
    voucherExp: "2023/09/30",
    voucherTargetPrice: 200000,
    voucherTotalUsage: 280000,
    progress: 10,
    status: "In Progress",
    startTime: "2023/06/23 07:00:00",
    endTime: "2023/06/24 07:00:00",
    maximumAmount: 500,
    maximumTime: 3,
    branchInfo: "Myeongdong, Busan, Kangnam, Daegu, Daejeon",
    descrition:
      "Jorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc vulputate libero et velit interdum, ac aliquet odio mattis.",
  },
  {
    campaignId: "2131316",
    brandName: "7-Eleven Korea",
    brandCategory: "Food & Beverage",
    brandLogo: BrandLogo,
    voucherLogo: VoucherLogo,
    voucherName: "Golden voucher - Special holiday",
    voucherAmount: 50000,
    voucherExp: "2023/09/30",
    voucherTargetPrice: 200000,
    voucherTotalUsage: 280000,
    progress: 10,
    status: "In Progress",
    startTime: "2023/06/23 07:00:00",
    endTime: "2023/06/24 07:00:00",
    maximumAmount: 500,
    maximumTime: 3,
    branchInfo: "Myeongdong, Busan, Kangnam, Daegu, Daejeon",
    descrition:
      "Jorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc vulputate libero et velit interdum, ac aliquet odio mattis.",
  },
  {
    campaignId: "2131317",
    brandName: "7-Eleven Korea",
    brandCategory: "Food & Beverage",
    brandLogo: BrandLogo,
    voucherLogo: VoucherLogo,
    voucherName: "Golden voucher - Special holiday",
    voucherAmount: 50000,
    voucherExp: "2023/09/30",
    voucherTargetPrice: 200000,
    voucherTotalUsage: 280000,
    progress: 10,
    status: "In Progress",
    startTime: "2023/06/23 07:00:00",
    endTime: "2023/06/24 07:00:00",
    maximumAmount: 500,
    maximumTime: 3,
    branchInfo: "Myeongdong, Busan, Kangnam, Daegu, Daejeon",
    descrition:
      "Jorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc vulputate libero et velit interdum, ac aliquet odio mattis.",
  },
  {
    campaignId: "2131318",
    brandName: "7-Eleven Korea",
    brandCategory: "Food & Beverage",
    brandLogo: BrandLogo,
    voucherLogo: VoucherLogo,
    voucherName: "Golden voucher - Special holiday",
    voucherAmount: 50000,
    voucherExp: "2023/09/30",
    voucherTargetPrice: 200000,
    voucherTotalUsage: 280000,
    progress: 10,
    status: "In Progress",
    startTime: "2023/06/23 07:00:00",
    endTime: "2023/06/24 07:00:00",
    maximumAmount: 500,
    maximumTime: 3,
    branchInfo: "Myeongdong, Busan, Kangnam, Daegu, Daejeon",
    descrition:
      "Jorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc vulputate libero et velit interdum, ac aliquet odio mattis.",
  },
];

export const dataGpDetail: any[] = [
  {
    id: 123131,
    purchaseTime: ["2023/06/23", "20:12:59"],
    userName: "Chae Song Hwa",
    useId: "Andrea",
    phone: "010-3213-231",
    email: "dr.songhwa@gmail.com",
    quantity: 4,
    amount: 80000,
  },
  {
    id: 123131,
    purchaseTime: ["2023/06/23", "20:12:59"],
    userName: "Chae Song Hwa",
    useId: "Andrea",
    phone: "010-3213-231",
    email: "dr.songhwa@gmail.com",
    quantity: 4,
    amount: 80000,
  },
  {
    id: 123131,
    purchaseTime: ["2023/06/23", "20:12:59"],
    userName: "Chae Song Hwa",
    useId: "Andrea",
    phone: "010-3213-231",
    email: "dr.songhwa@gmail.com",
    quantity: 4,
    amount: 80000,
  },
  {
    id: 123131,
    purchaseTime: ["2023/06/23", "20:12:59"],
    userName: "Chae Song Hwa",
    useId: "Andrea",
    phone: "010-3213-231",
    email: "dr.songhwa@gmail.com",
    quantity: 4,
    amount: 80000,
  },
  {
    id: 123131,
    purchaseTime: ["2023/06/23", "20:12:59"],
    userName: "Chae Song Hwa",
    useId: "Andrea",
    phone: "010-3213-231",
    email: "dr.songhwa@gmail.com",
    quantity: 4,
    amount: 80000,
  },
];

export const brandCategoryOptions: DefaultOptionType[] | undefined = [
  {
    label: "7-Eleven",
    value: "7-Eleven",
  },
  {
    label: "8-Eleven",
    value: "8-Eleven",
  },
];

export const campaignStatusOptions: DefaultOptionType[] | undefined = [
  {
    label: "활성",
    value: 1,
  },
  {
    label: "비활성",
    value: 0,
  },
];

export const campaignProgressOptions: DefaultOptionType[] | undefined = [
  {
    label: "대기중",
    value: "INCOMING",
  },
  {
    label: "진행중",
    value: "IN_PROGRESS",
  },
  {
    label: "종료",
    value: "FINISHED",
  },
];
