export const MoneyConvert = (value: any, toFixed = false) => {
  if (!value) return "0";

  let numberValue = Number(value);

  if (toFixed) {
    // Kiểm tra nếu phần thập phân là 0 thì chỉ hiển thị phần nguyên
    if (numberValue % 1 === 0) {
      return numberValue.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    } else {
      return numberValue
        .toFixed(2)
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
  }

  if (Number.isInteger(numberValue)) {
    return numberValue.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  } else {
    return numberValue.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
};

export function formatNumberWithCommas(
  value: string,
  allowZero: boolean = false
): string {
  if (!value || (value === "0" && !allowZero)) {
    return "";
  }
  let numericValue = value.toString().replace(/[^0-9]/g, "");

  if (numericValue.length > 1 && numericValue[0] === "0" && !allowZero) {
    numericValue = numericValue.substring(1);
  }

  return numericValue.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export const formatPhoneNumber = (value: string) => {
  if (!value) {
    return;
  }
  const phoneNumber = value.toString().replace(/\D/g, "");

  let formattedPhoneValue = "";
  for (let i = 0; i < phoneNumber.length; i++) {
    if (i === 3 || i === 7) {
      formattedPhoneValue += "-";
    }
    if (i < 11) {
      formattedPhoneValue += phoneNumber[i];
    }
  }

  return formattedPhoneValue;
};
