import { styled } from "styled-components";

export const Wrapper = styled.div``;
export const Heading = styled.div`
  padding: 80px 30px 38px 30px;
  background-color: #fff;
`;
export const Title = styled.div`
  font-size: 28px;
  margin-bottom: 10px;
`;
export const SubTitle = styled.div``;
export const Content = styled.div`
  margin: 42px auto;
  max-width: 700px;
  width: 100%;
  border-radius: 20px;
  padding: 30px 25px 30px 25px;
  background-color: #fff;
  > h3 {
    font-size: 20px;
    font-family: var(--font-600);
    margin-bottom: 20px;
  }
`;
export const ListTime = styled.div`
  display: grid;
  gap: 20px;
  margin-bottom: 20px;
  .time-box {
    display: flex;
    align-items: center;
    gap: 20px;
    > span {
      display: block;
      font-size: 14px;
      font-family: var(--font-600);
      display: flex;
      align-items: center;
      padding: 0 20px;
      border: 1px solid #e7e7e7;
      height: 48px;
      flex: 1;
      border-radius: 20px;
    }
    > button {
      width: 80px;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #f1f1f1;
      border-radius: 20px;
      height: 48px;
      font-family: var(--font-600);
    }
  }
  h4 {
    font-size: 16px;
  }
`;
