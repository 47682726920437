import { Image, Upload } from "antd";
import ImgCrop from "antd-img-crop";
import { ReactComponent as CameraPlus } from "assets/icons/CameraPlus.svg";
import { useEffect, useRef, useState } from "react";
import * as S from "./style";

type Props = {
  initImage?: string;
  uploadDone?: boolean;
  crop?: {
    type?: "round" | "rect";
    aspect?: number;
  };
  onImageChange?: (file: File) => void;
  onModalOk?: ((value: any) => void) | undefined;
};

function UploadImageCrop(props: Props & React.HTMLAttributes<HTMLDivElement>) {
  const {
    initImage,
    onModalOk,
    crop = {
      type: "round",
      aspect: 1,
    },
    onImageChange = () => {},
  } = props;
  const [imageSrc, setImageSrc] = useState<string>("");
  const ref = useRef<any>(null);
  useEffect(() => {
    if (initImage) {
      setImageSrc(initImage);
    }
  }, [initImage]);

  useEffect(() => {
    return () => {
      imageSrc && URL.revokeObjectURL(imageSrc);
    };
  }, [imageSrc]);

  return (
    <S.Wrap
      className={`image-upload-container ${
        crop.type === "round" ? "round" : "rect"
      }`}
    >
      {/* <ImgCrop
        onModalOk={onModalOk}
        cropShape={crop.type}
        rotationSlider
        aspect={crop.aspect}
      > */}
      <Upload
        accept="image/png, image/jpeg, image/gif"
        customRequest={(e) => {
          const file: any = e.file;
          const type = file.type;
          if (type.includes("image")) {
            const src = URL.createObjectURL(file);
            setImageSrc(src);
            onImageChange(file);
          } else {
          }
        }}
        showUploadList={false}
        onDownload={() => false}
        className="hidden"
        style={{ display: "none", color: "red" }}
      >
        <div ref={ref}>Upload</div>
      </Upload>
      {/* </ImgCrop> */}
      <label className="upload-label">
        {imageSrc ? (
          <Image
            style={{
              objectFit: "contain",
            }}
            src={imageSrc}
          />
        ) : (
          <div
            onClick={() => {
              ref.current && ref.current?.click();
            }}
          >
            <CameraPlus />
          </div>
        )}
      </label>
      <label
        className="upload-btn"
        htmlFor=""
        onClick={() => {
          ref.current && ref.current?.click();
        }}
      >
        로고 교체
      </label>
    </S.Wrap>
  );
}

export default UploadImageCrop;
