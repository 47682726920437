const SpaceMonoBold = require("./SpaceMono-Bold.ttf");
const SpaceMonoRegular = require("./SpaceMono-Regular.ttf");

const WorkSansBold = require("./WorkSans-Bold.ttf");
const WorkSansLight = require("./WorkSans-Light.ttf");
const WorkSansMedium = require("./WorkSans-Medium.ttf");
const WorkSansRegular = require("./WorkSans-Regular.ttf");
const WorkSansSemiBold = require("./WorkSans-SemiBold.ttf");

const urlMainFont400 = require("./Pretendard/Pretendard-Regular.otf");
const urlMainFont500 = require("./Pretendard/Pretendard-Medium.otf");
const urlMainFont600 = require("./Pretendard/Pretendard-SemiBold.otf");
const urlMainFont700 = require("./Pretendard/Pretendard-Bold.otf");

export {
  SpaceMonoBold,
  SpaceMonoRegular,
  WorkSansBold,
  WorkSansLight,
  WorkSansMedium,
  WorkSansRegular,
  WorkSansSemiBold,
  urlMainFont400,
  urlMainFont500,
  urlMainFont600,
  urlMainFont700,
};
