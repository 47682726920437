import { getAnnouncementDetailApi } from "api/announcement";
import { ReactComponent as Campaign } from "assets/icons/Campaign.svg";
import { ReactComponent as PencilIcon } from "assets/icons/Pencil.svg";
import ButtonComp from "components/ButtonCmp";
import dayjs from "dayjs";
import MainHeader from "layouts/components/Header";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import * as S from "./style";

const AnnouncementDetail: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const [dataDetail, setDataDetail] = useState<any>({});

  const getAnnouncementDetail = async () => {
    try {
      if (id) {
        const { data }: any = await getAnnouncementDetailApi({ id: +id });
        if (data) {
          setDataDetail(data);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (id) {
      getAnnouncementDetail();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  return (
    <S.Wrapper>
      <MainHeader
        backLink="/customer-service/announcement"
        title="Announcement Detail"
        breadCrumb={[
          {
            title: "Announcement Management",
            link: "/customer-service/announcement",
            icon: <Campaign />,
          },
          {
            title: "Announcement detail",
          },
        ]}
        rightBtn={
          <ButtonComp
            style={{ padding: "0 30px", borderRadius: "12px" }}
            icon={<PencilIcon />}
            onClick={() =>
              navigate(`/customer-service/announcement/edit/${id}`)
            }
          >
            Edit Announcement
          </ButtonComp>
        }
      />
      <S.Content>
        <S.BlockTop>
          <h3>공개여부</h3>
          <button className={dataDetail.status === "SHOWING" ? "show" : "hide"}>
            {dataDetail.status === "SHOWING" ? "공개" : "비공개"}
          </button>
          <p>
            Create at{" "}
            {dayjs(dataDetail.createdTime).format("YYYY/MM/DD HH:mm")}
          </p>
          {/* <h3>Announcement Type: {dataDetail.type}</h3> */}
        </S.BlockTop>
        <S.MainContent>
          <S.Title>
            <h4>{dataDetail.title}</h4>
          </S.Title>
          <S.HasTag>
            {dataDetail?.hashtag && dataDetail.hashtag.length > 0
              ? dataDetail.hashtag
                  .split(",")
                  .map((h: string, i: number) => <p>#{h}</p>)
              : null}
          </S.HasTag>
          <S.Description>
            <div
              dangerouslySetInnerHTML={{ __html: dataDetail.description ?? "" }}
            />
          </S.Description>
        </S.MainContent>
      </S.Content>
    </S.Wrapper>
  );
};

export default AnnouncementDetail;
