import { ReactComponent as DownIcon } from "assets/icons/arrow-down.svg";
import { ReactComponent as UpIcon } from "assets/icons/arrow-up.svg";
import { useEffect, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import * as S from "../../style";
import { getUnexpiredMembershipApi } from "api/membership";

const MembershipItem = ({ brand }: any) => {
  const [showMorePremium, setShowMorePremium] = useState(false);
  const [showMoreNormal, setShowMoreNormal] = useState(false);

  const toggleShowMorePremium = () => setShowMorePremium(!showMorePremium);
  const toggleShowMoreNormal = () => setShowMoreNormal(!showMoreNormal);

  return (
    <div className="membership-item">
      <div className="wr-image">
        <img src={brand.logoImage} alt={brand.brandName} />
      </div>
      <div className="wr-text">
        <h2>{brand.brandName}</h2>
        <div className="membership-list">
          {brand.premiumMembership.length > 0 && (
            <>
              <span className="label">Premium</span>
              <ul>
                {(showMorePremium
                  ? brand.premiumMembership
                  : brand.premiumMembership.slice(0, 3)
                ).map((item: any, index: number) => (
                  <li key={index}>{item}</li>
                ))}
              </ul>
              {brand.premiumMembership.length > 3 && (
                <button
                  className="show-hidden-btn"
                  onClick={toggleShowMorePremium}
                >
                  {showMorePremium ? (
                    <span>
                      Show Less
                      <UpIcon />
                    </span>
                  ) : (
                    <span>
                      Show More <DownIcon />
                    </span>
                  )}
                </button>
              )}
            </>
          )}
        </div>

        <div className="membership-list">
          {brand.normalMembership.length > 0 && (
            <>
              <span className="label">Normal</span>
              <ul>
                {(showMoreNormal
                  ? brand.normalMembership
                  : brand.normalMembership.slice(0, 3)
                ).map((item: any, index: number) => (
                  <li key={index}>{item}</li>
                ))}
              </ul>
              {brand.normalMembership.length > 3 && (
                <button
                  className="show-hidden-btn"
                  onClick={toggleShowMoreNormal}
                >
                  {showMoreNormal ? (
                    <span>
                      Show Less
                      <UpIcon />
                    </span>
                  ) : (
                    <span>
                      Show More <DownIcon />
                    </span>
                  )}
                </button>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

const UnExpired: React.FC = () => {
  const { userId } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const [membershipUnExpire, setMembershipUnExpire] = useState<any>([]);

  const getUnExpiredMembership = async () => {
    if (!userId) {
      return;
    }
    const { data, error }: any = await getUnexpiredMembershipApi({
      userId: +userId,
    });
    if (error) {
      console.error(error);
      return;
    }
    const formattedData: any = [];

    data.membership.forEach((item: any) => {
      if (
        item.premiumMembership.length > 0 &&
        item.normalMembership.length > 0
      ) {
        formattedData.push({
          brandId: item.brandId,
          brandName: item.brandName,
          logoImage: item.logoImage,
          premiumMembership: item.premiumMembership,
          normalMembership: [],
        });
        formattedData.push({
          brandId: item.brandId,
          brandName: item.brandName,
          logoImage: item.logoImage,
          premiumMembership: [],
          normalMembership: item.normalMembership,
        });
      } else {
        formattedData.push(item);
      }
    });
    setMembershipUnExpire(formattedData);
  };

  useEffect(() => {
    // if (searchParams.get("joinTab") === "unExpired") {
    getUnExpiredMembership();
    // }
  }, []);
  // console.log("subExpire", membershipExpire);

  return (
    <S.UnExpired>
      {membershipUnExpire.length > 0 ? (
        <S.BrandJoinInner>
          {membershipUnExpire?.map((brand: any, index: number) => (
            <MembershipItem key={index} brand={brand} />
          ))}
        </S.BrandJoinInner>
      ) : (
        <div className="no-data">No Data</div>
      )}
    </S.UnExpired>
  );
};

export default UnExpired;
