import styled from "styled-components";

export const Wrapper = styled.div`
  .ant-select {
    height: 100%;
    border: 1px solid var(--main-border);
    /* display: flex;
    align-items: center; */
    border-radius: 20px;
    padding: 0;
    min-width: 180px;
    font-size: 14px;
    &:where(.css-dev-only-do-not-override-14wwjjs).ant-select
      .ant-select-arrow {
      color: var(--brand-color);
    }
    &:hover,
    &.ant-select-open {
      border-color: var(--active-border-color);
    }
    .ant-select-selector {
      border: 0;
      height: 100%;
      display: flex;
      align-items: center;
      border-radius: 20px;
      padding: 0 16px;
      background-color: transparent;
      .ant-select-selection-placeholder {
        font-size: 14px;
        color: var(--brand-color);
      }
      &:active,
      &:focus {
        border: 0;
      }
    }
  }
  &.has-prefix {
    .ant-select-selector {
      padding-left: 42px;
    }
  }
`;
export const PrefixIcon = styled.div`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 11px;
  display: flex;
  align-items: center;
`;
