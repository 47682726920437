import { Breadcrumb } from "antd";
import { BreadcrumbItemType } from "antd/es/breadcrumb/Breadcrumb";
import { ReactComponent as CaretLeftIcon } from "assets/icons/CaretLeft.svg";
import { ReactComponent as CaretRightIcon } from "assets/icons/CaretRightActive.svg";
import { ReactNode } from "react";
import { useNavigate } from "react-router-dom";
import { HeaderStyle } from "./style";
type Props = {
  backLink: string;
  title: string;
  showBack?: boolean;
  rightBtn?: ReactNode | ReactNode[];
  breadCrumb?: {
    title: string;
    link?: string;
    icon?: ReactNode | undefined;
  }[];
};


function MainHeader({
  backLink,
  showBack = true,
  title,
  rightBtn,
  breadCrumb = [],
}: Props) {
  // console.log(breadCrumb);
  const navigate = useNavigate();
  const itemBreadcrumb: Partial<BreadcrumbItemType>[] = breadCrumb
    ? breadCrumb.map((item, index) => {
        return {
          key: index,
          title: (
            <div className="title-breadcrum">
              {item.icon} {item.title}
            </div>
          ),
          href: item.link,
        };
      })
    : [];
  return (
    <HeaderStyle>
      <div className="header-container">
        <div>
          {showBack && (
            <CaretLeftIcon
              className="back-icon"
              onClick={() => {
                navigate(backLink);
              }}
            />
          )}
        </div>
        <div className="title-container">
          <div>
            <h1>{title}</h1>
            <Breadcrumb items={itemBreadcrumb} separator={<CaretRightIcon />} />
          </div>
          {rightBtn && rightBtn}
        </div>
      </div>
    </HeaderStyle>
  );
}

export default MainHeader;
