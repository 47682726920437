import { styled } from "styled-components";

export const Wrapper = styled.div`
  padding-bottom: 30px;
  height: 100%;
  background-color: var(--sub-background);
  .filter-container {
    padding: 0 30px;
    display: flex;
    gap: 12px;
    height: 60px;
    margin: 30px 0 20px 0;
  }
  .ant-input {
    background-color: transparent;
  }
`;

export const TdTextInner = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  p {
    margin: 0;
  }
  .big-text {
    font-size: 16px;
    line-height: 25.6px;
  }
  .small-text {
    font-size: 14px;
    line-height: 22.4px;
  }
`;
export const TdBrandInner = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
`;
export const ViewDetailBtn = styled.div`
  cursor: pointer;
`;

export const TableContainer = styled.div`
  td {
    vertical-align: middle;
  }
  th {
    font-size: 16px;
  }
`;
