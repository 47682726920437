import { styled } from "styled-components";

export const Wrapper = styled.div``;
export const Content = styled.div`
  max-width: 732px;
  padding: 0 16px;
  width: 100%;
  margin: auto;
  display: grid;
  gap: 20px;
`;
export const BlockTop = styled.div`
  padding: 30px 20px;
  background-color: #fff;
  border-radius: 20px;
  margin-top: 20px;
  > h3 {
    font-size: 18px;
    font-family: var(--font-600);
  }
  > button {
    height: 31px;
    padding: 5px;
    font-size: 18px;
    font-family: var(--font-500);
    color: #fff;
    border: 0;
    border-radius: 5px;
    margin: 12px 0;
    &.show {
      background-color: #4aa159;
    }
    &.hide {
      background-color: #858584;
    }
  }
  > p {
    font-size: 16px;
    margin-bottom: 20px;
  }
`;
export const MainContent = styled.div`
  padding: 30px 20px;
  background-color: #fff;
  border-radius: 20px;
  h4 {
    font-size: 16px;
    font-family: var(--font-600);
    line-height: normal;
    margin-bottom: 6px;
  }
`;

export const Question = styled.div`
  margin-bottom: 20px;
`;
export const Answer = styled.div``;
