export const dataFullBank = [
  {
    label: "카카오뱅크",
    value: "090",
  },
  {
    label: "국민은행",
    value: "004",
  },
  {
    label: "기업은행",
    value: "003",
  },
  {
    label: "농협은행",
    value: "011",
  },
  {
    label: "신한은행",
    value: "088",
  },
  {
    label: "산업은행",
    value: "002",
  },
  {
    label: "우리은행",
    value: "020",
  },
  {
    label: "한국씨티은행",
    value: "027",
  },
  {
    label: "하나은행",
    value: "081",
  },
  {
    label: "SC제일은행",
    value: "023",
  },
  {
    label: "경남은행",
    value: "039",
  },
  {
    label: "광주은행",
    value: "034",
  },
  {
    label: "대구은행",
    value: "031",
  },
  {
    label: "도이치은행",
    value: "055",
  },
  {
    label: "뱅크오브아메리카",
    value: "060",
  },
  {
    label: "부산은행",
    value: "032",
  },
  {
    label: "산림조합중앙회",
    value: "064",
  },
  {
    label: "저축은행",
    value: "103",
  },
  {
    label: "새마을금고",
    value: "045",
  },
  {
    label: "수협은행",
    value: "007",
  },
  {
    label: "신협중앙회",
    value: "048",
  },
  {
    label: "우체국",
    value: "071",
  },
  {
    label: "전북은행",
    value: "037",
  },
  {
    label: "제주은행",
    value: "035",
  },
  {
    label: "중국공상은행",
    value: "062",
  },
  {
    label: "HSBC은행",
    value: "054",
  },
  {
    label: "IP모간체이스은행",
    value: "057",
  },
  {
    label: "케이뱅크",
    value: "089",
  },
  {
    label: "토스뱅크",
    value: "092",
  },
  {
    label: "교보증권",
    value: "261",
  },
  {
    label: "대신증권",
    value: "267",
  },
  {
    label: "메리츠증권",
    value: "287",
  },
  {
    label: "미래에셋증권",
    value: "238",
  },
  {
    label: "부국증권",
    value: "290",
  },
  {
    label: "삼성증권",
    value: "240",
  },
  {
    label: "신한금융투자",
    value: "278",
  },
  {
    label: "에스케이증권",
    value: "266",
  },
  {
    label: "현대차증권",
    value: "263",
  },
  {
    label: "이베스트투자증권",
    value: "265",
  },
  {
    label: "케이프투자증권",
    value: "292",
  },
  {
    label: "키움증권",
    value: "264",
  },
  {
    label: "하나금융투자",
    value: "270",
  },
  {
    label: "하이투자증권",
    value: "262",
  },
  {
    label: "한국투자증권",
    value: "243",
  },
  {
    label: "한화투자증권",
    value: "269",
  },
  {
    label: "KB증권",
    value: "218",
  },
  {
    label: "NH투자증권",
    value: "247",
  },
  {
    label: "토스증권",
    value: "271",
  },
];
