import { LoadingOutlined } from "@ant-design/icons";
import { Button, Form, Radio, message } from "antd";
import { NoticeType } from "antd/es/message/interface";
import { createFaqApi } from "api/faq";
import { ReactComponent as Close } from "assets/icons/Close.svg";
import { ReactComponent as FilePlus } from "assets/icons/FilePlus.svg";
import Editor from "components/Editor";
import CsInput from "components/Input";
import SelectCmp from "components/SelectCmp";
import MainHeader from "layouts/components/Header";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Editor as TinyMCEEditor } from "tinymce";
import { uploadFileFirst } from "utils/uploadImage";
import { categories } from "../Client";
import { statusOptions } from "../mockData";
import * as S from "./style";

const FAQCreate: React.FC = () => {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [messageApi, contextHolder] = message.useMessage({ maxCount: 2 });
  const [loading, setLoading] = useState<boolean>(false);
  const [lenghtDes, setLenghDes] = useState<number>(0);

  const setTextSearch = (value: string) => {
    // console.log(value);
  };
  const showMess = (type: NoticeType, mess: string) => {
    messageApi.open({
      type: type,
      content: mess,
    });
  };
  const onFinish = async (values: any) => {
    // console.log("Success:", values);
    setLoading(true);
    try {
      let resultUpload = await uploadFileFirst(values.answer);
      console.log();

      const { data, error }: any = await createFaqApi({
        type: values.category,
        status: values.status,
        question: values.question,
        answer: resultUpload.html,
        // answer: values.answer,
      });
      if (error?.errorCode === "OUT_OF_RANGE") {
        form.setFields([
          {
            name: "answer",
            errors: ["Please enter max 10000 characters"],
          },
        ]);
        setLoading(false);
        return;
      }
      if (data) {
        navigate("/customer-service/faq");
      } else {
        showMess("error", "Create Failed");
      }
      setLoading(false);
    } catch (err) {
      showMess("error", "Create Failed");
      console.error(err);
      setLoading(false);
    }
  };

  const onFinishFailed = (err: any) => {
    console.log(err);
  };

  const onReset = () => {
    form.resetFields();
    navigate("/customer-service/faq");
  };

  const onFill = (e: any) => {
    // form.setFieldsValue({ note: "Hello world!", gender: "male" });
    console.log("e", e);
  };

  // const handleChangeContent = async (e: any, editor: any) => {
  //   form.setFieldsValue({
  //     answer: e,
  //   });
  // };

  const handleChangeContent = async (
    contentHtml: string,
    editor: TinyMCEEditor
  ) => {
    const textContent = editor.getContent({ format: "text" });
    const length = textContent === "\n" ? 0 : textContent.length;
    setLenghDes(length);

    if (length === 0) {
      form.setFields([
        {
          name: "answer",
          errors: ["This field is required"],
        },
      ]);
      return;
    } else if (length < 3) {
      form.setFields([
        {
          name: "answer",
          errors: ["세글자 이상 입력해 주세요"],
        },
      ]);
      return;
    }
    form.setFieldsValue({
      answer: contentHtml,
    });
  };


  return (
    <S.Wrapper>
      {contextHolder}
      <MainHeader backLink="/customer-service/faq" title="FAQ 작성하기" />
      <Form
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
        layout="vertical"
        className="form-content"
        form={form}
        initialValues={{ status: "SHOWING" }}
      >
        <S.Block>
          <Form.Item>
            <S.WrChooseCate
              style={{ display: "inline-block", width: "calc(50% - 15px)" }}
            >
              <Form.Item
                name="category"
                label="문의 유형"
                rules={[{ required: true, message: "This field is require" }]}
              >
                <SelectCmp
                  // defaultValue="inactive"
                  style={{
                    width: "100%",
                    height: 48,
                  }}
                  options={categories}
                  placeholder="문의 유형"
                />
              </Form.Item>
              {/* <S.AddCate onClick={() => setIsOpenModal(true)}>
                Add category
              </S.AddCate> */}
            </S.WrChooseCate>
            <Form.Item
              name="status"
              label="공개여부"
              style={{
                display: "inline-block",
                width: "calc(50% - 15px)",
                margin: "0 0 0 30px",
              }}
            >
              <Radio.Group size="large">
                {statusOptions?.map((item, index) => (
                  <Radio key={index} value={item.value}>
                    {item.label}
                  </Radio>
                ))}
              </Radio.Group>
            </Form.Item>
          </Form.Item>
        </S.Block>
        <S.Block>
          <Form.Item
            name="question"
            label="질문 (제목)"
            rules={[
              {
                required: true,
                message: "This field is required",
              },
            ]}
          >
            <CsInput
              // defaultValue="inactive"
              style={{
                width: "100%",
                height: 48,
              }}
              placeholder="입력하세요"
              onChange={(e) => setTextSearch(e.target.value)}
              onBlur={(e) =>
                form.setFieldsValue({
                  question: e.target.value.trim().replace(/\s+/g, " "),
                })
              }
            />
          </Form.Item>
          <Form.Item
            name="answer"
            label="답변 (본문)"
            rules={[
              {
                validator: () => {
                  if (lenghtDes === 0) {
                    return Promise.reject("This field is required!");
                  } else if (lenghtDes < 3) {
                    return Promise.reject("세글자 이상 입력해 주세요");
                  } else {
                    return Promise.resolve();
                  }
                },
              },
            ]}
          >
            <Editor
              data={form.getFieldValue("answer")}
              onChangeValue={(e, editor) => handleChangeContent(e, editor)}
            />
          </Form.Item>
        </S.Block>
        <Form.Item className="group-btn">
          <Button
            onClick={onReset}
            htmlType="submit"
            className="btn btn-cancel"
            icon={<Close />}
          >
            Cancel
          </Button>

          {loading ? (
            <Button
              style={{
                width: "220px",
                height: "52px",
                backgroundColor: "#526557",
              }}
            >
              <LoadingOutlined />
            </Button>
          ) : (
            <Button
              onClick={onFill}
              htmlType="submit"
              className="btn btn-create"
              icon={<FilePlus />}
            >
              Create
            </Button>
          )}
        </Form.Item>
      </Form>
      {/* <Modal
        title="Create category"
        open={isOpenModal}
        onCancel={() => setIsOpenModal(false)}
        footer={false}
      >
        <S.ModalContent>
          <Form
            name="createType"
            onFinish={handleCreateCategory}
            autoComplete="off"
            form={formCreateType}
          >
            <Form.Item
              name="typeName"
              rules={[{ required: true, message: "This field is require" }]}
            >
              <CsInput
                style={{ height: "48px" }}
                placeholder="Enter category name"
              />
            </Form.Item>
            <S.ModalBtn>
              <Button
                onClick={() => setIsOpenModal(false)}
                className="cancel-btn"
              >
                Cancel
              </Button>
              <Button htmlType="submit" type="primary" className="create-btn">
                Create
              </Button>
            </S.ModalBtn>
          </Form>
        </S.ModalContent>
      </Modal> */}
    </S.Wrapper>
  );
};

export default FAQCreate;
