import api from "axiosInstance/config";
import { API_URL } from "../constants";
import {
  CreateFaqApi,
  CreateFaqCategoryApi,
  DeleteFaqApi,
  GetFaqDetailApi,
  GetListFaqApi,
  UpdateFaqApi,
} from "model/faq";

export const createFaqApi = async (data: CreateFaqApi) => {
  return api.request({
    url: API_URL.CREATE_FAQ,
    method: "POST",
    tokenRequired: true,
    data,
  });
};

export const updateFaqApi = async (data: UpdateFaqApi) => {
  return api.request({
    url: API_URL.UPDATE_FAQ,
    method: "POST",
    tokenRequired: true,
    data,
  });
};

export const createFaqCategoryApi = async (data: CreateFaqCategoryApi) => {
  return api.request({
    url: API_URL.CREATE_FAQ_CATEGORIES,
    method: "POST",
    tokenRequired: true,
    data,
  });
};

export const getFaqCategoryApi = async () => {
  return api.request({
    url: API_URL.GET_FAQ_CATEGORIES,
    method: "GET",
    tokenRequired: true,
  });
};

export const getListFaqApi = async (params: GetListFaqApi) => {
  return api.request({
    url: API_URL.GET_LIST_FAQ,
    method: "GET",
    tokenRequired: true,
    params,
  });
};

export const getFaqDetailApi = async (params: GetFaqDetailApi) => {
  return api.request({
    url: API_URL.GET_FAQ_DETAIL,
    method: "GET",
    tokenRequired: true,
    params,
  });
};

export const deleteFaqDetailApi = async (data: DeleteFaqApi) => {
  return api.request({
    url: API_URL.DELETE_FAQ,
    method: "POST",
    tokenRequired: true,
    data,
  });
};
