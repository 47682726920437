import { getListUserBrandApi } from "api/user";
import { ReactComponent as PlusIcon } from "assets/icons/Plus.svg";
import ButtonComp from "components/ButtonCmp";
import CsInput from "components/Input";
import SelectCmp from "components/SelectCmp";
import { GetListUserBrandApi } from "model/user";
import { DefaultOptionType } from "rc-cascader";
import { useCallback, useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { debounce } from "utils/debounce";
import BrandUserTable from "./BrandUserTable";
import * as S from "./style";

const statusOptions: DefaultOptionType[] | undefined = [
  {
    label: "비활성",
    value: "INACTIVE",
  },
  {
    label: "활성",
    value: "ACTIVE",
  },
  {
    label: "가입중",
    value: "INITIAL",
  },
  {
    label: "대기중",
    value: "WAITING",
  },
];

function BrandUser() {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [dataTable, setDataTable] = useState<any>([]);
  const [totalElement, setTotalElement] = useState<number>(0);
  const [filter, setFilter] = useState<GetListUserBrandApi>({});
  const [searchValue, setSearchValue] = useState<string>("");

  const getListUserBrand = async () => {
    const { data, error }: any = await getListUserBrandApi({
      size: 10,
      page: Number(searchParams.get("page") || 1),
      status: filter.status,
      querySearch: filter.querySearch,
    });
    if (data?.datas) {
      setDataTable(data.datas);
      setTotalElement(+data.pageable.totalElement);
    }
    if (error) {
      setDataTable([]);
      setTotalElement(0);
    }
  };

  // const setTextSearch = debounce((e: React.ChangeEvent<HTMLInputElement>) => {
  //   const value = e.target.value;
  //   setFilter((prev: GetListUserBrandApi) => ({
  //     ...prev,
  //     querySearch: value,
  //   }));
  // }, 500);

  const handleSearch = useCallback(
    debounce((value: string) => {
      setFilter((prev: GetListUserBrandApi) => {
        return { ...prev, querySearch: value };
      });
      searchParams.set("page", "1");
      setSearchParams(searchParams);
    }, 1000),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  useEffect(() => {
    getListUserBrand();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter, searchParams]);

  useEffect(() => {
    localStorage.removeItem("accountInfo");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <S.Wrapper>
      <div className="headings title-button-container">
        <h1 className="main-headings">브랜드 계정 관리</h1>
        <ButtonComp
          onClick={() => navigate("create-brand-account")}
          icon={<PlusIcon />}
        >
          브랜드 계정 생성
        </ButtonComp>
      </div>
      <div className="filter-container">
        <CsInput
          placeholder="브랜드명/ID/연락처/이메일 검색"
          onChange={(e) => {
            const inputValue: string = e.target.value;
            const cleanedValue = inputValue.replace(/\s+/g, " ");
            if (cleanedValue !== " ") {
              setSearchValue(cleanedValue);
              handleSearch(cleanedValue);
            }
          }}
          value={searchValue}
        />
        <SelectCmp
          onChange={(value) => {
            setFilter((prev: GetListUserBrandApi) => ({
              ...prev,
              status: value,
            }));
            searchParams.set("page", "1");
            setSearchParams(searchParams);
          }}
          style={{
            width: 200,
          }}
          options={statusOptions}
          placeholder="계정 상태"
        />
      </div>
      <BrandUserTable totalElement={totalElement} data={dataTable} />
    </S.Wrapper>
  );
}

export default BrandUser;
