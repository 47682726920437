import api from "axiosInstance/config";
import { API_URL } from "../constants";
import {
  CreateGroupBuyingApi,
  FetListOrderApi,
  GetGroupBuyingDetailApi,
  GetOrderDetailApi,
  GetTransactionHistoryApi,
  UpdateGbApi,
  UpdateGbIncomingApi,
  UpdateStatusOrderApi,
} from "model/groupBuying";

export const createGroupBuyingApi = async (data: CreateGroupBuyingApi) => {
  return api.request({
    url: API_URL.CREATE_GROUP_BUYING,
    method: "POST",
    tokenRequired: true,
    data: data,
  });
};

export const updateGbApi = async (data: UpdateGbApi) => {
  return api.request({
    url: API_URL.UPDATE_GB,
    method: "POST",
    tokenRequired: true,
    data: data,
  });
};

export const updateGbIncomingApi = async (data: UpdateGbIncomingApi) => {
  return api.request({
    url: API_URL.UPDATE_GB_INCOMING,
    method: "POST",
    tokenRequired: true,
    data: data,
  });
};

export const getGroupBuyingDetailApi = async (
  params: GetGroupBuyingDetailApi
) => {
  return api.request({
    url: API_URL.GROUP_BUYING_DETAIL,
    method: "GET",
    tokenRequired: true,
    params,
  });
};

// order history
export const getListOrderApi = async (params: FetListOrderApi) => {
  return api.request({
    url: API_URL.LIST_ORDER,
    method: "GET",
    tokenRequired: true,
    params,
  });
};

export const getOrderDetailApi = async (params: GetOrderDetailApi) => {
  return api.request({
    url: API_URL.ORDER_DETAIL,
    method: "GET",
    tokenRequired: true,
    params,
  });
};

export const updateStatusOrderApi = async (data: UpdateStatusOrderApi) => {
  return api.request({
    url: API_URL.UPDATE_STATUS_ORDER,
    method: "POST",
    tokenRequired: true,
    data: data,
  });
};

export const getOrderCountApi = async (params?: {
  querySearch?: string;
  brandId?: number;
  startDate?: string;
  endDate?: string;
}) => {
  return api.request({
    url: API_URL.ORDER_COUNT,
    method: "GET",
    tokenRequired: true,
    params,
  });
};

//TransactionHistory
export const getTransactionHistoryApi = async (
  params: GetTransactionHistoryApi
) => {
  return api.request({
    url: API_URL.LIST_TRANSACTION_HISTORY,
    method: "GET",
    tokenRequired: true,
    params,
  });
};

export const getAllBranchApi = async (params: {
  brandId?: number | undefined;
}) => {
  return api.request({
    url: API_URL.LIST_BRANCH,
    method: "GET",
    tokenRequired: true,
    params,
  });
};

export const duplicateGbApi = async (data: { ids?: number[] }) => {
  return api.request({
    url: API_URL.DUPLICATE_GB,
    method: "POST",
    tokenRequired: true,
    data,
  });
};
