import { styled } from "styled-components";

export const Wrapper = styled.div`
    width: 100%;
    background-color: var(--light-color);
    padding: 30px 20px;
    border-radius: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .title {
        color: var(--brand-color, #526557);
        font-size: 18px;
        font-style: normal;
        font-family: var(--font-600);
        margin-bottom: 16px;
    }
    span.ant-tag {
        font-size: 18px;
        font-style: normal;
        font-family: var(--font-500);
        padding: 5px;
        border-radius: 5px;
    }
    .account-time {
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        margin-top: 12px;
    }
    .account-reason {
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        margin-top: 8px;
    }
    .right-container {
        display: flex;
        gap:16px;
        button {
            width: 160px;
            height: 52px;
        }
    }
`