import { DefaultOptionType } from "antd/es/select";

export const data: any[] = [
  {
    id: "1",
    question:
      "Horem ipsum dolor sit amet, consectetur adipiscing elit.  ipsum dolor sit amet, consectetur adipiscing elit?",
    category: "In Progress",
    createDate: "2023/06/12 15:40",
    status: "Show",
    description: "<p>12312321312123123312233</p>",
  },
  {
    id: "2",
    question:
      "Horem ipsum dolor sit amet, consectetur adipiscing elit.  ipsum dolor sit amet, consectetur adipiscing elit?",
    category: "In Progress",
    createDate: "2023/06/12 15:40",
    status: "Hide",
    description: `<p>12312321312123123312233</p>`,
  },
  {
    id: "3",
    question:
      "Horem ipsum dolor sit amet, consectetur adipiscing elit.  ipsum dolor sit amet, consectetur adipiscing elit?",
    category: "In Progress",
    createDate: "2023/06/12 15:40",
    status: "Hide",
    description: "<p>12312321312123123312233</p>",
  },
  {
    id: "4",
    question:
      "Horem ipsum dolor sit amet, consectetur adipiscing elit.  ipsum dolor sit amet, consectetur adipiscing elit?",
    category: "In Progress",
    createDate: "2023/06/12 15:40",
    status: "Show",
    description: "<p>12312321312123123312233</p>",
  },
  {
    id: "5",
    question:
      "Horem ipsum dolor sit amet, consectetur adipiscing elit.  ipsum dolor sit amet, consectetur adipiscing elit?",
    category: "In Progress",
    createDate: "2023/06/12 15:40",
    status: "Show",
    description: "<p>12312321312123123312233</p>",
  },
  {
    id: "6",
    question:
      "Horem ipsum dolor sit amet, consectetur adipiscing elit.  ipsum dolor sit amet, consectetur adipiscing elit?",
    category: "In Progress",
    createDate: "2023/06/12 15:40",
    status: "Show",
    description: "<p>12312321312123123312233</p>",
  },
  {
    id: "7",
    question:
      "Horem ipsum dolor sit amet, consectetur adipiscing elit.  ipsum dolor sit amet, consectetur adipiscing elit?",
    category: "In Progress",
    createDate: "2023/06/12 15:40",
    status: "Show",
    description: "<p>12312321312123123312233</p>",
  },
  {
    id: "8",
    question:
      "Horem ipsum dolor sit amet, consectetur adipiscing elit.  ipsum dolor sit amet, consectetur adipiscing elit?",
    category: "In Progress",
    createDate: "2023/06/12 15:40",
    status: "Show",
    description: "<p>12312321312123123312233</p>",
  },
];

export const statusOptions: DefaultOptionType[] | undefined = [
  {
    label: "공개",
    value: "SHOWING",
  },
  {
    label: "비공개",
    value: "HIDING",
  },
];

export const groupBuyingOptions: DefaultOptionType[] | undefined = [
  {
    label: "In Progress",
    value: "In Progress",
  },
  {
    label: "Finish",
    value: "Finish",
  },
];
