import { ColumnsType } from "antd/es/table";
import { ReactComponent as CaretRight } from "assets/icons/CaretRight.svg";
import { ReactComponent as Plus } from "assets/icons/Plus.svg";
import { ReactComponent as SearchIcon } from "assets/icons/Search.svg";
import CsInput from "components/Input";
import SelectCmp from "components/SelectCmp";
import CsTable from "components/Table";
import { useNavigate, useSearchParams } from "react-router-dom";
import * as S from "./style";

import { Button } from "antd";
import { DefaultOptionType } from "antd/es/select";
import {
  getAnnouncementCategoryApi,
  getListAnnouncementApi,
} from "api/announcement";
import dayjs from "dayjs";
import { useCallback, useEffect, useState } from "react";
import { debounce } from "utils/debounce";

const Client: React.FC = () => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [dataTable, setDateTable] = useState<any>([]);
  const [categories, setCategories] = useState<DefaultOptionType[]>([]);
  const [totalElement, setTotalElement] = useState<number>(0);
  const [typeId, setTypeId] = useState<number>();
  const [dateSort, setDateSort] = useState<"asc" | "desc">();
  const [dateSortString, setDateSortString] = useState<
    "ascend" | "descend" | undefined
  >();
  const [searchValue, setSearchValue] = useState("");

  const handleSearch = useCallback(
    debounce((value: string) => {
      setSearchValue(value);
    }, 500),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const columns: ColumnsType<any> = [
    {
      title: "No",
      dataIndex: "cl1",
      key: "cl1",
      render: (_, record) => <p className="question">{record.id}</p>,
    },
    {
      title: "작성일",
      dataIndex: "cl2",
      key: "cl2",
      render: (_, record) => <p className="category">{dayjs(record.createdTime).format("YYYY-MM-DD HH:mm")}</p>,
    },
    {
      title: "제목",
      dataIndex: "cl3",
      key: "cl3",
      render: (_, record) => (
        <p className="create-date">
          {record.title}
        </p>
      ),
      width: "60%",
    },
    {
      title: "공개여부",
      dataIndex: "cl4",
      key: "cl4",
      render: (_, record) => (
        <p
          className={`status ${
            record.status === "SHOWING" ? "active" : "inactive"
          }`}
        >
          {record.status === "SHOWING" ? "공개" : "비공개"}
        </p>
      ),
    },
    {
      title: <></>,
      dataIndex: "viewDetail",
      key: "viewDetail",
      render: (_, record) => (
        <S.ViewDetailBtn
          onClick={() =>
            navigate(`/customer-service/announcement/${record.id}`)
          }
        >
          <CaretRight />
        </S.ViewDetailBtn>
      ),
      width: 56,
    },
  ];

  const getListAnnouncement = async () => {
    try {
      const { data }: any = await getListAnnouncementApi({
        querySearch: searchValue.trim(),
        page: Number(searchParams.get("page") || 1),
        size: 10,
        typeId,
        createDateSort: dateSort,
      });
      if (data) {
        if (data?.datas) {
          setDateTable(data?.datas);
          setTotalElement(+data.pageable.totalElement);
        } else {
          setDateTable([]);
          setTotalElement(0);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getAnnouncementCategory = async () => {
    try {
      const { data }: any = await getAnnouncementCategoryApi();
      if (data) {
        const formatData: DefaultOptionType[] = data.map((item: any) => {
          const newItem: DefaultOptionType = {
            label: item.name,
            value: Number(item.id),
          };
          return newItem;
        });
        setCategories(formatData);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getListAnnouncement();
  }, [dateSort, searchParams, searchValue, typeId]);

  useEffect(() => {
    getAnnouncementCategory();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleTableChange = (_: any, __: any, sorter: any) => {
    const { order } = sorter;

    if (order !== dateSortString) {
      setDateSortString(order);
      setDateSort(
        order === "ascend" ? "asc" : order === "descend" ? "desc" : undefined
      );
      searchParams.set("page", "1");
      setSearchParams(searchParams);
    }
  };

  return (
    <S.TableWraper>
      <div className="filter-container">
        <CsInput
          placeholder="Search content"
          onChange={(e) => {
            const inputValue: string = e.target.value;
            const cleanedValue = inputValue.replace(/\s+/g, " ");
            if (cleanedValue !== " ") {
              setSearchValue(cleanedValue);
              handleSearch(cleanedValue);
            }
          }}
          value={searchValue}
          suffix={<SearchIcon />}
          style={{
            height: 60,
          }}
        />
        {/* <SelectCmp
          // defaultValue="inactive"
          style={{
            width: 180,
            height: 60,
          }}
          options={categories}
          placeholder="Category"
          onChange={(value) => {
            setTypeId(value);
            searchParams.set("page", "1");
            setSearchParams(searchParams);
          }}
        /> */}
        <Button
          className="btn-create"
          icon={<Plus />}
          onClick={() => navigate(`/customer-service/announcement/create`)}
          style={{minWidth: 150, display: "flex", justifyContent: "center"}}
        >
          Create
        </Button>
      </div>
      <CsTable
        className="center-table"
        onChange={handleTableChange}
        columns={columns}
        dataSource={dataTable}
        pagination={
          totalElement > 10
            ? {
                current: Number(searchParams.get("page")) || 1,
                total: totalElement,
                showSizeChanger: false,
                onChange: (page) => {
                  searchParams.set("page", page.toString());
                  setSearchParams(searchParams);
                },
              }
            : false
        }
      />
    </S.TableWraper>
  );
};

export default Client;
