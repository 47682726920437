import TabSimple, { TabItem } from "components/TabSimple";
import DetailInfo from "./DetailInfo";
import * as S from "./style";
import ContentTable from "../../Shares/ContentTable";
import PostTable from "../../Shares/PostTable";
import CommentTable from "../../Shares/CommentTable";

const tabItems: TabItem[] = [
  {
    id: "content",
    title: "멤버십 콘텐츠 조회 ",
    content: <ContentTable />,
  },
  {
    id: "post",
    title: "포스트 작성",
    content: <PostTable />,
  },
  {
    id: "comment",
    title: "댓글 작성",
    content: <CommentTable />,
  },
];

const NormalDetail: React.FC = () => {
  return (
    <S.Wrapper>
      <S.Heading>
        <h1>멤버십 회원 상세정보</h1>
      </S.Heading>
      <S.Content>
        <DetailInfo />
        <div className="underline" />
        <TabSimple defaultActive="content" items={tabItems} />
      </S.Content>
    </S.Wrapper>
  );
};

export default NormalDetail;
