import { styled } from "styled-components";

export const Wrap = styled.div`
    display: flex;
    flex:1;
    height: 100vh;
    justify-content: center;
    align-items: center;
    .login-container {
        display: flex;
        max-width: 1280px;
        max-height: 900px;
        gap:80px;
        background-color: var(--hint-color);
        &>div {
            width: 50%;
        }
        .form-login {
            display: flex;
            flex-direction: column;
            justify-content: center;
            padding-right: 40px;
            h2 {
                font-size: 48px;
                line-height: 56px;
                margin-top:40px;
            }
            form {
                margin-top: 40px;
                display: flex;
                flex-direction: column;
                max-width: 440px;
                .ant-form-item {
                    margin-bottom: 24px;
                }
            }
            .title-container {
                display: flex;
                align-items: center;
                gap:12px;
                p {
                    font-size: 18px;
                    font-style: normal;
                    font-family: var(--font-600);
                }
            }
        }
    }
`