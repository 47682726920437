// Trong components/Table.tsx
import { Form, Input } from "antd";
import { useNavigate, useSearchParams } from "react-router-dom";
import * as S from "./style";

import { useForm } from "antd/es/form/Form";
import { checkAuth } from "api/user";
import { ReactComponent as EmailIcon } from "assets/icons/Email.svg";
import { ReactComponent as LockKeyIcon } from "assets/icons/LockKey.svg";
import { ReactComponent as PhoneIcon } from "assets/icons/Phone.svg";
import { ReactComponent as UserIcon } from "assets/icons/Users-1.svg";
import { ReactComponent as BrandIdIcon } from "assets/icons/BrandIDIcon.svg";
import ButtonComp from "components/ButtonCmp";
import { useEffect, useState } from "react";
import { debounce } from "utils/debounce";

export interface AccountInfoStorage {
  email: string;
  password: string;
  manager: string;
  phone: string;
  userName: string;
}

interface Props {}

const CreateBrandAccountStep1: React.FC<Props> = ({}) => {
  // const location: any = useLocation();
  // console.log('location', location);

  const [searchParams, setSearchParams] = useSearchParams();
  const [userNameError, setUserNameError] = useState<boolean>(false);
  const [emailError, setEmailError] = useState<boolean>(false);
  // const [accountInfoStorage, setAccountInfoStorage] = useState<any>({});
  const [showPass, setShowPass] = useState<boolean>(false);
  const [searchValue, setSearchValue] = useState<string>("");
  const [form] = useForm();
  const navigate = useNavigate();

  const onReset = () => {
    form.resetFields();
    localStorage.removeItem("accountInfo");
    navigate("/users/brand-user");
  };

  const onFill = (e: any) => {
    console.log("e", e);
  };

  useEffect(() => {
    searchParams.set("step", "1");
    setSearchParams(searchParams);
  }, []);

  const onFinish = async (accountInfo: any) => {
    // console.log("Success:", accountInfo);
    try {
      const { error }: any = await checkAuth({
        userName: accountInfo.userName,
        email: accountInfo.email,
      });
      const data = error?.data;

      if (data?.email === false || data?.userName === false) {
        const errors = [];

        if (data?.email == false) {
          errors.push({
            name: "email",
            errors: ["Email already exists. Please choose another email"],
          });
        }

        if (data?.userName === false) {
          errors.push({
            name: "userName",
            errors: ["Username already exists. Please choose another username"],
          });
        }

        form.setFields(errors);
      } else {
        form.setFields([
          {
            name: "userName",
            errors: [],
          },
          {
            name: "email",
            errors: [],
          },
        ]);
        setUserNameError(false);
        setEmailError(false);

        searchParams.set("step", "2");
        setSearchParams(searchParams);
        localStorage.setItem("accountInfo", JSON.stringify(accountInfo));
      }
    } catch (error) {
      console.error(error);
    }
  };
  const onFinishFailed = (errorInfo: any) => {
    // console.log("Failed:", errorInfo);
  };

  useEffect(() => {
    const accountInfoStorage: AccountInfoStorage | null = JSON.parse(
      localStorage.getItem("accountInfo") || "null"
    );
    if (accountInfoStorage) {
      form.setFieldsValue({
        userName: accountInfoStorage.userName,
        email: accountInfoStorage.email,
        manager: accountInfoStorage.manager,
        password: accountInfoStorage.password,
        phone: accountInfoStorage.phone,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const validatePassword = (value: string) => {
    if (value) {
      if (value.length === 0) {
        return "패스워드를 입력해 주세요";
      }

      if (value.length < 8) {
        return "패스워드는 8자 이상, 대문자+특수문자+숫자가 1개 이상씩 혼합되어야 합니다";
      }

      const regex =
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]+$/;
      if (!regex.test(value)) {
        return "패스워드는 8자 이상, 대문자+특수문자+숫자가 1개 이상씩 혼합되어야 합니다";
      }

      if (value === form.getFieldValue("userName")) {
        return "Password must not be the same username";
      }
    } else {
      return "패스워드를 입력해 주세요";
    }

    return "";
  };

  const handlePasswordChange = debounce((password: string) => {
    form.setFieldsValue({
      password: password.trim().replace(/\s+/g, ""),
    });
    const error = validatePassword(password);
    // console.log(error);
    form.setFields([
      {
        name: "password",
        errors: error ? [error] : [],
      },
    ]);
  }, 500);

  return (
    <>
      <S.ContentHeader>
        <h1>브랜드 계정 생성 (1/2)</h1>
        <p>계정 정보</p>
      </S.ContentHeader>
      <S.ContentBody>
        <Form
          name="basic"
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
          form={form}
          onKeyPress={(e) => {
            if (e.key === "Enter") {
              e.preventDefault();
            }
          }}
        >
          <S.FieldContainer>
            <Form.Item
              name="userName"
              rules={[
                { required: true, message: "아이디를 입력해 주세요" },
                {
                  min: 3,
                  message: "3글자 이상이어야 합니다",
                },
                {
                  pattern: /^[a-zA-Z0-9\s-_]+$/,
                  message: "ID 생성은 알파벳, 숫자, -(하이픈), _(언더바)으로 가능합니다.",
                },
                {
                  validator: () => {
                    if (userNameError) {
                      return Promise.reject(
                        "Brand ID already exist. Please choose another brand ID"
                      );
                    }
                    return Promise.resolve();
                  },
                },
              ]}
            >
              <Input
                prefix={<BrandIdIcon />}
                placeholder="브랜드ID"
                className="form-input"
                onKeyDown={(e) => {
                  if (e.key === " ") {
                    e.preventDefault();
                  }
                }}
                onBlur={(e) =>
                  form.setFieldsValue({
                    userName: e.target.value.trim().replace(/\s+/g, ""),
                  })
                }
              />
            </Form.Item>

            <Form.Item
              name="email"
              rules={[
                {
                  required: true,
                  message: "메일 주소를 입력해 주세요",
                },
                {
                  type: "email",
                  message:
                    "유효한 이메일 주소를 입력해주세요 ex) 123@gmail.com",
                },
                {
                  validator: (_, value) => {
                    if (emailError) {
                      return Promise.reject(
                        "Email already exist. Please choose another username"
                      );
                    }
                    return Promise.resolve();
                  },
                },
              ]}
            >
              <Input
                prefix={<EmailIcon />}
                placeholder="담당자 메일 주소"
                className="form-input"
              />
            </Form.Item>

            <Form.Item
              name="manager"
              rules={[
                { required: true, message: "담당자 이름 입력해 주세요" },
                {
                  min: 3,
                  message: "3글자 이상이어야 합니다",
                },
              ]}
            >
              <Input
                prefix={<UserIcon />}
                placeholder="담당자 이름"
                className="form-input"
                // onKeyDown={(e) => {
                //   if (e.key === " ") {
                //     e.preventDefault();
                //   }
                // }}
                onBlur={(e) =>
                  form.setFieldsValue({
                    manager: e.target.value.trim(),
                  })
                }
              />
            </Form.Item>

            <Form.Item
              name="phone"
              rules={[
                { required: true, message: "휴대폰 번호를 입력해 주세요" },
                {
                  len: 13,
                  message: "휴대폰 번호 11자리를 입력해주세요",
                },
              ]}
            >
              <Input
                prefix={<PhoneIcon />}
                placeholder="담당자 휴대폰 번호"
                className="form-input"
                maxLength={13}
                onChange={(e) => {
                  const phoneNumber = e.target.value.replace(/\D/g, "");
                  let formattedPhoneValue = "";
                  for (let i = 0; i < phoneNumber.length; i++) {
                    if (i === 3 || i === 7) {
                      formattedPhoneValue += "-";
                    }
                    if (i < 11) {
                      formattedPhoneValue += phoneNumber[i];
                    }
                  }
                  form.setFieldValue("phone", formattedPhoneValue);
                }}
              />
            </Form.Item>

            <Form.Item
              name="password"
              rules={[
                // { required: true, message: "패스워드를 입력해 주세요" },
                {
                  validator: (_, value) => {
                    const error = validatePassword(value);
                    if (error) {
                      return Promise.reject(error);
                    }
                    return Promise.resolve();
                  },
                },
              ]}
            >
              <Input.Password
                prefix={<LockKeyIcon />}
                placeholder="패스워드"
                className="form-input"
                onChange={(e: React.FocusEvent<HTMLInputElement, Element>) =>
                  handlePasswordChange(e.target.value)
                }
              />
            </Form.Item>
          </S.FieldContainer>

          <S.ButtonContainer>
            <ButtonComp
              // htmlType="submit"
              onClick={onReset}
              status="noBackground"
            >
              취소
            </ButtonComp>
            <ButtonComp onClick={onFill} htmlType="submit">
              다음 단게
            </ButtonComp>
          </S.ButtonContainer>
        </Form>
      </S.ContentBody>
    </>
  );
};

export default CreateBrandAccountStep1;
