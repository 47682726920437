import { message, Select } from "antd";
// import ArrowDownIcon from "@assets/icons/community/expand_more.svg";
import { updateEmailReceiveApi } from "api/membership";
import dayjs from "dayjs";
import useWindowSize from "hooks/useWindowSize";
import { useParams } from "react-router-dom";
import * as S from "../style";

interface DetaildataProps {
  data?: any;
  getAllDetailAccount: () => Promise<void>;
}

const accMapping: any = {
  NAVER: "네이버",
  NORMAL: "이메일",
  KAKAO: "카카오톡",
  APPLE: "애플",
};

const Detaildata: React.FC<DetaildataProps> = ({
  data,
  getAllDetailAccount,
}) => {
  const { width } = useWindowSize();
  const { userId } = useParams<{ userId: string }>();

  const handleChangeEmaiLReceive = async (v: string) => {
    // console.log("v", v)
    if (!userId) return;
    const emailReceived: boolean = v === "1" ? true : false;
    const { data, error }: any = await updateEmailReceiveApi({
      userId: +userId,
      emailReceived,
    });
    if (error) {
      console.error(error);
      message.error("Error updating email");
      return;
    }
    message.success("Successfully updated");
    getAllDetailAccount();
  };

  return (
    <S.WrapField>
      <div className="field-item">
        <label>이름</label>
        <div>{data?.displayName}</div>
      </div>
      <div className="field-item">
        <label>생년월일</label>
        <div>{dayjs(data?.birthDate).format("YYYY-MM-DD")}</div>
      </div>
      <div className="field-item">
        <label>성별</label>
        <div>{data?.gender === "MALE" ? "남성" : "여성"}</div>
      </div>
      <div className="field-item">
        <label>연락처</label>
        <div>{data?.verifiedPhoneNumber}</div>
      </div>
      <div className="field-item">
        <label>이메일</label>
        <div>{data?.email}</div>
      </div>
      <div className="field-item">
        <label>회원가입일</label>
        <div>{dayjs(data?.registeredAt).format("YYYY-MM-DD HH:mm")}</div>
      </div>
      <div className="field-item">
        <label>가입경로</label>
        <div>{accMapping[data?.accountType]}</div>
      </div>
      <div className="field-item">
        <label>최근방문일</label>
        <div>{dayjs(data?.lastLogin).format("YYYY-MM-DD HH:mm")}</div>
      </div>
      <div className="field-item">
        <label>마케팅 수신 여부</label>
        <div className="wr-select">
          <Select
            popupClassName="custom-select-dropdown"
            value={data?.emailReceive ? "1" : "0"}
            // value={sort}
            style={{
              width: "100%",
              maxWidth: width && width > 767 ? "220px" : "100%",
              height: 48,
            }}
            onChange={handleChangeEmaiLReceive}
            // suffixIcon={
            //   <div>
            //     <ArrowDownIcon />
            //   </div>
            // }
            options={[
              { value: "1", label: "동의" },
              { value: "0", label: "비동의" },
            ]}
          />
        </div>
      </div>
    </S.WrapField>
  );
};

export default Detaildata;
