import { Col, Modal, Row, message } from "antd";
import { getOrderDetailApi, updateStatusOrderApi } from "api/groupBuying";
import { ReactComponent as Campaign } from "assets/icons/Campaign.svg";
import { ReactComponent as WarningIcon } from "assets/icons/WarningCircle.svg";
import ButtonComp from "components/ButtonCmp";
import dayjs from "dayjs";
import isBetween from "dayjs/plugin/isBetween";
import MainHeader from "layouts/components/Header";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { MoneyConvert } from "utils/format";
import { renderStatusColor } from "utils/statusRenderColor";
import noImage from "assets/images/no-image.png";
import * as S from "./style";
import { StatusType } from "model/groupBuying";
import { ReactComponent as KakaoIcon } from "assets/icons/kakao.svg";
import { ReactComponent as AppleIcon } from "assets/icons/apple.svg";
import { ReactComponent as NaverIcon } from "assets/icons/naver.svg";
import { accountTypeMapping } from "pages/UserManagement/ClientUser/DeleteAccountDetail";
import React from "react";
dayjs.extend(isBetween);

const statusMapping: any = {
  PAID: "결제완료",
  OWNED: "보유중",
  REQUESTED: "환불 요청",
  REFUNDED: "환불 완료",
  WAITING: "입금대기",
};

const iconSocialMapping: any = {
  NAVER: <NaverIcon className="big-icon" />,
  NORMAL: <React.Fragment />,
  KAKAO: <KakaoIcon className="big-icon" />,
  APPLE: <AppleIcon className="big-icon" />,
};

const OrderDetail: React.FC = () => {
  const [messageApi, contextHolder] = message.useMessage({ maxCount: 2 });
  const navigate = useNavigate();
  const { orderId } = useParams();
  const [detailData, setDetailData] = useState<any>({});
  const [isOpenModal, setIsOpenModal] = useState<boolean>(false);
  const [returnErr, setReturnErr] = useState<boolean>(false);

  const getOderDetail = async () => {
    try {
      if (orderId) {
        const { data }: any = await getOrderDetailApi({ id: +orderId });
        if (data) {
          setDetailData(data);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const errorMess = (message: string) => {
    messageApi.open({
      type: "error",
      content: message,
    });
  };

  useEffect(() => {
    if (orderId) {
      getOderDetail();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderId]);

  const handleUpdateOrderStatus = async (status: StatusType) => {
    if (orderId) {
      const { data, error }: any = await updateStatusOrderApi({
        id: +orderId,
        status,
        isBanking: detailData?.paymentMethod === "BANKING" ? true : undefined,
      });

      if (error) {
        setReturnErr(true);
        setIsOpenModal(false);
        return;
      }
      setReturnErr(false);
      getOderDetail();
    }
  };

  const handleApprove = async () => {
    handleUpdateOrderStatus("REFUNDED");
  };

  const handleConfirmPayment = async () => {
    handleUpdateOrderStatus("PAID");
  };

  const handleReject = async () => {
    const isBetween = dayjs().isBetween(
      detailData.startTime,
      detailData.endTime,
      null,
      "[]"
    );
    handleUpdateOrderStatus(isBetween ? "PAID" : "OWNED");
  };

  return (
    <S.Wrapper>
      {contextHolder}
      <MainHeader
        backLink="/group-buying/order"
        title="주문 내역 상세"
        // rightBtn={
        //   <ButtonComp
        //     style={{ padding: "0 30px" }}
        //     icon={<PencilIcon />}
        //     onClick={() => navigate(`/group-buying/order/${orderId}`)}
        //   >
        //     Edit campaign
        //   </ButtonComp>
        // }
        breadCrumb={[
          {
            title: "주문 내역",
            link: "/group-buying/order",
            icon: <Campaign />,
          },
          {
            title: "주문 내역 상세",
          },
        ]}
      />
      <S.Content>
        <Row gutter={70}>
          <Col className="gutter-row" span={24}>
            <S.Block className="flex-center space-between flex-row">
              <div className="order-info">
                <p className="order-no">
                  주문 No.#{detailData?.id} | 결제 날짜:{" "}
                  {dayjs(detailData?.createdTime).format("YYYY/MM/DD HH:mm")}
                </p>
                <S.Status
                  style={{
                    backgroundColor: `${renderStatusColor(detailData?.status)}`,
                  }}
                >
                  {statusMapping[detailData?.status]}
                </S.Status>
                <p className="create-at">
                  프리미엄 멤버십 종료날짜{" "}
                  {dayjs(detailData?.expiredTime)
                    .subtract(1, "minutes")
                    .format("YYYY/MM/DD HH:mm")}
                </p>
              </div>
              {detailData.status === "REQUESTED" && (
                <div className="grp-btn">
                  <ButtonComp
                    className="btn-approve"
                    status="noBackground"
                    onClick={() => setIsOpenModal(true)}
                  >
                    환불 승인
                  </ButtonComp>
                  <ButtonComp className="btn-reject" onClick={handleReject}>
                    환불 거절
                  </ButtonComp>
                </div>
              )}
              {detailData.status === "WAITING" && (
                <div className="grp-btn has-text">
                  <p>
                    입금 완료를 확인하셨습니까?
                    <br />
                    확인 버튼을 누르시면 주문 상태가 입금대기에서 결제완료로
                    변경할 것입니다.
                  </p>
                  <ButtonComp
                    className="btn-reject"
                    onClick={handleConfirmPayment}
                  >
                    확인
                  </ButtonComp>
                </div>
              )}
            </S.Block>
          </Col>
          <Col
            className="gutter-row"
            span={detailData.status === "REQUESTED" ? 8 : 24}
          >
            <S.Block className="flex-center flex flex-row justify-between">
              <div className="info-left">
                <img
                  className="voucher-logo"
                  src={detailData?.voucherImage || noImage}
                  alt="voucherLogo"
                  width={80}
                  height={80}
                />
                <div className="voucher-info">
                  <p className="voucher-name">{detailData?.voucherName}</p>
                  <p className="expire">
                    {dayjs(detailData?.startTime).format("YYYY/MM/DD HH:mm")} -{" "}
                    {dayjs(detailData?.endTime).format("YYYY/MM/DD HH:mm")}
                  </p>
                </div>
              </div>
              <div className="info-user-bank">
                {detailData.bankAccountName && (
                  <div>
                    <h4>입금 정보</h4>
                    <div className="line">
                      <label>입금자명</label>
                      <span>{detailData?.bankAccountName}</span>
                    </div>
                    {/* <div className="line">
                      <label>은행명</label>
                      <span>{detailData?.bankName}</span>
                    </div>
                    <div className="line">
                      <label>계좌번호</label>
                      <span>{detailData?.bankAccountNumber}</span>
                    </div> */}
                  </div>
                )}
                {detailData?.bill && (
                  <a target="_blank" href={detailData?.bill || ""} title="bill">
                    Transfer Bill
                  </a>
                )}
              </div>
            </S.Block>
            <S.Block>
              <div className="user-info">
                <img
                  className="avt"
                  src={detailData?.avatar || noImage}
                  alt="avt"
                  width={24}
                  height={24}
                />
                <p className="user-name">{detailData?.displayName}</p>
              </div>
              <p className="user-phone">{detailData?.phoneNumber}</p>
              <div
                className="user-email"
                style={{ display: "flex", alignItems: "center", gap: 10 }}
              >
                <div>{detailData?.email}</div> <div>-</div>
                <div style={{ display: "flex", alignItems: "center", gap: 6 }}>
                  {accountTypeMapping[detailData.accountType]}
                </div>
              </div>
            </S.Block>

            {detailData?.type === "GIFT" && (
              <S.Block>
                <S.Title>선물받는 분 정보</S.Title>
                <S.FLexLine>
                  <p className="label">성함</p>
                  <p className="value">{detailData?.receiverName} </p>
                </S.FLexLine>
                <S.FLexLine>
                  <p className="label">연락처</p>
                  <p className="value">{detailData?.receiverPhoneNumber}</p>
                </S.FLexLine>
                <S.FLexLine>
                  <p className="label"></p>
                  {detailData?.isReceived ? (
                    <div
                      style={{ display: "flex", alignItems: "center", gap: 6 }}
                    >
                      <div>
                        {iconSocialMapping[detailData?.receiver?.accountType]}
                      </div>
                      <div>{detailData?.receiver?.email}</div>
                    </div>
                  ) : (
                    "미수령"
                  )}
                </S.FLexLine>
              </S.Block>
            )}

            <S.Block className="no-pd">
              <div className="list">
                <S.Title>주문 내역</S.Title>
                <S.FLexLine>
                  <p className="label">구매 수량</p>
                  <p className="value">{detailData?.quantity}개</p>
                </S.FLexLine>
                <S.FLexLine>
                  <p className="label">판매 단가</p>
                  <p className="value">
                    {" "}
                    x {MoneyConvert(detailData.voucherPrice)}원
                  </p>
                </S.FLexLine>
                <S.FLexLine>
                  <p className="label">총 결제액</p>
                  <p className="value color-blue">
                    {MoneyConvert(detailData.totalValue)}원
                  </p>
                </S.FLexLine>
                {/* <S.FLexLine>
                  <p className="label">Redeem points</p>
                  <p className="value color-red">- 50,000 W</p>
                </S.FLexLine>
                <S.FLexLine>
                  <p className="label">Yeolmae coupon</p>
                  <p className="value color-red">- 230,000 W</p>
                </S.FLexLine>
                <S.FLexLine>
                  <p className="label">Brand coupon</p>
                  <p className="value color-red">- 170,000 W</p>
                </S.FLexLine> */}
              </div>
              <div className="total">
                <S.FLexLine className="total-info">
                  <p className="label">총 결제 금액</p>
                  <p className="value">
                    {MoneyConvert(detailData.totalValue)}원
                  </p>
                </S.FLexLine>
              </div>
            </S.Block>
          </Col>
          {detailData.status === "REQUESTED" && (
            <Col className="gutter-row" span={16}>
              <S.Block className="no-pd">
                <S.RefundSummary>
                  <S.Title className="white">환불 내역</S.Title>
                  <div className="summary-info">
                    <p className="label">총 결제액</p>
                    <p className="value">
                      {MoneyConvert(detailData?.totalReceive)}원
                    </p>
                  </div>
                </S.RefundSummary>
                <S.RefundDetail>
                  <S.Title>환불 세부정보</S.Title>
                  <S.FLexLine>
                    <p className="label">결제 금액</p>
                    <p className="value color-blue">
                      {MoneyConvert(detailData?.totalValue)}원
                    </p>
                  </S.FLexLine>
                  <S.FLexLine>
                    <p className="label">환불 수수료</p>
                    <p className="value color-red">
                      - {MoneyConvert(detailData?.refundFee)}원
                    </p>
                  </S.FLexLine>
                  <S.FLexLine>
                    {/*className="has-border" */}
                    <p className="label">총 환불액</p>
                    <p className="value color-blue">
                      {MoneyConvert(detailData?.totalRefund)}원
                    </p>
                  </S.FLexLine>
                  {/* <S.FLexLine>
                    <p className="label">Refund method</p>
                    <p className="value">Kakaopay</p>
                  </S.FLexLine>
                  <S.FLexLine>
                    <p className="label">Fee via payment gateway</p>
                    <p className="value color-red">- 15,000 W</p>
                  </S.FLexLine> */}
                  <S.FLexLine className="has-border-top">
                    <p className="label">총 결제액</p>
                    <p className="value color-blue">
                      {MoneyConvert(detailData?.totalReceive)}원
                    </p>
                  </S.FLexLine>
                </S.RefundDetail>
              </S.Block>
            </Col>
          )}
        </Row>
      </S.Content>
      <Modal
        width={460}
        open={isOpenModal}
        footer={false}
        onCancel={() => setIsOpenModal(false)}
      >
        <S.ModalContent>
          <WarningIcon />
          <p>#{orderId} 결제를 환불하시겠습니까?</p>
          <S.ModalBtn>
            <ButtonComp
              className="approve"
              status="noBackground"
              onClick={handleApprove}
            >
              네
            </ButtonComp>
            <ButtonComp
              className="cancel"
              onClick={() => setIsOpenModal(false)}
            >
              취소
            </ButtonComp>
          </S.ModalBtn>
        </S.ModalContent>
      </Modal>

      <Modal
        width={460}
        open={returnErr}
        footer={false}
        onCancel={() => setReturnErr(false)}
      >
        <S.ModalContent>
          <WarningIcon />
          <p>Refund failed</p>
          <S.ModalBtn className="one">
            <ButtonComp
              className="cancel"
              status="noBackground"
              onClick={() => setReturnErr(false)}
            >
              Cancel
            </ButtonComp>
          </S.ModalBtn>
        </S.ModalContent>
      </Modal>
    </S.Wrapper>
  );
};

export default OrderDetail;
