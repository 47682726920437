import { message, Modal, ModalProps } from "antd";
import { ReactComponent as CloseIcon } from "assets/icons/membership/close.svg";
import * as S from "../style";
import Checkbox from "components/Checkbox";
import { useState } from "react";
import { formatNumberWithCommas, MoneyConvert } from "utils/format";
import dayjs from "dayjs";
import { getMembershipRefundApi } from "api/membership";
interface Props extends ModalProps {
  handleClose: () => void;
  refundSuccess?: () => void;
  data?: any;
}

const RefundDetailModal: React.FC<Props> = ({
  open,
  data,
  handleClose,
  refundSuccess,
}) => {
  const [agree, setAgree] = useState<boolean>(false);
  const [amountCancel, setAmountCancel] = useState<string>();
  const [validate, setValidate] = useState<any>({
    required: false,
    maxAmount: false,
  });

  const handleSubmit = async () => {
    // Validate required
    const required = !amountCancel || amountCancel === "";

    // Validate maxAmount
    const amountNumber = amountCancel
      ? parseFloat(amountCancel.replace(/,/g, ""))
      : 0;
    const maxAmount = amountNumber > Number(data?.amount);

    // Update validate state
    setValidate({
      required: required,
      maxAmount: maxAmount,
    });

    // If both required and maxAmount are false, perform handleOk
    if (!required && !maxAmount) {
      // handleOk?.();
      const resp: any = await getMembershipRefundApi({
        id: data?.id,
        amount: Number(amountCancel.replace(/,/g, "")),
      });
      if (resp.result) {
        message.success("Refund Successfully");
        refundSuccess?.();
        handleClose();
      } else {
        console.log(resp);
        message.error("Refund Failed");
      }
    }
  };

  const handleAmountChange = (value: string) => {
    setAmountCancel(formatNumberWithCommas(value));

    // Validate required
    setValidate((prevState: any) => ({
      ...prevState,
      required: value === "",
    }));

    // Validate maxAmount
    const amountNumber = parseFloat(value.replace(/,/g, ""));
    setValidate((prevState: any) => ({
      ...prevState,
      maxAmount: amountNumber > Number(data?.amount),
    }));
  };

  return (
    <Modal
      onCancel={handleClose}
      width={450}
      closeIcon={null}
      open={open}
      // open={true}
      footer={null}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          cursor: "pointer",
        }}
        onClick={handleClose}
      >
        <h2>관리자 환불</h2>
        <CloseIcon />
      </div>
      <br />
      <hr />
      <br />
      <S.RefundDetailModalContent>
        <div className="line">
          <div className="left">결제금액</div>
          <div className="right">{MoneyConvert(data?.amount, true)}원</div>
        </div>
        <div className="line">
          <div className="left">결제일</div>
          <div className="right">
            {dayjs(data?.createdTime).format("YYYY.MM.DD")}
          </div>
        </div>
        <div className="line">
          <div className="left">결제취소일</div>
          <div className="right">{dayjs().format("YYYY.MM.DD")}</div>
        </div>
        <div className="line">
          <div className="left">멤버십 적용기간</div>
          <div className="right">
            {" "}
            {Math.ceil(
              Math.abs(dayjs(data?.createdTime).diff(dayjs(), "days", true))
            )}
            일
          </div>
        </div>
        <div className="line">
          <div className="left">멤버십 이용기록</div>
          <div className="right" style={{ color: "#D00000" }}>
            {data?.used ? "기록있음" : "기록 없음"}
          </div>
        </div>
        <div className="line">
          <div className="left" style={{ fontFamily: "Pretendard-700" }}>
            환불 예정 금액
          </div>
          <div className="right" style={{ fontFamily: "Pretendard-700" }}>
            {MoneyConvert(data?.refundAmount, true)}원
          </div>
        </div>
        <div className="underline" />
        <div className="input-text-top">취소할 금액</div>
        <div className="wr-input">
          <input
            value={amountCancel}
            onChange={(e) => handleAmountChange(e.target.value)}
          />
          <div>원</div>
        </div>
        {/* Error messages */}
        {validate.required && (
          <div style={{ color: "red" }}>Please enter a cancel amount</div>
        )}
        {validate.maxAmount && (
          <div style={{ color: "red" }}>
            The cancel amount should not exceed{" "}
            {MoneyConvert(data?.amount, true)}원
          </div>
        )}
        <div className="wr-checkbox">
          <Checkbox
            onChange={(checked) => setAgree(checked)}
            label="브랜드와 협의된 환불 진행입니다. "
          />
        </div>
        <div className="wr-btn">
          <button
            onClick={handleSubmit}
            className={!agree ? "disabled" : ""}
            disabled={!agree}
          >
            환불하기
          </button>
        </div>
      </S.RefundDetailModalContent>
      <br />
    </Modal>
  );
};
export default RefundDetailModal;
