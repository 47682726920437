import { styled } from "styled-components";
export const Wrapper = styled.div`
  padding-bottom: 50px;
  .no-data {
    height: 308px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #ededed;
    color: #9d9d9d;
  }
`;
export const Heading = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  padding: 100px 30px 0 30px;
  gap: 10px;
  background-color: var(--light-color);
`;
export const Content = styled.div`
  padding: 30px 30px 0 30px;
  background-color: #fff;
  .underline {
    width: 100%;
    height: 10px;
    background-color: #f5f5f5;
    display: block;
    margin: 50px 0;
  }
`;
export const Body = styled.div`
  max-width: 1095px;
  margin: auto;
  width: 100%;
`;
export const Card = styled.div`
  padding: 50px;
  background-color: #fff;
  border-radius: 20px;
  margin: 30px auto;
  > h3 {
    font-size: 24px;
    font-family: var(--font-500);
    margin-bottom: 16px;
    color: #000;
  }
`;
export const WrapField = styled.div`
  max-width: 940px;
  display: grid;
  @media (min-width: 768px) {
    grid-gap: 32px 20px;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(4, auto);
    grid-template-areas:
      "f0 f1 f2"
      "f3 f4 f4"
      "f5 f6 f7"
      "f8 . .";
  }
  .field-item {
    label {
      font-size: 16px;
      line-height: 19.09px;
      margin-bottom: 12px;
      color: #2d2d2d;
      font-family: "Pretendard-600";
      display: block;
    }
    > div {
      &:not(.wr-select) {
        font-size: 15px;
        line-height: 15px;
        margin-bottom: 12px;
        color: #2d2d2d;
        font-family: "Pretendard-500";
        height: 48px;
        border-radius: 50px;
        background-color: #f9f9f9;
        border: 1px solid #ededed;
        padding: 0 16px;
        display: flex;
        align-items: center;
      }
      &.has-label {
        display: flex;
        gap: 12px;
        > span {
          display: block;
          font-size: 14px;
          line-height: 18px;
          color: #15a0a1;
          font-family: "Pretendard-500";
          height: 18px;
          padding: 0 4px;
          background-color: #eafcfc;
        }
      }
    }
    &:nth-child(1) {
      grid-area: f0;
    }
    &:nth-child(2) {
      grid-area: f1;
    }
    &:nth-child(3) {
      grid-area: f2;
    }
    &:nth-child(4) {
      grid-area: f3;
    }
    &:nth-child(5) {
      grid-area: f4;
    }
    &:nth-child(6) {
      grid-area: f5;
    }
    &:nth-child(7) {
      grid-area: f6;
    }
    &:nth-child(8) {
      grid-area: f7;
    }
    &:nth-child(9) {
      grid-area: f8;
    }
  }
  .ant-select-single {
    .ant-select-selector {
      height: 48px;
      border-radius: 50px;
      background-color: #fff;
      display: flex;
      align-items: center;
      border: 1px solid #ededed;
    }
  }
`;

export const BrandJoin = styled.div``;
export const UnExpired = styled.div``;
export const Expired = styled.div``;

export const BrandJoinInner = styled.div`
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 30px 20px;
  max-height: 310px;
  overflow-y: auto;
  height: auto;
  .membership-item {
    display: flex;
    gap: 10px;
    h2 {
      font-family: var(--font-700);
    }
    .label {
      font-size: 13px;
      font-family: var(--font-500);
      line-height: 13px;
      padding: 2px 4px;
      color: #15a0a1;
      background-color: #eafcfc;
      display: block;
      margin: 2px 0 4px 0;
      text-align: center;
      width: fit-content;
    }
    ul {
      display: block;
      margin-bottom: 5px;
    }
    li {
      font-size: 14px;
      width: 100%;
    }
    .show-hidden-btn {
      font-size: 13px;
      width: 100%;
      text-align: left;
      color: #767575;
      > span {
        display: flex;
        align-items: baseline;
        gap: 4px;
      }
    }
  }
  .wr-image {
    width: 50px;
    img {
      border: 1px solid #ededed;
      border-radius: 50%;
    }
  }
  .wr-text {
    width: calc(100% - 50px);
    text-align: left;
  }
`;

export const Subscribed = styled.div`
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 30px 20px;
  max-height: 310px;
  overflow-y: auto;
  height: auto;
  .subscribed-item {
    display: flex;
    gap: 10px;
    align-items: center;
  }
  img {
    width: 50px;
    border-radius: 50%;
    border: 1px solid #ededed;
  }
  .brand-name {
    width: calc(100% - 50px);
    text-align: left;
  }
`;
export const OrderHistory = styled.div`
  position: relative;
  .btn-add {
    position: absolute;
    top: 4px;
    right: 0;
    padding: 4px 16px;
    background: #f2f2eb;
    border-radius: 4px;
    font-family: var(--font-600);
  }
`;
