import { LoadingOutlined } from "@ant-design/icons";
import { Button, Form, Modal, Radio, message } from "antd";
import { NoticeType } from "antd/es/message/interface";
import { DefaultOptionType } from "antd/es/select";
import {
  createAnnouncementCategoryApi,
  deleteAnnouncementDetailApi,
  getAnnouncementCategoryApi,
  getAnnouncementDetailApi,
  updateAnnouncementApi,
} from "api/announcement";
import { ReactComponent as Close } from "assets/icons/Close.svg";
import { ReactComponent as FilePlus } from "assets/icons/FilePlus.svg";
import { ReactComponent as Trash } from "assets/icons/Trash.svg";
import Editor from "components/Editor";
import HashTagInput from "components/HashTagInput";
import CsInput from "components/Input";
import SelectCmp from "components/SelectCmp";
import MainHeader from "layouts/components/Header";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Editor as TinyMCEEditor } from "tinymce";
import { uploadFileFirst } from "utils/uploadImage";
import { statusOptions } from "../mockData";
import * as S from "./style";

const AnnouncementEdit: React.FC = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [formCreateType] = Form.useForm();
  const [messageApi, contextHolder] = message.useMessage({ maxCount: 2 });
  const [initData, setInitData] = useState<any>({});
  const [isOpenDeleteModal, setIsOpenDeleteModal] = useState<boolean>(false);
  const [isOpenResultModal, setIsOpenResultModal] = useState<boolean>(false);
  const [deleteSuccess, setDeleteSuccess] = useState<boolean>(false);
  const [categories, setCategories] = useState<DefaultOptionType[]>();
  const [isOpenModal, setIsOpenModal] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [lenghtDes, setLenghDes] = useState<number>(0);

  const showMess = (type: NoticeType, mess: string) => {
    messageApi.open({
      type: type,
      content: mess,
    });
  };
  const handleTagsChange = (newTags: string[]) => {
    // console.log(newTags);

    form.setFieldValue("hashtag", newTags);
  };

  const getAnnouncementDetail = async () => {
    try {
      if (id) {
        const { data }: any = await getAnnouncementDetailApi({ id: +id });
        if (data) {
          setInitData(data);
          form.setFieldsValue({
            category: data?.typeId,
            title: data?.title,
            status: data?.status,
            description: data?.description,
            hashtag: data?.hashtag ? data.hashtag.split(",") : [],
          });
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (id) {
      getAnnouncementDetail();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const setTextSearch = (value: string) => {
    // console.log(value);
  };
  const onFinish = async (values: any) => {
    // console.log("Success:", values);
    setLoading(true);
    if (id) {
      try {
        let resultUpload = await uploadFileFirst(values.description);
        const { data, error }: any = await updateAnnouncementApi({
          id: +id,
          // typeId: values.category,
          status: values.status,
          title: values.title,
          description: resultUpload.html,
          // hashtag: values.hashtag.length > 0 ? values.hashtag : undefined,
        });

        if (error) {
          if (error?.errorCode === "OUT_OF_RANGE") {
            form.setFields([
              {
                name: "description",
                errors: ["Please enter max 10000 characters"],
              },
            ]);
            setLoading(false);
            return;
          }
          showMess("error", error.message);
        }
        if (data) {
          navigate(`/customer-service/announcement/${id}`);
        }

        setLoading(false);
      } catch (err) {
        console.error(err);
        setLoading(false);
      }
    }
  };

  const onReset = () => {
    form.resetFields();
    navigate(`/customer-service/announcement/${id}`);
  };

  const onFill = () => {
    // formRef.current?.setFieldsValue({ note: "Hello world!", gender: "male" });
    // console.log("123123");
  };

  const handleChangeContent = async (
    contentHtml: string,
    editor: TinyMCEEditor
  ) => {
    const textContent = editor.getContent({ format: "text" });
    const length = textContent === "\n" ? 0 : textContent.length;
    setLenghDes(length);

    if (length === 0) {
      form.setFields([
        {
          name: "description",
          errors: ["This field is required"],
        },
      ]);
    } else if (length < 3) {
      form.setFields([
        {
          name: "description",
          errors: ["세글자 이상 입력해 주세요"],
        },
      ]);
    } else {
      form.setFieldsValue({
        description: contentHtml,
      });
    }
  };

  const handleDelete = async () => {
    setIsOpenDeleteModal(false);
    setIsOpenResultModal(true);
    try {
      if (id) {
        const resp: any = await deleteAnnouncementDetailApi({ id: +id });
        if (resp.result) {
          setDeleteSuccess(true);
        } else {
          setDeleteSuccess(false);
        }
      }
    } catch (error) {
      console.log(error);
      setDeleteSuccess(false);
    }
  };

  const handleCreateCategory = async (values: any) => {
    // console.log("values", values);
    try {
      const { data, error }: any = await createAnnouncementCategoryApi({
        name: values.typeName,
      });
      if (error) {
        showMess("error", error.message);
        setIsOpenModal(false);
        formCreateType.resetFields();
        return;
      }
      if (data) {
        getAllCategory();
        showMess("success", "Create category successfully");
        setIsOpenModal(false);
        formCreateType.resetFields();
      }
    } catch (error) {
      console.error(error);
      setIsOpenModal(false);
      formCreateType.resetFields();
    }
  };

  const getAllCategory = async () => {
    try {
      const { data }: any = await getAnnouncementCategoryApi();
      const formatData: DefaultOptionType[] = data.map((item: any) => {
        const newItem: DefaultOptionType = {
          label: item.name,
          value: Number(item.id),
        };
        return newItem;
      });
      setCategories(formatData);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getAllCategory();
  }, []);

  return (
    <S.Wrapper>
      {contextHolder}
      <MainHeader
        backLink="/customer-service/announcement"
        title="Edit Announcement"
      />
      <Form
        onFinish={onFinish}
        autoComplete="off"
        layout="vertical"
        className="form-content"
        form={form}
      >
        <S.Block>
          <Form.Item>
            {/* <S.WrChooseCate
              style={{ display: "inline-block", width: "calc(50% - 15px)" }}
            >
              <Form.Item
                name="category"
                label="Choose category"
                rules={[{ required: true, message: "This field is require" }]}
              >
                <SelectCmp
                  // defaultValue="inactive"
                  style={{
                    width: "100%",
                    height: 48,
                  }}
                  options={categories}
                  placeholder="Choose category"
                />
              </Form.Item>
              <S.AddCate onClick={() => setIsOpenModal(true)}>
                Add category
              </S.AddCate>
            </S.WrChooseCate> */}
            <Form.Item
              name="status"
              label="공개여부"
              style={{
                display: "inline-block",
                width: "calc(50% - 15px)",
                margin: "0 0 0 30px",
              }}
            >
              <Radio.Group size="large">
                {statusOptions?.map((item, index) => (
                  <Radio key={index} value={item.value}>
                    {item.label}
                  </Radio>
                ))}
              </Radio.Group>
            </Form.Item>
          </Form.Item>
        </S.Block>
        <S.Block>
          <Form.Item
            name="title"
            label="제목"
            rules={[
              { required: true, message: "This field is require" },
              {
                min: 3,
                message: "세글자 이상 입력해 주세요",
              },
            ]}
          >
            <CsInput
              // defaultValue="inactive"
              style={{
                width: "100%",
                height: 48,
              }}
              placeholder="Enter Title"
              onChange={(e) => setTextSearch(e.target.value)}
              onBlur={(e) =>
                form.setFieldsValue({
                  title: e.target.value.trim().replace(/\s+/g, " "),
                })
              }
            />
          </Form.Item>
          <Form.Item
            name="description"
            label="Description"
            rules={[
              {
                validator: () => {
                  if (lenghtDes === 0) {
                    return Promise.reject("This field is required!");
                  } else if (lenghtDes < 3) {
                    return Promise.reject("세글자 이상 입력해 주세요");
                  } else {
                    return Promise.resolve();
                  }
                },
              },
            ]}
          >
            <Editor
              data={initData.description}
              onChangeValue={(e, editor) => handleChangeContent(e, editor)}
            />
          </Form.Item>
          {/* <Form.Item name="hashtag" label="Hashtag">
            <HashTagInput
              tags={form.getFieldValue("hashtag")}
              onTagsChange={handleTagsChange}
              // onCloseTags={onCloseTags}
            />
          </Form.Item> */}
        </S.Block>
        <Form.Item className="group-btn">
          <Button
            onClick={() => setIsOpenDeleteModal(true)}
            className="btn btn-delete"
            icon={<Trash />}
          >
            Delete
          </Button>
          <Button
            onClick={onReset}
            htmlType="submit"
            className="btn btn-cancel"
            icon={<Close />}
          >
            Cancel
          </Button>
          {loading ? (
            <Button style={{ width: "220px" }}>
              <LoadingOutlined />
            </Button>
          ) : (
            <Button
              onClick={onFill}
              htmlType="submit"
              className="btn btn-create"
              icon={<FilePlus />}
            >
              Save
            </Button>
          )}
        </Form.Item>
      </Form>
      <Modal
        open={isOpenResultModal}
        onCancel={() => setIsOpenDeleteModal(false)}
        footer={false}
      >
        <S.ModalContent>
          {deleteSuccess ? <p>Delete successfully</p> : <p>Delete failed</p>}
          <S.ModalBtn>
            <Button
              style={{
                backgroundColor: "#526557",
                borderColor: "#526557",
                color: "#fff",
                minWidth: "100px",
              }}
              onClick={() => {
                setIsOpenResultModal(false);
                navigate("/customer-service/announcement");
              }}
              className="cancel-btn"
            >
              OK
            </Button>
          </S.ModalBtn>
        </S.ModalContent>
      </Modal>
      <Modal
        title="Delete Announcement"
        open={isOpenDeleteModal}
        onCancel={() => setIsOpenDeleteModal(false)}
        footer={false}
      >
        <S.ModalContent>
          <p>Are you sure you want to delete?</p>
          <S.ModalBtn>
            <Button
              onClick={() => setIsOpenDeleteModal(false)}
              className="cancel-btn"
            >
              Cancel
            </Button>
            <Button onClick={handleDelete} className="delete-btn">
              Delete
            </Button>
          </S.ModalBtn>
        </S.ModalContent>
      </Modal>

      <Modal
        title="Create category"
        open={isOpenModal}
        onCancel={() => setIsOpenModal(false)}
        footer={false}
      >
        <S.ModalContent>
          <Form
            name="createType"
            onFinish={handleCreateCategory}
            autoComplete="off"
            form={formCreateType}
          >
            <Form.Item
              name="typeName"
              rules={[{ required: true, message: "This field is require" }]}
            >
              <CsInput
                style={{ height: "48px" }}
                placeholder="Enter category name"
              />
            </Form.Item>
            <S.ModalBtn>
              <Button
                onClick={() => setIsOpenModal(false)}
                className="cancel-btn"
              >
                Cancel
              </Button>
              <Button htmlType="submit" className="create-cate-btn">
                Create
              </Button>
            </S.ModalBtn>
          </Form>
        </S.ModalContent>
      </Modal>
    </S.Wrapper>
  );
};

export default AnnouncementEdit;
