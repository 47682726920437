import React, { useEffect, useState } from "react";
import * as S from "./style";
import MonthPicker from "./MonthPicker";
import { ReactComponent as ArrowUpIcon } from "assets/icons/settlement/arrow_upward_alt.svg";
import { ReactComponent as ArrowDownIcon } from "assets/icons/settlement/arrow_downward_alt.svg";
import RadioComp from "components/Radio";
import { CloseOutlined } from "@ant-design/icons";
import { message, Select } from "antd";
import SettlementTable from "./SettlementTable";
import {
  downloadSettlementLogApi,
  FetSettlementProps,
  getSettlementApi,
} from "api/settlement";
import { useSearchParams } from "react-router-dom";
import dayjs, { Dayjs } from "dayjs";
import { MoneyConvert } from "utils/format";
import fileDownload from "js-file-download";
import ListBrandSelectSearch from "components/ListBrandSelectSearch";

type FilterType = {
  settlementTime?: Dayjs;
  settlementStatus: string;
  invoice: string;
  querySearch: string;
  brandId?: number;
};

type SettlementType = {
  data: any;
  summary: any;
  totalElement: number;
};

type SortProps = {
  sortType: "brandName" | "settlementAmount";
  sortDirect: "asc" | "desc";
};

const Settlement: React.FC = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [filter, setFilter] = useState<FilterType>({
    settlementTime: dayjs().subtract(1, "month"),
    settlementStatus: "ALL",
    invoice: "ALL",
    querySearch: "",
  });
  const [isFilterChanged, setIsFilterChanged] = useState<boolean>(false);
  const [settlement, setSettlement] = useState<SettlementType>();
  const [sort, setSort] = useState<SortProps>({
    sortDirect: "asc",
    sortType: "brandName",
  });

  const getSettlement = async (payload?: FetSettlementProps) => {
    const { data, error }: any = await getSettlementApi({
      size: 10,
      page: Number(searchParams.get("page")) || 1,
      settlementMonth:
        filter.settlementTime?.format("M-YYYY") ||
        dayjs().subtract(1, "month").format("M-YYYY"),
      brandId: filter.brandId,
      sortType: sort?.sortType,
      sortDirect: sort?.sortDirect,
      status:
        filter.settlementStatus === "ALL"
          ? undefined
          : filter.settlementStatus === "1"
          ? 1
          : 0,
      taxRelease:
        filter.invoice === "ALL" ? undefined : filter.invoice === "1" ? 1 : 0,
      ...payload,
    });
    if (error) {
      console.error(error);
      return;
    }
    setSettlement({
      data: data?.datas,
      summary: data?.dataAdd,
      totalElement: data?.pageable?.totalElement,
    });
  };

  const handleFilter = () => {
    getSettlement();
  };

  const setDefaultFilter = () => {
    searchParams.set("page", "1");
    setSearchParams(searchParams);
    setFilter({
      settlementTime: dayjs().subtract(1, "month"),
      settlementStatus: "ALL",
      invoice: "ALL",
      querySearch: "",
    });
    setIsFilterChanged(false);
    getSettlement({
      page: 1,
      settlementMonth: dayjs().subtract(1, "month").format("M-YYYY"),
      status: undefined,
      taxRelease: undefined,
    });
  };

  const handleDownloadExcel = async () => {
    try {
      const resp: any = await downloadSettlementLogApi({
        settlementMonth:
          filter?.settlementTime?.format("M-YYYY") ||
          dayjs().subtract(1, "month").format("M-YYYY"),
        brandId: filter.brandId,
        sortType: sort?.sortType,
        sortDirect: sort?.sortDirect,
        status:
          filter.settlementStatus === "ALL"
            ? undefined
            : filter.settlementStatus === "1"
            ? 1
            : 0,
        taxRelease:
          filter.invoice === "ALL" ? undefined : filter.invoice === "1" ? 1 : 0,
      });
      if (resp) {
        fileDownload(resp, "SettlementLog.xlsx");
        message.success("Successfully downloaded");
      }
    } catch (error) {
      console.error("Error downloading file: ", error);
    }
  };

  useEffect(() => {
    const isChanged: boolean =
      filter.settlementTime?.format("M-YYYY") !==
        dayjs().subtract(1, "month").format("M-YYYY") ||
      filter.settlementStatus !== "ALL" ||
      filter.invoice !== "ALL" ||
      filter.querySearch !== "";
    setIsFilterChanged(isChanged);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter]);

  useEffect(() => {
    getSettlement({
      settlementMonth: dayjs().subtract(1, "month").format("M-YYYY"),
      // settlementMonth: "6-2024",
    });
  }, []);

  return (
    <S.Wrapper>
      <div className="headings">
        <h1 className="main-headings">정산 관리</h1>
        <p className="sub-headings">
          브랜드에 지급할 정산 내역을 조회할 수 있습니다. 매월 1일 전월 정산
          내역이 업데이트됩니다.{" "}
        </p>
      </div>
      <S.Body>
        {/* MonthPicker----------------- */}
        <MonthPicker
          onChange={(e) => {
            console.log("e", e);
            setFilter((prev: FilterType) => ({
              ...prev,
              settlementTime: e,
            }));
          }}
          defaultValue={dayjs(filter.settlementTime)}
        />
        <S.MonthPickerContent>
          <div className="def">
            <div className="ghi">① 결제 완료 금액</div>
            <div className="jkl">
              <span className="mno">
                {MoneyConvert(settlement?.summary?.paymentAmount, true)}
              </span>
              <span className="pqr">원</span>
            </div>
            <div className="stu">
              {settlement?.summary?.paymentAmount >=
              settlement?.summary?.previousPaymentAmount ? (
                <ArrowUpIcon />
              ) : (
                <ArrowDownIcon />
              )}
              <span
                className="vwx"
                style={{
                  color:
                    settlement?.summary?.paymentAmount >=
                    settlement?.summary?.previousPaymentAmount
                      ? "#15A0A1"
                      : "#C70039",
                }}
              >
                {MoneyConvert(
                  settlement?.summary?.paymentAmount -
                    settlement?.summary?.previousPaymentAmount,
                  true
                )}
                원
              </span>
              <span className="yza">(지난 달 대비)</span>
            </div>
          </div>
          <div className="def center">
            <div className="ghi">② 플랫폼 이용 수수료</div>
            <div className="jkl">
              <span className="mno">
                {MoneyConvert(settlement?.summary?.platformFee, true)}
              </span>
              <span className="pqr">원</span>
            </div>
            <div className="stu">
              {settlement?.summary?.platformFee >=
              settlement?.summary?.previousPlatformFee ? (
                <ArrowUpIcon />
              ) : (
                <ArrowDownIcon />
              )}
              <span
                className="vwx"
                style={{
                  color:
                    settlement?.summary?.platformFee >=
                    settlement?.summary?.previousPlatformFee
                      ? "#15A0A1"
                      : "#C70039",
                }}
              >
                {MoneyConvert(
                  settlement?.summary?.platformFee -
                    settlement?.summary?.previousPlatformFee,
                  true
                )}
                원
              </span>
              <span className="yza">(지난 달 대비)</span>
            </div>
          </div>
          <div className="def center no-border">
            <div className="ghi">③ 결제대행 수수료</div>
            <div className="jkl">
              <span className="mno">
                {MoneyConvert(settlement?.summary?.paymentFee, true)}
              </span>
              <span className="pqr">원</span>
            </div>
            <div className="stu">
              {settlement?.summary?.paymentFee >=
              settlement?.summary?.previousPaymentFee ? (
                <ArrowUpIcon />
              ) : (
                <ArrowDownIcon />
              )}
              <span
                className="vwx"
                style={{
                  color:
                    settlement?.summary?.paymentFee >=
                    settlement?.summary?.previousPaymentFee
                      ? "#15A0A1"
                      : "#C70039",
                }}
              >
                {MoneyConvert(
                  settlement?.summary?.paymentFee -
                    settlement?.summary?.previousPaymentFee,
                  true
                )}
                원
              </span>
              <span className="yza">(지난 달 대비)</span>
            </div>
          </div>
        </S.MonthPickerContent>
        {/* Filter----------------- */}
        <S.Filter>
          <div className="line mb">
            <div className="left">정산 지급</div>
            <div className="right">
              <RadioComp
                defaultValue={filter.settlementStatus}
                options={[
                  {
                    label: "전체",
                    value: "ALL",
                  },
                  {
                    label: "완료",
                    value: "1",
                  },
                  {
                    label: "미완료",
                    value: "0",
                  },
                ]}
                onChange={(v) =>
                  setFilter((prev: any) => ({ ...prev, settlementStatus: v }))
                }
              />
            </div>
          </div>
          <div className="line mb">
            <div className="left">세금계산서 발행</div>
            <div className="right">
              <RadioComp
                defaultValue={filter.invoice}
                options={[
                  {
                    label: "전체",
                    value: "ALL",
                  },
                  {
                    label: "완료",
                    value: "1",
                  },
                  {
                    label: "미완료",
                    value: "0",
                  },
                ]}
                onChange={(v) =>
                  setFilter((prev: any) => ({ ...prev, invoice: v }))
                }
              />
            </div>
          </div>
          <div className="line">
            <div className="left">브랜드명</div>
            <div className="right">
              {/* <div className="wr-input">
                <input
                  value={filter.querySearch}
                  onChange={(e) => {
                    setFilter((prev: any) => ({
                      ...prev,
                      querySearch: e.target.value.trim(),
                    }));
                  }}
                />
                {filter?.querySearch && (
                  <button
                    onClick={() =>
                      setFilter((prev: any) => ({
                        ...prev,
                        querySearch: "",
                      }))
                    }
                  >
                    <CloseOutlined />
                  </button>
                )}
              </div> */}
              <ListBrandSelectSearch
                onChange={(v) =>
                  setFilter((prev: any) => {
                    return { ...prev, brandId: Number(v) };
                  })
                }
              />
            </div>
          </div>
          <div className="wr-btn">
            {isFilterChanged && (
              <button
                onClick={() => {
                  setDefaultFilter();
                }}
                className="clear-btn"
              >
                Clear
              </button>
            )}
            <button onClick={handleFilter} className="filter-btn">
              검색
            </button>
          </div>
        </S.Filter>
        {/* Table----------------- */}
        {settlement?.data.length > 0 ? (
          <React.Fragment>
            <h3 className="total">전체 목록 (총 N건)</h3>
            <S.Sort>
              <Select
                defaultValue="brandNameUp"
                options={[
                  { value: "brandNameUp", label: "브랜드명 오름차순" },
                  { value: "brandNameDown", label: "브랜드명 내림차순" },
                  { value: "amountUp", label: "정산확정금액 내림차순" },
                  { value: "amountDown", label: "정산확정금액 오름차순" },
                ]}
                onChange={(v) => {
                  if (v === "brandNameUp") {
                    setSort({ sortDirect: "asc", sortType: "brandName" });
                  } else if (v === "brandNameDown") {
                    setSort({ sortDirect: "desc", sortType: "brandName" });
                  } else if (v === "amountUp") {
                    setSort({
                      sortDirect: "asc",
                      sortType: "settlementAmount",
                    });
                  } else {
                    setSort({
                      sortDirect: "desc",
                      sortType: "settlementAmount",
                    });
                  }
                }}
              />
              <button onClick={handleDownloadExcel}>정산 정보 다운로드</button>
            </S.Sort>
            <S.Table>
              <SettlementTable
                data={settlement?.data}
                totalElement={settlement?.totalElement || 0}
                getSettlement={getSettlement}
              />
            </S.Table>
          </React.Fragment>
        ) : (
          <S.NoData>No data available at this time</S.NoData>
        )}
      </S.Body>
    </S.Wrapper>
  );
};

export default Settlement;
