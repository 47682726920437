import { Button, Form } from "antd";
import { getDetailInquiryApi, replyInquiryApi } from "api/inquiry";
import { ReactComponent as Close } from "assets/icons/Close.svg";
import { ReactComponent as FilePlus } from "assets/icons/FilePlus.svg";
import Editor from "components/Editor";
import dayjs from "dayjs";
import MainHeader from "layouts/components/Header";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Editor as TinyMCEEditor } from "tinymce";
import { uploadFileFirst } from "utils/uploadImage";
import { LoadingOutlined } from "@ant-design/icons";
import * as S from "./style";
import { typeMappings } from "pages/CustomerService/FAQ/Client";

const InquiryReply: React.FC = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [form] = Form.useForm();
  const [initData, setInitData] = useState<any>({});
  const [lenghtDes, setLenghDes] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(false);

  const getInquiryInfo = async () => {
    try {
      if (id) {
        const { data }: any = await getDetailInquiryApi({ id: +id });
        // console.log(data);
        if (data) {
          setInitData(data);
        }
      }
    } catch (error) {
      console.error(error);
    }
  };
  const onFill = () => {
    // console.log("onFill");
  };

  const onFinish = async (values: any) => {
    // console.log("Success:", values);
    setLoading(true);
    try {
      if (id) {
        let resultUpload = await uploadFileFirst(values.answer);
        const { data, error }: any = await replyInquiryApi({
          id: +id,
          answer: resultUpload.html,
          // answer: values.answer,
        });
        if (error?.errorCode === "OUT_OF_RANGE") {
          form.setFields([
            {
              name: "answer",
              errors: ["Please enter max 10000 characters"],
            },
          ]);
          setLoading(false);
          return;
        }
        if (data) {
          navigate("/customer-service/inquiry");
          setLoading(false);
        }
      }
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };

  const onReset = () => {
    form.resetFields();
    navigate("/customer-service/inquiry");
  };

  const handleChangeContent = async (
    contentHtml: string,
    editor: TinyMCEEditor
  ) => {
    const textContent = editor.getContent({ format: "text" });
    const length = textContent === "\n" ? 0 : textContent.length;
    setLenghDes(length);

    if (length === 0) {
      form.setFields([
        {
          name: "answer",
          errors: ["This field is required"],
        },
      ]);
    } else if (length < 3) {
      form.setFields([
        {
          name: "answer",
          errors: ["세글자 이상 입력해 주세요"],
        },
      ]);
    } else {
      form.setFieldsValue({
        answer: contentHtml,
      });
    }
  };

  useEffect(() => {
    getInquiryInfo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <S.Wrapper>
      <MainHeader backLink="/customer-service/inquiry" title="1:1 Inquiry" />
      <Form
        onFinish={onFinish}
        autoComplete="off"
        layout="vertical"
        className="form-content"
        form={form}
      >
        <S.Block>
          <div className="container-block">
            <p className="title">질문 작성자</p>
            <div>
              <span className="title">{initData?.displayName}</span>
              <span> {"|"} </span>
              <span>{initData?.verifiedPhoneNumber}</span>
            </div>
          </div>
          <div className="container-block">
            <p className="title">질문 작성일</p>
            <div>
              <span>
                {dayjs(initData?.createdTime).format("YYYY/MM/DD HH:mm")}
              </span>
            </div>
          </div>
        </S.Block>
        <S.Block>
          <p className="title">
            문의 유형: {typeMappings[initData.type] || initData.type}
          </p>
          <p className="title">{initData.subject}</p>
          <p
            style={{
              fontSize: 16,
              whiteSpace: "pre-line",
            }}
          >
            {initData.description}
          </p>
          <Form.Item
            name="answer"
            label="답변하기"
            rules={[
              {
                validator: () => {
                  if (lenghtDes === 0) {
                    return Promise.reject("This field is required");
                  } else if (lenghtDes < 3) {
                    return Promise.reject("세글자 이상 입력해 주세요");
                  } else {
                    return Promise.resolve();
                  }
                },
              },
            ]}
          >
            <Editor
              data={""}
              onChangeValue={(e, editor) => handleChangeContent(e, editor)}
            />
          </Form.Item>
        </S.Block>
        <Form.Item className="group-btn">
          <Button
            onClick={onReset}
            htmlType="submit"
            className="btn btn-cancel"
            icon={<Close />}
          >
            취소
          </Button>
          {loading ? (
            <Button
              style={{
                width: "220px",
                height: "52px",
                backgroundColor: "#526557",
              }}
            >
              <LoadingOutlined />
            </Button>
          ) : (
            <Button
              onClick={onFill}
              htmlType="submit"
              className="btn btn-create"
              icon={<FilePlus />}
            >
              답변하기
            </Button>
          )}
        </Form.Item>
      </Form>
    </S.Wrapper>
  );
};

export default InquiryReply;
