import { Button, Radio, RadioChangeEvent, Upload, UploadFile } from "antd";
import { memo, useEffect, useState } from "react";
import { UploadOutlined } from "@ant-design/icons";
import { UploadChangeParam } from "antd/es/upload";
import styled from "styled-components";
import { v4 as uuidv4 } from "uuid";

type FieldType = {
  display: boolean;
  image?: any;
};

interface Props {
  onChange?: (e: FieldType) => void;
  setRequired?: (e: boolean) => void;
  defaultValue?: FieldType;
  logoLabel?: string;
  labelTop?: string;
  labelBottom?: string;
}

const ErrorMessage = styled.div`
  color: #ff4d4f;
  margin-top: 8px;
  font-size: 12px;
`;

const RadioUpload: React.FC<Props> = ({
  onChange,
  setRequired,
  defaultValue,
  labelTop,
  labelBottom = "이미지 업로드",
}) => {
  const [field, setFiled] = useState<FieldType>({
    display: false,
    image: undefined,
  });
  // const [initField, setInitField] = useState<FieldType>({display: false,
  //   image: undefined,})
  // console.log("field", field);
  useEffect(() => {
    if (!defaultValue?.image) {
      return;
    }
    let valueFormat;
    if (typeof defaultValue.image === "string") {
      valueFormat = {
        uid: uuidv4(),
        name: defaultValue.image,
        status: "done",
        url: defaultValue.image,
      };
      setFiled({ display: defaultValue.display, image: valueFormat });
    }
  }, [defaultValue]);

  const [isRequired, setIsRequired] = useState<boolean>(false);

  const handleChangeDisplay = (e: RadioChangeEvent) => {
    const v = e.target.value;
    setFiled((prev: FieldType) => ({ ...prev, display: v }));
    onChange?.({
      display: v,
      image: field.image,
    });
    if (v) {
      if (!field.image) {
        setIsRequired(true);
        setRequired?.(true);
      }
    } else {
      setIsRequired(false);
      setRequired?.(false);
    }
  };

  const handleChangeImage = (e: UploadChangeParam<UploadFile<any>>) => {
    const { file } = e;
    if (file.status === "removed") {
      setFiled((prev: FieldType) => ({ ...prev, image: undefined }));
      onChange?.({
        display: field.display,
        image: undefined,
      });
      setIsRequired(true);
      setRequired?.(true);
      return;
    }
    setFiled((prev: FieldType) => ({ ...prev, image: file }));
    onChange?.({
      display: field.display,
      image: file,
    });
    setIsRequired(false);
    setRequired?.(false);
  };

  return (
    <div>
      <Radio.Group
        onChange={handleChangeDisplay}
        value={field.display}
        style={{ display: "block", marginBottom: 8 }}
      >
        <Radio style={{ display: "block" }} value={false}>
          {labelTop}
        </Radio>
        <Radio style={{ display: "block" }} value={true}>
          {labelBottom}
        </Radio>
      </Radio.Group>
      {field.display && (
        <Upload
          accept="image/png, image/jpeg, image/gif"
          beforeUpload={() => false}
          onChange={handleChangeImage}
          maxCount={1}
          // fileList={[]}
          fileList={field?.image ? [field?.image] : undefined}
        >
          <Button
            onClick={(e) => e.preventDefault()}
            htmlType="button"
            icon={<UploadOutlined />}
            style={{ display: "flex", alignItems: "center" }}
          >
            Upload
          </Button>
        </Upload>
      )}
      {isRequired && <ErrorMessage>This field is required</ErrorMessage>}
    </div>
  );
};

export default memo(RadioUpload);
