import { styled } from "styled-components";

export const Wrapper = styled.div`
  height: 100%;
  background-color: var(--sub-background);
  .ant-select-selection-item {
    display: flex;
  }
  .ant-input-affix-wrapper,
  .ant-picker,
  .ant-input {
    background-color: transparent;
  }
  .filter-container {
    padding: 0 30px;
    display: flex;
    gap: 12px;
    height: 60px;
    margin: 20px 0 30px 0;
  }
  .order-tabs {
    .ant-tabs-nav {
      margin-bottom: 0;
    }
    .ant-tabs-nav-wrap {
      padding: 0 24px;
      .ant-tabs-nav-list {
        width: 100%;
        display: flex;
        justify-content: center;
        .ant-tabs-tab {
          flex-grow: 1;
          display: flex;
          justify-content: center;
          padding: 28px 0;
        }
        .ant-tabs-tab-active.ant-tabs-tab-active .ant-tabs-tab-btn {
          color: var(--brand-color);
          font-family: var(--font-600);
        }
        .ant-tabs-ink-bar {
          background-color: var(--brand-color);
        }
      }
    }
    .ant-tabs-tab-btn {
      font-size: 16px;
      color: var(--brand-color);
    }
    .ant-table {
      background-color: var(--light-color);
      thead.ant-table-thead th {
        background-color: var(--light-color);
        height: 92px;
      }
      tbody tr:nth-child(odd) {
        background-color: var(--sub-background);
      }
    }
    .has-number {
      display: flex;
      gap: 16px;
      align-items: center;
      p {
        background-color: #dd6b59;
        color: var(--light-color);
        padding: 5px 10px;
        border-radius: 20px;
        font-size: 16px;
        font-family: "SpaceMonoRegular";
      }
    }
  }
`;

export const Heading = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  padding: 0 30px;
  gap: 10px;
  .sub-heading {
    font-size: 16px;
    line-height: normal;
    font-family: var(--font-500);
  }
  .btn-create {
    border-radius: 12px;
    background: var(--brand-color);
    color: var(--light-color) !important;
    width: 220px;
    height: 52px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
  }
`;

export const TdTextInner = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  p {
    margin: 0;
  }
  .big-text {
    font-size: 16px;
    line-height: 25.6px;
  }
  .small-text {
    font-size: 14px;
    line-height: 22.4px;
  }
`;

export const DateInfo = styled.div`
  .create-date {
    font-size: 16px;
    line-height: 22.4px;
    margin-bottom: 5px;
  }
  .transaction-id {
    line-height: 19.6px;
    color: #858584;
  }
`;

export const CustomerInfo = styled.div`
  .bank-holder {
    font-size: 16px;
    line-height: 22.4px;
    margin-bottom: 5px;
    font-family: var(--font-500);
  }
  .bank-account {
    line-height: 19.6px;
    color: #858584;
  }
`;

export const GroupBuying = styled.div`
  display: flex;
  gap: 12px;
  flex-direction: column;
`;

export const BrandInfo = styled.div`
  display: flex;
  gap: 6px;
  align-items: center;
`;

export const VoucherInfo = styled.div`
  display: flex;
  gap: 6px;
  align-items: flex-start;
  .voucher-name {
    font-size: 16px;
    line-height: 22.4px;
    margin-bottom: 5px;
    font-family: var(--font-500);
  }
`;

export const BranchInfo = styled.div`
  font-size: 16px;
  line-height: 22.4px;
  font-family: var(--font-500);
`;

export const Amount = styled.div`
  font-size: 16px;
  line-height: 22.4px;
  font-family: "SpaceMonoBold";
`;

export const Message = styled.div`
  line-height: 21px;
`;

export const Status = styled.div`
  min-height: 28px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  color: var(--light-color);
  padding: 0 3px;
  font-size: 16px;
  text-transform: capitalize;
  min-width: 70px;
`;

export const Quantity = styled.div`
  display: flex;
  flex-direction: column;
  .voucher-amount {
    font-size: 16px;
    line-height: 22.4px;
    margin-bottom: 5px;
    font-family: var(--font-500);
  }
  .price {
    line-height: 19.6px;
    color: #858584;
  }
`;

export const TableWraper = styled.div`
  .ant-table thead tr th {
    font-size: 20px;
  }
`;

export const ViewDetailBtn = styled.div`
  cursor: pointer;
  display: flex;
`;
