import { Button } from "antd";
import { styled } from "styled-components";

export const ButtonStyle = styled(Button)`
    border-radius: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    span {
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
    }
`