import { Tag } from "antd";
import ButtonMain from "components/ButtonMain";
import { GB_STATUS, TIME_FORMAT } from "constants/index";
import dayjs from "dayjs";
import { ReactNode } from "react";
import * as S from "./style";
import React from "react";

type Props = {
  status?: GB_STATUS;
  time?: string;
  reason?: string;
  onClick?: (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    id: string
  ) => void;
};

function GbStatus({ status = GB_STATUS.Active, time, reason, onClick }: Props) {
  const mapStatus: Record<
    GB_STATUS,
    {
      tag: { label: string; backgroundColor: string };
      timeString: string;
      buttons?: {
        id: "active" | "inactive";
        element: ReactNode[];
      }[];
    }
  > = {
    [GB_STATUS.Active]: {
      tag: {
        label: "활성",
        backgroundColor: "#4AA159",
      },
      timeString: "게시 날짜",
      buttons: [
        {
          id: "inactive",
          element: [<ButtonMain>비활성 시키기</ButtonMain>],
        },
      ],
    },
    [GB_STATUS.Inactive]: {
      tag: {
        label: "비활성",
        backgroundColor: "#BDBDBD",
      },
      timeString: "비활성화 날짜",
      buttons: [
        {
          id: "active",
          element: [<ButtonMain type="primary">활성화 하기</ButtonMain>],
        },
      ],
    },
  };

  const handleButtonClick = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>,
    id: string
  ) => {
    if (onClick) {
      onClick(e, id);
    }
  };

  return (
    <S.Wrapper>
      <div className="left-container">
        <p className="title">프리미엄 멤버십 상태</p>
        <div>
          <Tag color={mapStatus[status].tag.backgroundColor}>
            {mapStatus[status].tag.label}
          </Tag>
          <p className="account-time">
            {`${mapStatus[status].timeString} ${
              time || dayjs(new Date()).format(TIME_FORMAT)
            }`}
          </p>
          {reason && <p className="account-reason">비활성 사유: {reason}</p>}
        </div>
      </div>
      <div className="right-container">
        {mapStatus[status].buttons?.map((x) => (
          <div
            key={x.id}
            onClick={(e: React.MouseEvent<HTMLDivElement, MouseEvent>) =>
              handleButtonClick(e, x.id)
            }
          >
            {x.element.map((el: ReactNode, i: number) => (
              <React.Fragment key={i}>{el}</React.Fragment>
            ))}
          </div>
        ))}
      </div>
    </S.Wrapper>
  );
}

export default GbStatus;
