import {
  Col,
  Form,
  Input,
  Modal,
  Progress,
  Row,
  Tabs,
  TabsProps,
  message,
} from "antd";
import { useForm } from "antd/es/form/Form";
import { NoticeType } from "antd/es/message/interface";
import { updateStatusGbApi } from "api/brand";
import { getGroupBuyingDetailApi, updateGbApi } from "api/groupBuying";
import { ReactComponent as Campaign } from "assets/icons/Campaign.svg";
import { ReactComponent as PencilIcon } from "assets/icons/Pencil.svg";
import { ReactComponent as SearchIcon } from "assets/icons/Search.svg";
import { ReactComponent as WarningCircleIcon } from "assets/icons/WarningCircle.svg";
import noImage from "assets/images/no-image.png";
import ButtonComp from "components/ButtonCmp";
import DateRangePicker from "components/DateRangePicker";
import GbStatus from "components/GbStatus";
import CsInput from "components/Input";
import { GB_STATUS } from "constants/index";
import dayjs from "dayjs";
import MainHeader from "layouts/components/Header";
import { UpdateStatusGbApi } from "model/brand";
import { GetGroupBuyingDetailApi } from "model/groupBuying";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { debounce } from "utils/debounce";
import { MoneyConvert } from "utils/format";
import CanceledTable from "./CanceledTable";
import ImageListComp from "./ImageList";
import PurchaseTable from "./PurchaseTable";
import * as S from "./style";
import { progressMapping } from "../CampainTable";
import CsTable from "components/Table";
import { ColumnsType } from "antd/es/table";

type GbStatusType = 0 | 1;

const CampaignDetail: React.FC = () => {
  const navigate = useNavigate();
  const [form] = useForm();
  const [confirmVisible, setConfirmVisible] = useState<boolean>(false);
  const [openHistory, setOpenHistory] = useState<boolean>(false);
  const [messageApi, contextHolder] = message.useMessage({ maxCount: 2 });
  const { campaignId }: any = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const [voucherData, setVoucherData] = useState<any>({});
  const [purchasedData, setPurchasedData] = useState<any>([]);
  const [offlineSoldHistory, setOfflineSoldHistory] = useState<any>([]);
  const [purchasedTotal, setPurchasedTotal] = useState<number>(0);
  const [canceledData, setCanceledData] = useState<any>([]);
  const [canceledTotal, setCanceledTotal] = useState<number>(0);
  const [filter, setFilter] = useState<GetGroupBuyingDetailApi>({
    id: +campaignId,
  });
  const [selectedStatus, setSelectedStatus] = useState<GbStatusType>();
  const [reason, setReason] = useState<string>("");
  const [searchValue, setSearchValue] = useState<string>("");
  const [clearFilterDate, setClearFilterDate] = useState<boolean>(false);
  const [sold, setSold] = useState<string>("");

  const handleUpdateSold = async () => {
    if (!voucherData.id) return;
    const { data, error }: any = await updateGbApi({
      id: voucherData.id,
      offlineSold: sold ? +sold : undefined,
    });
    if (error) {
      message.error("Update failed!");
      return;
    }
    message.success("Update completed!");
    getGroupBuyingDetail();
    setSold("");
  };

  const setReasonValue = (e: React.ChangeEvent<HTMLInputElement>) => {
    setReason(e.target.value);
  };

  const showMess = (type: NoticeType, message: string) => {
    messageApi.open({
      type: type,
      content: message,
    });
  };

  const getGroupBuyingDetail = async () => {
    try {
      if (!campaignId) return;

      const { data }: any = await getGroupBuyingDetailApi({
        id: filter.id,
        querySearch: filter?.querySearch?.trim(),
        startDate: filter.startDate,
        endDate: filter.endDate,
        page: Number(searchParams.get("page") || 1),
        size: 10,
      });

      if (data) {
        setVoucherData(data.voucher);
        if (data.purchasedOrder) {
          setPurchasedData(data.purchasedOrder.datas);
          setPurchasedTotal(+data.purchasedOrder.pageable.totalElement);
        }
        if (data.canceledOrder) {
          setCanceledData(data.canceledOrder.datas);
          setCanceledTotal(+data.canceledOrder.pageable.totalElement);
        }
        if (data.offlineSoldHistory) {
          setOfflineSoldHistory(data.offlineSoldHistory);
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (campaignId) {
      getGroupBuyingDetail();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter, searchParams]);

  const handleChangeDate = (_: any, dateString: [string, string] | string) => {
    // console.log(dateString[0]);

    setFilter((prev: GetGroupBuyingDetailApi) => ({
      ...prev,
      startDate: dateString[0],
      endDate: dateString[1],
    }));
    searchParams.set("page", "1");
    setSearchParams(searchParams);
  };

  // const setTextSearch = debounce((value: string) => {
  //   setFilter((prev: GetGroupBuyingDetailApi) => {
  //     return { ...prev, querySearch: value };
  //   });
  // }, 500);

  const handleSearch = useCallback(
    debounce((value: string) => {
      setFilter((prev: GetGroupBuyingDetailApi) => {
        return { ...prev, querySearch: value };
      });
      searchParams.set("page", "1");
      setSearchParams(searchParams);
    }, 1000),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const updateStatus = async (
    status: 0 | 1,
    shouldUpdateReason: boolean = false
  ) => {
    if (!campaignId) {
      console.error("Campaign ID is missing.");
      return;
    }

    const requestData: UpdateStatusGbApi = {
      id: +campaignId,
      status: status,
    };

    if (shouldUpdateReason) {
      requestData.message = reason;
    }

    try {
      const { data, error }: any = await updateStatusGbApi(requestData);
      if (data) {
        getGroupBuyingDetail();
        showMess("success", "Update status successfully");
      }
      if (error) {
        showMess("error", "Update status failed!");
      }
      form.resetFields();
    } catch (error) {
      console.error(error);
      showMess("error", "Update status failed!");
      form.resetFields();
    }
  };

  const handleChangeStatus = async (_: any, btnKey: string) => {
    const statusMap: Record<string, GbStatusType> = {
      active: 1,
      inactive: 0,
    };
    const status = statusMap[btnKey];
    // console.log(status);
    if (status === 0) {
      setSelectedStatus(status);
      setConfirmVisible(true);
    } else {
      // console.log("status2", status);
      await updateStatus(status, false);
    }
  };

  const handleConfirm = async () => {
    console.log(selectedStatus);

    setConfirmVisible(false);
    if (selectedStatus !== undefined) {
      await updateStatus(selectedStatus, true);
    }
  };

  const items: TabsProps["items"] = [
    {
      key: "purchasedOrder",
      label: `주문 내역`,
      children: (
        <PurchaseTable data={purchasedData} totalItem={purchasedTotal} />
      ),
    },
    {
      key: "canceledOrder",
      label: `취소 내역`,
      children: <CanceledTable data={canceledData} totalItem={canceledTotal} />,
    },
  ];

  const onChangeTab = (key: any) => {
    searchParams.set("type", key);
    searchParams.delete("page");
    setFilter({ id: +campaignId });
    setSearchValue("");
    setClearFilterDate(true);
    setSearchParams(searchParams);
  };

  const remaining = useMemo(() => {
    return (
      voucherData?.quantity -
        voucherData?.totalSold -
        voucherData?.offlineSold || 0
    );
  }, [voucherData]);

  const columns: ColumnsType<any> = [
    {
      title: "배정 일자",
      dataIndex: "createdTime",
      key: "createdTime",
      render: (_, record) => (
        <div>{dayjs(record?.createdTime).format("YYYY-MM-DD HH:mm")}</div>
      ),
    },
    {
      title: "추가 판매분",
      dataIndex: "quantity",
      key: "quantity",
      render: (_, record) => <div>{record?.quantity}</div>,
    },
  ];

  return (
    <S.Wrapper>
      <MainHeader
        backLink="/group-buying/campaign"
        title="프리미엄 멤버십 발급 세부 정보"
        rightBtn={
          voucherData?.progress === "FINISHED" ? undefined : (
            <ButtonComp
              style={{ padding: "0 30px" }}
              icon={<PencilIcon />}
              onClick={() =>
                navigate(`/group-buying/campaign/campaign-edit/${campaignId}`)
              }
            >
              수정하기
            </ButtonComp>
          )
        }
        breadCrumb={[
          {
            title: "발급관리",
            link: "/group-buying/campaign",
            icon: <Campaign />,
          },
          {
            title: "세부정보",
          },
        ]}
      />
      <S.Content>
        <S.ContentTop>
          <GbStatus
            onClick={handleChangeStatus}
            status={voucherData?.status ? GB_STATUS.Active : GB_STATUS.Inactive}
            time={dayjs(voucherData?.changeStatusAt).format("YYYY-MM-DD")}
            reason={!voucherData?.status && voucherData.message}
          />
        </S.ContentTop>
        <Row gutter={70}>
          <Col className="gutter-row" span={8}>
            <S.Block className="flex-center">
              <img
                className="brand-logo"
                src={voucherData?.brandLogoImage || noImage}
                alt="brandLogo"
                style={{ width: "60px", height: "60px", objectFit: "contain" }}
              />
              <p className="brand-name">{voucherData?.brandName}</p>
              <div className="brand-category">
                <span className="brand-category-name">
                  {voucherData?.categoryName}
                </span>
                {/* <span> | </span>
                <span>Fast Food</span> */}
              </div>
              <img
                className="voucher-logo"
                src={voucherData?.voucherImage || noImage}
                alt="voucherLogo"
                style={{
                  width: "192px",
                  height: "128px",
                  objectFit: "contain",
                }}
              />
              <p className="voucher-name">{voucherData?.voucherName}</p>
              <p className="status">
                {progressMapping[voucherData?.progress]}
                {/* {voucherData?.progress === "FINISHED"
                  ? "Finish"
                  : voucherData?.progress === "IN_PROGRESS"
                  ? "In progress"
                  : "Upcoming"} */}
              </p>
              <div className="gb-image-change">
                {voucherData?.displayLogo && (
                  <div className="logo-change">
                    <label>Logo Change</label>
                    <img src={voucherData?.logoImage} alt="image" />
                  </div>
                )}
                {voucherData?.displayBackground && (
                  <div className="background-change">
                    <label>Background Change</label>
                    <img src={voucherData?.backgroundImage} alt="image" />
                  </div>
                )}
              </div>
            </S.Block>
            <S.Block>
              <S.VoucherDetail>
                <div className="left">
                  <p className="label">프리미엄 멤버십 금액 가치 (원가)</p>
                  <p className="value price">
                    {MoneyConvert(voucherData?.rootPrice) || 0}원
                  </p>
                </div>
                <div className="right">
                  <p className="label">할인가</p>
                  <p className="value">{voucherData?.discountPercentage}%</p>
                </div>
              </S.VoucherDetail>
              <S.VoucherDetail>
                <div className="left">
                  <p className="label">판매액 (혜택가)</p>
                  <p className="value price">
                    {MoneyConvert(voucherData?.voucherPrice) || 0}원
                  </p>
                </div>
                <div className="right">
                  <p className="label">발행 개수</p>
                  <p className="value">
                    {MoneyConvert(voucherData?.quantity) || 0}개
                  </p>
                </div>
              </S.VoucherDetail>
              <S.VoucherDetail>
                <div className="left">
                  <p className="label">총 금액</p>
                  <p className="value price">
                    {MoneyConvert(voucherData?.rootAmount) || 0}원
                  </p>
                </div>
                <div className="right">
                  <p className="label">프리미엄 멤버십 사용 종료일</p>
                  <p className="value">
                    {dayjs(voucherData?.expiredTime).format("YYYY/MM/DD HH:mm")}

                    {/* {voucherData?.expiredTime
                      .replace("T", " ")
                      .replace(/-/g, "/")
                      .slice(0, 16)} */}
                  </p>
                </div>
              </S.VoucherDetail>
              <S.VoucherDetail>
                <div className="left">
                  <p className="label">최대 구매 가능 수량</p>
                  <p className="value">
                    {voucherData?.maxPurchaseQuantity
                      ? `${MoneyConvert(
                          voucherData?.maxPurchaseQuantity
                        )} vouchers/time`
                      : "-"}
                  </p>
                </div>
                <div className="right">
                  <p className="label">최대 참여 가능 수</p>
                  <p className="value">
                    {voucherData?.maxPurchaseQuantity
                      ? `${MoneyConvert(
                          voucherData?.maxPurchaseFrequency
                        )} times`
                      : "-"}
                  </p>
                </div>
              </S.VoucherDetail>
            </S.Block>
            <S.Block>
              <h3 style={{ fontSize: 18, marginBottom: 7 }}>입금 정보</h3>
              <S.PlaceTime>
                <p className="label">입금자명</p>
                <p className="value">{voucherData?.bankAccountName || "-"}</p>
              </S.PlaceTime>
              <S.PlaceTime>
                <p className="label">은행명</p>
                <p className="value">{voucherData?.bankName || "-"}</p>
              </S.PlaceTime>
              <S.PlaceTime>
                <p className="label">계좌번호</p>
                <p className="value">{voucherData?.bankAccountNumber || "-"}</p>
              </S.PlaceTime>
            </S.Block>
            <S.Block>
              <S.PlaceTime>
                <p className="label">프리미엄 멤버십 판매 시작 날짜</p>
                <p className="value">
                  {dayjs(voucherData?.startTime).format("YYYY/MM/DD HH:mm")}
                </p>
              </S.PlaceTime>
              <S.PlaceTime>
                <p className="label">프리미엄 멤버십 판매 종료 날짜</p>
                <p className="value">
                  {dayjs(voucherData?.endTime).format("YYYY/MM/DD HH:mm")}
                </p>
              </S.PlaceTime>
              <S.PlaceTime className="flex-start">
                <p className="label">설명</p>
                <p className="value">{voucherData?.description}</p>
              </S.PlaceTime>
            </S.Block>
            <S.Block>
              <div style={{ marginBottom: "10px" }}>
                <ImageListComp data={voucherData?.voucherDetail} />
              </div>
              <S.PlaceTime className="flex-col">
                <p className="label">발급자</p>
                <p className="value">
                  {voucherData.issuer ? voucherData.issuer : "-"}
                </p>
              </S.PlaceTime>
              <S.PlaceTime className="flex-col">
                <p className="label">이용 가능한 매장</p>
                <p className="value">
                  {voucherData.availableStores
                    ? voucherData.availableStores
                    : "-"}
                </p>
              </S.PlaceTime>
              <S.PlaceTime className="flex-col">
                <p className="label">예약 조건</p>
                <p className="value">
                  {voucherData.reservationConditions
                    ? voucherData.reservationConditions
                    : "-"}
                </p>
              </S.PlaceTime>
              <S.PlaceTime className="flex-col">
                <p className="label">유효 기간</p>
                <p className="value">
                  {voucherData.validityPeriod
                    ? voucherData.validityPeriod
                    : "-"}
                </p>
              </S.PlaceTime>
              <S.PlaceTime className="flex-col">
                <p className="label">취소/환불 조건</p>
                <p className="value">
                  {voucherData.cancelRefundPolicy
                    ? voucherData.cancelRefundPolicy
                    : "-"}
                </p>
              </S.PlaceTime>
              <S.PlaceTime className="flex-col">
                <p className="label">환불 정책</p>
                <p className="value">
                  {voucherData.balanceRefundConditions
                    ? voucherData.balanceRefundConditions
                    : "-"}
                </p>
              </S.PlaceTime>
              <S.PlaceTime className="flex-col">
                <p className="label">환불 방법</p>
                <p className="value">
                  {voucherData.refundMethod ? voucherData.refundMethod : "-"}
                </p>
              </S.PlaceTime>
              <S.PlaceTime className="flex-col">
                <p className="label">소비자 상담 연락처</p>
                <p className="value">
                  {voucherData.consumerConsultationContact
                    ? voucherData.consumerConsultationContact
                    : "-"}
                </p>
              </S.PlaceTime>
            </S.Block>
          </Col>
          <Col className="gutter-row" span={16}>
            {voucherData?.progress !== "INCOMING" && (
              <S.Block>
                <S.ProgressDetail>
                  <p className="label">판매액/목표금액</p>
                  <p className="value">
                    {MoneyConvert(
                      voucherData?.totalSold * voucherData?.voucherPrice
                    ) || 0}
                    원 / {MoneyConvert(voucherData?.targetAmount) || 0}원
                  </p>
                </S.ProgressDetail>
                <Progress
                  percent={
                    ((voucherData?.totalSold * voucherData?.voucherPrice) /
                      voucherData?.targetAmount) *
                    100
                  }
                  strokeColor="#526557"
                  showInfo={false}
                />
                <S.ProgressDetail>
                  <div className="sold">
                    {voucherData?.totalSold}개 판매됨{" "}
                    <div className="update-sold">
                      <div className="text-u">추가 판매분 설정</div>
                      <input
                        disabled={remaining <= 0}
                        type="text"
                        value={sold}
                        onChange={(e) => {
                          const v = e.target.value.replace(/[^0-9]/g, "");
                          setSold(v);
                        }}
                        style={{ opacity: remaining <= 0 ? "0.5" : "1" }}
                      />
                      <button
                        className={`${
                          !sold || Number(sold) > Number(remaining)
                            ? "disabled"
                            : ""
                        }`}
                        disabled={!sold || Number(sold) > Number(remaining)}
                        onClick={handleUpdateSold}
                      >
                        저장
                      </button>
                      <div
                        onClick={() => setOpenHistory(true)}
                        className="view-history"
                      >
                        설정 내역 보기
                      </div>
                    </div>
                  </div>
                  <p className="available">
                    {MoneyConvert(
                      voucherData?.quantity -
                        voucherData?.totalSold -
                        voucherData?.offlineSold
                    ) || 0}
                    개 남음
                  </p>
                </S.ProgressDetail>
                {Number(sold) > Number(remaining) && (
                  <div style={{ fontSize: 12, color: "#ff4d4f" }}>
                    잔여 수량보다 적거나 같은 숫자로 기입해 주세요
                  </div>
                )}
                <div></div>
              </S.Block>
            )}

            <S.Block>
              <S.TableTitle>판매 내역</S.TableTitle>
              <div className="filter-container">
                <CsInput
                  placeholder="회원명, 연락처로 검색"
                  onChange={(e) => {
                    const inputValue: string = e.target.value;
                    const cleanedValue = inputValue.replace(/\s+/g, " ");
                    if (cleanedValue !== " ") {
                      setSearchValue(cleanedValue);
                      handleSearch(cleanedValue);
                    }
                  }}
                  value={searchValue}
                  suffix={<SearchIcon />}
                />
                <DateRangePicker
                  type="range"
                  placeholder={["검색 시작일", "검색 종료일"]}
                  onChange={handleChangeDate}
                  clearValue={clearFilterDate}
                  onClearFilter={() => setClearFilterDate(false)}
                />
              </div>

              <div className="tab-custom">
                <Tabs
                  defaultActiveKey={searchParams.get("type") || ""}
                  items={items}
                  onChange={onChangeTab}
                />
              </div>
            </S.Block>
          </Col>
        </Row>
      </S.Content>
      <Modal
        open={confirmVisible}
        onCancel={() => setConfirmVisible(false)}
        footer={false}
        width={460}
      >
        <S.ModalContent>
          <div style={{ justifyContent: "center", display: "flex" }}>
            <WarningCircleIcon />
          </div>
          <h3>프리미엄 멤버십 중단 사유를 입력하세요</h3>
          <p style={{ marginBottom: 0 }}>
            프리미엄 멤버십 명: {voucherData?.voucherName}
          </p>
          <p>브랜드명: {voucherData?.brandName}</p>
          <Form form={form} onFinish={handleConfirm} name="updateGbStatusMess">
            <Form.Item
              name="reason"
              rules={[
                { required: true, message: "사유를 입력하세요" },
                {
                  min: 3,
                  message: "세글자 이상 입력해 주세요",
                },
              ]}
            >
              <Input
                placeholder="입력하세요"
                onChange={setReasonValue}
                onBlur={(e: React.FocusEvent<HTMLInputElement, Element>) =>
                  form.setFieldsValue({
                    reason: e.target.value.trim().replace(/\s+/g, " "),
                  })
                }
              />
            </Form.Item>
            <S.ModalBtn>
              <ButtonComp
                status="noBackground"
                className="btn-cancel"
                onClick={() => setConfirmVisible(false)}
              >
                취소
              </ButtonComp>
              <ButtonComp className="btn-submit" htmlType="submit">
                중단하기
              </ButtonComp>
            </S.ModalBtn>
          </Form>
        </S.ModalContent>
      </Modal>
      <Modal
        open={openHistory}
        onCancel={() => setOpenHistory(false)}
        footer={false}
        width={460}
      >
        <S.ModalContent style={{ marginTop: 20 }}>
          <CsTable
            columns={columns}
            dataSource={offlineSoldHistory}
            size="small"
            className="center-table"
            // pagination={
            //   totalItem > 10
            //     ? {
            //         current: Number(searchParams.get("page") || 1),
            //         total: totalItem,
            //         onChange: (page) => {
            //           searchParams.set("page", page.toString());
            //           setSearchParams(searchParams);
            //         },
            //       }
            //     : false
            // }
            pagination={false}
          />
        </S.ModalContent>
      </Modal>
      {contextHolder}
    </S.Wrapper>
  );
};

export default CampaignDetail;
