// Trong components/Table.tsx
import { ColumnsType } from "antd/es/table";
import { ReactComponent as CaretRightIcon } from "assets/icons/CaretRight.svg";
import brandImgDemo from "assets/images/avatar-demo.png";
import ButtonComp from "components/ButtonCmp";
import CsTable from "components/Table";
import dayjs from "dayjs";
import { useNavigate, useSearchParams } from "react-router-dom";
import * as S from "./style";

interface TableProps {
  data: any[];
  totalElement: number;
}

const statusMapping: any = {
  INACTIVE: "비활성",
  ACTIVE: "활성",
  INITIAL: "가입중",
  WAITING: "대기중",
  REJECT: "비승인",
};

const BrandUserTable: React.FC<TableProps> = ({ data, totalElement }) => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const columns: ColumnsType<any> = [
    {
      title: "ID",
      dataIndex: "userId",
      key: "userId",
    },
    {
      title: "브랜드명",
      dataIndex: "brand",
      key: "brand",
      render: (_: any, record) => (
        <S.TdBrandInner>
          <img
            style={{
              width: "24px",
              height: "24px",
              objectFit: "contain",
              borderRadius: "50%",
            }}
            src={record?.logoImage || brandImgDemo}
            alt="brand"
          />
          <span>{record?.brandName}</span>
        </S.TdBrandInner>
      ),
    },
    {
      title: "브랜드ID",
      dataIndex: "userName",
      key: "userName",
    },
    {
      title: "연락처/이메일",
      dataIndex: "userInfo",
      key: "userInfo",
      render: (_: any, record) => (
        <S.TdTextInner>
          <p className="big-text">{record?.userPhoneNumber}</p>
          <p className="small-text">{record?.userEmail}</p>
        </S.TdTextInner>
      ),
    },
    {
      title: "상태",
      dataIndex: "status",
      key: "status",
      render: (_: any, record) => (
        <ButtonComp
          style={{ minWidth: 68 }}
          status={record.brandStatus.toLowerCase()}
        >
          {statusMapping[record.brandStatus]}
        </ButtonComp>
      ),
    },
    {
      title: "가입 정보",
      dataIndex: "date",
      key: "date",
      render: (_: any, record) => (
        <S.TdTextInner>
          <p className="big-text">
            {dayjs(record.registeredDate).format("YYYY/MM/DD")}
          </p>
          <p className="small-text">
            {dayjs(record.registeredDate).format("HH:mm:ss")}
          </p>
        </S.TdTextInner>
      ),
    },
    {
      title: "역할",
      dataIndex: "branchRole",
      key: "branchRole",
      render: (_, record) => (
        <S.TdTextInner>
          {record.branchRole === "HEAD_ADMIN" ? "브랜드 대표" : "not update"}
        </S.TdTextInner>
      ),
    },
    {
      title: <></>,
      dataIndex: "viewDetail",
      key: "viewDetail",
      render: (_, record) => (
        <S.ViewDetailBtn
          onClick={() => navigate(`${record.brandId}/${record.userId}`)}
        >
          <CaretRightIcon />
        </S.ViewDetailBtn>
      ),
    },
  ];

  return (
    <S.TableContainer>
      <CsTable
        columns={columns}
        dataSource={data}
        pagination={
          totalElement >= 10
            ? {
                current: Number(searchParams.get("page") || 1),
                total: totalElement,
                pageSize: 10,
                showSizeChanger: false,
                onChange: (page) => {
                  searchParams.set("page", page.toString());
                  setSearchParams(searchParams);
                },
              }
            : false
        }
      />
    </S.TableContainer>
  );
};

export default BrandUserTable;
