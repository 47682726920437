import React, { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import MainHeader from "layouts/components/Header";
import ProgressBar from "components/ProgressBar";
import CreateBrandAccountStep1 from "./Step1";
import CreateBrandAccountStep2 from "./Step2";
import * as S from "./style";

const CreateBrandAccount: React.FC = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const currentStep = Number(searchParams.get("step"));

  useEffect(() => {
    if (currentStep === 3) {
      navigate("/users/brand-user");
    }
  }, [currentStep, navigate]);

  return (
    <S.Wrapper>
      <MainHeader backLink="/users/brand-user" title="브랜드 계정 생성" />
      <ProgressBar currentStep={currentStep} />
      <S.Container>
        <S.Content>
          {currentStep === 2 ? (
            <CreateBrandAccountStep2 />
          ) : (
            <CreateBrandAccountStep1 />
          )}
        </S.Content>
      </S.Container>
    </S.Wrapper>
  );
};

export default CreateBrandAccount;
