// import InfoIcon from "assets/icons/membership/info.svg";
import { message } from "antd";
import { ColumnsType } from "antd/es/table";
import {
  downloadUsedLogApi,
  getMembershipPreRefundApi,
  getMembershipRefundHistoryApi,
} from "api/membership";
import { ReactComponent as InfoIcon } from "assets/icons/membership/info.svg";
import CsTable from "components/Table";
import dayjs from "dayjs";
import fileDownload from "js-file-download";
import React, { useState } from "react";
import { useSearchParams } from "react-router-dom";
import { MoneyConvert } from "utils/format";
import * as S from "../style";
import DownloadModal from "./DownloadModal";
import RefundDetailModal from "./RefundDetailModal";
import RefundInfoModal from "./RefundInfoModal";
import RefundTableModal from "./RefundTableModal";
import UsageHistoryInfoModal from "./UsageHistoryInfoModal";

interface Props {
  totalElement: number;
  data: any;
  getOrderMembershipHistory?: (payload?: any) => Promise<void>;
}

export type OpenModalType = {
  purchaseStatus: boolean;
  membershipUsageHistory: boolean;
  brandRefund: boolean;
  download: boolean;
  brandDirectlyRefunds: boolean;
  refundTable: boolean;
};

export const paymentStatusMapping: any = {
  PAID: "결제완료",
  CANCEL: "결제최소",
  CLOSE: "구매확정",
  USED: "맴버십 이용",
  REQUESTED: "환불요청",
  REFUNDED: "환불완료",
};
export const paymentMethodMapping: any = {
  TOSS: "신용/체크카드",
  KAKAO: "KAKAO",
  NAVER: "NAVER",
};

const OrderHistoryTable: React.FC<Props> = ({
  data,
  totalElement,
  getOrderMembershipHistory,
}) => {
  // const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();
  const [downloadData, setDownloadData] = useState<{
    brandId: number;
    subscriberId: number;
  }>();
  const [openModal, setOpenModal] = useState<OpenModalType>({
    purchaseStatus: false,
    membershipUsageHistory: false,
    brandRefund: false,
    download: false,
    brandDirectlyRefunds: false,
    refundTable: false,
  });
  const [preRefund, setPreRefund] = useState<any>();
  const [refundHistory, setRefundHistory] = useState<any>();

  const handleDownloadExcel = async () => {
    if (!downloadData?.brandId || !downloadData?.subscriberId) return;
    try {
      const resp: any = await downloadUsedLogApi({
        brandId: downloadData?.brandId,
        subscriberId: downloadData?.subscriberId,
      });
      if (resp) {
        fileDownload(resp, "맴버십 이용기록.xlsx");
        message.success("Successfully downloaded");
        setOpenModal((prev: OpenModalType) => ({
          ...prev,
          download: false,
        }));
      }
    } catch (error) {
      console.error("Error downloading file: ", error);
    }
  };

  const getHistoryRefund = async (id: number) => {
    if (!id) return;
    const { data, error }: any = await getMembershipRefundHistoryApi({ id });
    if (error) {
      console.error(error);
      return;
    }
    setRefundHistory(data);
    setOpenModal((prev: OpenModalType) => ({
      ...prev,
      refundTable: true,
    }));
  };

  const getPreRefund = async (id: number) => {
    if (!id) return;
    const { data, error }: any = await getMembershipPreRefundApi({ id });
    if (error) {
      console.error(error);
      return;
    }
    setPreRefund(data);
    setOpenModal((prev: OpenModalType) => ({
      ...prev,
      brandDirectlyRefunds: true,
    }));
  };

  // const handleRefund

  const columns: ColumnsType<any> = [
    {
      title: <S.StyledTitle>결제일시</S.StyledTitle>,
      dataIndex: "cl2",
      width: 130,
      render: (_, record) => {
        return (
          <div>
            <S.StyledSubTitle>
              {dayjs(record.payAt).format("YYYY-MM-DD")}
            </S.StyledSubTitle>
            <S.StyledSubTitle>
              {dayjs(record.payAt).format("HH:mm:ss")}
            </S.StyledSubTitle>
          </div>
        );
      },
    },
    {
      title: <S.StyledTitle>브랜드명</S.StyledTitle>,
      dataIndex: "cl0",
      width: 150,
      render: (_, record) => {
        return <S.StyledText>{record.brandName}</S.StyledText>;
      },
    },
    {
      title: <S.StyledTitle>멤버십명</S.StyledTitle>,
      dataIndex: "cl1",
      width: 120,
      render: (_, record) => {
        return <S.StyledText>{record.membershipName}</S.StyledText>;
      },
    },
    {
      title: <S.StyledTitle>회원명</S.StyledTitle>,
      dataIndex: "cln1",
      width: 120,
      render: (_, record) => {
        return <S.StyledText>{record.displayName}</S.StyledText>;
      },
    },
    {
      title: <S.StyledTitle>회원 연락처</S.StyledTitle>,
      dataIndex: "cln2",
      width: 120,
      render: (_, record) => {
        return <S.StyledText>{record.phoneNumber}</S.StyledText>;
      },
    },
    {
      title: <S.StyledTitle>회원 이메일</S.StyledTitle>,
      dataIndex: "cl8",
      width: 260,
      render: (_, record) => {
        return <S.StyledText>{record.email}</S.StyledText>;
      },
    },
    {
      title: <S.StyledTitle>결제상태</S.StyledTitle>,
      dataIndex: "cl3",
      width: 105,
      render: (_, record) => {
        return (
          <S.StyledText>
            {paymentStatusMapping[record.paymentStatus]}
          </S.StyledText>
        );
      },
    },
    {
      title: <S.StyledTitle>결제방법</S.StyledTitle>,
      dataIndex: "cl7",
      width: 106,
      render: (_, record) => {
        return (
          <S.StyledText>
            {paymentMethodMapping[record.paymentMethod]}
          </S.StyledText>
        );
      },
    },
    {
      title: <S.StyledTitle>결제 금액</S.StyledTitle>,
      dataIndex: "cl5",
      width: 131,
      render: (_, record) => {
        return <S.StyledText>{MoneyConvert(record.amount, true)}</S.StyledText>;
      },
    },
    {
      title: <S.StyledTitle>취소 금액</S.StyledTitle>,
      dataIndex: "cl6",
      width: 131,
      render: (_, record) => {
        return (
          <S.StyledText>{MoneyConvert(record.cancelAmount, true)}</S.StyledText>
        );
      },
    },
    {
      title: <S.StyledTitle>최종금액</S.StyledTitle>,
      dataIndex: "cl4",
      width: 131,
      render: (_, record) => {
        const result = record.amount - +record.cancelAmount;
        return <S.StyledText>{MoneyConvert(result, true)}</S.StyledText>;
      },
    },
    {
      title: (
        <S.StyledTitle
          className="has-info"
          onClick={() =>
            setOpenModal((prev: OpenModalType) => ({
              ...prev,
              membershipUsageHistory: true,
            }))
          }
        >
          멤버십 이용 기록
          <InfoIcon />
        </S.StyledTitle>
      ),
      dataIndex: "cl10",
      width: 155,
      render: (_, record) => {
        // return <S.StyledText>{record.cl10 === "Y" ? }</S.StyledText>;
        return (
          <React.Fragment>
            {record.used ? (
              <S.StyledTextLink
                onClick={() => {
                  setOpenModal((prev: OpenModalType) => ({
                    ...prev,
                    download: true,
                  }));
                  setDownloadData({
                    brandId: record.brandId,
                    subscriberId: record.id,
                  });
                }}
              >
                Y
              </S.StyledTextLink>
            ) : (
              <S.StyledText>N</S.StyledText>
            )}
          </React.Fragment>
        );
      },
    },
    {
      title: (
        <S.StyledTitle
          className="has-info"
          onClick={() =>
            setOpenModal((prev: OpenModalType) => ({
              ...prev,
              brandRefund: true,
            }))
          }
        >
          관리자 환불
          <InfoIcon />
        </S.StyledTitle>
      ),
      dataIndex: "cl11",
      width: 125,
      render: (_, record) => {
        return (
          <>
            {record.paymentStatus === "REQUESTED" ? (
              <S.StyledButton onClick={() => getPreRefund(record.id)}>
                환불
              </S.StyledButton>
            ) : record.paymentStatus === "REFUNDED" ? (
              <div
                onClick={() => getHistoryRefund(record.id)}
                style={{ textDecoration: "underline", cursor: "pointer" }}
              >
                환불내역
              </div>
            ) : (
              <React.Fragment />
            )}
          </>
        );
      },
    },
  ];

  return (
    <div>
      <CsTable
        className="no-margin-x membership-order-history-table"
        columns={columns}
        dataSource={data}
        scroll={{ x: "max-content" }}
        pagination={
          totalElement && totalElement > 10
            ? {
                current: Number(searchParams.get("page")) || 1,
                total: totalElement,
                pageSize: 10,
                showSizeChanger: false,
                onChange: (page: number) => {
                  searchParams.set("page", page.toString());
                  setSearchParams(searchParams);
                },
              }
            : false
        }
      />
      {/* <PurchaseStatusInfoModal
        open={openModal.purchaseStatus}
        handleClose={() =>
          setOpenModal((prev: OpenModalType) => ({
            ...prev,
            purchaseStatus: false,
          }))
        }
      /> */}
      <UsageHistoryInfoModal
        open={openModal.membershipUsageHistory}
        handleClose={() =>
          setOpenModal((prev: OpenModalType) => ({
            ...prev,
            membershipUsageHistory: false,
          }))
        }
      />
      <RefundInfoModal
        open={openModal.brandRefund}
        handleClose={() =>
          setOpenModal((prev: OpenModalType) => ({
            ...prev,
            brandRefund: false,
          }))
        }
      />
      <RefundDetailModal
        data={preRefund}
        open={openModal.brandDirectlyRefunds}
        handleClose={() =>
          setOpenModal((prev: OpenModalType) => ({
            ...prev,
            brandDirectlyRefunds: false,
          }))
        }
        refundSuccess={getOrderMembershipHistory}
      />
      <DownloadModal
        open={openModal.download}
        handleClose={() =>
          setOpenModal((prev: OpenModalType) => ({
            ...prev,
            download: false,
          }))
        }
        handleOk={handleDownloadExcel}
      />
      <RefundTableModal
        data={refundHistory}
        open={openModal.refundTable}
        handleClose={() =>
          setOpenModal((prev: OpenModalType) => ({
            ...prev,
            refundTable: false,
          }))
        }
      />
    </div>
  );
};
export default OrderHistoryTable;
