import MainLayout from "layouts/MainLayout";
import Announcement from "pages/CustomerService/Announcement";
import AnnouncementCreate from "pages/CustomerService/Announcement/AnnouncementCreate";
import AnnouncementDetail from "pages/CustomerService/Announcement/AnnouncementDetail";
import FAQ from "pages/CustomerService/FAQ";
import FAQCreate from "pages/CustomerService/FAQ/FAQCreate";
import FAQDetail from "pages/CustomerService/FAQ/FAQDetail";
import FAQEdit from "pages/CustomerService/FAQ/FAQEdit";
import Inquiry from "pages/CustomerService/Inquiry";
import DetailInquiry from "pages/CustomerService/Inquiry/Detail";
import CampaingnManagement from "pages/GroupBuying/CampaignManagement";
import CampaignCreate from "pages/GroupBuying/CampaignManagement/CampaignCreate";
import CampaignDetail from "pages/GroupBuying/CampaignManagement/CampaignDetail";
import CampaignEdit from "pages/GroupBuying/CampaignManagement/CampaignEdit";
import OrderHistory from "pages/GroupBuying/OrderHistory";
import OrderDetail from "pages/GroupBuying/OrderHistory/OrderDetail";
import TransactionHistory from "pages/GroupBuying/TransactionHistory";
import Login from "pages/Login";
import BrandUser from "pages/UserManagement/BrandUser";
import BranUserDetail from "pages/UserManagement/BrandUser/BrandUserDetail";
import EditBrandAccount from "pages/UserManagement/BrandUser/EditBrandAccount";
import ClientUser from "pages/UserManagement/ClientUser";
import ClientUserDetail from "pages/UserManagement/ClientUser/ClientUserDetail";

import AnnouncementEdit from "pages/CustomerService/Announcement/AnnouncementEdit";
import InquiryReply from "pages/CustomerService/Inquiry/Reply";
import CreateBrandAccount from "pages/UserManagement/BrandUser/CreateBrandAccount";
import ClientUserEdit from "pages/UserManagement/ClientUser/ClientUserEdit";
import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import DeleteAccountDetail from "pages/UserManagement/ClientUser/DeleteAccountDetail";
import Protected from "routes/PrivateRoute";
import Settlement from "pages/Settlement";
import MembershipOrderHistory from "pages/Membership/OrderHistory";
import MemberManager from "pages/Membership/MemberManager";
import NormalDetail from "pages/Membership/MemberManager/Normal/Detail";
import PremiumDetail from "pages/Membership/MemberManager/Premium/Detail";
import SlideList from "pages/Brand/SlideBanner/SlideList";
import CreateSlide from "pages/Brand/SlideBanner/CreateSlide";
import BrandRecommended from "pages/Brand/Recommended";
import EditSlide from "pages/Brand/SlideBanner/EditSlide";
import DeliveryDelay from "pages/Brand/DeliveryDelay";

const App: React.FC = () => (
  <div className="App">
    <Routes>
      <Route path="/" element={<Login />} />
      <Route path="*" element={<Navigate to="/" replace />} />
      
      <Route path="/users">
        <Route path="client-user" element={<Protected><MainLayout><ClientUser /></MainLayout></Protected>} />
        <Route path="client-user/:userId" element={<Protected><ClientUserDetail /></Protected>} />
        <Route path="client-user/list-delete/:userId" element={<Protected><DeleteAccountDetail /></Protected>} />
        <Route path="client-user/edit/:userId" element={<Protected><ClientUserEdit /></Protected>} />
        <Route path="brand-user" element={<Protected><MainLayout><BrandUser /></MainLayout></Protected>} />
        <Route path="brand-user/:brandId/:userId" element={<Protected><BranUserDetail /></Protected>} />
        <Route path="brand-user/create-brand-account" element={<Protected><CreateBrandAccount /></Protected>} />
        <Route path="brand-user/edit/:brandId/:userId" element={<Protected><EditBrandAccount /></Protected>} />
      </Route>

      <Route path="/group-buying">
        <Route path="campaign" element={<Protected><MainLayout><CampaingnManagement /></MainLayout></Protected>} />
        <Route path="campaign/campaign-create" element={<Protected><CampaignCreate /></Protected>} />
        <Route path="campaign/campaign-edit/:campaignId" element={<Protected><CampaignEdit /></Protected>} />
        <Route path="campaign/:campaignId" element={<Protected><CampaignDetail /></Protected>} />
        <Route path="order" element={<Protected><MainLayout><OrderHistory /></MainLayout></Protected>} />
        <Route path="order/:orderId" element={<Protected><OrderDetail /></Protected>} />
        <Route path="transaction" element={<Protected><MainLayout><TransactionHistory /></MainLayout></Protected>} />
      </Route>

      <Route path="/settlement" element={<Protected><MainLayout><Settlement /></MainLayout></Protected>} />

      <Route path="/membership">
        <Route path="order-history" element={<Protected><MainLayout><MembershipOrderHistory /></MainLayout></Protected>} />
        <Route path="member-manager" element={<Protected><MainLayout><MemberManager /></MainLayout></Protected>} />
        <Route path="normal-detail/:userId" element={<Protected><MainLayout><NormalDetail /></MainLayout></Protected>} />
        <Route path="premium-detail/:userId/:brandId" element={<Protected><MainLayout><PremiumDetail /></MainLayout></Protected>} />
      </Route>

      <Route path="/brand">
        <Route path="slide-management" element={<Protected><MainLayout><SlideList /></MainLayout></Protected>} />
        <Route path="create-slide" element={<Protected><CreateSlide /></Protected>} />
        <Route path="edit-slide/:id" element={<Protected><EditSlide /></Protected>} />
        <Route path="recommend" element={<Protected><MainLayout><BrandRecommended /></MainLayout></Protected>} />
        <Route path="set-delivery-delay" element={<Protected><MainLayout><DeliveryDelay /></MainLayout></Protected>} />
      </Route>
    
      <Route path="/customer-service">
        <Route path="inquiry" element={<Protected><MainLayout><Inquiry /></MainLayout></Protected>} />
        <Route path="inquiry/:id" element={<Protected><DetailInquiry /></Protected>} />
        <Route path="inquiry/reply/:id" element={<Protected><InquiryReply /></Protected>} />
        <Route path="faq" element={<Protected><MainLayout><FAQ /></MainLayout></Protected>} />
        <Route path="faq/create" element={<Protected><FAQCreate /></Protected>} />
        <Route path="faq/:id" element={<Protected><FAQDetail /></Protected>} />
        <Route path="faq/edit/:id" element={<Protected><FAQEdit /></Protected>} />
        <Route path="announcement" element={<Protected><MainLayout><Announcement /></MainLayout></Protected>} />
        <Route path="announcement/create" element={<Protected><AnnouncementCreate /></Protected>} />
        <Route path="announcement/:id" element={<Protected><AnnouncementDetail /></Protected>} />
        <Route path="announcement/edit/:id" element={<Protected><AnnouncementEdit /></Protected>} />
      </Route>
    </Routes>
  </div>
);

export default App;
