// import api from "axios/config"
import api from "axiosInstance/config";
import {
  CheckAuth,
  GetClientUserDetailApi,
  GetDetailUserBrandApi,
  GetListUserApi,
  GetListUserBrandApi,
  UpdateAccountStatusApi,
  UpdateUserApi,
  loginInfo,
} from "model/user";
import { API_URL } from "../constants";

export const loginApi = async (data: loginInfo) => {
  return api.request({
    url: API_URL.LOGIN,
    method: "POST",
    // tokenRequired: true,
    data,
  });
};
// client
export const getListUserApi = async (params: GetListUserApi) => {
  return api.request({
    url: API_URL.LIST_USER,
    method: "GET",
    tokenRequired: true,
    params,
  });
};

export const getListUserDeleteApi = async (params: GetListUserApi) => {
  return api.request({
    url: API_URL.LIST_USER_DELETE,
    method: "GET",
    tokenRequired: true,
    params,
  });
};

export const getListUserDeleteCountApi = async (params: {
  querySearch?: string;
  isActive?: 1 | 0;
}) => {
  return api.request({
    url: API_URL.LIST_USER_DELETE_COUNT,
    method: "GET",
    tokenRequired: true,
    params,
  });
};

export const updateRequestDeleteApi = async (data: {
  id: number;
  isDelete: boolean;
}) => {
  return api.request({
    url: API_URL.UPDATE_REQUEST_DELETE,
    method: "POST",
    tokenRequired: true,
    data,
  });
};

export const getClientUserDetailApi = async (
  params: GetClientUserDetailApi
) => {
  return api.request({
    url: API_URL.CLIENT_USER_DETAIL,
    method: "GET",
    tokenRequired: true,
    params,
  });
};

export const updateAccountStatusApi = async (data: UpdateAccountStatusApi) => {
  return api.request({
    url: API_URL.UPDATE_ACCOUNT_STATUS,
    method: "POST",
    tokenRequired: true,
    data,
  });
};

export const updateUserApi = async (data: UpdateUserApi) => {
  return api.request({
    url: API_URL.UPDATE_USER,
    method: "POST",
    tokenRequired: true,
    data,
  });
};

// brand

export const getListUserBrandApi = async (params: GetListUserBrandApi) => {
  return api.request({
    url: API_URL.LIST_USER_BRAND,
    method: "GET",
    tokenRequired: true,
    params,
  });
};

export const getDetailUserBrandApi = async (params: GetDetailUserBrandApi) => {
  return api.request({
    url: API_URL.DETAIL_USER_BRAND,
    method: "GET",
    tokenRequired: true,
    params,
  });
};

export const checkAuth = async (params: CheckAuth) => {
  return api.request({
    url: API_URL.CHECK_AUTH,
    method: "GET",
    tokenRequired: true,
    params,
  });
};

export const getAllAccountApi = async (data: {
  membershipType?: string[];
  sortType?: string;
  sortDirect?: "asc" | "desc";
  queryType?: "NAME" | "PHONE" | "EMAIL";
  querySearch?: string;
  emailReceived?: boolean[];
  timeFilter?: "WEEK" | "MONTH" | "DATE";
  startDate?: string;
  endDate?: string;
  accountType?: string[];
  page?: number;
  size?: number;
}) => {
  return api.request({
    url: API_URL.GET_ALL_ACCOUNT,
    method: "POST",
    tokenRequired: true,
    data,
  });
};

export const getAllDetailAccountApi = async (params: { userId: number }) => {
  return api.request({
    url: API_URL.GET_ALL_DETAIL_ACCOUNT,
    method: "GET",
    tokenRequired: true,
    params,
  });
};

export const updateClientAcc = async (data: {
  id: number;
  phoneNumber?: string;
  manager?: string;
}) => {
  return api.request({
    url: API_URL.UPDATE_CLIENT_APP,
    method: "POST",
    tokenRequired: true,
    data,
  });
};

export const getPremiumOrderSingleApi = async (params: {
  userId: number;
  page?: number;
  size?: number;
}) => {
  return api.request({
    url: API_URL.GET_PREMIUM_ORDER_SINGLE_API,
    method: "GET",
    tokenRequired: true,
    params,
  });
};

export const getNormalOrderSingleApi = async (params: {
  userId: number;
  page?: number;
  size?: number;
}) => {
  return api.request({
    url: API_URL.GET_NORMAL_ORDER_SINGLE_API,
    method: "GET",
    tokenRequired: true,
    params,
  });
};

export const downloadAllAccountApi = async (data: {
  membershipType?: string[];
  sortType?: string;
  sortDirect?: "asc" | "desc";
  queryType?: "NAME" | "PHONE" | "EMAIL";
  querySearch?: string;
  emailReceived?: any[];
  timeFilter?: "WEEK" | "1M" | "DATE";
  startDate?: string;
  endDate?: string;
  accountType?: string[];
  page?: number;
  size?: number;
}) => {
  return api.request({
    url: API_URL.DOWNLOAD_ALL_ACCOUNT,
    method: "POST",
    tokenRequired: true,
    responseType: "blob",
    data,
  });
};
