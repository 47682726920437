import { Modal, ModalProps } from "antd";
import { ReactComponent as CloseIcon } from "assets/icons/membership/close.svg";
import { useState } from "react";
import { formatNumberWithCommas } from "utils/format";
interface Props extends ModalProps {
  handleClose: () => void;
  handleOk?: () => void;
}

const DownloadModal: React.FC<Props> = ({ open, handleClose, handleOk }) => {
  return (
    <Modal
      onCancel={handleClose}
      width={450}
      closeIcon={null}
      open={open}
      footer={null}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          cursor: "pointer",
        }}
        onClick={handleClose}
      >
        <h2>멤버십 이용 기록 다운로드</h2>
        <CloseIcon />
      </div>
      <br />
      <hr />
      <br />
      <div>
        <p>
          브랜드 피드 및 커뮤니티 내 멤버십 전용 콘텐츠 이용 기록을 다운로드할
          수 있습니다.
        </p>
      </div>
      <br />
      <div style={{ textAlign: "right" }}>
        <button
          style={{
            color: "#fff",
            height: 40,
            borderRadius: 50,
            backgroundColor: "#526557",
            border: 0,
            padding: "0 24px",
            cursor: "pointer",
          }}
          onClick={handleOk}
        >
          엑셀파일로 다운로드
        </button>
      </div>
      <br />
    </Modal>
  );
};
export default DownloadModal;
