import { ReactNode, useEffect, useState } from "react";
import * as S from "./style";
import { Radio, RadioChangeEvent } from "antd";

type ItemType = {
  label: ReactNode;
  value: string;
};

interface Props {
  options?: ItemType[];
  defaultValue?: string;
  onChange?: (value: string) => void;
}

function RadioComp({ options, defaultValue, onChange }: Props) {
  const [value, setValue] = useState<string>();

  const internalOnChange = (e: RadioChangeEvent) => {
    const newValue = e.target.value;
    // console.log("radio checked", newValue);
    setValue(newValue);
    onChange && onChange(newValue);
  };

  useEffect(() => {
    defaultValue && setValue(defaultValue);
  }, [defaultValue]);

  return (
    <S.Wrapper>
      <Radio.Group onChange={internalOnChange} value={value}>
        {options?.map((o: ItemType) => (
          <Radio key={o.value} value={o.value}>
            {o.label}
          </Radio>
        ))}
      </Radio.Group>
    </S.Wrapper>
  );
}

export default RadioComp;
