import { Select } from "antd";
import PickTime from "pages/Membership/OrderHistory/components/PickTime";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { CloseOutlined } from "@ant-design/icons";
import * as S from "../style";
import MultiCheckbox from "components/MultiCheckbox";
import ListBrandSelectSearch from "components/ListBrandSelectSearch";
import NormalTable from "./NormalTable";
import { downloadNormalApi, getNormalListApi } from "api/membership";
import { ReactComponent as DownloadIcon } from "assets/icons/membership/download.svg";
import fileDownload from "js-file-download";

type Option = {
  label: string;
  value: string;
};

type TimeType = "WEEK" | "1M" | "DATE";

type FilterType = {
  timeFilter?: TimeType;
  registedStatus?: string[];
  emailReceived?: string[];
  queryType?: "EMAIL" | "PHONE" | "NAME";
  querySearch?: string;
  brandId?: string;
};

const emailReceivedOptions: Option[] = [
  {
    label: "전체",
    value: "ALL",
  },
  {
    label: "Y",
    value: "1",
  },
  {
    label: "N",
    value: "0",
  },
];

const registedStatusOptions: Option[] = [
  // {
  //   label: "전체",
  //   value: "ALL",
  // },
  {
    label: "멤버십 유지",
    value: "RENEWAL",
  },
  {
    label: "만료",
    value: "EXPIRED",
  },
];

const sortOptions = [
  { value: "firstRegistedDate-desc", label: "최초가입일 최신순" },
  { value: "firstRegistedDate-asc", label: "최초가입일 오래된순" },
  { value: "totalPay-desc", label: "진행회차 내림차순" },
  { value: "totalPay-asc", label: "진행회차 오름차순" },
  { value: "lastJoiningDate-desc", label: "최근방문일 오래된순" },
  { value: "lastJoiningDate-asc", label: "최근방문일 최신순" },
];

const NormalMember: React.FC = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [filter, setFilter] = useState<FilterType>({
    timeFilter: "WEEK",
    queryType: "EMAIL",
    querySearch: "",
    registedStatus: registedStatusOptions.map((el: Option) => el.value),
    emailReceived: emailReceivedOptions.map((el: Option) => el.value),
  });
  const [isFilterChanged, setIsFilterChanged] = useState<boolean>(false);
  // const [sort, setSort] = useState<"asc" | "desc">("desc");
  const [dataTable, setDataTable] = useState<any>({
    data: [],
    totalElement: 0,
  });
  useEffect(() => {
    const registedStatusDefault = registedStatusOptions.every((option) =>
      filter?.registedStatus?.includes(option.value)
    );
    const emailReceivedDefault = emailReceivedOptions.every((option) =>
      filter?.emailReceived?.includes(option.value)
    );

    const isChanged: boolean =
      !!filter.brandId ||
      filter.timeFilter !== "WEEK" ||
      !registedStatusDefault ||
      !emailReceivedDefault ||
      filter.queryType !== "EMAIL" ||
      filter.querySearch !== "";
    setIsFilterChanged(isChanged);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter]);
  const defaultFilter = () => {
    const defaultValue: FilterType = {
      brandId: undefined,
      timeFilter: "WEEK",
      queryType: "EMAIL",
      querySearch: "",
      registedStatus: registedStatusOptions.map((el: Option) => el.value),
      emailReceived: emailReceivedOptions.map((el: Option) => el.value),
    };
    setFilter(defaultValue);
    getListNormal(defaultValue);
  };
  const handleSortChange = (value: string) => {
    const [sortType, sortDirect] = value.split("-");
    getListNormal({ sortType, sortDirect });
  };
  // console.log("filter", filter);

  const getListNormal = async (payload?: any) => {
    const { data, error }: any = await getNormalListApi({
      page: Number(searchParams.get("page")) || 1,
      size: 50,
      ...filter,
      emailReceived: filter.emailReceived?.filter((el: string) => el !== "ALL"),
      ...payload,
    });
    if (error) {
      console.log("error", error);
      return;
    }
    if (data.datas) {
      setDataTable({
        data: data.datas,
        totalElement: data.pageable.totalElement,
      });
    }
  };
  // console.log('dataTable', dataTable);

  const handleFilter = () => {
    getListNormal();
  };

  const handleDownload = async (params?: any) => {
    const resp: any = await downloadNormalApi(filter ? filter : {});
    if (resp) {
      fileDownload(resp, "member_normal.xlsx");
    }
  };

  useEffect(() => {
    getListNormal();
  }, [searchParams.get("page")]);

  return (
    <S.Normal>
      <S.Filter>
        <div className="line mb">
          <div className="block-left">브랜드명</div>
          <div className="block-right" style={{ maxWidth: "421px" }}>
            <ListBrandSelectSearch
              defaultValue={filter.brandId}
              onChange={(v) => {
                if (v === "clear") {
                  setFilter((prev: FilterType) => ({
                    ...prev,
                    brandId: undefined,
                  }));
                } else {
                  setFilter((prev: FilterType) => ({
                    ...prev,
                    brandId: v,
                  }));
                }
              }}
            />
          </div>
        </div>
        <div className="line mb">
          <div className="block-left">가입일</div>
          <div className="block-right">
            <PickTime
              defaultTimeType={filter.timeFilter}
              onChange={(t, d) => {
                setFilter((prev: FilterType) => ({
                  ...prev,
                  timeFilter: t,
                  startDate: d?.start,
                  endDate: d?.end,
                }));
              }}
            />
          </div>
        </div>
        <div className="line mb">
          <div className="block-left">가입 상태</div>
          <div className="block-right">
            <MultiCheckbox
              defaultValues={filter.registedStatus}
              options={registedStatusOptions}
              onChange={(v) =>
                setFilter((prev: FilterType) => ({
                  ...prev,
                  registedStatus: v as string[],
                }))
              }
            />
          </div>
        </div>
        <div className="line mb">
          <div className="block-left">마케팅수신</div>
          <div className="block-right">
            <MultiCheckbox
              defaultValues={filter.emailReceived}
              options={emailReceivedOptions}
              onChange={(v) =>
                setFilter((prev: FilterType) => ({
                  ...prev,
                  emailReceived: v,
                }))
              }
            />
          </div>
        </div>
        <div className="line">
          <div className="block-left">상세 조건</div>
          <div className="block-right flex">
            <div className="wr-select">
              <Select
                popupClassName="custom-select-dropdown"
                defaultValue="email"
                value={filter.queryType}
                style={{ width: 125 }}
                onChange={(value) =>
                  setFilter((prev: any) => ({
                    ...prev,
                    queryType: value as "email" | "id",
                  }))
                }
                options={[
                  { value: "EMAIL", label: "이메일계정" },
                  { value: "NAME", label: "이름" },
                  { value: "PHONE", label: "휴대전화번호" },
                ]}
              />
            </div>
            <div className="wr-input">
              <input
                value={filter.querySearch}
                onChange={(e) => {
                  setFilter((prev: any) => ({
                    ...prev,
                    querySearch: e.target.value.trim(),
                  }));
                }}
              />
              {filter?.querySearch && (
                <button
                  onClick={() =>
                    setFilter((prev: any) => ({
                      ...prev,
                      querySearch: "",
                    }))
                  }
                >
                  <CloseOutlined />
                </button>
              )}
            </div>
          </div>
        </div>
        <div className="wr-btn">
          {isFilterChanged && (
            <button
              onClick={() => {
                // setIsFilterChanged(false);
                defaultFilter();
              }}
              className="clear-btn"
            >
              Clear
            </button>
          )}
          <button onClick={handleFilter} className="filter-btn">
            검색
          </button>
        </div>
      </S.Filter>
      <S.Sort>
        <h3>전체 목록 (총 {dataTable.totalElement}건)</h3>
        <div className="sort-download">
          <div className="wr-select">
            <Select
              popupClassName="custom-select-dropdown"
              defaultValue="firstRegistedDate-desc"
              style={{ width: 200 }}
              onChange={handleSortChange}
              options={sortOptions}
            />
          </div>
          <button onClick={handleDownload}>
            <DownloadIcon /> 엑셀 다운로드
          </button>
        </div>
      </S.Sort>
      <NormalTable
        data={dataTable.data}
        totalElement={dataTable.totalElement}
      />
    </S.Normal>
  );
};

export default NormalMember;
