import { DefaultOptionType } from "antd/es/select";
import { getAllBranchApi, getTransactionHistoryApi } from "api/groupBuying";
import { ReactComponent as SearchIcon } from "assets/icons/Search.svg";
import { ReactComponent as IconSearch14 } from "assets/icons/search14.svg";
import DateRangePicker from "components/DateRangePicker";
import CsInput from "components/Input";
import ListBrandSelectSearch from "components/ListBrandSelectSearch";
import SelectCmp from "components/SelectCmp";
import { GetTransactionHistoryApi } from "model/groupBuying";
import { useCallback, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { debounce } from "utils/debounce";
import TransactionTable from "./TransactionTable";
import * as S from "./style";

const TransactionHistory: React.FC = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [dataTable, setDataTable] = useState<any[]>([]);
  const [totalElement, setTotalElement] = useState<number>(0);
  const [filter, setFilter] = useState<GetTransactionHistoryApi>({});
  // const [listBrand, setListBrand] = useState<any>([]);
  const [listBranch, setListBranch] = useState<any>([]);
  const [searchValue, setSearchValue] = useState<string>("");

  const getAllBranch = async (brandId: number) => {
    const { data, error }: any = await getAllBranchApi({
      brandId: brandId,
    });
    if (data) {
      const uniqueValues = new Set();

      const formatData = data.reduce((acc: any, item: any) => {
        if (!uniqueValues.has(item.id)) {
          const newItem: DefaultOptionType = {
            label: item.branchName,
            value: item.id,
          };

          uniqueValues.add(item.id);
          acc.push(newItem);
        }
        return acc;
      }, []);

      setListBranch(formatData);
    }
    if (error) {
      console.error(error);
    }
  };

  const getTransactionHistory = async () => {
    const queryParams: GetTransactionHistoryApi = {
      page: Number(searchParams.get("page")) || 1,
      size: 10,
      querySearch: filter?.querySearch?.trim(),
      brandId: filter?.brandId,
      branchId: filter?.branchId,
      startDate: filter?.startDate,
      endDate: filter?.endDate,
    };

    const transactionDateSort = searchParams.get("transactionDateSort") as
      | "asc"
      | "desc";
    const amountSort = searchParams.get("amountSort") as "asc" | "desc";

    if (transactionDateSort) {
      queryParams.transactionDateSort = transactionDateSort;
    }

    if (amountSort) {
      queryParams.amountSort = amountSort;
    }

    try {
      const { data }: any = await getTransactionHistoryApi(queryParams);
      if (data.datas) {
        setDataTable(data.datas);
        setTotalElement(+data.pageable.totalElement);
      } else {
        setDataTable([]);
        setTotalElement(0);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    searchParams.delete("transactionDateSort");
    searchParams.delete("amountSort");
    searchParams.set("page", "1");
    setSearchParams(searchParams);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getTransactionHistory();
  }, [filter, searchParams]);

  const handleSearch = useCallback(
    debounce((value: string) => {
      setFilter((prev: GetTransactionHistoryApi) => {
        return { ...prev, querySearch: value };
      });
      searchParams.set("page", "1");
      setSearchParams(searchParams);
    }, 500),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const handleChangeDate = (_: any, dateString: [string, string] | string) => {
    setFilter((prev: GetTransactionHistoryApi) => ({
      ...prev,
      startDate: dateString[0],
      endDate: dateString[1],
    }));
    searchParams.set("page", "1");
    setSearchParams(searchParams);
  };
  // console.log(filter.branchId);

  return (
    <S.Wrapper>
      <div className="headings">
        <h1 className="main-headings">프리미엄 멤버십 사용 내역</h1>
        <p className="sub-heading">
          프리미엄 멤버십을 사용한 내역들을 확인 할 수 있습니다.
        </p>
      </div>
      <div className="filter-container">
        <CsInput
          placeholder="발급명, 회원명으로 검색"
          onChange={(e) => {
            const inputValue: string = e.target.value;
            const cleanedValue = inputValue.replace(/\s+/g, " ");
            if (cleanedValue !== " ") {
              setSearchValue(cleanedValue);
              handleSearch(cleanedValue);
            }
          }}
          value={searchValue}
          suffix={<SearchIcon />}
        />
        <ListBrandSelectSearch
          // defaultValue="inactive"
          style={{
            width: 180,
          }}
          // options={listBrand}
          // placeholder="Brand"
          iconWhenOpen={<IconSearch14 />}
          type="filter"
          onChange={(value) => {
            if (value === "clear") {
              setFilter((prev: GetTransactionHistoryApi) => ({
                ...prev,
                brandId: undefined,
              }));
            } else {
              setFilter((prev: GetTransactionHistoryApi) => ({
                ...prev,
                brandId: Number(value),
              }));
            }
            if (filter.brandId) {
              setFilter((prev: GetTransactionHistoryApi) => ({
                ...prev,
                branchId: undefined,
              }));
            }
            getAllBranch(Number(value));
            searchParams.set("page", "1");
            setSearchParams(searchParams);
          }}
        />
        <SelectCmp
          // defaultValue="inactive"
          value={filter.branchId}
          disabled={filter.brandId ? false : true}
          style={{
            width: 180,
          }}
          options={listBranch}
          placeholder="사용처"
          onChange={(value) => {
            setFilter((prev: GetTransactionHistoryApi) => ({
              ...prev,
              branchId: value,
            }));
            searchParams.set("page", "1");
            setSearchParams(searchParams);
          }}
        />
        <DateRangePicker
          type="range"
          placeholder={["검색 시작일", "검색 종료일"]}
          onChange={handleChangeDate}
        />
      </div>
      <TransactionTable data={dataTable} totalElement={totalElement} />
    </S.Wrapper>
  );
};

export default TransactionHistory;
