import { Modal, ModalProps } from "antd";
import { ColumnsType } from "antd/es/table";
import { ReactComponent as CloseIcon } from "assets/icons/membership/close.svg";
import CsTable from "components/Table";
import dayjs from "dayjs";
import { MoneyConvert } from "utils/format";

interface Props extends ModalProps {
  handleClose: () => void;
  data?: any;
}

const RefundTableModal: React.FC<Props> = ({ open, data, handleClose }) => {
  const dataTable = [
    {
      refundedTime: data?.refundedTime,
      cancelAmount: data?.cancelAmount,
    },
  ];
  
  const columns: ColumnsType<any> = [
    {
      title: "결제취소일시",
      dataIndex: "cl1",
      width: "50%",
      render: (_, record) => {
        console.log('record', record);
        
        return (
          <div style={{ textAlign: "center" }}>
            <div>{dayjs(record?.refundedTime).format("YYYY-MM-DD")}</div>
            <div>{dayjs(record?.refundedTime).format("HH:mm:ss")}</div>
          </div>
        );
      },
    },
    {
      title: "취소금액",
      dataIndex: "cl2",
      width: "50%",
      render: (_, record) => {
        return <div>{MoneyConvert(record?.cancelAmount, true)}</div>;
      },
    },
  ];

  return (
    <Modal
      onCancel={handleClose}
      width={450}
      closeIcon={null}
      open={open}
      footer={null}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          cursor: "pointer",
        }}
        onClick={handleClose}
      >
        <h2>관리자 환불</h2>
        <CloseIcon />
      </div>
      <br />
      <hr />
      <br />
      <div>
        <CsTable
          className="tb-center"
          columns={columns}
          dataSource={dataTable || []}
          pagination={false}
        />
      </div>

      <br />
    </Modal>
  );
};
export default RefundTableModal;
