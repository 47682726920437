import type { ColumnsType } from "antd/es/table/interface";
import { ReactComponent as CaretRight } from "assets/icons/CaretRight.svg";
import imgError from "assets/images/image-error.png";
import noImage from "assets/images/no-image.png";
import CsTable from "components/Table";
import dayjs from "dayjs";
import { useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { MoneyConvert } from "utils/format";
import { SortProps } from ".";
import * as S from "./style";

interface Props {
  data: any;
  sort: SortProps | undefined;
  setSort: React.Dispatch<React.SetStateAction<SortProps | undefined>>;
  totalElement: number;
  setIds: React.Dispatch<React.SetStateAction<number[]>>;
}

export const progressMapping: any = {
  INCOMING: "대기중",
  IN_PROGRESS: "진행중",
  FINISHED: "종료",
};

const CampaignTable: React.FC<Props> = ({
  data,
  totalElement,
  setSort,
  setIds,
}) => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [sortString, setSortString] = useState<
    "ascend" | "descend" | undefined
  >();

  const columns: ColumnsType<any> = [
    {
      title: "No",
      dataIndex: "gbId",
      key: "gbId",
    },
    {
      title: "브랜드",
      dataIndex: "brandName",
      key: "brandName",
      render: (_, record) => (
        <S.BrandInfo>
          <img
            onError={(e: any) => {
              e.target.src = imgError;
            }}
            src={record.logoImage || noImage}
            alt="brandLogo"
            width={40}
            height={40}
          />
          <div className="right-info">
            <p className="brand-name">{record.brandName}</p>
            <p className="brand-category">{record.categoryName}</p>
          </div>
        </S.BrandInfo>
      ),
    },
    {
      title: "브랜드 멤버십 발급명",
      dataIndex: "voucherName",
      key: "voucherName",
      render: (_, record) => (
        <S.VoucherInfo>
          <img
            src={record.voucherImage || noImage}
            alt="brandLogo"
            width={40}
            height={40}
            onError={(event: any) => {
              event.target.src = imgError;
            }}
          />
          <div className="right-info">
            <p className="voucher-name">{record.voucherName}</p>
            <p className="voucher-detail">
              총 발급 수: {MoneyConvert(record.quantity) || 0} | 사용 종료 날짜:{" "}
              {dayjs(record.expiredTime)
                .subtract(1, "day")
                .format("YYYY/MM/DD")}
            </p>
          </div>
        </S.VoucherInfo>
      ),
    },
    {
      title: "목표금액/판매액",
      dataIndex: "targetAmount",
      key: "targetAmount",
      render: (_, record) => (
        <S.TargetAmount>
          <p className="total">{MoneyConvert(record.targetAmount) || 0}원</p>
          <p className="per-unit">
            {MoneyConvert(record.voucherPrice) || 0}원/개
          </p>
        </S.TargetAmount>
      ),
      sorter: true,
      sortOrder: sortString,
    },
    {
      title: "공개여부",
      dataIndex: "status",
      key: "status",
      render: (_, record) => (
        <>
          {record.status ? (
            <S.StatusBtn style={{ backgroundColor: "#4AA159" }}>
              공개
            </S.StatusBtn>
          ) : (
            <S.StatusBtn style={{ backgroundColor: "#BDBDBD" }}>
              비공개
            </S.StatusBtn>
          )}
        </>
      ),
    },
    {
      title: "판매율/판매상태",
      dataIndex: "progress",
      key: "progress",
      render: (_, record) => (
        <S.Progress>
          <p className="progress">
            {(record?.progressPercent && record?.progressPercent.toFixed()) ||
              0}
            %
          </p>
          <p
            style={{
              backgroundColor: `${
                record.progress === "IN_PROGRESS" ? "#4aa159" : undefined
              }`,
              color: `${
                record.progress === "IN_PROGRESS" ? "#fff" : undefined
              }`,
            }}
            className="status"
          >
            {progressMapping[record.progress]}
          </p>
        </S.Progress>
      ),
    },
    {
      title: "발급 기간",
      dataIndex: "time",
      key: "time",
      render: (_, record) => (
        <S.RangeTime>
          <p className="start-time">
            {dayjs(record.startTime).format("YYYY/MM/DD HH:mm")}
          </p>
          <p className="end-time">
            {dayjs(record.endTime).format("YYYY/MM/DD HH:mm")}
          </p>
        </S.RangeTime>
      ),
    },
    {
      title: <></>,
      dataIndex: "viewDetail",
      key: "viewDetail",
      render: (_, record) => (
        <S.ViewDetailBtn
          onClick={() => navigate(`/group-buying/campaign/${record.gbId}`)}
        >
          <CaretRight />
        </S.ViewDetailBtn>
      ),
      width: 56,
    },
  ];

  const rowSelection = {
    onChange: (_: React.Key[], selectedRows: any) => {
      // console.log(
      //   `selectedRowKeys: ${selectedRowKeys}`,
      //   "selectedRows: ",
      //   selectedRows
      // );
      const ids = selectedRows.map((el: any) => el.gbId);
      setIds(ids);
    },
    // getCheckboxProps: (record: any) => ({
    //   disabled: record.name === "Disabled User", // Column configuration not to be checked
    //   name: record.name,
    // }),
  };

  const handleTableChange = (_: any, __: any, sorter: any) => {
    const sortOrderMap: { [key: string]: string } = {
      ascend: "asc",
      descend: "desc",
    };

    const order = sortOrderMap[sorter?.order] as
      | "ascend"
      | "descend"
      | undefined;

    if (sorter?.order !== sortString) {
      setSortString(sorter.order);
      setSort({ targetAmountSort: order });
      searchParams.set("page", "1");
      setSearchParams(searchParams);
    }
  };

  return (
    <S.TableWraper>
      <CsTable
        rowSelection={{
          type: "radio",
          ...rowSelection,
        }}
        columns={columns}
        dataSource={data}
        onChange={handleTableChange}
        sortDirections={undefined}
        pagination={{
          current: Number(searchParams.get("page")) || 1,
          total: totalElement,
          showSizeChanger: false,
          onChange: (page) => {
            setSortString(undefined);
            searchParams.set("page", page.toString());
            setSearchParams(searchParams);
          },
        }}
      />
    </S.TableWraper>
  );
};

export default CampaignTable;
