import { Table } from "antd";
import { styled } from "styled-components";

export const TableStyle = styled(Table)`
  .ant-table {
    border-radius: 0;
    .ant-table-thead {
      > tr > th {
        &:before {
          display: none;
        }
      }
    }
  }
  .ant-table,
  thead.ant-table-thead th {
    background-color: var(--sub-background);
    color: var(--text-color);
    font-family: "WorkSansSemiBold";
  }
  .ant-table tbody tr:nth-child(odd) {
    background-color: var(--light-color);
  }
  .ant-table tbody tr td {
    color: var(--text-color);
  }
  .ant-table tbody tr td:first-child,
  .ant-table thead tr th:first-child {
    padding-left: 30px;
  }
  .ant-table tbody tr td:last-child,
  .ant-table thead tr th:last-child {
    padding-right: 30px;
  }
  &.center-table {
    thead.ant-table-thead th {
      text-align: center;
    }
    tbody.ant-table-tbody td {
      text-align: center;
    }
  }
`;
