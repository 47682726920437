import { styled } from "styled-components";

export const Wrapper = styled.div`
  height: 100%;
  background-color: var(--sub-background);
  .ant-select-single {
    .ant-select-selector {
      height: 40px;
      border-radius: 50px;
      background-color: transparent;
      display: flex;
      align-items: center;
      border: 1px solid;
    }
  }
`;
export const Heading = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  padding: 0 30px;
  gap: 10px;
  .sub-heading {
    font-size: 16px;
    line-height: normal;
    font-family: var(--font-500);
  }
`;
export const Body = styled.div`
  padding: 0 30px;
`;
export const Filter = styled.div`
  margin-top: 50px;
  .ant-picker {
    border: 1px solid;
  }
  .line {
    display: flex;
    align-items: center;
    gap: 16px;
    &.mb {
      margin-bottom: 30px;
    }
    .block-left {
      width: 100px;
    }
    .block-right {
      flex: 1;
      &.flex {
        display: flex;
        gap: 16px;
      }
      /* .wr-select {
        .ant-select-single {
          .ant-select-selector {
            height: 40px;
            border-radius: 50px;
            background-color: transparent;
            display: flex;
            align-items: center;
            border: 1px solid;
          }
        }
      } */
      .wr-input {
        position: relative;
        width: 280px;
        input {
          width: 100%;
          height: 40px;
          background-color: transparent;
          border: 1px solid;
          padding: 0 10px;
          font-size: 16px;
          border-radius: 20px;
          padding-right: 45px;
          &:focus {
            outline: none;
          }
        }
        button {
          width: 30px;
          height: 30px;
          border: 0;
          position: absolute;
          right: 7px;
          top: 50%;
          transform: translateY(-50%);
          cursor: pointer;
          background-color: transparent;
        }
      }
    }
  }
  .wr-btn {
    margin-top: 40px;
    margin-left: 116px;
    display: flex;
    gap: 16px;
    max-width: 420px;
    > button {
      height: 48px;
      border: 0;
      padding: 0 16px;
      flex: 1;
      border-radius: 50px;
      cursor: pointer;
      &.filter-btn {
        background: #526557;
        color: #fff;
      }
      &.clear-btn {
        border: 1px solid #526557;
        color: #526557;
      }
    }
  }
`;
export const PickTime = styled.div`
  display: flex;
  gap: 16px;
  .wr-time-type {
    display: flex;
    border: 1px solid var(--main-border);
    border-radius: 50px;
  }
  .time-btn {
    height: 40px;
    background-color: transparent;
    border: 0;
    padding: 0 12px;
    cursor: pointer;
    &:first-child {
      border-right: 1px solid var(--main-border);
    }
    &:last-child {
      border-left: 1px solid var(--main-border);
    }
    &.active {
      font-family: var(--font-700);
    }
  }
  .wr-date {
    display: flex;
    align-items: center;
  }
  .ant-picker {
    height: 40px;
    background-color: transparent;
    border-radius: 50px;
    .ant-picker-input:hover .ant-picker-clear {
      display: none;
    }
  }
`;

export const FlexContainer = styled.div`
  margin-top: 50px;
  display: flex;
  flex-direction: column;
  gap: 23px;

  @media (min-width: 768px) {
    flex-direction: row;
    gap: 12px;
  }

  @media (min-width: 1440px) {
    gap: 20px;
  }

  margin-bottom: 20px;
`;

export const Card = styled.div`
  flex: 1;
  border: 1px solid #2d2d2d;
  border-radius: 20px;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  padding: 25px 71px;

  h3 {
    font-size: 18px;
    color: #2d2d2d;
    margin-bottom: 15px;
    font-weight: 700;
  }

  .grid {
    display: grid;
    gap: 5px;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, auto);
  }

  span {
    color: #767575;
    font-family: "Pretendard-500";
    &:nth-child(3),
    &:nth-child(4) {
      font-family: "Pretendard-600";
      color: #000;
    }
  }
`;
export const TextBottom = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 37px;

  @media (min-width: 768px) {
    margin-bottom: 46px;
  }
`;
export const Dot = styled.span`
  width: 3px;
  height: 3px;
  background-color: #767575;
  display: block;
  border-radius: 50%;
`;
export const Text = styled.span`
  font-size: 15px;
  color: #767575;
  line-height: 20px;
  font-weight: 400;
`;
export const Sort = styled.div`
  margin-bottom: 30px;
`;

export const WrapTable = styled.div`
  .ant-table thead tr th:first-child {
    padding-left: 8px;
  }
  .ant-table-wrapper .ant-table-thead > tr > th {
    padding: 12px 8px;
    text-align: center;
  }
  .ant-table thead tr th:last-child {
    padding-right: 8px;
  }
  .ant-table tbody tr td:first-child {
    padding-left: 8px;
  }
  .ant-table-wrapper .ant-table-tbody > tr > td {
    padding: 12px 8px;
    text-align: center;
  }
  .ant-table tbody tr td:last-child {
    padding-right: 8px;
  }
`;

export const StyledText = styled.div`
  font-size: 14px;
  color: #2d2d2d;
`;
export const StyledTextLink = styled.div`
  font-size: 14px;
  color: #2d2d2d;
  text-decoration: underline;
  cursor: pointer;
`;

export const StyledTitle = styled(StyledText)`
  font-size: 14px;
  font-weight: 400;
  &.has-info {
    display: flex;
    align-items: center;
    gap: 9px;
    cursor: pointer;
  }
`;

export const StyledSubTitle = styled(StyledText)`
  font-size: 14px;
  font-weight: 500;
`;

export const StyledButton = styled.button`
  height: 28px;
  padding: 0 8px;
  color: #fff;
  font-size: 14px;
  font-weight: 700;
  background-color: #2d2d2d;
  cursor: pointer;
`;
export const RefundDetailModalContent = styled.div`
  .line {
    margin-bottom: 8px;
    display: flex;
    gap: 16px;
    justify-content: space-between;
  }
  .underline {
    width: 100%;
    height: 1px;
    display: block;
    margin-top: 16px;
    margin-bottom: 24px;
    background-color: #d9d9d9;
  }
  .input-text-top {
    margin-bottom: 7px;
  }
  .wr-input {
    position: relative;
    > div {
      position: absolute;
      right: 15px;
      top: 50%;
      transform: translateY(-50%);
    }
    input {
      width: 100%;
      height: 40px;
      border-radius: 50px;
      border: 1px solid;
      padding: 0 16px;
      padding-right: 40px;
      &:focus {
        outline: none;
        box-shadow: none;
      }
    }
  }
  .wr-checkbox {
    margin-top: 16px;
  }
  .wr-btn {
    margin-top: 32px;
    text-align: right;
    button {
      height: 40px;
      padding: 0 24px;
      background-color: #526557;
      border: 0;
      color: #fff;
      cursor: pointer;
      &.disabled {
        background-color: #d9d9d9;
        cursor: default;
      }
    }
  }
`;
