import CsTable from "components/Table";
import * as S from "./style";
// import { data } from "./mockData";
import { ColumnsType } from "antd/es/table";
import { MoneyConvert } from "utils/format";
import { ReactComponent as CaretRight } from "assets/icons/CaretRight.svg";
import { useNavigate, useSearchParams } from "react-router-dom";
import { renderStatusColor } from "utils/statusRenderColor";
import dayjs from "dayjs";
import { memo, useState } from "react";
import noImage from "assets/images/no-image.png";

interface Props {
  data: any;
  totalElement: number;
}

const statusMapping: any = {
  REQUESTED: "환불신청",
  PAID: "결제완료",
  OWNED: "보유중",
  REFUNDED: "환불완료",
  WAITING: "입금대기",
};

const paymentMethodsMapping: any = {
  KAKAO: "Kakao Pay",
  NAVER: "Naver Pay",
  OWNED: "보유중",
  TOSS: "Card",
  BANKING: "계좌이체",
};

const OrderTable: React.FC<Props> = ({ data, totalElement }) => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [sortString, setSortString] = useState<{
    order: "ascend" | "descend" | undefined;
    field: string | undefined;
  }>();

  const columns: ColumnsType<any> = [
    {
      title: "주문일",
      dataIndex: "orderId",
      key: "orderId",
      width: "250px",
      render: (_, record) => (
        <S.DateInfo>
          <p className="create-date">
            {dayjs(record.createdTime).format("YYYY/MM/DD HH:mm")}
          </p>
          <p className="transaction-id">{record.id}</p>
        </S.DateInfo>
      ),
      sorter: true,
    },
    {
      title: "회원명",
      dataIndex: "customerName",
      key: "customerName",
      width: "200px",
      render: (_, record) => (
        <S.CustomerInfo>
          <p className="bank-holder">{record.displayName}</p>
          <p className="bank-account">{record.phoneNumber}</p>
        </S.CustomerInfo>
      ),
    },
    {
      title: "형태",
      dataIndex: "type",
      key: "type",
      width: "200px",
      render: (_, record) => (
        <S.CustomerInfo>
          <p className="bank-holder">
            {record.type === "GIFT" ? "선물하기" : "구매하기"}
          </p>
        </S.CustomerInfo>
      ),
    },
    {
      title: "프리미엄 멤버십 발급명 /  브랜드",
      dataIndex: "brandName",
      key: "brandName",
      width: "400px",
      render: (_, record) => (
        <S.GroupBuying>
          <S.VoucherInfo>
            <img
              src={record.voucherImage || noImage}
              alt="voucherLogo"
              width={24}
              height={24}
            />
            <p className="voucher-name">{record.voucherName}</p>
          </S.VoucherInfo>
          <S.BrandInfo>
            <img
              src={record.logoImage || noImage}
              alt="brandLogo"
              width={24}
              height={24}
            />
            <p className="brand-name">{record.brandName}</p>
          </S.BrandInfo>
        </S.GroupBuying>
      ),
    },
    {
      title: "결제방식",
      dataIndex: "paymentMethods",
      key: "paymentMethods",
      width: "200px",
      render: (_, record) => (
        <S.CustomerInfo>
          <p className="bank-holder">
            {paymentMethodsMapping[record.paymentMethod]}
          </p>
        </S.CustomerInfo>
      ),
    },
    {
      title: "수량",
      dataIndex: "brandName",
      key: "brandName",
      width: "150px",
      render: (_, record) => (
        <S.Quantity>
          <p className="voucher-amount">{MoneyConvert(record.quantity)}개</p>
          <p className="price">x{MoneyConvert(record.voucherPrice)}원</p>
        </S.Quantity>
      ),
    },
    {
      title: "결제 총 금액",
      dataIndex: "orderValue",
      key: "orderValue",
      render: (_, record) => (
        <S.Amount>{MoneyConvert(record.totalValue) || 0}원</S.Amount>
      ),
      width: "200px",
      sorter: true,
    },
    {
      title: "Invoices",
      dataIndex: "bill",
      key: "bill",
      width: "100px",
      render: (_, record: any) => <>{record?.bill ? "Y" : ""}</>,
    },
    {
      title: "상태",
      dataIndex: "status",
      key: "status",
      render: (_, record: any) => (
        <S.Status
          style={{ backgroundColor: `${renderStatusColor(record.status)}` }}
        >
          {statusMapping[record.status] || record.status}
        </S.Status>
      ),
    },
    {
      title: <></>,
      dataIndex: "viewDetail",
      key: "viewDetail",
      render: (_, record) => (
        <S.ViewDetailBtn
          onClick={() => navigate(`/group-buying/order/${record.id}`)}
        >
          <CaretRight />
        </S.ViewDetailBtn>
      ),
      // width: 56,
    },
  ];
  // console.log('sortString', sortString);

  const handleTableChange = (_: any, __: any, sorter: any) => {
    if (
      sorter?.order !== sortString?.order ||
      sorter?.field !== sortString?.field
    ) {
      // console.log("vao day");

      setSortString({
        order: sorter.order,
        field: sorter.field,
      });
      const sortFieldMap: any = {
        orderId: "orderDateSort",
        orderValue: "totalValueSort",
      };

      const sortOrderMap: any = {
        ascend: "asc",
        descend: "desc",
      };

      for (const key in sortFieldMap) {
        if (searchParams.has(sortFieldMap[key])) {
          searchParams.delete(sortFieldMap[key]);
        }
      }

      const field = sortFieldMap[sorter.field];
      const order = sortOrderMap[sorter.order];

      if (field && order) {
        searchParams.set(field, order);
      }

      searchParams.set("page", "1");
      setSearchParams(searchParams);
    }
  };

  return (
    <S.TableWraper>
      <CsTable
        onChange={handleTableChange}
        columns={columns}
        dataSource={data}
        pagination={
          totalElement > 10
            ? {
                current: Number(searchParams.get("page")) || 1,
                total: totalElement,
                pageSize: 10,
                showSizeChanger: false,
                onChange: (page) => {
                  searchParams.set("page", page.toString());
                  setSearchParams(searchParams);
                },
              }
            : false
        }
      />
    </S.TableWraper>
  );
};

export default memo(OrderTable);
