import { ColumnsType } from "antd/es/table";
import CsTable from "components/Table";
import dayjs from "dayjs";
import { useNavigate, useSearchParams } from "react-router-dom";
import { MoneyConvert } from "utils/format";

interface Props {
  data: any;
  totalElement: number;
}
const membershipLabels: any = {
  NONE: "일반 회원",
  PREMIUM: "프리미엄",
  NORMAL: "멤버십",
};
const AccountTable: React.FC<Props> = ({ data, totalElement }) => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  // const totalElement = 100;
  // console.log("data", data);
  // console.log('totalElement', totalElement);

  const columns: ColumnsType<any> = [
    {
      title: "회원가입일",
      dataIndex: "cl1",
      key: "cl1",
      // width: "250px",
      fixed: "left",
      render: (_, record) => (
        <div>{dayjs(record.createdTime).format("YYYY-MM-DD")}</div>
      ),
    },
    {
      title: "가입경로",
      dataIndex: "cl2",
      key: "cl2",
      // width: "250px",
      fixed: "left",
      render: (_, record) => <div>{record?.accountType}</div>,
    },
    {
      title: "이메일계정",
      dataIndex: "cl3",
      key: "cl3",
      width: "250px",
      fixed: "left",
      render: (_, record) => <div>{record?.email}</div>,
    },
    {
      title: "멤버십유형",
      dataIndex: "cl4",
      key: "cl4",
      fixed: "left",
      // width: "250px",
      render: (_, record) => {
        return (
          <div>{membershipLabels[record?.membershipType] || "알 수 없음"}</div>
        );
      },
    },
    {
      title: "이름",
      dataIndex: "cl5",
      key: "cl5",
      // width: "250px",
      render: (_, record) => <div>{record?.displayName}</div>,
    },
    {
      title: "전화번호",
      dataIndex: "cl6",
      key: "cl6",
      // width: "250px",
      render: (_, record) => <div>{record?.phoneNumber}</div>,
    },
    {
      title: "생년월일",
      dataIndex: "cl7",
      key: "cl7",
      render: (_, record) => (
        <div>{dayjs(record?.birthDate).format("YYYY-MM-DD")}</div>
      ),
    },
    {
      title: "성별",
      dataIndex: "cl8",
      key: "cl8",
      // width: "250px",
      render: (_, record) => (
        // <div>{dayjs(record?.lastPay).format("YYYY-MM-DD")}</div>
        // <div>
        //   {record?.registedStatus === "RENEWAL" ? "멤버십 유지" : "만료"}
        // </div>
        <div>{record?.gender === "MALE" ? "남성" : "여성"}</div>
      ),
    },
    {
      title: "최근방문일",
      dataIndex: "cl9",
      key: "cl9",
      // width: "250px",
      render: (_, record) => (
        <div>
          {record?.lastLogin
            ? dayjs(record?.lastLogin).format("YYYY-MM-DD")
            : "-"}
        </div>
      ),
    },
    {
      title: "마케팅수신",
      dataIndex: "cl10",
      key: "cl10",
      // width: "250px",
      render: (_, record) => <div>{record?.emailReceive ? "Y" : "N"}</div>,
    },
  ];

  const handleRowClick = (record: any) => {
    console.log("record", record);
    navigate(`/users/client-user/${record.id}`);
  };

  return (
    <div>
      <CsTable
        // onChange={handleTableChange}
        scroll={{ y: 750 }}
        className="no-margin-x center-table"
        columns={columns}
        dataSource={data}
        onRow={(record, rowIndex) => {
          return {
            onClick: () => handleRowClick(record), // Click vào hàng
          };
        }}
        pagination={
          totalElement >= 50
            ? {
                current: Number(searchParams.get("page")) || 1,
                total: totalElement,
                pageSize: 50,
                showSizeChanger: false,
                onChange: (page) => {
                  searchParams.set("page", page.toString());
                  setSearchParams(searchParams);
                },
              }
            : false
        }
      />
    </div>
  );
};
export default AccountTable;
