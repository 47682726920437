import { styled } from "styled-components";

export const Wrapper = styled.div``;
export const Content = styled.div`
  max-width: 732px;
  padding: 0 16px;
  width: 100%;
  margin: auto;
  display: grid;
  gap: 20px;
`;
export const BlockTop = styled.div`
  padding: 30px 20px;
  background-color: #fff;
  border-radius: 20px;
  margin-top: 20px;
  display: grid;
  gap: 12px;
  span {
    font-size: 16px;
    font-style: normal;
    line-height: normal;
    &.strong {
      font-family: var(--font-600);
    }
  }
  > h3 {
    font-size: 18px;
    font-family: var(--font-600);
  }
  .line {
    display: flex;
    justify-content: space-between;
  }
  .user-info {
    display: flex;
    align-items: center;
  }
  .vertical-line {
    width: 1px;
    height: 25px;
    background-color: #bdbdbd;
    display: block;
    margin: 0 8px;
  }
`;
export const MainContent = styled.div`
  padding: 30px 20px;
  background-color: #fff;
  border-radius: 20px;
  h4 {
    font-size: 16px;
    font-family: var(--font-600);
    line-height: normal;
    margin-bottom: 6px;
  }
`;

export const Question = styled.div`
  margin-bottom: 20px;
  h4 {
    margin-bottom: 7px;
  }
  p {
    font-size: 16px;
    line-height: 22px;
    &.desc {
      white-space: pre-line;
    }
  }
`;
export const Answer = styled.div``;
