import { LoadingOutlined } from "@ant-design/icons";
import { Button, Form, Modal, Radio, message } from "antd";
import { NoticeType } from "antd/es/message/interface";
import {
  deleteFaqDetailApi,
  getFaqDetailApi,
  updateFaqApi
} from "api/faq";
import { ReactComponent as Close } from "assets/icons/Close.svg";
import { ReactComponent as FilePlus } from "assets/icons/FilePlus.svg";
import { ReactComponent as Trash } from "assets/icons/Trash.svg";
import Editor from "components/Editor";
import CsInput from "components/Input";
import SelectCmp from "components/SelectCmp";
import MainHeader from "layouts/components/Header";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Editor as TinyMCEEditor } from "tinymce";
import { uploadFileFirst } from "utils/uploadImage";
import { categories } from "../Client";
import { statusOptions } from "../mockData";
import * as S from "./style";

const FAQEdit: React.FC = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [messageApi, contextHolder] = message.useMessage({ maxCount: 2 });
  const [form] = Form.useForm();
  const [initData, setInitData] = useState<any>({});
  const [isOpenDeleteModal, setIsOpenDeleteModal] = useState<boolean>(false);
  const [isOpenResultModal, setIsOpenResultModal] = useState<boolean>(false);
  const [deleteSuccess, setDeleteSuccess] = useState<boolean>(false);
  const [lenghtDes, setLenghDes] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(false);

  const showMess = (type: NoticeType, mess: string) => {
    messageApi.open({
      type: type,
      content: mess,
    });
  };

  const getFaqDetail = async () => {
    try {
      if (id) {
        const { data }: any = await getFaqDetailApi({ id: +id });
        if (data) {
          setInitData(data);
          form.setFieldsValue({
            category: data?.type,
            question: data?.question,
            status: data?.status,
            answer: data?.answer,
          });
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (id) {
      getFaqDetail();
    }
  }, [id]);

  const setTextSearch = (value: string) => {
    // console.log(value);
  };
  const onFinish = async (values: any) => {
    // console.log("Success:", values);
    setLoading(true);
    try {
      if (id) {
        let resultUpload = await uploadFileFirst(values.answer);
        // console.log(resultUpload);

        const { data, error }: any = await updateFaqApi({
          id: +id,
          type: values.category,
          status: values.status,
          question: values.question,
          answer: resultUpload.html,
          // answer: values.answer,
        });
        if (error?.errorCode === "OUT_OF_RANGE") {
          form.setFields([
            {
              name: "answer",
              errors: ["Please enter max 10000 characters"],
            },
          ]);
          setLoading(false);
          return;
        }
        if (data) {
          navigate(`/customer-service/faq/${id}`);
        } else {
          showMess("error", "Create Failed");
        }
        setLoading(false);
      }
    } catch (error) {
      console.error(error);
      showMess("error", "Create Failed");
      setLoading(false);
    }
  };

  const onReset = () => {
    form.resetFields();
    navigate(`/customer-service/faq/${id}`);
  };

  const onFill = (e: any) => {
    form.setFieldsValue({ note: "Hello world!", gender: "male" });
    // console.log("123123", e);
  };

  const handleChangeContent = async (
    contentHtml: string,
    editor: TinyMCEEditor
  ) => {
    const textContent = editor.getContent({ format: "text" });
    const length = textContent === "\n" ? 0 : textContent.length;
    setLenghDes(length);

    if (length === 0) {
      form.setFields([
        {
          name: "answer",
          errors: ["This field is required"],
        },
      ]);
      return;
    } else if (length < 3) {
      form.setFields([
        {
          name: "answer",
          errors: ["세글자 이상 입력해 주세요"],
        },
      ]);
      return;
    }
    form.setFieldsValue({
      answer: contentHtml,
    });
  };

  const handleDelete = async () => {
    setIsOpenDeleteModal(false);
    setIsOpenResultModal(true);
    try {
      if (id) {
        const resp: any = await deleteFaqDetailApi({ id: +id });
        if (resp.result) {
          setDeleteSuccess(true);
        } else {
          setDeleteSuccess(false);
        }
      }
    } catch (error) {
      console.log(error);
      setDeleteSuccess(false);
    }
  };

  return (
    <S.Wrapper>
      {contextHolder}
      <MainHeader backLink="/customer-service/faq" title="Edit FAQ" />
      <Form
        onFinish={onFinish}
        autoComplete="off"
        layout="vertical"
        className="form-content"
        form={form}
      >
        <S.Block>
          <Form.Item>
            <S.WrChooseCate
              style={{ display: "inline-block", width: "calc(50% - 15px)" }}
            >
              <Form.Item
                name="category"
                label="Choose category"
                rules={[{ required: true, message: "This field is require" }]}
              >
                <SelectCmp
                  // defaultValue="inactive"
                  style={{
                    width: "100%",
                    height: 48,
                  }}
                  options={categories}
                  placeholder="Choose category"
                />
              </Form.Item>
            </S.WrChooseCate>
            <Form.Item
              name="status"
              label="Status"
              style={{
                display: "inline-block",
                width: "calc(50% - 15px)",
                margin: "0 0 0 30px",
              }}
            >
              <Radio.Group size="large">
                {statusOptions?.map((item, index) => (
                  <Radio key={index} value={item.value}>
                    {item.label}
                  </Radio>
                ))}
              </Radio.Group>
            </Form.Item>
          </Form.Item>
        </S.Block>
        <S.Block>
          <Form.Item
            name="question"
            label="Question"
            rules={[{ required: true, message: "This field is require" }]}
          >
            <CsInput
              // defaultValue="inactive"
              style={{
                width: "100%",
                height: 48,
              }}
              placeholder="Write the title here"
              onChange={(e) => setTextSearch(e.target.value)}
            />
          </Form.Item>
          <Form.Item
            name="answer"
            label="Answer"
            rules={[
              {
                validator: () => {
                  if (lenghtDes === 0) {
                    return Promise.reject("This field is required!");
                  } else if (lenghtDes < 3) {
                    return Promise.reject("세글자 이상 입력해 주세요");
                  } else {
                    return Promise.resolve();
                  }
                },
              },
            ]}
          >
            <Editor
              data={initData?.answer || ""}
              onChangeValue={(e, editor) => handleChangeContent(e, editor)}
            />
          </Form.Item>
        </S.Block>
        <Form.Item className="group-btn">
          <Button
            onClick={() => setIsOpenDeleteModal(true)}
            className="btn btn-delete"
            icon={<Trash />}
          >
            Delete
          </Button>
          <Button
            onClick={onReset}
            htmlType="submit"
            className="btn btn-cancel"
            icon={<Close />}
          >
            Cancel
          </Button>
          {loading ? (
            <Button
              style={{
                width: "220px",
                height: "52px",
                backgroundColor: "#526557",
              }}
            >
              <LoadingOutlined />
            </Button>
          ) : (
            <Button
              onClick={onFill}
              htmlType="submit"
              className="btn btn-create"
              icon={<FilePlus />}
            >
              Save
            </Button>
          )}
        </Form.Item>
      </Form>
      <Modal
        open={isOpenResultModal}
        onCancel={() => setIsOpenDeleteModal(false)}
        footer={false}
      >
        <S.ModalContent>
          {deleteSuccess ? <p>Delete successfully</p> : <p>Delete failed</p>}
          <S.ModalBtn>
            <Button
              style={{
                backgroundColor: "#526557",
                borderColor: "#526557",
                color: "#fff",
                minWidth: "100px",
              }}
              onClick={() => {
                setIsOpenResultModal(false);
                navigate("/customer-service/faq");
              }}
              className="cancel-btn"
            >
              OK
            </Button>
          </S.ModalBtn>
        </S.ModalContent>
      </Modal>
      <Modal
        title="Delete Faq"
        open={isOpenDeleteModal}
        onCancel={() => setIsOpenDeleteModal(false)}
        footer={false}
      >
        <S.ModalContent>
          <p>Are you sure you want to delete?</p>
          <S.ModalBtn>
            <Button
              onClick={() => setIsOpenDeleteModal(false)}
              className="cancel-btn"
            >
              Cancel
            </Button>
            <Button onClick={handleDelete} className="delete-btn">
              Delete
            </Button>
          </S.ModalBtn>
        </S.ModalContent>
      </Modal>
    </S.Wrapper>
  );
};

export default FAQEdit;
