type Status = "REQUESTED" | "PAID" | "OWNED" | "REFUNDED" | "WAITING";
export const renderStatusColor = (status: Status) => {
  switch (status) {
    case "REQUESTED":
      return "#DD6B59";
    case "PAID":
      return "#3478F6";
    case "OWNED":
      return "#04AE01";
    case "REFUNDED":
      return "#BDBDBD";
    case "WAITING":
      return "#ff9900";
  }
};
