import React, { useEffect, useState } from "react";
import styled from "styled-components";
import CheckedIcon from "assets/images/checkbox/check_box.png";
import UnCheckedIcon from "assets/images/checkbox/check_box_outline_blank.png";
export type Option = {
  label: string;
  value: string;
};

type MultiCheckboxProps = {
  options: Option[];
  onChange?: (values: string[]) => void;
  defaultValues?: string[];
  size?: number;
  labelPaddingLeft?: number;
  labelClass?: string;
};

// Tạo một styled component cho span, với thuộc tính isSelected
const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 32px;
`;
const CheckboxText = styled.span<{ $isSelected: boolean }>`
  font-size: 15px;
  font-family: Pretendard-500;
  line-height: 20px;
  color: ${(props) => (props.$isSelected ? "#2D2D2D" : "#757575")};
`;

const CheckboxIcon = styled.span`
  position: absolute;
  top: 0;
  left: 0;
`;

const Checkmark = styled.span`
  position: absolute;
  top: 0;
  left: 0;
  cursor: pointer;
`;

const MultiCheckbox: React.FC<MultiCheckboxProps> = ({
  options,
  onChange,
  defaultValues = [],
  size = 20,
  labelPaddingLeft = 10,
  labelClass,
}) => {
  const [selectedValues, setSelectedValues] = useState<string[]>([]);
  // console.log("selectedValues", selectedValues);

  useEffect(() => {
    setSelectedValues(defaultValues);
  }, [defaultValues]);

  const handleOptionChange = (value: string) => {
    const isChecked = selectedValues.includes(value);
    let updatedValues: string[] = [];

    if (value === "ALL") {
      updatedValues = isChecked ? [] : options.map((el: Option) => el.value);
    } else {
      updatedValues = isChecked
        ? selectedValues.filter((el: string) => el !== value && el !== "ALL")
        : [...selectedValues, value];
    }

    // Auto select "All" if all options are checked
    const areAllOthersChecked = options
      .filter((option) => option.value !== "ALL")
      .every((option) => updatedValues.includes(option.value));
    if (areAllOthersChecked) {
      updatedValues = [...updatedValues, "ALL"];
    } else {
      updatedValues = updatedValues.filter((value) => value !== "ALL");
    }

    setSelectedValues(updatedValues);

    onChange && onChange(updatedValues);
  };

  return (
    <Wrapper>
      {options.map((option, index) => (
        <label
          key={index}
          style={{
            position: "relative",
            display: "flex",
            alignItems: "center",
            paddingLeft: size,
          }}
        >
          <input
            type="checkbox"
            value={option.value}
            checked={selectedValues.includes(option.value)}
            onChange={() => handleOptionChange(option.value)}
            style={{
              position: "absolute",
              opacity: 0,
              cursor: "pointer",
              left: 0,
              top: 0,
              width: 18,
              height: 18,
            }}
          />
          <CheckboxText
            $isSelected={selectedValues.includes(option.value)}
            style={{
              paddingLeft: labelPaddingLeft,
            }}
            className={labelClass}
          >
            {option.label}
          </CheckboxText>
          <CheckboxIcon>
            <img src={UnCheckedIcon} alt="UnCheckedIcon" />
          </CheckboxIcon>
          {selectedValues.includes(option.value) && (
            <Checkmark>
              <img src={CheckedIcon} alt="Checkmark" />
            </Checkmark>
          )}
        </label>
      ))}
    </Wrapper>
  );
};

export default MultiCheckbox;
