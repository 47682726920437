import { Editor } from "@tinymce/tinymce-react";
import { useEffect, useState } from "react";

// TinyMCE so the global var exists
// eslint-disable-next-line no-unused-vars
import { Editor as TinyMCEEditor } from "tinymce";
import tinymce from "tinymce/tinymce";

// Theme
import "tinymce/themes/silver";
// Toolbar icons
import "tinymce/icons/default";
// Editor styles
import "tinymce/skins/ui/oxide/skin.min.css";

import "tinymce/plugins/code";

interface Props {
  onChangeValue?: (
    htmlContent: string,
    editor: TinyMCEEditor,
    length: number
  ) => void;
  onBlur?: (e: any, t: any) => void;
  data: string;
  onInit?: (editor: TinyMCEEditor) => void;
}

export default function EditorComponent({
  onChangeValue,
  data,
}: // onInit,
Props) {
  const [value, setValue] = useState("");
  tinymce.init({
    /* ... */
    font_formats:
      "Andale Mono=andale mono,times; Arial=arial,helvetica,sans-serif; Arial Black=arial black,avant garde; Book Antiqua=book antiqua,palatino; Comic Sans MS=comic sans ms,sans-serif; Courier New=courier new,courier; Georgia=georgia,palatino; Helvetica=helvetica; Impact=impact,chicago; Symbol=symbol; Tahoma=tahoma,arial,helvetica,sans-serif; Terminal=terminal,monaco; Times New Roman=times new roman,times; Trebuchet MS=trebuchet ms,geneva; Verdana=verdana,geneva; Webdings=webdings; Wingdings=wingdings,zapf dingbats",
  });

  useEffect(() => {
    setValue(data);
  }, [data]);

  const handleChange = (content: string, editor: TinyMCEEditor) => {
    const length = editor.getContent({ format: "text" }).length;

    setValue(content);
    onChangeValue?.(content, editor, length);
  };

  return (
    <>
      <Editor
        value={value}
        onEditorChange={handleChange}
        init={{
          skin: false,
          content_css: "/reset-tinymce.css",
          browser_spellcheck: true,
          plugins: "code image editimage media link table",
          toolbar: [
            { name: "history", items: ["undo", "redo"] },
            { name: "fonts selector", items: ["fontfamily"] },
            { name: "fontsizes", items: ["fontsize"] },
            { name: "styles", items: ["styles"] },
            { name: "formatting", items: ["bold", "italic", "underline"] },
            {
              name: "alignment",
              items: ["alignleft", "aligncenter", "alignright", "alignjustify"],
            },
            { name: "indentation", items: ["outdent", "indent"] },
            {
              name: "Images",
              items: ["image", "rotateleft", "rotateright", "fliph"],
            },
            { name: "link", items: ["link"] },
            { name: "code", items: ["code"] },
            { name: "table", items: ["table"] },
            { name: "media", items: ["media"] },
          ],
          fontsize_formats: "8pt 10pt 12pt 14pt 16pt 18pt 24pt 36pt 48pt",
          font_family_formats: `
            Montserrat Regular=Montserrat-Regular;
            Montserrat Black=Montserrat-Black;
            Montserrat Light=Montserrat-Light;
            Montserrat Medium=Montserrat-Medium;
            Montserrat SemiBold=Montserrat-SemiBold;
            Montserrat Thin=Montserrat-Thin;
            Montserrat ExtraBold=Montserrat-ExtraBold;
            Montserrat ExtraBoldItalic=Montserrat-ExtraBoldItalic;
            Montserrat ExtraLight=Montserrat-ExtraLight;
            Montserrat ExtraLightItalic=Montserrat-ExtraLightItalic;
          `,
          file_picker_types: "image",
          /* and here's our custom image picker*/
          file_picker_callback: function (cb, value, meta) {
            const input = document.createElement("input");
            input.setAttribute("type", "file");
            input.setAttribute("accept", "image/*");

            /*
      Note: In modern browsers input[type="file"] is functional without
      even adding it to the DOM, but that might not be the case in some older
      or quirky browsers like IE, so you might want to add it to the DOM
      just in case, and visually hide it. And do not forget do remove it
      once you do not need it anymore.
    */

            input.onchange = function (e) {
              const file: any = input?.files?.[0];

              const reader = new FileReader();
              reader.onload = function () {
                /*
                Note: Now we need to register the blob in TinyMCEs image blob
                registry. In the next release this part hopefully won't be
                necessary, as we are looking to handle it internally.
                */
                var id = "blobid" + new Date().getTime();
                var blobCache = tinymce?.activeEditor?.editorUpload.blobCache;
                var base64 = `${reader.result}`.split(",")[1];
                var blobInfo: any = blobCache?.create(id, file, base64);
                blobCache?.add(blobInfo);

                /* call the callback and populate the Title field with the file name */
                cb(blobInfo.blobUri(), { title: file.name });
              };
              reader.readAsDataURL(file);
            };

            input.click();
          },
        }}
      />
      {/* <button onClick={log}>Log editor content</button> */}
    </>
  );
}
