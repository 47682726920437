import { DatePicker } from "antd";
import { ReactComponent as LeftArrow } from "assets/icons/settlement/left-arrow.svg";
import { ReactComponent as RightArrow } from "assets/icons/settlement/right-arrow.svg";
import React, { useEffect, useState } from "react";

import dayjs, { Dayjs } from "dayjs";
import * as S from "./style";

interface MonthPickerProps {
  onChange?: (selectedDate: Dayjs) => void;
  defaultValue?: Dayjs;
}

const MonthPicker: React.FC<MonthPickerProps> = ({ onChange, defaultValue }) => {
  const [selectedDate, setSelectedDate] = useState<Dayjs | null>(null);

  useEffect(() => {
    // Set initial value to the default value or current month
    setSelectedDate(defaultValue || dayjs());
  }, [defaultValue]);

  const formatMonth = (date: Dayjs): string => {
    return `${date.format("MMM")} ${date.year()}`;
    // return `${date.year()}년 ${date.format("MM")}월`;
    // if (i18n.language === "en") {

    // } else {

    // }
  };

  const handleMonthChange = (date: Dayjs | null, dateString: string) => {
    setSelectedDate(date);
    onChange && onChange(date!);
  };

  const handlePrevMonth = () => {
    if (selectedDate) {
      const prevMonth = selectedDate.subtract(1, "month");
      setSelectedDate(prevMonth);
      onChange && onChange(prevMonth);
    }
  };

  const handleNextMonth = () => {
    if (selectedDate) {
      const nextMonth = selectedDate.add(1, "month");
      setSelectedDate(nextMonth);
      onChange && onChange(nextMonth);
    }
  };

  return (
    <S.MonthPicker>
      <button onClick={handlePrevMonth}>
        <LeftArrow />
      </button>
      <div>
        <DatePicker.MonthPicker
          value={selectedDate}
          onChange={handleMonthChange}
          placeholder="Select Month"
          format={formatMonth}
          allowClear={false}
          suffixIcon={false}
          className={`custom-month-picker`}
        />
        <span>정산예정</span>
      </div>
      <button onClick={handleNextMonth}>
        <RightArrow />
      </button>
    </S.MonthPicker>
  );
};

export default MonthPicker;
