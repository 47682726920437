import { Button, message } from "antd";
import { DefaultOptionType } from "antd/es/select";
import { fetchCampaignList } from "api/brand";
import { fetchCategories } from "api/category";
// import { ReactComponent as Plus } from "assets/icons/Plus.svg";
import { ReactComponent as SearchIcon } from "assets/icons/Search.svg";
import DateRangePicker from "components/DateRangePicker";
import CsInput from "components/Input";
import SelectCmp from "components/SelectCmp";
import { memo, useCallback, useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import CampaignTable from "./CampainTable";
import { campaignProgressOptions, campaignStatusOptions } from "./mockData";
import * as S from "./style";
import { CampaignListData } from "model/brand";
import { debounce } from "utils/debounce";
import { duplicateGbApi } from "api/groupBuying";
import { CopyOutlined, PlusOutlined } from "@ant-design/icons";

export interface SortProps {
  targetAmountSort?: "ascend" | "descend" | undefined;
}

const CampaingnManagement: React.FC = () => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [data, setData] = useState<any>(null);
  const [filter, setFilter] = useState<CampaignListData>({});
  const [searchValue, setSearchValue] = useState<string>("");
  const [sort, setSort] = useState<SortProps | undefined>({});
  const [categories, setCategories] = useState<
    DefaultOptionType[] | undefined
  >();
  const [totalElement, setTotalElement] = useState<number>(0);
  const [ids, setIds] = useState<number[]>([]);

  const getCampaignList = async () => {
    try {
      const response: any = await fetchCampaignList({
        querySearch: filter?.querySearch?.trim(),
        categoryId: filter.categoryId,
        status: filter.status,
        progress: filter.progress,
        startTime: filter.startTime,
        endTime: filter.endTime,
        targetAmountSort: sort?.targetAmountSort,
        page: Number(searchParams.get("page") || 1),
        size: 10,
      });
      const campaignData = response.data;
      setData(campaignData);
      setTotalElement(+campaignData?.pageable?.totalElement);
    } catch (error) {
      console.error(error);
    }
  };

  const getCategories = async () => {
    try {
      const response: any = await fetchCategories();
      const formatData = response.data.map((item: any) => {
        const newItem = {
          label: item.categoryName,
          value: Number(item.id),
        };
        return newItem;
      });
      setCategories(formatData);
    } catch (error) {
      console.error(error);
    }
  };

  const handleSearch = useCallback(
    debounce((value: string) => {
      setFilter((prev: CampaignListData) => {
        return { ...prev, querySearch: value };
      });
      searchParams.set("page", "1");
      setSearchParams(searchParams);
    }, 500),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  useEffect(() => {
    getCategories();
    searchParams.set("page", "1");
    setSearchParams(searchParams);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getCampaignList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter, sort, searchParams]);

  const handleChangeDate = (_: any, dateString: [string, string] | string) => {
    setFilter((prev: CampaignListData) => ({
      ...prev,
      startTime: dateString[0],
      endTime: dateString[1],
    }));
    searchParams.set("page", "1");
    setSearchParams(searchParams);
  };

  const handleDuplicate = async () => {
    const [id] = [ids];
    navigate(`/group-buying/campaign/campaign-edit/${id}?action=duplicate`);
    // const { data, error }: any = await duplicateGbApi({ ids });
    // if (error) {
    //   console.error(error);
    //   message.error("Duplicate error");
    //   return;
    // }
    // getCampaignList();
    // message.success("Duplicate successfully");
  };

  return (
    <S.Wrapper>
      <S.Heading className="headings">
        <h1 className="main-headings">프리미엄 멤버십 관리 리스트</h1>
        <div style={{ display: "flex", gap: 16 }}>
          <Button
            icon={<CopyOutlined />}
            onClick={handleDuplicate}
            className="btn-create"
          >
            복사 발급
          </Button>
          <Button
            className="btn-create"
            icon={<PlusOutlined />}
            onClick={() => navigate("campaign-create")}
          >
            발급하기
          </Button>
        </div>
      </S.Heading>
      <div className="filter-container">
        <CsInput
          placeholder="브랜드명, 발급명으로 찾기"
          onChange={(e) => {
            const inputValue: string = e.target.value;
            const cleanedValue = inputValue.replace(/\s+/g, " ");
            if (cleanedValue !== " ") {
              setSearchValue(cleanedValue);
              handleSearch(cleanedValue);
            }
          }}
          value={searchValue}
          suffix={<SearchIcon />}
        />
        <SelectCmp
          // defaultValue="inactive"
          style={{
            width: 180,
          }}
          options={categories}
          placeholder="카테고리 분류"
          onChange={(value) => {
            setFilter((prev: CampaignListData) => ({
              ...prev,
              categoryId: value,
            }));
            searchParams.set("page", "1");
            setSearchParams(searchParams);
          }}
        />
        <SelectCmp
          // defaultValue="inactive"
          style={{
            width: 180,
          }}
          options={campaignStatusOptions}
          placeholder="오픈 상태"
          onChange={(value) => {
            setFilter((prev: CampaignListData) => ({
              ...prev,
              status: value,
            }));
            searchParams.set("page", "1");
            setSearchParams(searchParams);
          }}
        />
        <SelectCmp
          // defaultValue="inactive"
          style={{
            width: 200,
          }}
          options={campaignProgressOptions}
          placeholder="진행 상태"
          onChange={(value) => {
            setFilter((prev: CampaignListData) => ({
              ...prev,
              progress: value,
            }));
            searchParams.set("page", "1");
            setSearchParams(searchParams);
          }}
        />
        <DateRangePicker
          type="range"
          onChange={handleChangeDate}
          placeholder={["검색 시작일", "검색 종료일"]}
        />
      </div>
      <CampaignTable
        setIds={setIds}
        sort={sort}
        setSort={setSort}
        data={data?.datas ?? []}
        totalElement={totalElement}
      />
    </S.Wrapper>
  );
};

export default memo(CampaingnManagement);
