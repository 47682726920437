import { Select } from "antd";
import PickTime from "pages/Membership/OrderHistory/components/PickTime";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { CloseOutlined } from "@ant-design/icons";
import * as S from "../../Membership/MemberManager/style";
import MultiCheckbox from "components/MultiCheckbox";
import { ReactComponent as DownloadIcon } from "assets/icons/membership/download.svg";
import fileDownload from "js-file-download";
import AccountTable from "./AccountTable";
import { downloadAllAccountApi, getAllAccountApi } from "api/user";

type Option = {
  label: string;
  value: string;
};

type FilterType = {
  membershipType?: string[];
  sortType?: string;
  sortDirect?: "asc" | "desc";
  queryType?: "NAME" | "PHONE" | "EMAIL";
  querySearch?: string;
  emailReceived?: any[];
  timeFilter?: "WEEK" | "1M" | "DATE";
  startDate?: string;
  endDate?: string;
  accountType?: string[];
  page?: number;
  size?: number;
};

const joinByOptions: Option[] = [
  { label: "전체", value: "ALL" },
  {
    label: "NORMAL",
    value: "NORMAL",
  },
  {
    label: "KAKAO",
    value: "KAKAO",
  },
  {
    label: "NAVER",
    value: "NAVER",
  },
  {
    label: "APPLE",
    value: "APPLE",
  },
];

const membershipTypeOptions: Option[] = [
  { label: "전체", value: "ALL" },
  {
    label: "프리미엄",
    value: "PREMIUM",
  },
  {
    label: "멤버십",
    value: "NORMAL",
  },
  {
    label: "일반 회원",
    value: "NONE",
  },
];

const emailReceivedOptions: Option[] = [
  { label: "전체", value: "ALL" },
  {
    label: "Y",
    value: "Y",
  },
  {
    label: "N",
    value: "N",
  },
];

const sortOptions = [
  { value: "registeredDate-desc", label: "가입일 최신순" },
  { value: "registeredDate-asc", label: "가입일 오래된순" },
  { value: "lastLogin-desc", label: "최근방문일 최신순" },
  { value: "lastLogin-asc", label: "최근방문일 오래된순" },
];

const AccountList: React.FC = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [filter, setFilter] = useState<FilterType>({
    queryType: "EMAIL",
    querySearch: "",
    accountType: joinByOptions.map((el: Option) => el.value),
    membershipType: membershipTypeOptions.map((el: Option) => el.value),
    emailReceived: emailReceivedOptions.map((el: Option) => el.value),
  });
  const [isFilterChanged, setIsFilterChanged] = useState<boolean>(false);
  // const [sort, setSort] = useState<"asc" | "desc">("desc");
  const [dataTable, setDataTable] = useState<any>({
    data: [],
    totalElement: 0,
  });
  useEffect(() => {
    const accountTypeDefault = joinByOptions.every((option) =>
      filter?.accountType?.includes(option.value)
    );
    const membershipTypeDefault = membershipTypeOptions.every((option) =>
      filter?.membershipType?.includes(option.value)
    );
    const isMarketingDefault = emailReceivedOptions.every((option) =>
      filter?.emailReceived?.includes(option.value)
    );

    const isChanged: boolean =
      !!filter.timeFilter ||
      !accountTypeDefault ||
      !membershipTypeDefault ||
      !isMarketingDefault ||
      filter.queryType !== "EMAIL" ||
      filter.querySearch !== "";
    setIsFilterChanged(isChanged);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter]);
  const defaultFilter = () => {
    const defaultValues: any = {
      timeFilter: undefined,
      queryType: "EMAIL",
      querySearch: "",
      accountType: joinByOptions.map((el: Option) => el.value),
      membershipType: membershipTypeOptions.map((el: Option) => el.value),
      emailReceived: emailReceivedOptions.map((el: Option) => el.value),
    };
    setFilter(defaultValues);
    getListAccount(defaultValues);
  };

  const handleFilter = () => {
    getListAccount();
  };

  const handleSortChange = (value: string) => {
    const [sortType, sortDirect] = value.split("-");
    getListAccount({ sortType, sortDirect });
  };

  const handleDownload = async (params?: any) => {
    const resp: any = await downloadAllAccountApi(
      filter
        ? {
            ...filter,
            accountType: filter.accountType?.filter((el: any) => el !== "ALL"),
            membershipType: filter.membershipType?.filter(
              (el: any) => el !== "ALL"
            ),
            emailReceived: filter.emailReceived
              ?.filter((el: any) => el !== "ALL")
              ?.map((item: any) => item === "Y"),
          }
        : {}
    );
    if (resp) {
      fileDownload(resp, "all_account.xlsx");
    }
  };
  console.log("data", dataTable);

  const getListAccount = async (payload?: any) => {
    const { data, error }: any = await getAllAccountApi({
      page: Number(searchParams.get("page")) || 1,
      size: 50,
      ...filter,
      membershipType: filter?.membershipType?.filter((el: any) => el !== "ALL"),
      emailReceived: filter?.emailReceived
        ?.filter((el: any) => el !== "ALL")
        .map((item: any) => (item === "Y" ? true : false)),
      accountType: filter?.accountType?.filter((el: any) => el !== "ALL"),
      ...payload,
    });

    if (data?.datas) {
      setDataTable({
        data: data.datas,
        totalElement: data.pageable.totalElement,
      });
    } else {
      setDataTable({
        data: [],
        totalElement: 0,
      });
    }
  };

  useEffect(() => {
    getListAccount();
  }, [searchParams.get("page")]);

  return (
    <S.Normal>
      <S.Filter>
        <div className="line mb">
          <div className="block-left">가입일</div>
          <div className="block-right">
            <PickTime
              defaultTimeType={filter.timeFilter}
              onChange={(t, d) => {
                setFilter((prev: FilterType) => ({
                  ...prev,
                  timeFilter: t,
                  startDate: d?.start,
                  endDate: d?.end,
                }));
              }}
            />
          </div>
        </div>
        <div className="line mb">
          <div className="block-left">가입경로</div>
          <div className="block-right">
            <MultiCheckbox
              defaultValues={filter.accountType}
              options={joinByOptions}
              onChange={(v) =>
                setFilter((prev: FilterType) => ({
                  ...prev,
                  accountType: v as string[],
                }))
              }
            />
          </div>
        </div>
        <div className="line mb">
          <div className="block-left">멤버십유형</div>
          <div className="block-right">
            <MultiCheckbox
              defaultValues={filter.membershipType}
              options={membershipTypeOptions}
              onChange={(v) =>
                setFilter((prev: FilterType) => ({
                  ...prev,
                  membershipType: v,
                }))
              }
            />
          </div>
        </div>
        <div className="line mb">
          <div className="block-left">마케팅수신</div>
          <div className="block-right">
            <MultiCheckbox
              defaultValues={filter.emailReceived}
              options={emailReceivedOptions}
              onChange={(v) =>
                setFilter((prev: FilterType) => ({
                  ...prev,
                  emailReceived: v,
                }))
              }
            />
          </div>
        </div>
        <div className="line">
          <div className="block-left">상세 조건</div>
          <div className="block-right flex">
            <div className="wr-select">
              <Select
                popupClassName="custom-select-dropdown"
                defaultValue="email"
                value={filter.queryType}
                style={{ width: 125 }}
                onChange={(value) =>
                  setFilter((prev: any) => ({
                    ...prev,
                    queryType: value as "email" | "id",
                  }))
                }
                options={[
                  { value: "EMAIL", label: "이메일계정" },
                  { value: "PHONE", label: "휴대전화번호" },
                  { value: "NAME", label: "이름" },
                ]}
              />
            </div>
            <div className="wr-input">
              <input
                value={filter.querySearch}
                onChange={(e) => {
                  setFilter((prev: any) => ({
                    ...prev,
                    querySearch: e.target.value.trim(),
                  }));
                }}
              />
              {filter?.querySearch && (
                <button
                  onClick={() =>
                    setFilter((prev: any) => ({
                      ...prev,
                      querySearch: "",
                    }))
                  }
                >
                  <CloseOutlined />
                </button>
              )}
            </div>
          </div>
        </div>
        <div className="wr-btn">
          {isFilterChanged && (
            <button
              onClick={() => {
                // setIsFilterChanged(false);
                defaultFilter();
              }}
              className="clear-btn"
            >
              Clear
            </button>
          )}
          <button onClick={handleFilter} className="filter-btn">
            검색
          </button>
        </div>
      </S.Filter>
      <S.Sort>
        <h3>전체 목록 (총 {dataTable.totalElement}건)</h3>
        <div className="sort-download">
          <div className="wr-select">
            <Select
              popupClassName="custom-select-dropdown"
              defaultValue="registeredDate-desc"
              style={{ width: 200 }}
              onChange={handleSortChange}
              options={sortOptions}
            />
          </div>
          <button onClick={handleDownload}>
            <DownloadIcon /> 엑셀 다운로드
          </button>
        </div>
      </S.Sort>
      <AccountTable
        data={dataTable.data}
        totalElement={dataTable.totalElement}
      />
    </S.Normal>
  );
};

export default AccountList;
