import styled from "styled-components";

export const Wrapper = styled.div`
  &.required {
    .ant-select-selector {
      border-color: red;
    }
  }
  .ant-select-selector {
    height: 32px !important;
    line-height: 32px;
    display: flex;
    align-items: center;
    border-radius: 8px;
    .ant-select-selection-placeholder {
      font-size: 14px !important;
    }
    .ant-select-selection-item {
      display: flex;
    }
    .ant-input-affix-wrapper {
      background-color: transparent;
      .ant-input {
        background-color: transparent;
      }
    }
  }
`;
