
import api from "axiosInstance/config";
import { API_URL } from "../constants";
import { CreateAnnouncementApi, CreateAnnouncementCategoryApi, DeleteAnnouncementApi, GetAnnouncementDetailApi, GetListAnnouncementApi, UpdateAnnouncementApi } from "model/announcement";

export const createAnnouncementApi = async (data: CreateAnnouncementApi) => {
  return api.request({
    url: API_URL.CREATE_ANNOUNCEMENT,
    method: "POST",
    tokenRequired: true,
    data,
  });
};

export const updateAnnouncementApi = async (data: UpdateAnnouncementApi) => {
  return api.request({
    url: API_URL.UPDATE_ANNOUNCEMENT,
    method: "POST",
    tokenRequired: true,
    data,
  });
};


export const createAnnouncementCategoryApi = async (data: CreateAnnouncementCategoryApi) => {
  return api.request({
    url: API_URL.CREATE_ANNOUNCEMENT_CATEGORIES,
    method: "POST",
    tokenRequired: true,
    data,
  });
};

export const getAnnouncementCategoryApi = async () => {
  return api.request({
    url: API_URL.GET_ANNOUNCEMENT_CATEGORIES,
    method: "GET",
    tokenRequired: true,
  });
};

export const getListAnnouncementApi = async (params: GetListAnnouncementApi) => {
  return api.request({
    url: API_URL.GET_LIST_ANNOUNCEMENT,
    method: "GET",
    tokenRequired: true,
    params,
  });
};

export const getAnnouncementDetailApi = async (params: GetAnnouncementDetailApi) => {
  return api.request({
    url: API_URL.GET_ANNOUNCEMENT_DETAIL,
    method: "GET",
    tokenRequired: true,
    params,
  });
};

export const deleteAnnouncementDetailApi = async (data: DeleteAnnouncementApi) => {
  return api.request({
    url: API_URL.DELETE_ANNOUNCEMENT,
    method: "POST",
    tokenRequired: true,
    data,
  });
};