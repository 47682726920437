import { styled } from "styled-components";

export const Wrapper = styled.div`
  height: 100%;
  background-color: var(--sub-background);
  .tab-custom {
    .ant-tabs-nav {
      margin-bottom: 0;
    }
    .ant-tabs-nav-wrap {
      padding: 0 24px;
      .ant-tabs-nav-list {
        width: 100%;
        display: flex;
        justify-content: center;
        .ant-tabs-tab {
          flex-grow: 1;
          display: flex;
          justify-content: center;
          padding: 28px 0;
        }
        .ant-tabs-tab-active.ant-tabs-tab-active .ant-tabs-tab-btn {
          color: var(--brand-color);
          font-family: var(--font-600);
        }
        .ant-tabs-ink-bar {
          background-color: var(--brand-color);
        }
      }
    }
    .ant-tabs-tab-btn {
      font-size: 16px;
      color: var(--brand-color);
    }
    .ant-table {
      background-color: var(--light-color);
      thead.ant-table-thead th {
        background-color: var(--light-color);
        height: 92px;
      }
      tbody tr:nth-child(odd) {
        background-color: var(--sub-background);
      }
    }
  }
`;

export const Content = styled.div`
  padding: 34px 115px 19px;
`;

export const Block = styled.div`
  background-color: var(--light-color);
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  padding: 30px 20px;
  &.flex-center {
    align-items: center;
  }
  .gb-image-change {
    display: flex;
    gap: 16px;
    margin-top: 40px;
    > div {
      width: 50%;
    }
    /* img {
      max-width: 250px;
    } */
    label {
      font-size: 16px;
      font-family: var(--font-600);
      display: block;
      margin-bottom: 16px;
    }
  }
  .brand-name,
  .voucher-name {
    font-size: 16px;
    line-height: 22.4px;
    font-family: var(--font-600);
    margin-bottom: 9px;
  }
  img {
    border-radius: 5px;
  }
  .brand-category {
    margin-bottom: 36px;
    .brand-category-name {
      padding: 3px;
      border-radius: 3px;
      font-size: 12px;
      display: inline-block;
      background-color: var(--hint-color);
      line-height: 16.8px;
    }
  }
  .voucher-logo {
    margin-bottom: 6px;
  }
  .status {
    font-size: 10px;
    line-height: 16px;
    background-color: var(--hint-color);
    padding: 3px;
    border-radius: 3px;
    min-width: 50px;
    text-align: center;
  }
  .filter-container {
    display: flex;
    gap: 12px;
    height: 60px;
    margin: 30px 0 20px 0;
  }
`;

export const VoucherDetail = styled.div`
  display: flex;
  justify-content: space-between;
  &:not(:last-child) {
    margin-bottom: 20px;
  }
  .right {
    text-align: right;
  }
  .label {
    font-family: "SpaceMonoRegular";
    font-size: 12px;
    line-height: 13.2px;
    color: #858584;
    margin-bottom: 8px;
  }
  .value {
    font-size: 16px;
    line-height: 22.4px;
    font-family: var(--font-500);
    &.price {
      font-family: "SpaceMonoBold";
    }
  }
`;

export const PlaceTime = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  &.flex-start {
    align-items: flex-start;
  }
  /* &:not(:first-child, :last-child) {
    margin-bottom: 12px;
  } */
  .label {
    line-height: 19.6px;
    min-width: 200px;
  }
  .value {
    font-size: 16px;
    line-height: 25.6px;
  }
  &.flex-col {
    align-items: flex-start;
    flex-direction: column;
  }
`;

export const ProgressDetail = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  &:not(:first-child, :last-child) {
    margin-bottom: 12px;
  }
  .label {
    line-height: normal;
  }
  .value {
    font-size: 16px;
    line-height: normal;
    font-family: "SpaceMonoBold";
  }
  .sold {
    font-size: 16px;
    line-height: normal;
    display: flex;
    align-items: center;
    gap: 5px;
    .update-sold {
      display: flex;
      align-items: center;
      gap: 5px;
      input {
        border: 1px solid;
        width: 100px;
        height: 30px;
        border-radius: 8px;
        padding: 0 8px;
      }
      button {
        height: 30px;
        line-height: 30px;
        background-color: #526557;
        color: #fff;
        font-family: var(--font-600);
        padding: 0 12px;
        border-radius: 8px;
        &.disabled {
          opacity: 0.5;
        }
      }
      .view-history {
        font-size: 14px;
        text-decoration: underline;
        cursor: pointer;
      }
    }
    .text-u {
      font-family: var(--font-600);
    }
  }
  .available {
    font-size: 16px;
    font-family: var(--font-500);
  }
`;

export const TableWraper = styled.div``;
export const TableTitle = styled.div`
  font-size: 20px;
  font-family: var(--font-600);
`;

export const GpId = styled.div`
  font-size: 16px;
`;

export const GpInfo = styled.div`
  .top {
    font-size: 16px;
    margin-bottom: 10px;
  }
  .bottom {
    font-size: 12px;
  }
  .price {
    font-size: 16px;
    line-height: normal;
    font-family: "SpaceMonoBold";
  }
`;
export const ContentTop = styled.div`
  margin-bottom: 20px;
`;

export const ModalContent = styled.div`
  padding: 20px 6px 30px 6px;
  text-align: center;
  .ant-form-item-explain-error {
    text-align: left;
  }
  > h3 {
    font-size: 16px;
    font-family: var(--font-600);
    margin-bottom: 12px;
    line-height: 130%;
  }
  p {
    font-size: 16px;
    font-family: var(--font-400);
    margin-bottom: 12px;
    line-height: 130%;
  }
  .ant-input {
    height: 48px;
    border-radius: 10px;
    padding: 0 20px;
    font-size: 14px;
    line-height: 130%;
    &::placeholder {
      font-size: 14px;
      line-height: 130%;
      text-transform: capitalize;
    }
  }
`;

export const ModalBtn = styled.div`
  margin-top: 20px;
  display: flex;
  gap: 20px;
  align-items: center;
  .btn-cancel {
    width: 160px;
  }
  .btn-submit {
    width: 100%;
  }
  > div {
    &:last-child {
      flex: 1;
    }
  }
`;

export const ListImage = styled.div`
  position: relative;
`;

export const ImageItem = styled.div`
  width: 100%;
`;

export const ImageBox = styled.div`
  width: 33.3333%;
  .ant-image {
    width: 100%;
  }
  img {
    width: 152px !important;
    height: 152px !important;
    object-fit: cover;
  }
`;

export const MainList = styled.div`
  display: flex;
  gap: 8px;
  position: relative;
`;

export const PreviewModal = styled.div``;

export const ImageMore = styled.div`
  width: 152px;
  height: 152px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 0;
  z-index: 1;
  background-color: rgba(0, 0, 0, 0.5);
  color: #fff;
  font-size: 16px;
  cursor: pointer;
`;
