// import api from "axios/config"
import api from "axiosInstance/config";
import { API_URL } from "../constants";
import {
  CampaignListData,
  ChangeApproveStatus,
  CreateBrandApi,
  CreateSlideApi,
  EditSlideApi,
  SlidePositionApi,
  UpdateBrandApi,
  UpdateStatusGbApi,
} from "model/brand";

export const fetchCampaignList = async (params: CampaignListData) => {
  return api.request({
    url: API_URL.CAMPAIGN_LIST,
    method: "GET",
    tokenRequired: true,
    params: params,
  });
};

export const fetchAllBrand = async () => {
  return api.request({
    url: API_URL.LIST_BRAND,
    method: "GET",
    tokenRequired: true,
  });
};

export const createBrandApi = async (data: CreateBrandApi) => {
  return api.request({
    url: API_URL.CREATE_BRAND,
    method: "POST",
    tokenRequired: true,
    data,
  });
};

export const updateBrandApi = async (data: UpdateBrandApi) => {
  return api.request({
    url: API_URL.UPDATE_BRAND,
    method: "POST",
    tokenRequired: true,
    data,
  });
};

export const changeApproveStatus = async (data: ChangeApproveStatus) => {
  return api.request({
    url: API_URL.CHANGE_APPROVE_STATUS,
    method: "POST",
    tokenRequired: true,
    data,
  });
};

export const updateStatusGbApi = async (data: UpdateStatusGbApi) => {
  return api.request({
    url: API_URL.UPDATE_GB_STATUS,
    method: "POST",
    tokenRequired: true,
    data,
  });
};

export const getListSlideApi = async () => {
  return api.request({
    url: API_URL.GET_LIST_SLIDE,
    method: "GET",
    tokenRequired: true,
  });
};

export const getDetailSlideApi = async (params: { id: number }) => {
  return api.request({
    url: API_URL.GET_DETAIL_SLIDE,
    method: "GET",
    tokenRequired: true,
    params,
  });
};

export const createSlideApi = async (data: CreateSlideApi) => {
  return api.request({
    url: API_URL.CREATE_SLIDE,
    method: "POST",
    tokenRequired: true,
    data,
  });
};

export const editSlideApi = async (data: EditSlideApi) => {
  return api.request({
    url: API_URL.EDIT_SLIDE,
    method: "POST",
    tokenRequired: true,
    data,
  });
};

export const positionSlideApi = async (data: any) => {
  return api.request({
    url: API_URL.SLIDE_POSITION,
    method: "POST",
    tokenRequired: true,
    data,
  });
};

export const deleteSlideApi = async (data: { id: number }) => {
  return api.request({
    url: API_URL.DELETE_SLIDE,
    method: "POST",
    tokenRequired: true,
    data,
  });
};

export const getListDelayTimeApi = async () => {
  return api.request({
    url: API_URL.GET_LIST_DELAY_TIME,
    method: "GET",
    tokenRequired: true,
  });
};

export const createDelayTimeApi = async (data: {
  startTime: string;
  endTime: string;
}) => {
  return api.request({
    url: API_URL.CREATE_DELAY_TIME,
    method: "POST",
    tokenRequired: true,
    data,
  });
};

export const deleteDelayTimeApi = async (data: { id: number }) => {
  return api.request({
    url: API_URL.DELETE_DELAY_TIME,
    method: "POST",
    tokenRequired: true,
    data,
  });
};

export const getBrandHasPurchaseApi = async (params: {
  querySearch?: string;
  page?: number;
  size?: number;
}) => {
  return api.request({
    url: API_URL.LIST_BRAND_HAS_PURCHASE,
    method: "GET",
    tokenRequired: true,
    params,
  });
};

export const addPremiumHistoryApi = async (data: {
  id?: number;
  gbId?: number;
  amount?: number;
  userId?: number;
  cancelAmount?: boolean;
}) => {
  return api.request({
    url: API_URL.ADD_PREMIUM_HISTORY,
    method: "POST",
    tokenRequired: true,
    data,
  });
};

export const getPremiumLogApi = async (params: {
  userId: number;
  size?: number;
  page?: number;
}) => {
  return api.request({
    url: API_URL.GET_PREMIUM_LOG,
    method: "GET",
    tokenRequired: true,
    params,
  });
};
