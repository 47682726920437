import { styled } from "styled-components";

export const Wrapper = styled.div``;
export const Content = styled.div`
  padding: 34px 0 100px 0;
  display: grid;
  gap: 20px;
  grid-template-columns: 375px 1fr;
`;
export const BlockItem = styled.div`
  background-color: #fff;
  padding: 40px 20px;
  border-radius: 20px;
  .text-top {
    margin-bottom: 17px;
  }
  h3 {
    font-size: 16px;
    font-family: var(--font-600);
  }
  .line {
    display: flex;
    justify-content: space-between;
    align-items: center;
    &:not(:last-child) {
      margin-bottom: 14px;
    }
    .label {
      display: flex;
      align-items: center;
      gap: 12px;
      > span {
        color: #91a896;
        font-size: 14px;
        font-weight: 400;
      }
    }
    .value {
      text-align: right;
      max-width: 430px;
      .ant-image {
        display: none;
      }
      > span {
        font-size: 16px;
        font-weight: 400;
        color: #526557;
        &.strong {
          font-family: var(--font-500);
        }
      }
    }
  }
  .underline {
    width: 100%;
    height: 1px;
    background-color: #d7e1da;
    margin-top: 17px;
    margin-bottom: 30px;
  }
  &:nth-of-type(1) {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 8px;
    > h2 {
      font-size: 18px;
      font-family: var(--font-600);
    }
    > p {
      font-size: 14px;
      font-weight: 400;
    }
  }
  &:nth-of-type(2) {
    .text-top {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }
  &:nth-of-type(3) {
    /* .line:not(:last-child) {
      margin-bottom: 8px;
    } */
    span {
      font-size: 16px;
      font-weight: 400;
      line-height: normal;
    }
  }
  &:nth-of-type(4) {
    grid-row-start: 2;
    grid-row-end: 4;
    grid-column-start: 2;
    grid-column-end: 3;
  }
  &:nth-of-type(5) {
    .label {
      > span {
        font-size: 16px;
        color: var(--text-color);
      }
    }
  }
`;
export const Container = styled.div`
  max-width: 1095px;
  margin: auto;
  width: 100%;
`;
export const ContentTop = styled.div`
  margin-top: 34px;
`;
export const ModalContent = styled.div`
  padding: 20px 6px 30px 6px;
  text-align: center;
  .ant-form-item-explain-error {
    text-align: left;
  }
  > h3 {
    font-size: 16px;
    font-family: var(--font-600);
    margin-bottom: 12px;
    line-height: 130%;
  }
  p {
    font-size: 16px;
    font-family: var(--font-400);
    margin-bottom: 12px;
    line-height: 130%;
  }
  .ant-input {
    height: 48px;
    border-radius: 10px;
    padding: 0 20px;
    font-size: 14px;
    line-height: 130%;
    &::placeholder {
      font-size: 14px;
      line-height: 130%;
      text-transform: capitalize;
    }
  }
`;

export const ModalBtn = styled.div`
  margin-top: 20px;
  display: flex;
  gap: 20px;
  align-items: center;
  .btn-cancel {
    width: 160px;
  }
  .btn-submit {
    width: 100%;
  }
  > div {
    &:last-child {
      flex: 1;
    }
  }
`;

export const ViewDetail = styled.div`
  display: flex;
  gap: 12px;
  align-items: center;
  > div {
    text-decoration: underline;
    font-size: 16px;
    cursor: pointer;
  }
  > button {
    cursor: pointer;
    border: 0;
    background-color: transparent;
  }
`;
