import { styled } from "styled-components";

export const Wrapper = styled.div`
  height: 100%;
  background-color: var(--sub-background);
  .ant-input-affix-wrapper,
  .ant-picker,
  .ant-input {
    background-color: transparent;
  }
  .filter-container {
    padding: 0 30px;
    display: flex;
    gap: 12px;
    height: 60px;
    margin: 20px 0 52px 0;
  }
  .ant-select-single.ant-select-show-arrow .ant-select-selection-item {
    display: flex;
    align-items: center;
  }
`;

export const Heading = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  padding: 0 30px;
  gap: 10px;
  .sub-heading {
    font-size: 16px;
    line-height: normal;
    font-family: var(--font-500);
  }
  .btn-create {
    border-radius: 12px;
    background: var(--brand-color);
    color: var(--light-color) !important;
    width: 220px;
    height: 52px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
  }
`;

export const TdTextInner = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  p {
    margin: 0;
  }
  .big-text {
    font-size: 16px;
    line-height: 25.6px;
  }
  .small-text {
    font-size: 14px;
    line-height: 22.4px;
  }
`;

export const DateInfo = styled.div`
  .create-date {
    font-size: 16px;
    line-height: 22.4px;
    margin-bottom: 5px;
  }
  .transaction-id {
    line-height: 19.6px;
    color: #858584;
  }
`;

export const CustomerInfo = styled.div`
  .bank-holder {
    font-size: 16px;
    line-height: 22.4px;
    margin-bottom: 5px;
    font-family: var(--font-500);
  }
  .bank-account {
    line-height: 19.6px;
    color: #858584;
  }
`;

export const BrandInfo = styled.div`
  display: flex;
  gap: 6px;
  align-items: center;
`;

export const BranchInfo = styled.div`
  font-size: 16px;
  line-height: 22.4px;
  font-family: var(--font-500);
`;

export const Amount = styled.div`
  font-size: 16px;
  line-height: 22.4px;
  font-family: "SpaceMonoBold";
`;

export const Message = styled.div`
  line-height: 21px;
`;

export const TableWraper = styled.div`
  .ant-table thead tr th {
    font-size: 20px;
  }
`;
