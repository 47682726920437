import axios, { AxiosInstance, AxiosRequestConfig } from "axios";
import { store } from "../redux/store";
import { logout } from "../redux/user/userSlice";

interface AxiosRequestConfigCustom extends AxiosRequestConfig {
  tokenRequired?: boolean;
}

class API {
  api: AxiosInstance;
  url = process.env.REACT_APP_API_URL;

  constructor() {
    this.api = axios.create({
      baseURL: this.url,
      headers: {
        "Content-Type": "application/json",
      },
    });
  }

  private setAuthToken(): void {
    const token = store.getState().user.currentUser?.accessToken;
    if (token) {
      this.api.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    } else {
      delete this.api.defaults.headers.common["Authorization"];
    }
  }

  public async request<T>(config: AxiosRequestConfigCustom): Promise<T> {
    if (config.tokenRequired) {
      this.setAuthToken();
    }

    return new Promise((resolve, reject) => {
      this.api
        .request(config)
        .then((res) => resolve(res.data))
        .catch((err) => {
          if (err.response) {
            if (err.response.status === 403) {
              store.dispatch(logout());
            }
            resolve({ data: [], error: err.response.data } as T);
          }
          reject(err);
        });
    });
  }
}

class UserAPI extends API {
  constructor() {
    super();
    this.api = axios.create({
      baseURL: process.env.REACT_APP_CLIENT_API_URL,
      headers: {
        "Content-Type": "application/json",
      },
    });
    // this.history = createBrowserHistory()
  }
}

const api = new API();
export const UserAPi = new UserAPI();

export default api;
