import { useEffect } from "react";
import { useNavigate } from "react-router";
import { useAppSelecter } from "redux/hook";
import { loginSelect } from "redux/user/userSlice";

type Props = {
  children: React.ReactElement;
};

function Protected({ children }: Props) {
  const { currentUser } = useAppSelecter(loginSelect);
  const navigate = useNavigate();

  useEffect(() => {
    if (!currentUser) {
      navigate("/");
    }
  }, [navigate, currentUser]);

  return children;
}

export default Protected;
