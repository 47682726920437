import { ColumnsType } from "antd/es/table";
import { ReactComponent as CaretRight } from "assets/icons/CaretRight.svg";
import { ReactComponent as Plus } from "assets/icons/Plus.svg";
import { ReactComponent as SearchIcon } from "assets/icons/Search.svg";
import CsInput from "components/Input";
import SelectCmp from "components/SelectCmp";
import CsTable from "components/Table";
import { useNavigate, useSearchParams } from "react-router-dom";
import * as S from "./style";

import { Button } from "antd";
import { DefaultOptionType } from "antd/es/select";
import { getListFaqApi } from "api/faq";
import dayjs from "dayjs";
import { useCallback, useEffect, useState } from "react";
import { debounce } from "utils/debounce";

export const categories: DefaultOptionType[] = [
  {
    label: "사이트 이용",
    value: "MEMBERSHIP_USAGE",
  },
  {
    label: "주문 및 결제",
    value: "ORDER_PAYMENT",
  },
  {
    label: "취소 환불",
    value: "CANCELATION_REFUND",
  },
  {
    label: "기타",
    value: "OTHER",
  },
];

export const typeMappings: any = {
  MEMBERSHIP_USAGE: "Membership usage",
  ORDER_PAYMENT: "Order payment",
  CANCELATION_REFUND: "Cancelation refund",
  OTHER: "Other",
};

const Client: React.FC = () => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [dataTable, setDateTable] = useState<any>([]);
  // const [categories, setCategories] = useState<DefaultOptionType[]>([]);
  const [totalElement, setTotalElement] = useState<number>(0);
  const [dateSort, setDateSort] = useState<"asc" | "desc">();
  // const [typeId, setTypeId] = useState<number>();
  const [dateSortString, setDateSortString] = useState<
    "ascend" | "descend" | undefined
  >();
  const [searchValue, setSearchValue] = useState<string>("");

  const handleSearch = useCallback(
    debounce((value: string) => {
      setSearchValue(value);
      searchParams.set("page", "1");
      setSearchParams(searchParams);
    }, 500),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const columns: ColumnsType<any> = [
    {
      title: "질문 ",
      dataIndex: "question",
      key: "question",
      render: (_, record) => <p className="question">{record.question}</p>,
      width: "60%",
    },
    {
      title: "문의 유형",
      dataIndex: "type",
      key: "type",
      render: (_, record) => (
        <p className="category">{typeMappings[record.type] || record.type}</p>
      ),
    },
    {
      title: "작성 날짜",
      dataIndex: "createdTime",
      key: "createdTime",
      render: (_, record) => (
        <p className="create-date">
          {dayjs(record.createdTime).format("YYYY/MM/DD HH:mm")}
        </p>
      ),
      sorter: true,
    },
    {
      title: "공개 유무",
      dataIndex: "status",
      key: "status",
      render: (_, record) => (
        <p
          className={`status ${
            record.status === "SHOWING" ? "active" : "inactive"
          }`}
        >
          {record.status === "SHOWING" ? "공개" : "비공개"}
        </p>
      ),
    },
    {
      title: <></>,
      dataIndex: "viewDetail",
      key: "viewDetail",
      render: (_, record) => (
        <S.ViewDetailBtn
          onClick={() => navigate(`/customer-service/faq/${record.id}`)}
        >
          <CaretRight />
        </S.ViewDetailBtn>
      ),
      width: 56,
    },
  ];

  const getListFaq = async () => {
    try {
      const { data }: any = await getListFaqApi({
        querySearch: searchValue.trim(),
        page: Number(searchParams.get("page") || 1),
        size: 10,
        type: searchParams.get("type") || undefined,
        createDateSort: dateSort,
      });
      if (data) {
        if (data?.datas) {
          setDateTable(data.datas);
          setTotalElement(+data.pageable.totalElement);
        } else {
          setDateTable([]);
          setTotalElement(0);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  // const getFaqCategory = async () => {
  //   try {
  //     const { data }: any = await getFaqCategoryApi();
  //     if (data) {
  //       const formatData: DefaultOptionType[] = data.map((item: any) => {
  //         const newItem: DefaultOptionType = {
  //           label: item.name,
  //           value: Number(item.id),
  //         };
  //         return newItem;
  //       });
  //       setCategories(formatData);
  //     }
  //   } catch (error) {
  //     console.error(error);
  //   }
  // };

  useEffect(() => {
    getListFaq();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateSort, searchParams, searchValue]);

  // useEffect(() => {
  //   getFaqCategory();
  // }, []);

  const handleTableChange = (_: any, __: any, sorter: any) => {
    const { order } = sorter;

    if (order !== dateSortString) {
      setDateSortString(order);
      setDateSort(
        order === "ascend" ? "asc" : order === "descend" ? "desc" : undefined
      );
      searchParams.set("page", "1");
      setSearchParams(searchParams);
    }
  };

  return (
    <S.TableWraper>
      <div className="filter-container">
        <CsInput
          placeholder="질문 및 답변 검색"
          onChange={(e) => {
            const inputValue: string = e.target.value;
            const cleanedValue = inputValue.replace(/\s+/g, " ");
            if (cleanedValue !== " ") {
              setSearchValue(cleanedValue);
              handleSearch(cleanedValue);
            }
          }}
          value={searchValue}
          suffix={<SearchIcon />}
          style={{
            height: 60,
          }}
        />
        <SelectCmp
          // defaultValue="inactive"
          style={{
            width: 180,
            height: 60,
          }}
          options={categories}
          placeholder="문의 유형"
          onChange={(value) => {
            if (value) {
              searchParams.set("type", value);
              searchParams.set("page", "1");
              setSearchParams(searchParams);
            } else {
              searchParams.delete("type");
              setSearchParams(searchParams);
            }
          }}
        />
        <Button
          className="btn-create"
          icon={<Plus />}
          onClick={() => navigate(`/customer-service/faq/create`)}
        >
          FAQ작성
        </Button>
      </div>
      <CsTable
        onChange={handleTableChange}
        columns={columns}
        dataSource={dataTable}
        pagination={
          totalElement > 10
            ? {
                current: Number(searchParams.get("page")) || 1,
                total: totalElement,
                showSizeChanger: false,
                onChange: (page) => {
                  searchParams.set("page", page.toString());
                  setSearchParams(searchParams);
                },
              }
            : false
        }
      />
    </S.TableWraper>
  );
};

export default Client;
