import api from "axiosInstance/config"
import { API_URL } from "../constants";


export const uploadSingleFile = async (file: any) => {
  return api.request({
    url: API_URL.UPLOAD_SINGLE_FILE,
    method: "POST",
    data: file,
    headers: {
      "Content-Type": "multipart/form-data"
    }
  })
}

