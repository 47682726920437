import api from "axiosInstance/config";
import { API_URL } from "../constants";

export type FetSettlementProps = {
  settlementMonth: string;
  brandId?: number;
  sortType?: "brandName" | "settlementAmount";
  sortDirect?: "asc" | "desc";
  status?: 0 | 1;
  taxRelease?: 0 | 1;
  page?: number;
  size?: number;
};

export const getSettlementApi = async (params: FetSettlementProps) => {
  return api.request({
    url: API_URL.GET_SETTLEMENT,
    method: "GET",
    tokenRequired: true,
    params,
  });
};

export const handleCompleteSettlementApi = async (data: { id: number }) => {
  return api.request({
    url: API_URL.COMPLETE_SETTLEMENT,
    method: "POST",
    tokenRequired: true,
    data,
  });
};
export const handleCompleteReleaseTaxApi = async (data: { id: number }) => {
  return api.request({
    url: API_URL.COMPLETE_RELEASE_TAX,
    method: "POST",
    tokenRequired: true,
    data,
  });
};

export const downloadSettlementLogApi = async (params: FetSettlementProps) => {
  return api.request({
    url: API_URL.DOWNLOAD_SETTLEMENT_LOG,
    method: "GET",
    tokenRequired: true,
    responseType: "blob",
    params,
  });
};
