import { styled } from "styled-components";

export const Wrapper = styled.div`
  width: 100%;
  .ant-input,
  .ant-input-affix-wrapper {
    font-size: 16px;
    color: var(--brand-color);
    border-color: var(--brand-color);
    height: 100%;
    width: 100%;
    border-radius: 20px;
    padding: 0 20px;
    &::placeholder {
      color: #858584;
      opacity: 1;
    }
  }
`;
