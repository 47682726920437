import api, { UserAPi } from "axiosInstance/config";
import { API_URL } from "../constants";
import { TimeType } from "pages/Membership/OrderHistory/components/PickTime";
export type PaymentStatusType =
  | "PAID"
  | "CANCEL"
  | "CLOSE"
  | "USED"
  | "REQUESTED"
  | "REFUNDED"
  | "ALL";
export interface GetOrderMembershipHistoryApi {
  page?: number;
  size?: number;
  querySearch?: string;
  timeFilter?: TimeType;
  paymentStatus?: PaymentStatusType[];
  startDate?: string;
  endDate?: string;
  queryType?: "email" | "id";
  transactionDateSort?: "asc" | "desc";
}

export const getOrderMembershipHistoryApi = async (
  data: GetOrderMembershipHistoryApi
) => {
  return api.request({
    url: API_URL.ORDER_MEMBERSHIP_HISTORY,
    method: "POST",
    tokenRequired: true,
    data,
  });
};

export const getMembershipPreRefundApi = async (params: { id: number }) => {
  return api.request({
    url: API_URL.MEMBERSHIP_PRE_REFUND,
    method: "GET",
    tokenRequired: true,
    params,
  });
};

export const getMembershipRefundHistoryApi = async (params: { id: number }) => {
  return api.request({
    url: API_URL.MEMBERSHIP_REFUND_HISTORY,
    method: "GET",
    tokenRequired: true,
    params,
  });
};

export const getMembershipRefundApi = async (data: {
  id: number;
  amount: number;
}) => {
  return api.request({
    url: API_URL.MEMBERSHIP_REFUND,
    method: "POST",
    tokenRequired: true,
    data,
  });
};

export const downloadUsedLogApi = async (params: {
  brandId: number;
  subscriberId: number;
}) => {
  return api.request({
    url: API_URL.DOWNLOAD_USED_LOG,
    method: "GET",
    tokenRequired: true,
    responseType: "blob",
    params,
  });
};

export const getNormalListApi = async (data: any) => {
  return api.request({
    url: API_URL.GET_NORMAL_LIST,
    method: "POST",
    tokenRequired: true,
    data,
  });
};

export const getNormalDetailApi = async (params: { userId: number }) => {
  return api.request({
    url: API_URL.GET_NORMAL_DETAIL,
    method: "GET",
    tokenRequired: true,
    params,
  });
};

export const downloadNormalApi = async (data: any) => {
  return api.request({
    url: API_URL.DOWNLOAD_NORMAL,
    method: "POST",
    tokenRequired: true,
    responseType: "blob",
    data,
  });
};

export const updateEmailReceiveApi = async (data: {
  userId: number;
  emailReceived: boolean;
}) => {
  return api.request({
    url: API_URL.UPDATE_EMAIL_RECEIVE,
    method: "POST",
    tokenRequired: true,
    data,
  });
};

export const getContentHistoryApi = async (params: {
  userId: number;
  page: number;
  size: number;
}) => {
  return api.request({
    url: API_URL.GET_CONTENT_HISTORY,
    method: "GET",
    tokenRequired: true,
    params,
  });
};

export const getPostHistoryApi = async (params: {
  userId: number;
  page: number;
  size: number;
}) => {
  return api.request({
    url: API_URL.GET_POST_HISTORY,
    method: "GET",
    tokenRequired: true,
    params,
  });
};

export const getCommentOfPostApi = async (params: {
  userId: number;
  page: number;
  size: number;
}) => {
  return api.request({
    url: API_URL.GET_CONTENT_OF_POST,
    method: "GET",
    tokenRequired: true,
    params,
  });
};

export const getPremiumListApi = async (data: any) => {
  return api.request({
    url: API_URL.GET_PREMIUM_LIST,
    method: "POST",
    tokenRequired: true,
    data,
  });
};

export const getPremiumDetailApi = async (params: {
  userId: number;
  brandId: number;
}) => {
  return api.request({
    url: API_URL.GET_PREMIUM_DETAIL,
    method: "GET",
    tokenRequired: true,
    params,
  });
};

export const downloadPremiumApi = async (data: any) => {
  return api.request({
    url: API_URL.DOWNLOAD_PREMIUM,
    method: "POST",
    tokenRequired: true,
    responseType: "blob",
    data,
  });
};

export const getWalletHistoryApi = async (params: {
  userId: number;
  page: number;
  size: number;
  transactionType: string;
}) => {
  return UserAPi.request({
    url: API_URL.GET_WALLET_HISTORY,
    method: "GET",
    tokenRequired: true,
    params,
  });
};

export const getUnexpiredMembershipApi = async (params: {
  userId: number;
  page?: number;
  size?: number;
}) => {
  return api.request({
    url: API_URL.GET_UNEXPIRED_MEMBERSHIP,
    method: "GET",
    tokenRequired: true,
    params,
  });
};

export const getExpiredMembershipApi = async (params: {
  userId: number;
  page?: number;
  size?: number;
}) => {
  return api.request({
    url: API_URL.GET_EXPIRED_MEMBERSHIP,
    method: "GET",
    tokenRequired: true,
    params,
  });
};
