import { styled } from "styled-components";

export const Wrap = styled.div`
  display: flex;
  align-items: center;
  /* width: 330px; */
  gap: 24px;
  &.round {
    .upload-label {
      width: 128px;
    }
  }
  &.rect {
    .upload-label {
      width: 128px;
    }
  }
  .upload-label {
    cursor: pointer;
    height: 128px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 2px dashed var(--main-border);
    border-radius: 10px;
    overflow: hidden;
  }
  .upload-btn {
    cursor: pointer;
    border: 2px solid var(--main-border);
    padding: 12px 24px;
    border-radius: 10px;
    font-family: var(--font-500);
  }
  .ant-upload-wrapper {
    width: auto;
  }
  .hidden {
    display: none;
  }
`;

// export const BoxUpload = styled.div``;

// export const UploadEmpty = styled.div`
//   cursor: pointer;
//   width: 128px;
//   height: 128px;
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   border: 2px dashed var(--main-border);
//   border-radius: 10px;
//   overflow: hidden;
//   background-color: #fff;
//   border-radius: 50%;
//   /* img {
//     border-radius: 50%;
//   } */
// `;
