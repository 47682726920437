import api from "axiosInstance/config";
import { API_URL } from "../constants";
import { CreateCategoryApi } from "model/category";

export const fetchCategories = async () => {
  return api.request({
    url: API_URL.LIST_CATEGORY,
    method: "GET",
    tokenRequired: true,
  });
};

export const createCategoryApi = async (data: CreateCategoryApi) => {
  return api.request({
    url: API_URL.CREATE_CATEGORY,
    method: "POST",
    tokenRequired: true,
    data,
  });
};

