import { styled } from "styled-components";

export const ProgressContainer = styled.div`
  width: 100%;
  height: 3px;
  background-color: rgba(82, 101, 87, 0.1);
  display: block;
`;

export const ProgressBar = styled.div`
  background-color: rgba(82, 101, 87, 1);
  height: 3px;
  display: block;
  border-radius: 3px;
  width: 0%;
  transition: all 0.4s;
  &.step-1 {
    width: 33.333%;
  }
  &.step-2 {
    width: 66.666%;
  }
  &.step-3 {
    width: 100%;
  }
`;
