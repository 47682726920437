import { styled } from "styled-components";

export const Wrapper = styled.div`
  height: 100%;
  background-color: var(--sub-background);
  .filter-container {
    padding: 0 30px;
    display: flex;
    gap: 12px;
    height: 60px;
    margin: 30px 0 20px 0;
  }
  .form-content {
    max-width: 700px;
    margin: 30px auto 56px;

    .ant-form-item {
      margin-bottom: 0;
      .block-title {
        font-size: 20px;
        font-family: var(--font-600);
        color: var(--brand-color);
      }
      .ant-form-item-label {
        padding-bottom: 12px;
        label {
          font-size: 16px;
          font-family: var(--font-600);
          color: var(--brand-color);
        }
      }
      .checkbox-label {
        font-size: 16px;
        line-height: 22.4px;
        font-family: var(--font-500);
        color: var(--brand-color);
      }
      .ant-checkbox-inner {
        width: 20px;
        height: 20px;
        border: 2px solid #14ae5c;
      }
      .ant-radio-wrapper {
        color: var(--brand-color);
        span {
          font-family: var(--font-500);
        }
        .ant-radio {
          .ant-radio-inner {
            width: 20px;
            height: 20px;
            background-color: var(--light-color);
            border-color: var(--brand-color);
            border-width: 2px;
            &:after {
              background-color: var(--brand-color);
            }
          }
          &.ant-radio-checked {
            .ant-radio-inner {
              &:after {
                transform: scale(0.75);
              }
            }
          }
        }
      }
      .ant-picker {
        width: 100%;
        height: 48px;
      }
      .unit {
        font-size: 16px;
        line-height: 22.4px;
        font-family: var(--font-600);
        color: var(--brand-color);
      }
      &.group-btn .ant-form-item-control-input-content {
        display: flex;
        align-items: center;
        /* justify-content: space-between; */
        padding-top: 14px;
        gap: 20px;
        .btn {
          display: flex;
          height: 52px;
          justify-content: center;
          align-items: center;
          gap: 4px;
          flex-shrink: 0;
          border-radius: 12px;
          border: none;
          font-size: 16px;
          line-height: 25.6px;
        }
        .btn-cancel,
        .btn-delete {
          width: 160px;
          background: #e6e6e6;
          color: var(--brand-color) !important;
        }
        .btn-create {
          width: 220px;
          background: var(--brand-color);
          color: var(--light-color) !important;
          margin-left: auto;
        }
      }
    }
  }
`;

export const Heading = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 30px;
  .btn-create {
    border-radius: 12px;
    background: var(--brand-color);
    color: var(--light-color) !important;
    width: 220px;
    height: 52px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
  }
`;

export const Block = styled.div`
  background-color: var(--light-color);
  padding: 30px 20px;
  border-radius: 20px;
  margin-bottom: 16px;
  > .ant-form-item {
    &:not(:last-child) {
      margin-bottom: 20px;
    }
  }
`;

// modal create type
export const ModalContent = styled.div`
  padding: 30px 0 0;
`;
export const AddCate = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  font-size: 16px;
  font-family: var(--font-600);
  color: #3478f6;
  cursor: pointer;
`;

export const WrChooseCate = styled.div`
  position: relative;
`;

export const ModalBtn = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 16px;
  .ant-btn {
    height: 42px !important;
    font-size: 14px;
    border-radius: 8px;
  }
  .delete-btn {
    background-color: #f24822;
    color: #fff;
  }
  .create-cate-btn {
    background-color: #526557;
    color: #fff;
  }
`;
