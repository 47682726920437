import { styled } from "styled-components";

export const Wrapper = styled.div`
  height: 100%;
  .filter-container {
    display: flex;
    gap: 12px;
    padding: 20px 30px 52px;
    background-color: var(--sub-background);
    .btn-create {
      border-radius: 20px;
      background: var(--brand-color);
      height: 60px;
      width: 180px;
      display: flex;
      align-items: center;
      color: var(--light-color) !important;
      border: none !important;
    }
  }
  .inquiry-tabs {
    background-color: var(--light-color);
    .ant-tabs-nav {
      margin-bottom: 0;
    }
    .ant-tabs-nav-wrap {
      padding: 0 24px;
      .ant-tabs-nav-list {
        width: 100%;
        display: flex;
        justify-content: center;
        .ant-tabs-tab {
          flex-grow: 1;
          display: flex;
          justify-content: center;
          padding: 28px 0;
        }
        .ant-tabs-tab-active.ant-tabs-tab-active .ant-tabs-tab-btn {
          color: var(--brand-color);
          font-family: var(--font-600);
        }
        .ant-tabs-ink-bar {
          background-color: var(--brand-color);
        }
      }
    }
    .ant-tabs-tab-btn {
      font-size: 16px;
      color: var(--brand-color);
    }
  }
`;

export const Heading = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  padding: 100px 30px 0 30px;
  gap: 10px;
  background-color: var(--light-color);
  .sub-heading {
    font-size: 16px;
    line-height: normal;
    font-family: var(--font-500);
  }
  .btn-create {
    border-radius: 12px;
    background: var(--brand-color);
    color: var(--light-color) !important;
    width: 220px;
    height: 52px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
  }
`;

export const TableWraper = styled.div`
  .ant-input-affix-wrapper,
  .ant-picker,
  .ant-input {
    background-color: transparent;
  }
  .ant-table thead tr th {
    font-size: 20px;
  }
  .create-date {
    font-size: 16px;
  }
  .status {
    width: 54px;
    height: 32px;
    font-size: 16px;
    line-height: 22.4px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--light-color);
    &.active {
      background-color: #04ae01;
    }
    &.inactive {
      background-color: #858584;
    }
  }
`;

export const ViewDetailBtn = styled.div`
  cursor: pointer;
  display: flex;
`;
