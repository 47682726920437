import React, { useState } from "react";
import styled from "styled-components";
import ListBrandSelectSearch from "components/ListBrandSelectSearch";
import MainHeader from "layouts/components/Header";
import { useNavigate } from "react-router-dom";
import RadioComp from "components/Radio";
import { createSlideApi } from "api/brand";
import { uploadImage } from "utils/uploadImage";
import { message } from "antd";

const Wrapper = styled.div`
  /* padding: 20px; */

  label {
    margin-bottom: 7px;
    font-size: 16px;
    font-family: var(--font-600);
    display: block;
  }
`;

const Container = styled.div`
  padding: 50px 16px 80px 16px;
  max-width: 1200px;
  margin: auto;
`;

const ImageUpload = styled.div`
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  gap: 16px;
`;

const ImagePreview = styled.img`
  margin-top: 20px;
  width: 1158px;
  height: 652px;
  object-fit: cover;
  border: 1px solid #ccc;
`;

const ImageFrame = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  width: 1158px;
  height: 652px;
  object-fit: cover;
  border: 1px solid #ccc;
  font-size: 28px;
`;

const UploadLabel = styled.label`
  display: block;
  padding: 10px 20px;
  background-color: #526557;
  color: #fff;
  cursor: pointer;
  border-radius: 4px;
`;

const UploadInput = styled.input`
  display: none;
`;

const WrapSlide = styled.div`
  /* display: none; */
`;
const SelectBrand = styled.div`
  margin-bottom: 30px;
  .ant-select .ant-select-selector {
    background-color: transparent;
  }
`;
const WrapBtn = styled.div`
  display: flex;
  gap: 16px;
  justify-content: flex-end;
`;
const ButtonCreate = styled.button`
  display: inline-block;
  padding: 10px 20px;
  background-color: #526557;
  color: #fff;
  cursor: pointer;
  border-radius: 4px;
  min-width: 150px;
  text-align: center;
`;
const ButtonCancel = styled.div`
  min-width: 150px;
  text-align: center;
  display: inline-block;
  padding: 10px 20px;
  /* background-color: #526557; */
  color: #526557;
  border: 1px solid #526557;
  cursor: pointer;
  border-radius: 4px;
`;
const ChooseStatus = styled.div`
  margin-bottom: 20px;
`;

const ErrorMessage = styled.div`
  color: red;
  font-size: 14px;
  margin-top: 5px;
`;

type FieldType = {
  status: string;
  brandId?: string;
  banner?: any;
};

const CreateSlide: React.FC = () => {
  const navigate = useNavigate();
  const [image, setImage] = useState<string | null>(null);
  const [filed, setField] = useState<FieldType>({
    status: "1",
  });
  const [errors, setErrors] = useState({
    brandId: false,
    banner: false,
  });
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const handleImageUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImage(reader.result as string);
      };
      reader.readAsDataURL(file);
      setField((prev: FieldType) => ({ ...prev, banner: file }));
      setErrors((prev) => ({ ...prev, banner: false }));
    }
  };

  const handleSave = async () => {
    const { brandId, banner, status } = filed;
    if (!brandId || !banner) {
      setErrors({
        brandId: !brandId,
        banner: !banner,
      });
      return;
    }
    setIsSubmitting(true);
    let bannerUrl;

    if (banner) {
      bannerUrl = await uploadImage(banner);
    }

    const { data, error }: any = await createSlideApi({
      brandId: +brandId,
      image: bannerUrl,
      status: status === "1" ? true : false,
    });

    if (error) {
      console.error(error);
      message.error("Create slide failed");
      setIsSubmitting(false);
      return;
    }
    message.success("Slide created successfully");
    navigate("/brand/slide-management");
  };

  return (
    <Wrapper>
      <MainHeader
        backLink="/brand/slide-management"
        title="배너 등록"
        breadCrumb={[
          {
            title: "상단 배너 관리",
            link: "/brand/slide-management",
            // icon: <Campaign />,
          },
          {
            title: "배너 등록",
          },
        ]}
      />
      <Container>
        <WrapBtn>
          <ButtonCancel onClick={() => navigate("/brand/slide-management")}>
            Cancel
          </ButtonCancel>
          <ButtonCreate disabled={isSubmitting} onClick={handleSave}>
            Create
          </ButtonCreate>
        </WrapBtn>
        <ChooseStatus>
          <label>Choose Status</label>
          <RadioComp
            defaultValue={filed.status}
            onChange={(v) =>
              setField((prev: FieldType) => ({ ...prev, status: v }))
            }
            options={[
              {
                label: "Show",
                value: "1",
              },
              {
                label: "Hidden",
                value: "0",
              },
            ]}
          />
        </ChooseStatus>
        <SelectBrand>
          <label>
            Select Brand (When clicking the banner will go to branding site)
          </label>
          <ListBrandSelectSearch
            onChange={(v) => {
              setField((prev: FieldType) => ({ ...prev, brandId: v }));
              setErrors((prev) => ({ ...prev, brandId: false }));
            }}
          />
          {errors.brandId && (
            <ErrorMessage>This field is required</ErrorMessage>
          )}
        </SelectBrand>

        <WrapSlide>
          <ImageUpload>
            <UploadLabel htmlFor="upload">Upload Image</UploadLabel>
            <UploadInput
              id="upload"
              type="file"
              accept="image/*"
              onChange={handleImageUpload}
            />
          </ImageUpload>
          {errors.banner && <ErrorMessage>This field is required</ErrorMessage>}
          {image ? (
            <ImagePreview src={image} alt="Preview" />
          ) : (
            <ImageFrame>16:9 비율 권장</ImageFrame>
          )}
        </WrapSlide>
      </Container>
    </Wrapper>
  );
};

export default CreateSlide;
