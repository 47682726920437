import { Layout } from "antd";
import { ReactNode } from "react";
import Sidebar from "../components/Sidebar/Sidebar";
const { Content } = Layout;
interface Props {
  showHeader?: boolean;
  showSideBar?: boolean;
  title?: string;
  children: ReactNode | ReactNode[];
}
const MainLayout = ({ children, showSideBar = true, title }: Props) => {
  return (
    <Layout>
      <Layout>
        {showSideBar && <Sidebar />}
        <Content
          style={{
            marginLeft: "280px",
            paddingBottom: "100px",
            backgroundColor: "#F2F2EB",
          }}
        >
          {children}
        </Content>
      </Layout>
    </Layout>
  );
};

export default MainLayout;
