import { Tabs, TabsProps } from "antd";
import React from "react";
import * as S from "./style";

import Client from "./Client";
// import Brand from "./Brand";

const FAQ: React.FC = () => {
  const onChange = (key: string) => {
    // console.log(key);
  };
  // const items: TabsProps["items"] = [
  //   {
  //     key: "client",
  //     label: `For Client`,
  //     children: <Client />,
  //   },
  //   {
  //     key: "brand",
  //     label: `For Brand`,
  //     children: <></>,
  //   },
  // ];

  return (
    <S.Wrapper>
      <div className="headings">
        <h1 className="main-headings">공지사항</h1>
        {/* <p className="sub-heading">Official announcement to client & Brand</p> */}
      </div>
      <div className="inquiry-tabs">
        {/* <Tabs defaultActiveKey="3" items={items} onChange={onChange} /> */}
        <Client />
      </div>
    </S.Wrapper>
  );
};

export default FAQ;
