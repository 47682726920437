import { Layout } from "antd";
import { styled } from "styled-components";
const { Header } = Layout;
export const HeaderStyle = styled(Header)`
  display: flex;
  align-items: flex-end;
  height: auto;
  padding: 0 115px;
  line-height: 24px;
  background-color: var(--light-color);
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.25);
  .header-container {
    width: 100%;
    padding-top: 80px;
    h1 {
      font-size: 32px;
      font-style: normal;
      font-weight: 400;
      color: var(--brand-color);
    }
    .back-icon {
      cursor: pointer;
    }
    .title-container {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    .ant-breadcrumb {
      margin-top: 12px;
      font-size: 14px;
      li > a {
        color: var(--link-color);
        svg path {
          fill: var(--link-color);
        }
      }
      .ant-breadcrumb-separator {
        margin-top: 2px;
      }
      .title-breadcrum {
        display: flex;
        align-items: center;
        gap: 6px;
      }
    }
  }
  .back-icon {
    cursor: pointer;
  }
  .title-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    margin-top: 12px;
  }
  .ant-breadcrumb {
    margin-top: 12px;
    font-size: 14px;
    li > a {
      color: var(--link-color);
    }
    .title-breadcrum {
      display: flex;
      align-items: center;
      gap: 6px;
    }
  }
`;
