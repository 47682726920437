import { Input as AntdInput } from "antd";
import * as S from "./style";
import { forwardRef, ForwardedRef } from "react";
import type { InputProps, InputRef } from "antd";
interface CsInputProps extends InputProps {
  ref?: ForwardedRef<InputRef>;
}

const CsInput = forwardRef(
  (props: CsInputProps, ref: ForwardedRef<InputRef>) => {
    return (
      <S.Wrapper>
        <AntdInput ref={ref} {...props} />
      </S.Wrapper>
    );
  }
);

export default CsInput;
