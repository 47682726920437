import { styled } from "styled-components";
export const Wrapper = styled.div``;
export const Heading = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  padding: 100px 30px 0 30px;
  gap: 10px;
  background-color: var(--light-color);
`;
export const Content = styled.div`
  padding: 30px 30px 0 30px;
  background-color: #fff;
  .underline {
    width: 100%;
    height: 10px;
    background-color: #f5f5f5;
    display: block;
    margin: 50px 0;
  }
`;

export const WrapField = styled.div`
  max-width: 940px;
  display: grid;
  @media (min-width: 768px) {
    grid-gap: 32px 20px;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: repeat(6, auto);
    grid-template-areas:
      "f0 f0 . ."
      "f1 f1 f1 f1"
      "f2 f3 f4 f5"
      "f6 f7 f8 f9"
      "f10 f11 f12 ."
      "f13 f14 f14 ."
      "f15 . . .";
  }
  .field-item {
    label {
      font-size: 16px;
      line-height: 19.09px;
      margin-bottom: 12px;
      color: #2d2d2d;
      font-family: "Pretendard-600";
      display: block;
    }

    > div {
      &:not(.wr-select, .multi) {
        font-size: 15px;
        line-height: 15px;
        margin-bottom: 12px;
        color: #2d2d2d;
        font-family: "Pretendard-500";
        height: 48px;
        border-radius: 50px;
        background-color: #f9f9f9;
        border: 1px solid #ededed;
        padding: 0 16px;
        display: flex;
        align-items: center;
      }
      &.has-label {
        display: flex;
        gap: 12px;
        > span {
          display: block;
          font-size: 14px;
          line-height: 18px;
          color: #15a0a1;
          font-family: "Pretendard-500";
          height: 18px;
          padding: 0 4px;
          background-color: #eafcfc;
        }
        > .no-list {
          flex: 1;
          font-size: 15px;
          line-height: 15px;
          margin-bottom: 12px;
          color: #2d2d2d;
          font-family: "Pretendard-500";
          overflow: hidden;
          border-radius: 50px;
          background-color: #f9f9f9;
          border: 1px solid #ededed;
          padding: 16px;
          display: flex;
          align-items: center;
          min-height: 48px;
          display: flex;
          gap: 10px 38px;
          flex-wrap: wrap;
          @media (min-width: 1024px) {
            width: fit-content;
            min-width: 460px;
          }
          + .btn {
            cursor: pointer;
          }
          .no-item {
            display: flex;
            gap: 12px;
          }
          span {
            display: block;
            font-size: 14px;
            line-height: 18px;
            color: #15a0a1;
            font-family: "Pretendard-500";
            height: 18px;
            padding: 0 4px;
            background-color: #eafcfc;
            white-space: nowrap;
          }
        }
      }
    }
    &:nth-child(1) {
      grid-area: f0;
    }
    &:nth-child(2) {
      grid-area: f1;
    }
    &:nth-child(3) {
      grid-area: f2;
    }
    &:nth-child(4) {
      grid-area: f3;
    }
    &:nth-child(5) {
      grid-area: f4;
    }
    &:nth-child(6) {
      grid-area: f5;
    }
    &:nth-child(7) {
      grid-area: f6;
    }
    &:nth-child(8) {
      grid-area: f7;
    }
    &:nth-child(9) {
      grid-area: f8;
    }
    &:nth-child(10) {
      grid-area: f9;
    }
    &:nth-child(11) {
      grid-area: f10;
    }
    &:nth-child(12) {
      grid-area: f11;
    }
    &:nth-child(13) {
      grid-area: f12;
    }
    &:nth-child(14) {
      grid-area: f13;
    }
    &:nth-child(15) {
      grid-area: f14;
    }
    &:nth-child(16) {
      grid-area: f15;
    }
  }
  .ant-select-single {
    .ant-select-selector {
      height: 48px;
      border-radius: 50px;
      background-color: #fff;
      display: flex;
      align-items: center;
      border: 1px solid #ededed;
    }
  }
`;
