import React, { useState, ChangeEvent, useEffect } from "react";
import styled from "styled-components";
import CloseImg from "assets/images/close-upload-file.png";

interface SingleFileUploadProps {
  onChange?: (file: File | null) => void;
  buttonLabel: string;
  defaultName?: string;
}

const Container = styled.div`
  display: flex;
  gap: 22px;
  position: relative;
  max-width: 650px;
`;

const HiddenInput = styled.input`
  display: none !important;
`;

const ChooseFileButton = styled.button`
  color: #526557;
  font-family: "Pretendard", sans-serif;
  font-size: 14px;
  padding: 0 16px;
  border: 1px solid #526557;
  border-radius: 50px;
  min-width: 175px;
  height: 40px;
  line-height: 40px;
  background: transparent;
  cursor: pointer;
`;

const FileInfoContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 9px;
  width: 100%;
  overflow: hidden;
`;

const FileName = styled.p`
  font-family: "Pretendard", sans-serif;
  font-size: 15px;
  color: #526557;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  flex: 1;
`;

const ClearButton = styled.button`
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
`;

const SingleFileUpload: React.FC<SingleFileUploadProps> = ({
  onChange,
  buttonLabel,
  defaultName,
}) => {
  // const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [fileName, setFileName] = useState<string | null>(defaultName || null);
  const fileInputRef = React.createRef<HTMLInputElement>();

  useEffect(() => {
    setFileName(defaultName || null);
  }, [defaultName]);

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length > 0) {
      const file = event.target.files[0];
      // setSelectedFile(file);
      setFileName(file.name);
      if (onChange) {
        onChange(file);
      }
    }
  };

  const handleFileClear = () => {
    // setSelectedFile(null);
    setFileName(null);
    if (onChange) {
      onChange(null);
    }
  };

  const handleChooseFile = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  return (
    <Container>
      <HiddenInput type="file" ref={fileInputRef} onChange={handleFileChange} />
      <ChooseFileButton type="button" onClick={handleChooseFile}>
        {buttonLabel}
      </ChooseFileButton>
      {fileName && (
        <FileInfoContainer>
          <FileName>{fileName}</FileName>
          <ClearButton type="button" onClick={handleFileClear}>
            <img src={CloseImg} alt="close" />
          </ClearButton>
        </FileInfoContainer>
      )}
    </Container>
  );
};

export default SingleFileUpload;
