import { styled } from "styled-components";

export const Wrap = styled.div`
  height: 100%;
  background-color: var(--sub-background);
  padding-bottom: 30px;
  table {
    th {
      font-size: 20px;
    }
  }
  .inquiry-tabs {
    padding-top: 20px;
    .ant-tabs-nav-wrap {
      background-color: var(--light-color);
      padding: 0 24px;
      .ant-tabs-nav-list {
        width: 100%;
        display: flex;
        justify-content: center;
        .ant-tabs-tab {
          flex-grow: 1;
          display: flex;
          justify-content: center;
        }
        .ant-tabs-tab-active.ant-tabs-tab-active .ant-tabs-tab-btn {
          color: var(--brand-color);
        }
        .ant-tabs-ink-bar {
          background-color: var(--brand-color);
        }
      }
    }
  }
`;

export const ViewDetailBtn = styled.div`
  cursor: pointer;
`;
export const UserInfo = styled.div`
  > h3 {
    font-size: 16px;
    font-family: var(--font-600);
    text-transform: capitalize;
    color: #526557;
    line-height: 24px;
  }
  > p {
    font-size: 14px;
    color: #526557;
    line-height: 21px;
  }
`;

export const Wrapper = styled.div`
  .ant-input-affix-wrapper,
  .ant-picker,
  .ant-input {
    background-color: transparent;
  }
`;

export const Heading = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  padding: 100px 30px 10px 30px;
  gap: 10px;
  background-color: var(--light-color);
  .sub-heading {
    font-size: 16px;
    line-height: normal;
    font-family: var(--font-500);
  }
  .btn-create {
    border-radius: 12px;
    background: var(--brand-color);
    color: var(--light-color) !important;
    width: 220px;
    height: 52px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
  }
`;
