import { LoadingOutlined } from "@ant-design/icons";
import { Button, Checkbox, Form, Input, InputRef, message } from "antd";
import { createGroupBuyingApi } from "api/groupBuying";
import { uploadSingleFile } from "api/uploadSingleFile";
import { ReactComponent as Close } from "assets/icons/Close.svg";
import { ReactComponent as FilePlus } from "assets/icons/FilePlus.svg";
import { ReactComponent as IconSearch14 } from "assets/icons/search14.svg";
import DateRangePicker from "components/DateRangePicker";
import CsInput from "components/Input";
import ListBrandSelectSearch from "components/ListBrandSelectSearch";
import UploadImageCrop from "components/UploadImageCrop";
import dayjs from "dayjs";
import MainHeader from "layouts/components/Header";
import { CreateGroupBuyingApi } from "model/groupBuying";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { formatNumberWithCommas } from "utils/format";
import { formatTimeZone } from "utils/formatTimeZone";
import * as S from "./style";

import { DatePicker } from "antd";
import RadioUpload from "components/RadioUpload";
import UploadMultiImage from "components/UploadMultiImage";
import SelectCmp from "components/SelectCmp";
import { dataFullBank } from "utils/bankData";
import { log } from "console";

const { TextArea } = Input;

// interface WarningType {
//   type: "" | "error" | "success" | "warning" | "validating";
//   message: string | undefined;
// }

export type RequiredType = {
  logoImage: boolean;
  backgroundImage: boolean;
  formFields: boolean;
};

const CampaignCreate: React.FC = () => {
  // start variables -------------------------------------------------
  const voucherNameRef = useRef<InputRef>(null);
  const startTimeRef = useRef<any>(null);
  const voucherSellingPriceRef = useRef<InputRef>(null);
  const [messageApi, contextHolder] = message.useMessage({ maxCount: 2 });
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const isBankingValue = Form.useWatch("isBanking", form);
  const [loading, setLoading] = useState<boolean>(false);
  const [disableField, setDisableFiled] = useState<boolean>(true);
  const [startTimeError, setStartTimeError] = useState<boolean>(false);
  const [endTimeError, setEndTimeError] = useState<boolean>(false);
  const [required, setRequired] = useState<RequiredType>({
    logoImage: false,
    backgroundImage: false,
    formFields: false,
  });
  // console.log("isBankingValue", isBankingValue);

  const values = Form.useWatch([], form);
  useEffect(() => {
    form.validateFields({ validateOnly: true }).then(
      () => {
        return setRequired((prev: any) => ({ ...prev, formFields: false }));
      },
      () => {
        return setRequired((prev: any) => ({ ...prev, formFields: true }));
      }
    );
  }, [form, values]);
  // end variables -------------------------------------------------------

  const errorMess = (mess: string) => {
    messageApi.open({
      type: "error",
      content: mess,
    });
  };

  // start handle form ----------------------------------------------------

  const uploadImage = async (file: File) => {
    const formData = new FormData();
    formData.append("file", file);
    const resp: any = await uploadSingleFile(formData);
    if (resp?.imageUrl) {
      return resp?.imageUrl;
    } else {
      return undefined;
    }
  };

  const onFinishFailed = (errValue: any) => {
    voucherNameRef.current!.focus({
      cursor: "start",
    });
  };

  const onFinish = async (values: any) => {
    // console.log("values", values);

    setLoading(true);
    try {
      // upload image
      let voucherImageUrl: string = values.voucherImage;
      let voucherDetailImagesUrl: string[] = [];
      let logoUrl: string | undefined;
      let backgroundImageUrl: string | undefined;

      if (values.voucherImage) {
        voucherImageUrl = await uploadImage(values.voucherImage);
      }

      if (values.logoImage?.display && values.logoImage?.image) {
        logoUrl = await uploadImage(values.logoImage.image);
      }

      if (values.backgroundImage?.display && values.backgroundImage?.image) {
        backgroundImageUrl = await uploadImage(values.backgroundImage.image);
      }
      if (values.voucherDetail) {
        await Promise.all(
          values?.voucherDetail?.map(async (item: any) => {
            if (item.originFileObj) {
              const result = await uploadImage(item.originFileObj);
              voucherDetailImagesUrl.push(result);
            } else if (typeof item === "string") {
              voucherDetailImagesUrl.push(item);
            } else {
              voucherDetailImagesUrl.push(item.url);
            }
          })
        );
      }
      // console.log(
      //   "expiredTime",
      //   formatTimeZone(
      //     dayjs(values.expiredTime).add(1, "day").startOf("day"),
      //     "YYYY-MM-DD HH:mm:00"
      //   )
      // );

      const dataPayload: CreateGroupBuyingApi = {
        brandId: +values.brandId,
        voucherImage: voucherImageUrl,
        voucherName: values.voucherName,
        startTime: formatTimeZone(values.startTime, "YYYY-MM-DD HH:mm:00"),
        endTime: formatTimeZone(values.endTime, "YYYY-MM-DD HH:mm:00"),
        targetAmount: values.targetSellingAmount.replace(/,/g, ""),
        rootAmount: values.totalUsageAmount.replace(/,/g, ""),
        voucherPrice: values.voucherSellingPrice.replace(/,/g, ""),
        rootPrice: values.voucherUsageValue.replace(/,/g, ""),
        // rootPrice: "30", //
        // expiredTime: formatTimeZone(
        //   dayjs(values.expiredTime).endOf("day"),
        //   "YYYY-MM-DD HH:mm:ss"
        // ),
        expiredTime: dayjs(values.expiredTime)
          .tz("Asia/Seoul")
          .endOf("day")
          .toISOString(),
        quantity: parseInt(values.issuedVoucherQuantity.replace(/,/g, ""), 10),
        maxPurchaseQuantity: values.maximumPurchaseQuantity
          ? parseInt(values.maximumPurchaseQuantity.replace(/,/g, ""), 10)
          : undefined,
        maxPurchaseFrequency: values.maximumPurchaseFrequency
          ? parseInt(values.maximumPurchaseFrequency.replace(/,/g, ""), 10)
          : undefined,
        voucherDetail: voucherDetailImagesUrl,
        issuer: values.issuer,
        availableStores: values.availableStores,
        reservationConditions: values.reservationConditions,
        validityPeriod: values.validityPeriod,
        cancelRefundPolicy: values.cancelRefundPolicy,
        balanceRefundConditions: values.balanceRefundConditions,
        refundMethod: values.refundMethod,
        consumerConsultationContact: values.consumerConsultationContact,
        discountPercentage: values.discountPercentage,
        logoImage: values.logoImage?.display ? logoUrl : undefined,
        backgroundImage: values.backgroundImage?.display
          ? backgroundImageUrl
          : undefined,
        displayLogo: values.logoImage?.display,
        displayBackground: values.backgroundImage?.display,
        isBanking: values.isBanking,
        bankAccountName: values.bankAccountName || "",
        bankAccountNumber: values.bankAccountNumber || "",
        bankName: values.bankName || "",
      };

      //create Group Buying
      const { data, error }: any = await createGroupBuyingApi(dataPayload);

      if (error) {
        errorMess("Create Group Buying Failed!");
        if (error.errorCode === "INVALID_PERCENTAGE") {
          form.setFields([
            {
              name: "voucherSellingPrice",
              errors: [
                "Voucher selling price must less or equal to voucher usage value",
              ],
            },
          ]);
          if (voucherSellingPriceRef.current) {
            voucherSellingPriceRef.current.focus();
          }
          setLoading(false);
          return;
        } else if (error.errorCode === "DUPLICATE_TIME") {
          form.setFields([
            {
              name: "startTime",
              errors: ["Campaign times cannot overlap"],
            },
          ]);
          if (startTimeRef.current) {
            startTimeRef.current.focus();
          }
          setLoading(false);
          return;
        }
      }
      if (data) {
        navigate("/group-buying/campaign");
      }
      setLoading(false);
    } catch (error) {
      console.log(error);
      errorMess("Create Group Buying Failed!");
      setLoading(false);
    }
  };
  const onReset = () => {
    form.resetFields();
    navigate("/group-buying/campaign");
  };
  const onFill = () => {
    // formRef.current?.setFieldsValue({ note: "Hello world!", gender: "male" });
  };
  // end handle form ----------------------------------------------------

  // start auto calculate  ----------------------------------------------------------

  const calculateTotalUsageAmount = () => {
    const voucherUsageValue = form.getFieldValue("voucherUsageValue");
    const issuedVoucherQuantity = form.getFieldValue("issuedVoucherQuantity");
    if (voucherUsageValue && issuedVoucherQuantity) {
      const totalUsageAmount =
        parseInt(voucherUsageValue.replace(/,/g, ""), 10) *
        parseInt(issuedVoucherQuantity.replace(/,/g, ""), 10);
      form.setFieldsValue({
        totalUsageAmount: formatNumberWithCommas(totalUsageAmount.toString()),
      });

      setDisableFiled(false);
    } else {
      setDisableFiled(true);
      form.setFieldsValue({
        totalUsageAmount: undefined,
        discountPercentage: undefined,
        voucherSellingPrice: undefined,
        targetSellingAmount: undefined,
      });
    }
  };

  const calculateTargetSellingAmount = () => {
    const voucherSellingPrice = form.getFieldValue("voucherSellingPrice");
    const issuedVoucherQuantity = form.getFieldValue("issuedVoucherQuantity");

    if (voucherSellingPrice && issuedVoucherQuantity) {
      const targetSellingAmount =
        parseInt(voucherSellingPrice.replace(/,/g, ""), 10) *
        parseInt(issuedVoucherQuantity.replace(/,/g, ""), 10);
      form.setFieldsValue({
        targetSellingAmount: formatNumberWithCommas(
          targetSellingAmount.toString(),
          true
        ),
      });
    }
  };

  const onBlurDiscountPercentage = () => {
    const voucherUsageValue = form.getFieldValue("voucherUsageValue");
    const discountPercentage = form.getFieldValue("discountPercentage");

    if (voucherUsageValue && discountPercentage) {
      const voucherSellingPrice =
        (parseInt(voucherUsageValue.replace(/,/g, ""), 10) *
          (100 - parseInt(discountPercentage.replace(/,/g, ""), 10))) /
        100;
      console.log("voucherSellingPrice", voucherSellingPrice.toString());
      form.setFieldsValue({
        voucherSellingPrice: formatNumberWithCommas(
          voucherSellingPrice.toString(),
          true
        ),
      });
    }
  };

  const onBlurVoucherSellingPrice = () => {
    const voucherUsageValue = form.getFieldValue("voucherUsageValue");
    const voucherSellingPrice = form.getFieldValue("voucherSellingPrice");
    if (voucherUsageValue && voucherSellingPrice) {
      const discountPercentage =
        ((parseInt(voucherUsageValue.replace(/,/g, ""), 10) -
          parseInt(voucherSellingPrice.replace(/,/g, ""), 10)) /
          parseInt(voucherUsageValue.replace(/,/g, ""), 10)) *
        100;

      form.setFieldsValue({
        discountPercentage: formatNumberWithCommas(
          discountPercentage.toFixed().toString()
        ),
      });
      if (
        parseInt(voucherSellingPrice.replace(/,/g, ""), 10) >
        parseInt(voucherUsageValue.replace(/,/g, ""), 10)
      ) {
        form.setFields([
          {
            name: "voucherSellingPrice",
            errors: [
              "Voucher selling price must less or equal to voucher usage value",
            ],
          },
        ]);
      }
    }
  };

  const onBlurVoucherUsageValue = () => {
    const voucherUsageValue = form.getFieldValue("voucherUsageValue");
    const voucherSellingPrice = form.getFieldValue("voucherSellingPrice");

    if (voucherUsageValue && voucherSellingPrice) {
      const voucherUsageValueNumber = parseInt(
        voucherUsageValue.replace(/,/g, ""),
        10
      );
      const voucherSellingPriceNumber = parseInt(
        voucherSellingPrice.replace(/,/g, ""),
        10
      );
      if (voucherUsageValueNumber <= voucherSellingPriceNumber) {
        form.setFields([
          {
            name: "voucherUsageValue",
            errors: [
              "Voucher usage value must larger or equal to voucher selling price",
            ],
          },
        ]);
      } else {
        const discountPercentageNumber =
          ((voucherUsageValueNumber - voucherSellingPriceNumber) /
            voucherUsageValueNumber) *
          100;
        console.log(discountPercentageNumber);

        form.setFieldsValue({
          discountPercentage: formatNumberWithCommas(
            discountPercentageNumber.toFixed().toString()
          ),
        });
      }
    }
  };

  // end auto calculate  ----------------------------------------------------------

  const handleValuesChange = (changedValues: any, allValues: any) => {
    if (
      "startTime" in changedValues ||
      "endTime" in changedValues ||
      "expiredTime" in changedValues
    ) {
      const startTime = allValues.startTime;
      const endTime = allValues.endTime;
      const expiredTime = allValues.expiredTime;

      const setField = (name: string, error: string[]) => {
        form.setFields([{ name, errors: error }]);
      };

      setStartTimeError(
        startTime && endTime ? !startTime.isBefore(endTime) : false
      );
      setField(
        "startTime",
        startTime && endTime && !startTime.isBefore(endTime)
          ? ["Start time must be before end time"]
          : []
      );

      setEndTimeError(
        endTime && expiredTime ? endTime.isAfter(expiredTime) : false
      );
      setField(
        "endTime",
        endTime && expiredTime && endTime.isAfter(expiredTime)
          ? ["End time must be before voucher expiry date"]
          : []
      );
    }
  };

  const range = (start: number, end: number) => {
    const result = [];
    for (let i = start; i < end; i++) {
      result.push(i);
    }
    return result;
  };

  const disabledDateTime = (value: any) => {
    const startValue = form.getFieldValue("startTime");
    const valueCheck = dayjs(value).format("YYYY-MM-DD");
    if (valueCheck !== dayjs(startValue).format("YYYY-MM-DD")) {
      return {};
    }

    return {
      disabledHours: () => {
        if (!startValue) {
          return [];
        }
        return range(0, startValue.hour());
      },
      disabledMinutes: (selectedHour: number) => {
        if (!startValue) {
          return [];
        }
        return startValue.hour() === selectedHour
          ? range(0, startValue.minute())
          : [];
      },
      disabledSeconds: (selectedHour: number, selectedMinute: number) => {
        if (!startValue) {
          return [];
        }

        const isSameMinute =
          startValue.hour() === selectedHour &&
          startValue.minute() === selectedMinute;

        return isSameMinute ? range(0, startValue.second() + 1) : [];
      },
    };
  };
  // end format date ---------------------------------------------
  // console.log(
  //   'form.getFieldValue("logoImage")',
  //   form.getFieldValue("logoImage")
  // );

  return (
    <S.Wrapper>
      {contextHolder}
      <MainHeader
        backLink="/group-buying/campaign"
        title="프리미엄 멤버십 발급"
      />
      <Form
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
        layout="vertical"
        className="form-content"
        form={form}
        onValuesChange={handleValuesChange}
      >
        <S.Block>
          <Form.Item>
            <p className="block-title">프리미엄 멤버십 정보</p>
          </Form.Item>
          <Form.Item
            name="brandId"
            label="Brand"
            rules={[{ required: true, message: "발급할 브랜드 선택" }]}
          >
            <ListBrandSelectSearch
              onChange={(v: string) =>
                v === "clear"
                  ? form.resetFields(["brandId"])
                  : form.setFieldValue("brandId", v)
              }
              iconWhenOpen={<IconSearch14 />}
            />
          </Form.Item>
          <S.WrapUpload>
            <Form.Item
              name="logoImage"
              label="중앙 (로고 위치) 이미지 등록"
              // rules={[{ required: true, message: "This field is required." }]}
              style={{
                width: "50%",
                borderRight: "1px solid #ccc",
              }}
            >
              <RadioUpload
                onChange={(e) => {
                  form.setFieldValue("logoImage", e);
                  // console.log("logoImage", form.getFieldValue("logoImage"));
                }}
                setRequired={(e) =>
                  setRequired((prev: any) => ({ ...prev, logoImage: e }))
                }
                labelTop="브랜드 로고로 적용"
              />
            </Form.Item>
            <Form.Item
              name="backgroundImage"
              label="배경 이미지 등록"
              // rules={[{ required: true, message: "This field is required." }]}
              style={{
                width: "50%",
                paddingLeft: 16,
              }}
            >
              <RadioUpload
                onChange={(e) => {
                  form.setFieldValue("backgroundImage", e);
                }}
                setRequired={(e) =>
                  setRequired((prev: any) => ({
                    ...prev,
                    backgroundImage: e,
                  }))
                }
                labelTop="디폴트 배경으로 적용"
              />
            </Form.Item>
          </S.WrapUpload>
          <Form.Item
            name="voucherImage"
            label="썸네일 이미지"
            // rules={[
            //   { required: true, message: "썸네일 이미지를 등록해 주세요" },
            // ]}
          >
            <>
              <p className="img-size">600*600px 등 1:1 비율을 권장합니다.</p>
              <UploadImageCrop
                onImageChange={(file: File) => {
                  form.setFieldsValue({ voucherImage: file });
                }}
                crop={{ type: "rect", aspect: 1 }}
              />
            </>
          </Form.Item>
          <Form.Item
            name="voucherName"
            label="프리미엄 멤버십 발급 명"
            rules={[
              {
                required: true,
                message: "발급할 프리미엄 멤버십 이름을 써주세요",
              },
              {
                min: 3,
                message: "세글자 이상 입력해 주세요",
              },
            ]}
          >
            <CsInput
              ref={voucherNameRef}
              style={{
                width: "100%",
                height: 48,
              }}
              placeholder="입력해 주세요"
              // onChange={(e) => setTextSearch(e.target.value)}
              onBlur={(e) =>
                form.setFieldsValue({
                  voucherName: e.target.value.trim().replace(/\s+/g, " "),
                })
              }
            />
          </Form.Item>
        </S.Block>

        <S.Block>
          <Form.Item>
            <p className="block-title">
              발급 정보 (사용할 수 있는 기간은 발급이 끝난 직후부터 사용
              종료일까지입니다)
            </p>
          </Form.Item>

          <Form.Item>
            <Form.Item
              name="startTime"
              label="발급 시작 날짜"
              style={{ display: "inline-block", width: "calc(50% - 15px)" }}
              rules={[
                { required: true, message: "판매를 시작할 날짜를 정하세요" },
                {
                  validator: (_, value) => {
                    if (startTimeError) {
                      return Promise.reject(
                        "The start time must be less than the end time"
                      );
                    }
                    return Promise.resolve();
                  },
                },
              ]}
            >
              <>
                <DatePicker
                  ref={startTimeRef}
                  showTime={{ format: "HH:mm" }}
                  format="YYYY-MM-DD HH:mm"
                  placeholder="발급 날짜를 선택하세요"
                  disabledDate={(current) =>
                    current && current.isBefore(dayjs(), "day")
                  }
                />
              </>
            </Form.Item>
            <Form.Item
              name="endTime"
              label="발급 마감 날짜"
              style={{
                display: "inline-block",
                width: "calc(50% - 15px)",
                margin: "0 0 0 30px",
              }}
              rules={[
                { required: true, message: "판매를 종료할 날짜를 정하세요" },
                {
                  validator: (_, value) => {
                    if (endTimeError) {
                      return Promise.reject(
                        "End time must be before voucher expiry date"
                      );
                    }
                    return Promise.resolve();
                  },
                },
              ]}
            >
              <DatePicker
                showTime={{ format: "HH:mm" }}
                format="YYYY-MM-DD HH:mm"
                placeholder="마감 날짜를 선택하세요"
                disabledDate={(currentDate) => {
                  const startTime = dayjs(
                    form.getFieldValue("startTime")
                  ).format();
                  return (
                    !startTime ||
                    dayjs(currentDate).isBefore(dayjs(startTime, "YYYY-MM-DD"))
                  );
                }}
                disabledTime={disabledDateTime}
              />
            </Form.Item>
          </Form.Item>
        </S.Block>

        <S.Block>
          <Form.Item>
            <p className="block-title">프리미엄 멤버십 가격 설정</p>
          </Form.Item>

          <Form.Item>
            <Form.Item
              name="voucherUsageValue"
              label="원가"
              rules={[
                {
                  required: true,
                  message: "가격을 써주세요",
                },
              ]}
              // validateStatus={warning?.type}
              // help={warning?.message}
              style={{
                display: "inline-block",
                width: "calc(50% - 15px)",
              }}
            >
              <CsInput
                // disabled={disableField}
                style={{
                  width: "100%",
                  height: 48,
                }}
                placeholder="ex) 100,000"
                onChange={(e) => {
                  const formattedValue = formatNumberWithCommas(e.target.value);
                  form.setFieldValue("voucherUsageValue", formattedValue);
                }}
                onBlur={() => {
                  calculateTotalUsageAmount();
                  onBlurVoucherUsageValue();
                }}
                suffix={<p className="unit">원</p>}
              />
            </Form.Item>
            <Form.Item
              name="issuedVoucherQuantity"
              label="판매 수량"
              rules={[
                {
                  required: true,
                  message: "수량을 써주세요",
                },
              ]}
              style={{
                display: "inline-block",
                width: "calc(50% - 15px)",
                margin: "0 0 0 30px",
              }}
            >
              <CsInput
                style={{
                  width: "100%",
                  height: 48,
                }}
                placeholder="ex) 1,000"
                onChange={(e) => {
                  const formattedValue = formatNumberWithCommas(e.target.value);
                  form.setFieldValue("issuedVoucherQuantity", formattedValue);
                }}
                onBlur={() => {
                  calculateTotalUsageAmount();
                  // onBlurIssuedVoucherQuantity();
                  calculateTargetSellingAmount();
                  // onBlurVoucherUsageValue();
                  // onBlurTotalUsageAmount();
                }}
                suffix={<p className="unit">개</p>}
              />
            </Form.Item>
          </Form.Item>

          <Form.Item
            // style={{ display: "inline-block", width: "calc(50% - 15px)" }}
            name="totalUsageAmount"
            label="합"
            rules={[
              {
                required: true,
                message: "Total usage amount is required",
              },
            ]}
          >
            <CsInput
              disabled={true}
              style={{
                width: "100%",
                height: 48,
              }}
              placeholder="자동 계산 됩니다"
              onChange={(e) => {
                const formattedValue = formatNumberWithCommas(e.target.value);
                form.setFieldValue("totalUsageAmount", formattedValue);
              }}
              // onBlur={onBlurTotalUsageAmount}
              suffix={<p className="unit">원</p>}
            />
          </Form.Item>

          <Form.Item
            name="discountPercentage"
            label="할인율로 설정"
            // style={{
            //   display: "inline-block",
            //   width: "calc(50% - 15px)",
            //   margin: "0 0 0 30px",
            // }}
            rules={[
              {
                required: true,
                message: "Discount percentage is required",
              },
            ]}
          >
            <CsInput
              disabled={disableField}
              style={{
                width: "100%",
                height: 48,
              }}
              placeholder="ex) 10%"
              onChange={(e) => {
                let inputValue = e.target.value;
                const numericValue = parseFloat(
                  inputValue.replace(/[^0-9.]/g, "")
                );
                const clampedValue = Math.min(100, Math.max(0, numericValue));
                const formattedValue = formatNumberWithCommas(
                  clampedValue.toString(),
                  true
                );
                form.setFieldValue("discountPercentage", formattedValue);
              }}
              onBlur={() => {
                onBlurDiscountPercentage();
                calculateTargetSellingAmount();
              }}
              suffix={<p className="unit">%</p>}
            />
          </Form.Item>

          <Form.Item>
            <Form.Item
              name="voucherSellingPrice"
              label="할인가로 설정"
              style={{ display: "inline-block", width: "calc(50% - 15px)" }}
              rules={[
                {
                  required: true,
                  message: "Voucher selling price is required",
                },
              ]}
            >
              <CsInput
                ref={voucherSellingPriceRef}
                disabled={disableField}
                style={{
                  width: "100%",
                  height: 48,
                }}
                placeholder="ex) 90,000"
                onChange={(e) => {
                  const formattedValue = formatNumberWithCommas(
                    e.target.value,
                    true
                  );
                  form.setFieldValue("voucherSellingPrice", formattedValue);
                }}
                onBlur={() => {
                  onBlurVoucherSellingPrice();
                  calculateTargetSellingAmount();
                }}
                suffix={<p className="unit">원</p>}
              />
            </Form.Item>
            <Form.Item
              style={{
                display: "inline-block",
                width: "calc(50% - 15px)",
                margin: "0 0 0 30px",
              }}
              name="targetSellingAmount"
              label="Target selling amount"
              rules={[
                {
                  required: true,
                  message: "Target selling amount is required",
                },
              ]}
            >
              <CsInput
                // defaultValue="inactive"
                disabled={true}
                style={{
                  width: "100%",
                  height: 48,
                }}
                placeholder="ex) 90,000,000"
                onChange={(e) => {
                  const formattedValue = formatNumberWithCommas(
                    e.target.value,
                    true
                  );
                  form.setFieldValue("targetSellingAmount", formattedValue);
                }}
                suffix={<p className="unit">원</p>}
              />
            </Form.Item>
          </Form.Item>

          <Form.Item
            name="expiredTime"
            label="프리미엄 멤버십 사용 종료일"
            style={{ display: "inline-block", width: "calc(50% - 15px)" }}
            rules={[
              {
                required: true,
                message: "사용 종료일을 정하세요",
              },
            ]}
          >
            <DateRangePicker
              // onChange={(v: any) => {
              //   const t = dayjs(v).endOf("day").format("YYYY-MM-DD HH:mm:ss");
              //   console.log("t", t);
              // }}
              format="YYYY-MM-DD"
              placeholder="종료 날짜를 선택하세요"
              disabledDate={(currentDate) => {
                const endTime = form.getFieldValue("endTime");
                const endTimeFormat = dayjs(endTime).format("YYYY-MM-DD");
                return (
                  !endTime ||
                  dayjs(currentDate).isBefore(
                    dayjs(endTimeFormat).add(1, "day")
                  )
                );
              }}
            />
          </Form.Item>
        </S.Block>

        <S.Block>
          <Form.Item>
            <p className="block-title">
              사용 조건 (일반적인 발급인 경우 미설정)
            </p>
          </Form.Item>
          <Form.Item>
            <Form.Item
              name="maximumPurchaseQuantity"
              label="최대 구매 수량"
              style={{ display: "inline-block", width: "calc(50% - 15px)" }}
            >
              <CsInput
                // defaultValue="inactive"
                style={{
                  width: "100%",
                  height: 48,
                }}
                placeholder="ex) 10"
                onChange={(e) => {
                  const formattedValue = formatNumberWithCommas(e.target.value);
                  form.setFieldValue("maximumPurchaseQuantity", formattedValue);
                }}
                suffix={<p className="unit">개</p>}
              />
            </Form.Item>
            <Form.Item
              name="maximumPurchaseFrequency"
              label="구매 횟수 제한"
              style={{
                display: "inline-block",
                width: "calc(50% - 15px)",
                margin: "0 0 0 30px",
              }}
            >
              <CsInput
                // defaultValue="inactive"
                style={{
                  width: "100%",
                  height: 48,
                }}
                placeholder="ex) 3"
                onChange={(e) => {
                  const formattedValue = formatNumberWithCommas(e.target.value);
                  form.setFieldValue(
                    "maximumPurchaseFrequency",
                    formattedValue
                  );
                }}
                suffix={<p className="unit">회</p>}
              />
            </Form.Item>
          </Form.Item>
        </S.Block>

        <S.Block>
          {/* <Form.Item>
            <p className="block-title">
              신용카드 결제가 아닌 무통장 입금으로 전환하기 (원하는 경우
              체크해주세요)
            </p>
          </Form.Item> */}
          <Form.Item name="isBanking">
            <Checkbox
              onChange={(e) => {
                form.setFieldValue("isBanking", e.target.checked);
                if (!e.target.checked) {
                  form.setFields([
                    {
                      name: "bankName",
                      errors: [],
                    },
                    {
                      name: "bankAccountNumber",
                      errors: [],
                    },
                    {
                      name: "bankAccountName",
                      errors: [],
                    },
                  ]);
                }
              }}
            >
              신용카드 결제가 아닌 무통장 입금으로 전환하기 (원하는 경우
              체크해주세요)
            </Checkbox>
          </Form.Item>
          <Form.Item>
            <Form.Item
              name="bankName"
              label="은행 선택"
              style={{ display: "inline-block", width: "calc(50% - 15px)" }}
              rules={[
                {
                  required: isBankingValue,
                  message: "This field is required",
                },
              ]}
            >
              <SelectCmp
                disabled={!isBankingValue}
                style={{
                  width: "100%",
                  height: 48,
                }}
                options={dataFullBank}
                placeholder="입금받을 은행을 선택하세요"
                onChange={(v) => {
                  const bankName = dataFullBank.find(
                    (item: any) => item.value === v
                  );
                  form.setFieldValue("bankName", bankName?.label);
                }}
              />
            </Form.Item>
            <Form.Item
              name="bankAccountNumber"
              label="계좌번호"
              style={{
                display: "inline-block",
                width: "calc(50% - 15px)",
                margin: "0 0 0 30px",
              }}
              rules={[
                {
                  required: isBankingValue,
                  message: "This field is required",
                },
              ]}
            >
              <CsInput
                // defaultValue="inactive"
                disabled={!isBankingValue}
                style={{
                  width: "100%",
                  height: 48,
                }}
                placeholder="계좌번호를 입력하세요"
              />
            </Form.Item>
          </Form.Item>
          <Form.Item
            name="bankAccountName"
            label="예금주"
            style={{
              display: "inline-block",
              width: "calc(50% - 15px)",
            }}
            rules={[
              {
                required: isBankingValue,
                message: "This field is required",
              },
            ]}
          >
            <CsInput
              // defaultValue="inactive"
              disabled={!isBankingValue}
              style={{
                width: "100%",
                height: 48,
              }}
              placeholder="예금주를 입력하세요"
            />
          </Form.Item>
        </S.Block>

        <S.Block>
          <Form.Item>
            <p className="block-title">프리미엄 멤버십 상세 페이지</p>
          </Form.Item>

          <Form.Item name="voucherDetail">
            <UploadMultiImage
            // onChange={(file) => {
            //   setImagesDetail(file);
            // }}
            />
          </Form.Item>
        </S.Block>

        <S.Block>
          <Form.Item>
            <p className="block-title">발급자 정보</p>
          </Form.Item>

          <Form.Item
            name="issuer"
            label="발급자"
            rules={[
              {
                max: 300,
                message: "Please enter max 300 characters",
              },
            ]}
          >
            <TextArea
              placeholder={`이 프리미엄 멤버십을 발행하는 주최자 (회사 명)${"\n"}ex) 삼성`}
              rows={3}
              style={{ color: "#2D2D2D" }}
              onBlur={() => {
                const currentValue = form.getFieldValue("issuer");
                const processedValue = currentValue
                  ?.split("\n")
                  ?.map((line: string) => line?.trim()?.replace(/\s+/g, " "))
                  ?.join("\n");
                form.setFieldsValue({ issuer: processedValue });
              }}
            />
          </Form.Item>

          <Form.Item
            name="availableStores"
            label="이용 가능한 매장"
            rules={[
              {
                max: 300,
                message: "Please enter max 300 characters",
              },
            ]}
          >
            <TextArea
              placeholder={`해당 프리미엄 멤버십을 사용할 수 있는 매장${"\n"}ex) 삼성 서비스센터 대치점, 삼성 서비스센터 강남점`}
              rows={3}
              style={{ color: "#2D2D2D" }}
              onBlur={() => {
                const currentValue = form.getFieldValue("issuer");
                const processedValue = currentValue
                  ?.split("\n")
                  ?.map((line: string) => line?.trim()?.replace(/\s+/g, " "))
                  ?.join("\n");
                form.setFieldsValue({ issuer: processedValue });
              }}
            />
          </Form.Item>

          <Form.Item
            name="reservationConditions"
            label="사용 조건"
            rules={[
              {
                max: 300,
                message: "Please enter max 300 characters",
              },
            ]}
          >
            <TextArea
              placeholder={`프리미엄 멤버십을 사용을 할 수 있는 조건${"\n"}ex) 프리미엄 멤버십 회원 로그인 후 QR코드를 제시하세요`}
              rows={3}
              style={{ color: "#2D2D2D" }}
              onBlur={() => {
                const currentValue = form.getFieldValue("issuer");
                const processedValue = currentValue
                  ?.split("\n")
                  ?.map((line: string) => line?.trim()?.replace(/\s+/g, " "))
                  ?.join("\n");
                form.setFieldsValue({ issuer: processedValue });
              }}
            />
          </Form.Item>

          <Form.Item
            name="validityPeriod"
            label="프리미엄 멤버십 유효 기간"
            rules={[
              {
                max: 300,
                message: "Please enter max 300 characters",
              },
            ]}
          >
            <TextArea
              placeholder={`프리미엄 멤버십의 유효한 기간${"\n"}ex) 지정된 마감일까지 사용 가능합니다.`}
              rows={3}
              style={{ color: "#2D2D2D" }}
              onBlur={() => {
                const currentValue = form.getFieldValue("issuer");
                const processedValue = currentValue
                  ?.split("\n")
                  ?.map((line: string) => line?.trim()?.replace(/\s+/g, " "))
                  ?.join("\n");
                form.setFieldsValue({ issuer: processedValue });
              }}
            />
          </Form.Item>

          <Form.Item
            name="cancelRefundPolicy"
            label="취소/환불 조건"
            rules={[
              {
                max: 300,
                message: "Please enter max 300 characters",
              },
            ]}
          >
            <TextArea
              placeholder={`프리미엄 멤버십의 취소 및 환불 가능한 조건${"\n"}ex) 미사용 7일이내의 취소 및 환불이 가능합니다.`}
              rows={3}
              style={{ color: "#2D2D2D" }}
              onBlur={() => {
                const currentValue = form.getFieldValue("issuer");
                const processedValue = currentValue
                  ?.split("\n")
                  ?.map((line: string) => line?.trim()?.replace(/\s+/g, " "))
                  ?.join("\n");
                form.setFieldsValue({ issuer: processedValue });
              }}
            />
          </Form.Item>

          <Form.Item
            name="balanceRefundConditions"
            label="잔액 환불 정책"
            rules={[
              {
                max: 300,
                message: "Please enter max 300 characters",
              },
            ]}
          >
            <TextArea
              placeholder={`프리미엄 멤버십 사용 후의 환불 정책${"\n"}ex) 한번이라도 사용한 경우 환불이 불가능합니다.`}
              rows={3}
              style={{ color: "#2D2D2D" }}
              onBlur={() => {
                const currentValue = form.getFieldValue("issuer");
                const processedValue = currentValue
                  ?.split("\n")
                  ?.map((line: string) => line?.trim()?.replace(/\s+/g, " "))
                  ?.join("\n");
                form.setFieldsValue({ issuer: processedValue });
              }}
            />
          </Form.Item>

          <Form.Item
            name="refundMethod"
            label="환불 방법"
            rules={[
              {
                max: 300,
                message: "Please enter max 300 characters",
              },
            ]}
          >
            <TextArea
              placeholder={`환불 방법${"\n"}ex) 모아프렌즈 내 1:1 게시판을 통해 환불 신청 (구매자명, 구매 날짜, 구매한 프리미엄 멤버십명 기재, 사유 기재)`}
              rows={3}
              style={{ color: "#2D2D2D" }}
              onBlur={() => {
                const currentValue = form.getFieldValue("issuer");
                const processedValue = currentValue
                  ?.split("\n")
                  ?.map((line: string) => line?.trim()?.replace(/\s+/g, " "))
                  ?.join("\n");
                form.setFieldsValue({ issuer: processedValue });
              }}
            />
          </Form.Item>

          <Form.Item
            name="consumerConsultationContact"
            label="고객센터"
            rules={[
              {
                max: 300,
                message: "Please enter max 300 characters",
              },
            ]}
          >
            <TextArea
              placeholder={`문의 연락처${"\n"}ex) 모아프렌즈 1:1 게시판 문의`}
              rows={3}
              style={{ color: "#2D2D2D" }}
              onBlur={() => {
                const currentValue = form.getFieldValue("issuer");
                const processedValue = currentValue
                  ?.split("\n")
                  ?.map((line: string) => line?.trim()?.replace(/\s+/g, " "))
                  ?.join("\n");
                form.setFieldsValue({ issuer: processedValue });
              }}
            />
          </Form.Item>
        </S.Block>

        <Form.Item className="group-btn">
          <Button
            onClick={onReset}
            htmlType="submit"
            className="btn btn-cancel"
            icon={<Close />}
          >
            Cancel
          </Button>
          {loading ? (
            <Button className="btn btn-create">
              <LoadingOutlined />
            </Button>
          ) : (
            <Button
              onClick={onFill}
              htmlType="submit"
              className="btn btn-create"
              icon={<FilePlus />}
              disabled={
                required.backgroundImage ||
                required.logoImage ||
                required.formFields
              }
              style={
                required.backgroundImage ||
                required.logoImage ||
                required.formFields
                  ? {
                      backgroundColor: "#e5e5e5",
                      opacity: 0.7,
                    }
                  : {}
              }
            >
              Create Campaign
            </Button>
          )}
        </Form.Item>
      </Form>
    </S.Wrapper>
  );
};

export default CampaignCreate;
