import { styled } from "styled-components";

export const Wrapper = styled.div``;
export const Container = styled.div`
  width: 100%;
  max-width: 732px;
  margin: auto;
  padding: 0 16px;
`;
export const Content = styled.div`
  padding: 20px 0 100px 0;
  gap: 20px;
  display: grid;
`;
export const Card = styled.div`
  border-radius: 20px;
  background-color: #fff;
  padding: 30px 25px;
  .ant-image-mask {
    border-radius: 50%;
  }
  .ant-image {
    width: 200px;
    max-width: 128px;
    > img {
      border-radius: 50%;
    }
  }
`;

export const Action = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  .box-text {
    display: flex;
    flex-direction: column;
    gap: 16px;
    h3 {
      font-size: 18px;
      font-family: var(--font-600);
      line-height: normal;
    }
    p {
      font-size: 16px;
    }
  }
  .box-btn {
    display: flex;
    gap: 20px;
    .ant-btn {
      width: 160px;
      border-radius: 12px;
    }
  }
`;

export const BoxAvatar = styled.div`
  display: grid;
  gap: 8px;
  justify-content: center;
  text-align: center;
  .ant-image, img {
    width: 200px !important;
    height: 200px !important;
    max-width: 100%;
    object-fit: cover;
  }
  h3 {
    font-size: 18px;
    font-family: var(--font-600);
  }
  p {
    font-size: 14px;
  }
`;
export const BoxInfo = styled.div`
  display: grid;
  gap: 14px;
`;

export const Line = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
export const Label = styled.div`
  gap: 12px;
  display: flex;
  align-items: center;
  > svg {
    vertical-align: -3px;
    &.big-icon {
      vertical-align: -6px;
    }
  }
  > span {
    color: #91A896;
    &.strong {
      color: var(--text-color);
    }
  }
`;
export const Value = styled.div`
  > span {
    font-size: 16px;
  }
`;


export const ModalContent = styled.div`
  padding: 20px 6px 30px 6px;
  text-align: center;
  .ant-form-item-explain-error {
    text-align: left;
  }
  > h3 {
    font-size: 16px;
    font-family: var(--font-600);
    margin-bottom: 12px;
    line-height: 130%;
  }
  p {
    font-size: 16px;
    font-family: var(--font-400);
    margin-bottom: 12px;
    line-height: 130%;
  }
  .ant-input {
    height: 48px;
    border-radius: 10px;
    padding: 0 20px;
    font-size: 14px;
    line-height: 130%;
    &::placeholder {
      font-size: 14px;
      line-height: 130%;
      text-transform: capitalize;
    }
  }
`;

export const ModalBtn = styled.div`
  margin-top: 20px;
  display: flex;
  gap: 20px;
  align-items: center;
  .btn-cancel {
    width: 160px;
  }
  .btn-submit {
    width: 100%;
  }
  >  div {
    &:last-child {
      flex: 1;
    }
  }
`;
