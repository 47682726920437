import { Select } from "antd";
import { fetchAllBrand } from "api/brand";
import defaultBrandImg from "assets/images/brand-default.png";
import React, { ReactNode, memo, useEffect, useState } from "react";
import * as S from "./style";
import { ReactComponent as IconDownClose } from "assets/icons/ArrowDown14.svg";
import { ReactComponent as IconDownOpen } from "assets/icons/ArrowUp14.svg";
import { CloseSelectIcon } from "components/IconCustom";

interface Props {
  className?: string;
  onChange?: (v: string) => void;
  defaultValue?: string;
  disabled?: boolean;
  type?: "filter" | "normal";
  style?: React.CSSProperties;
  iconWhenClose?: ReactNode;
  iconWhenOpen?: ReactNode;
  hiddenClear?: boolean;
  clearValue?: boolean;
  onClearFilter?: () => void;
}

const ListBrandSelectSearch: React.FC<Props> = ({
  className,
  onChange,
  defaultValue,
  disabled,
  style,
  type = "normal",
  hiddenClear,
  iconWhenClose = <IconDownClose />,
  iconWhenOpen = <IconDownOpen />,
  clearValue,
  onClearFilter = () => {},
}) => {
  const [listBrand, setListBrand] = useState<any>([]);
  const [value, setValue] = useState<string>();
  const [isOpen, setIsOpen] = useState<boolean>(false);

  useEffect(() => {
    if (clearValue) {
      setValue(undefined);
      onClearFilter();
    }
  }, [clearValue, onClearFilter]);

  const getAllBrand = async () => {
    try {
      const { data }: any = await fetchAllBrand();
      if (data) {
        const listBrands = data?.datas;
        let uniqueArray: any = [];
        listBrands.forEach((element: any) => {
          if (!uniqueArray.find((i: any) => i?.brandId === element?.brandId)) {
            uniqueArray.push(element);
          }
        });

        const formatData = uniqueArray.map((item: any) => {
          return {
            label: item.brandName,
            value: String(item.brandId),
            logoUrl: item.logoImage,
            disabled: item.disable,
          };
        });
        setListBrand(formatData);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getAllBrand();
  }, []);

  useEffect(() => {
    setValue(defaultValue);
  }, [defaultValue]);

  const onSearch = (value: string) => {
    // console.log("search:", value);
  };

  const handleChange = (value: string) => {
    if (onChange) {
      onChange(value);
      setValue(value);
    }
  };

  const customOptions = listBrand.map((brand: any) => {
    // console.log("brand: ", brand);

    return (
      <Select.Option
        key={brand.value}
        value={brand.value}
        label={brand.label}
        // disabled={brand.disabled}
      >
        <span className={`brand-option`}>
          <img width={20} src={brand.logoUrl || defaultBrandImg} />
          <span className="brand-name">{brand.label}</span>
        </span>
      </Select.Option>
    );
  });

  return (
    <S.Wrapper className={`${className} ${type}`}>
      {value && !hiddenClear && (
        <div
          onClick={() => {
            setValue(undefined);
            onChange && onChange("clear");
          }}
          className="clearBtn"
        >
          <CloseSelectIcon color="#bfbfbf" />
        </div>
      )}
      <Select
        style={style}
        showSearch
        placeholder="브랜드 검색"
        optionFilterProp="label"
        onChange={handleChange}
        onSearch={onSearch}
        value={value}
        disabled={disabled}
        onDropdownVisibleChange={(open: boolean) => setIsOpen(open)}
        suffixIcon={isOpen ? iconWhenOpen : iconWhenClose}
      >
        {customOptions}
      </Select>
    </S.Wrapper>
  );
};

export default memo(ListBrandSelectSearch);
