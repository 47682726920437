import { styled } from "styled-components";

export const Wrapper = styled.div``;

export const Container = styled.div`
  width: 100%;
  max-width: 732px;
  margin: auto;
  padding: 0 16px;
`;

export const Content = styled.div`
  padding: 60px 0 100px 0;
`;

export const ContentHeader = styled.div`
  padding-bottom: 60px;
  display: grid;
  gap: 25px;
  > h1 {
    font-size: 32px;
    font-family: var(--font-600);
    line-height: 35.2px;
  }
  > p {
    font-size: 22px;
    font-weight: 400;
    line-height: 24.2px;
    text-transform: capitalize;
  }
`;

export const ContentBody = styled.div`
  form {
    display: grid;
    gap: 20px;
  }
  .ant-form-item {
    margin-bottom: 25px;
    &:last-child {
      margin-bottom: 0;
    }
  }
`;

export const ButtonContainer = styled.div`
  /* padding-top: 20px; */
  display: grid;
  grid-template-columns: 260px 1fr;
  gap: 20px;
  > div {
    .ant-btn {
      width: 100%;
    }
  }
`;

export const FieldContainer = styled.div`
  border-radius: 20px;
  padding: 30px 25px;
  background-color: #fff;
  h2 {
    font-size: 26px;
    font-family: var(--font-600);
    line-height: 28.6px;
    margin-bottom: 25px;
    color: var(--text-color);
  }
  h3 {
    font-size: 22px;
    font-style: normal;
    font-family: var(--font-600);
    line-height: 24.2px;
    color: var(--text-color);
    margin-bottom: 25px;
  }
  .image-upload-container {
    margin-bottom: 25px;
    .upload-label {
      border-radius: 50%;
    }
  }
`;

export const BrandInfor = styled.div``;

export const FieldPair = styled.div`
  display: flex;
  gap: 15px;
  .ant-form-item {
    flex: 1;
  }
`;
export const BrandLocation = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 25px;
  h3 {
    margin-bottom: 0;
  }
  > a {
    display: flex;
    align-items: center;
    gap: 6px;
  }
`;
export const AddressContainer = styled.div`
  margin-bottom: 25px;
  position: relative;
`;

export const WrPopupAdress = styled.div`
  position: absolute;
  top: 0;
  background: #fff;
  width: 100%;
  z-index: 111;
  border: 1px solid;
  .close-popup-adress {
    position: absolute;
    top: -27px;
    width: 25px;
    height: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    right: 0;
    background: #878787;
    color: #fff;
    cursor: pointer;
  }
`;
export const OverLayout = styled.div`
  &.active {
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: 11;
    display: block;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }
`;
