import styled from "styled-components";

export const Wrapper = styled.div`
  .ant-btn {
    color: #fff;
    font-size: 16px;
    padding: 0 12px;
    height: 52px;
    border-color: #526557;
    background-color: #526557;
    border-radius: 20px;
    &.status-btn {
      height: 36px;
    }
    &:hover,
    :where(.css-dev-only-do-not-override-14wwjjs).ant-btn-default:not(
        :disabled
      ):not(.ant-btn-disabled):hover {
      border-color: #526557;
      color: #fff;
      box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    }
    .ant-btn-icon {
      vertical-align: -3px;
    }
  }
`;
