import { useState } from "react";
import * as S from "./style";
import { Image } from "antd";

interface Props {
  data: any;
}

export const ImageListComp: React.FC<Props> = ({ data }) => {
  const dataRender = data?.filter((_: any, index: number) => index < 3);
  const [openPreview, setOpenPreview] = useState<boolean>();

  return (
    <S.ListImage>
      <S.MainList>
        {dataRender?.map((item: any) => (
          <S.ImageBox key={item?.id}>
            <Image src={item?.url} preview={{ visible: false }} />
          </S.ImageBox>
        ))}
        {data?.length > 4 && (
          <S.ImageMore onClick={() => setOpenPreview(true)}>
            +{data?.length - 4}
          </S.ImageMore>
        )}
      </S.MainList>

      <S.PreviewModal style={{ display: "none" }}>
        <Image.PreviewGroup
          preview={{
            visible: openPreview,
            onVisibleChange: (v) => setOpenPreview(v),
          }}
        >
          {data?.map((item: any) => (
            <Image
              key={item?.id}
              src={item?.url}
              preview={{ visible: false }}
            />
          ))}
        </Image.PreviewGroup>
      </S.PreviewModal>
    </S.ListImage>
  );
};

export default ImageListComp;
