import { getFaqDetailApi } from "api/faq";
import { ReactComponent as Campaign } from "assets/icons/Campaign.svg";
import { ReactComponent as PencilIcon } from "assets/icons/Pencil.svg";
import ButtonComp from "components/ButtonCmp";
import dayjs from "dayjs";
import MainHeader from "layouts/components/Header";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import * as S from "./style";
import { typeMappings } from "../Client";

const FAQDetail: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const [dataDetail, setDataDetail] = useState<any>({});

  const getFaqDetail = async () => {
    try {
      if (id) {
        const { data }: any = await getFaqDetailApi({ id: +id });
        if (data) {
          setDataDetail(data);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (id) {
      getFaqDetail();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  return (
    <S.Wrapper>
      <MainHeader
        backLink="/customer-service/faq"
        title="FAQ Detail"
        breadCrumb={[
          {
            title: "FAQ Management",
            link: "/customer-service/faq",
            icon: <Campaign />,
          },
          {
            title: "FAQ detail",
          },
        ]}
        rightBtn={
          <ButtonComp
            style={{ padding: "0 30px", borderRadius: "12px" }}
            icon={<PencilIcon />}
            onClick={() => navigate(`/customer-service/faq/edit/${id}`)}
          >
            Edit FAQ
          </ButtonComp>
        }
      />
      <S.Content>
        <S.BlockTop>
          <h3>FAQ display status</h3>
          <button className={dataDetail.status === "SHOWING" ? "show" : "hide"}>
            {dataDetail.status === "SHOWING" ? "Show" : "Hidden"}
          </button>
          <p>
            Create at{" "}
            {dayjs(dataDetail.createdTime).format("YYYY/MM/DD HH:mm")}
          </p>
          <h3>FAQ Type: {typeMappings[dataDetail.type] || dataDetail.type}</h3>
        </S.BlockTop>
        <S.MainContent>
          <S.Question>
            <h4>Question</h4>
            <div>{dataDetail.question}</div>
          </S.Question>
          <S.Answer>
            <h4>Answer</h4>
            <div
              dangerouslySetInnerHTML={{ __html: dataDetail.answer ?? "" }}
            />
          </S.Answer>
        </S.MainContent>
      </S.Content>
    </S.Wrapper>
  );
};

export default FAQDetail;
