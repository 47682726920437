import { DefaultOptionType } from "antd/es/select";
const dataBankName: DefaultOptionType[] = [
  "카카오뱅크",
  "국민은행",
  "기업은행",
  "농협은행",
  "신한은행",
  "산업은행",
  "우리은행",
  "한국씨티은행",
  "하나은행",
  "SC제일은행",
  "경남은행",
  "광주은행",
  "대구은행",
  "도이치은행",
  "뱅크오브아메리카",
  "부산은행",
  "산림조합중앙회",
  "저축은행",
  "새마을금고",
  "수협은행",
  "신협중앙회",
  "우체국",
  "전북은행",
  "제주은행",
  "중국건설은행",
  "중국공상은행",
  "중국은행",
  "BNP파리바은행",
  "HSBC은행",
  "IP모간체이스은행",
  "케이뱅크",
  "토스뱅크",
  "교보증권",
  "대신증권",
  "DB금융투자",
  "메리츠증권",
  "미래에셋증권",
  "부국증권",
  "삼성증권",
  "산영증권",
  "신한금융투자",
  "에스케이증권",
  "현대차증권",
  "이베스트투자증권",
  "케이프투자증권",
  "키움증권",
  "한국포스중권",
  "하나금융투자",
  "하이투자증권",
  "한국투자증권",
  "한화투자증권",
  "KB증권",
  "KTB투자증권",
  "BNK투자증권",
  "NH투자증권",
  "카카오페이증권",
  "IBK투자증권",
  "토스증권",
].map((bankName) => ({
  value: bankName,
  label: bankName,
}));

export default dataBankName;
