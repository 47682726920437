import api from "axiosInstance/config";
import { API_URL } from "../constants";
import { GetDetailInquiryApi, GetListInquiryApi, ReplyInquiryApi } from "model/inquiry";

export const getListInquiryApi = async (params: GetListInquiryApi) => {
  return api.request({
    url: API_URL.LIST_INQUIRY,
    method: "GET",
    tokenRequired: true,
    params,
  });
};

export const getDetailInquiryApi = async (params: GetDetailInquiryApi) => {
  return api.request({
    url: API_URL.INQUIRY_DETAIL,
    method: "GET",
    tokenRequired: true,
    params,
  });
};

export const replyInquiryApi = async (data: ReplyInquiryApi) => {
  return api.request({
    url: API_URL.INQUIRY_REPLY,
    method: "POST",
    tokenRequired: true,
    data,
  });
};
