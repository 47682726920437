import { Layout } from "antd";
import { styled } from "styled-components";
const { Sider } = Layout;
export const Sidebar = styled(Sider)`
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.25);
  background-color: #fff;
  z-index: 100;
  position: fixed !important;
  height: 100%;
  &.ant-layout-sider {
    background-color: #fff !important;
  }
  .ant-menu-submenu {
    border-radius: 0;
    .ant-menu-submenu-title {
      margin: 0;
      width: 100%;
      font-size: 16px;
      color: var(--brand-color);
    }
  }

  .ant-menu-submenu-title {
    padding: 22px 0;
    border-radius: 0;
  }
  .ant-menu-item {
    border-radius: 0;
    padding: 6px 0;
    margin: 0;
    width: 100%;
    .ant-menu-title-content {
      line-height: 24px;
      color: var(--brand-color);
    }
  }
  .ant-menu-item:hover {
    border-radius: 0;
    border-right: 3px solid #526557;
    background: var(--hint-color);
  }
  .ant-menu-item-selected {
    border-radius: 0;
    border-right: 3px solid #526557;
    background: var(--hint-color);
  }
  .logo-container {
    background-color: white;
    height: 80px;
    padding: 0 25px;
    display: flex;
    align-items: center;
    p {
      font-size: 20px;
      margin-left: 12px;
      font-family: var(--font-500);
      color: var(--brand-color);
    }
  }
  .ant-menu {
    border: 0 !important;
    .ant-menu-submenu-title {
      padding-right: 20px;
    }
    .ant-menu-title-content a {
      color: var(--brand-color);
    }
  }
`;

export const LogoutButton = styled.div`
  background-color: #fff;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  cursor: pointer;
  position: fixed;
  width: 280px;
  bottom: 20px;
  left: 0;
  span {
    font-size: 16px;
    color: #526557;
  }
`;
