import { message, Select } from "antd";
// import ArrowDownIcon from "@assets/icons/community/expand_more.svg";
import * as S from "./style";
import useWindowSize from "hooks/useWindowSize";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { getNormalDetailApi, updateEmailReceiveApi } from "api/membership";
import dayjs from "dayjs";
import { MoneyConvert } from "utils/format";

interface DetailInfoProps {
  data?: any;
}

const DetailInfo: React.FC<DetailInfoProps> = () => {
  const { width } = useWindowSize();
  const { id } = useParams();
  const [info, setInfo] = useState<any>();

  const getNormalDetail = async () => {
    if (!id) return;
    const { data, error }: any = await getNormalDetailApi({ userId: +id });
    if (error) {
      console.error(error);
      return;
    }
    setInfo(data);
  };

  const handleChangeEmaiLReceive = async (v: string) => {
    // console.log("v", v)
    if (!id) return;
    const emailReceived: boolean = v === "1" ? true : false;
    const { data, error }: any = await updateEmailReceiveApi({
      userId: +id,
      emailReceived,
    });
    if (error) {
      console.error(error);
      message.error("Error updating email");
      return;
    }
    message.success("Successfully updated");
    getNormalDetail();
  };

  useEffect(() => {
    getNormalDetail();
  }, []);
  return (
    <S.WrapField>
      <div className="field-item">
        <label>브랜드명</label>
        <div>{info?.brandName}</div>
      </div>
      <div className="field-item">
        <label>멤버십 No.</label>
        <div className="has-label">
          {info?.membershipNo} <span>프리미엄</span>
        </div>
      </div>
      <div className="field-item">
        <label>가입 상태 </label>
        <div>{info?.registedStatus === "EXPIRED" ? "만료" : "멤버십 유지"}</div>
      </div>
      <div className="field-item">
        <label>자동결제 상태</label>
        <div>{info?.status ? "유지" : "해지"}</div>
      </div>
      <div className="field-item">
        <label>진행회차</label>
        <div>{info?.totalPay}</div>
      </div>
      <div className="field-item">
        <label>최초가입일</label>
        <div>{dayjs(info?.firstRegistedDate).format("YYYY-MM-DD")}</div>
      </div>
      <div className="field-item">
        <label>최근결제일</label>
        <div>{dayjs(info?.lastPay).format("YYYY-MM-DD")}</div>
      </div>
      <div className="field-item">
        <label>멤버십 만료일</label>
        <div>{dayjs(info?.expiredDate).format("YYYY-MM-DD")}</div>
      </div>
      <div className="field-item">
        <label>최근 방문일</label>
        <div>{dayjs(info?.lastUsedDate).format("YYYY-MM-DD")}</div>
      </div>
      <div className="field-item">
        <label>이름</label>
        <div>{info?.displayName}</div>
      </div>
      <div className="field-item">
        <label>생년월일</label>
        <div>{dayjs(info?.birthDate).format("YYYY-MM-DD")}</div>
      </div>
      <div className="field-item">
        <label>성별</label>
        <div>{info?.gender === "MALE" ? "남성" : "여성"}</div>
      </div>
      <div className="field-item">
        <label>연락처</label>
        <div>{info?.phoneNumber}</div>
      </div>
      <div className="field-item">
        <label>이메일</label>
        <div>{info?.email}</div>
      </div>

      <div className="field-item">
        <label>마케팅 수신 여부</label>
        <div className="wr-select">
          <Select
            popupClassName="custom-select-dropdown"
            defaultValue={"1"}
            // value={sort}
            style={{
              width: "100%",
              maxWidth: width && width > 767 ? "220px" : "100%",
              height: 48,
            }}
            onChange={handleChangeEmaiLReceive}
            // suffixIcon={
            //   <div>
            //     <ArrowDownIcon />
            //   </div>
            // }
            options={[
              { value: "1", label: "동의" },
              { value: "0", label: "비동의" },
            ]}
          />
        </div>
      </div>
    </S.WrapField>
  );
};

export default DetailInfo;
