// Trong components/Table.tsx
import { Form, Input, InputRef, message } from "antd";
import { DefaultOptionType } from "antd/es/select";
import MainHeader from "layouts/components/Header";
import DaumPostcode from "react-daum-postcode";
import { useNavigate, useParams } from "react-router-dom";
import * as S from "./style";

import { ReactComponent as BankIcon } from "assets/icons/Bank.svg";
import { ReactComponent as CompassIcon } from "assets/icons/Compass.svg";
import { ReactComponent as EmailIcon } from "assets/icons/Email.svg";
import { ReactComponent as FileTextIcon } from "assets/icons/FileText.svg";
import { ReactComponent as MapPinLineIcon } from "assets/icons/MapPinLine.svg";
import { ReactComponent as PhoneIcon } from "assets/icons/Phone.svg";
import { ReactComponent as StorefrontIcon } from "assets/icons/Storefront.svg";
import { ReactComponent as TreeStructureIcon } from "assets/icons/TreeStructure.svg";
import { ReactComponent as UserListIcon } from "assets/icons/UserList.svg";
import { ReactComponent as UserIcon } from "assets/icons/Users-1.svg";

import ButtonComp from "components/ButtonCmp";

import { CloseOutlined } from "@ant-design/icons";
import { useForm } from "antd/es/form/Form";
import { updateBrandApi } from "api/brand";
import { fetchCategories } from "api/category";
import { uploadSingleFile } from "api/uploadSingleFile";
import { getDetailUserBrandApi, updateClientAcc } from "api/user";
import SelectCmp from "components/SelectCmp";
import UploadImage from "components/UploadImage";
import { useEffect, useRef, useState } from "react";
import { formatPhoneNumber } from "utils/format";
import dataBankName from "../bankList";
import SingleFileUpload from "components/SingleFileUpload";
// import imgLogoDemo from "assets/images/avatar-demo.png";

const EditBrandAccount: React.FC = () => {
  const brandNameRef = useRef<InputRef>(null);
  const navigate = useNavigate();
  const [form] = useForm();
  const { brandId, userId } = useParams<string>();
  // const [initData, setInitData] = useState<any>([]);
  const [messageApi, contextHolder] = message.useMessage({ maxCount: 2 });
  const [listCategory, setListCategory] = useState<DefaultOptionType[]>([]);
  const [openPopupAddress, setopenPopupAddress] = useState<boolean>(false);
  const [openCompanyAddress, setOpenCompanyAddress] = useState<boolean>(false);

  const errorMess = (mess: string) => {
    messageApi.open({
      type: "error",
      content: mess,
    });
  };

  const handleCompleteAdress = (data: any) => {
    let fullAddress = data.address;
    let extraAddress = "";

    if (data.addressType === "R") {
      if (data.bname !== "") {
        extraAddress += data.bname;
      }
      if (data.buildingName !== "") {
        extraAddress +=
          extraAddress !== "" ? `, ${data.buildingName}` : data.buildingName;
      }
      fullAddress += extraAddress !== "" ? ` (${extraAddress})` : "";
    }

    console.log(fullAddress); // e.g. '서울 성동구 왕십리로2길 20 (성수동1가)'
  };

  const handleCancel = () => {
    form.resetFields();
    navigate(`/users/brand-user/${brandId}/${userId}`);
  };

  const onFinish = async (values: any) => {
    // console.log("Success:", values);

    const uploadFile = async (file: any) => {
      if (!file || typeof file === "string") return file;

      const formData = new FormData();
      formData.append("file", file);

      try {
        const resp: any = await uploadSingleFile(formData);
        if (resp.error && resp.error.message !== "file not found") {
          throw new Error("Upload image failed");
        }
        return resp?.imageUrl || "";
      } catch (error) {
        console.error(error);
        throw new Error("Upload image failed");
      }
    };

    try {
      const imgUrl = await uploadFile(values.avatar);
      const businessLicenseUrl = await uploadFile(values.businessLicense);
      const bankImageUrl = await uploadFile(values.bankImage);

      if (!brandId || !userId) {
        console.error("can not find brandId or userId");
        return;
      }

      const { data, error }: any = await updateBrandApi({
        id: +brandId,
        logoImage: imgUrl || values.avatar,
        brandName: values.brandName,
        categoryId: +values.categoryId,
        brandHotline: values.brandHotline?.replace(/-/g, ""),
        cSEmail: values.csEmail,
        brandAddress: values.brandAddress,
        postCode: values.zoneCode,
        companyName: values.companyName,
        companyPhoneNumber: values.companyPhoneNumber?.replace(/-/g, ""),
        representative: values.representative,
        companyAddress: values.companyAddress,
        companyEmail: values.companyEmail,
        bankName: values.bankName,
        bankAccountName: values.bankAccountName,
        bankAccountNumber: values.bankAccountNumber,
        brandAddressDescription: values.moreAddress,
        businessLicense: businessLicenseUrl,
        bankImage: bankImageUrl,
      });

      if (error) {
        throw new Error("Update Failed!");
      }
      const resp: any = await updateClientAcc({
        id: +userId,
        phoneNumber: values.phoneNumber,
        manager: values.manager,
      });
      // console.log("resp", resp);

      if (resp.error) {
        console.log("(resp.error");

        throw new Error("Update Failed!");
      }
      navigate(`/users/brand-user/${brandId}/${userId}`);
    } catch (error: any) {
      errorMess(error.message);
    }
  };

  // const onFinish = async (values: any) => {
  //   console.log("Success:", values);
  //   let imgUrl: string = "";
  //   let businessLicenseUrl: string = "";
  //   let bankImageUrl: string = "";

  //   if (values.avatar && typeof values.avatar !== "string") {
  //     const formData = new FormData();
  //     formData.append("file", values.avatar);

  //     try {
  //       const resp: any = await uploadSingleFile(formData);
  //       if (resp.error && resp.error.message !== "file not found") {
  //         errorMess("Upload image failed");
  //         return;
  //       }
  //       if (resp?.imageUrl) {
  //         imgUrl = resp.imageUrl;
  //       }
  //     } catch (error) {
  //       console.log(error);
  //       errorMess("Upload image failed");
  //       return;
  //     }
  //   }

  //   if (values.businessLicense && typeof values.businessLicense !== "string") {
  //     const businessLicenseFormData = new FormData();
  //     businessLicenseFormData.append("file", values?.businessLicense);
  //     const businessLicenseResp: any = await uploadSingleFile(
  //       businessLicenseFormData
  //     );

  //     businessLicenseUrl = businessLicenseResp?.imageUrl || undefined;
  //   }
  //   if (values.bankImage && typeof values.bankImage !== "string") {
  //     const bankImageFormData = new FormData();
  //     bankImageFormData.append("file", values?.bankImage);
  //     const bankImageResp: any = await uploadSingleFile(bankImageFormData);
  //     bankImageUrl = bankImageResp?.imageUrl || undefined;
  //   }

  //   if (brandId) {
  //     try {
  //       const { data, error }: any = await updateBrandApi({
  //         id: +brandId,
  //         logoImage: imgUrl || values.avatar,
  //         brandName: values.brandName,
  //         categoryId: +values.categoryId,
  //         brandHotline: values.brandHotline?.replace(/-/g, ""),
  //         cSEmail: values.csEmail,
  //         brandAddress: values.brandAddress,
  //         postCode: values.zoneCode,
  //         companyName: values.companyName,
  //         companyPhoneNumber: values.companyPhoneNumber?.replace(/-/g, ""),
  //         representative: values.representative,
  //         companyAddress: values.companyAddress,
  //         companyEmail: values.companyEmail,
  //         bankName: values.bankName,
  //         bankAccountName: values.bankAccountName,
  //         bankAccountNumber: values.bankAccountNumber,
  //         brandAddressDescription: values.moreAddress,
  //         businessLicense: businessLicenseUrl,
  //         bankImage: bankImageUrl,
  //       });
  //       if (data) {
  //         navigate(`/users/brand-user/${brandId}/${userId}`);
  //       }
  //       if (error) {
  //         errorMess("Update Failed!");
  //         console.error(error);
  //       }
  //     } catch (error) {
  //       errorMess("Update Failed!");
  //       console.error(error);
  //     }
  //   }
  // };

  const onFinishFailed = (errorInfo: any) => {
    // console.log("Failed:", errorInfo);
    brandNameRef.current!.focus({
      cursor: "end",
    });
  };

  const handleCompleteCompanyAddress = (data: any) => {
    // console.log(data);
    let fullAddress = data.address;
    let extraAddress = "";

    if (data.addressType === "R") {
      if (data.bname !== "") {
        extraAddress += data.bname;
      }
      if (data.buildingName !== "") {
        extraAddress +=
          extraAddress !== "" ? `, ${data.buildingName}` : data.buildingName;
      }
      fullAddress += extraAddress !== "" ? ` (${extraAddress})` : "";
    }

    form.setFieldsValue({
      companyAddress: fullAddress,
    });
    // setAddressValue(fullAddress);
  };

  const getAllCategory = async () => {
    try {
      const { data }: any = await fetchCategories();
      if (data) {
        const formatData = data.map((item: any) => {
          const newItem: DefaultOptionType = {
            label: item.korName,
            value: item.id,
          };
          return newItem;
        });
        setListCategory(formatData);
      } else {
        setListCategory([]);
      }
    } catch (error) {
      console.log(error);
      setListCategory([]);
    }
  };

  useEffect(() => {
    if (userId && brandId) {
      const getDetailUserBrandA = async () => {
        const { data, error }: any = await getDetailUserBrandApi({
          userId: +userId,
          brandId: +brandId,
        });
        if (data) {
          // console.log(data);

          form.setFieldsValue({
            avatar: data.logoImage,
            brandName: data.brandName,
            categoryId: data.categoryId,
            brandHotline: data?.hotline
              ? formatPhoneNumber(data.hotline)
              : undefined,
            csEmail: data.customerServiceEmail,
            brandAddress: data.address,
            zoneCode: data.postCode,
            moreAddress: data.addressDescription,
            companyName: data.companyName,
            representative: data.representative,
            companyAddress: data.companyAddress,
            companyPhoneNumber: data.companyPhone
              ? formatPhoneNumber(data?.companyPhone)
              : undefined,
            companyEmail: data.companyEmail,
            bankName: data.bankName,
            bankAccountName: data.bankAccountName,
            bankAccountNumber: data.bankAccountNumber,
            businessLicense: data.businessLicense,
            bankImage: data.bankImage,
            email: data.email,
            phoneNumber: data.phoneNumber,
            manager: data.manager,
          });
          // setInitData(data);
        }
        if (error) {
          console.error(error);
        }
      };
      getDetailUserBrandA();
    }

    // Get all category
    getAllCategory();
  }, []);

  return (
    <S.Wrapper>
      {contextHolder}
      <MainHeader
        title="계정 정보 수정"
        backLink={`/users/brand-user/${brandId}/${userId}`}
      />
      <S.Container>
        <S.Content>
          {/* <S.ContentHeader>
            <h1>Step 2/2: Create Head admin Account</h1>
            <p>Create admin account</p>
          </S.ContentHeader> */}
          <S.ContentBody>
            <Form
              name="edit"
              form={form}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              autoComplete="off"
            >
              <S.FieldContainer>
                <h2>브랜드 정보</h2>
                <h3>브랜드 로고 및 브랜드명</h3>

                <Form.Item
                  name="avatar"
                  rules={[{ required: true, message: "Please choose avatar!" }]}
                >
                  <UploadImage
                    initImage={form.getFieldValue("avatar")}
                    onImageChange={(file: File) => {
                      form.setFieldsValue({ avatar: file });
                    }}
                  />
                </Form.Item>

                <Form.Item
                  name="brandName"
                  rules={[
                    { required: true, message: "Please input brand name!" },
                    {
                      min: 2,
                      message: "Please enter at least 2 characters",
                    },
                  ]}
                >
                  <Input
                    ref={brandNameRef}
                    prefix={<StorefrontIcon />}
                    placeholder="Brand name"
                    className="form-input"
                    onBlur={(e) =>
                      form.setFieldsValue({
                        brandName: e.target.value.trim().replace(/\s+/g, " "),
                      })
                    }
                  />
                </Form.Item>

                <Form.Item
                  name="categoryId"
                  rules={[
                    {
                      required: true,
                      message: "Please select category!",
                    },
                  ]}
                >
                  <SelectCmp
                    options={listCategory}
                    placeholder="Category"
                    className="form-input form-select"
                    prefix={<TreeStructureIcon />}
                    style={{ height: "46px", borderColor: "#d9d9d9" }}
                  />
                </Form.Item>

                <S.FieldPair>
                  <Form.Item
                    name="brandHotline"
                    // rules={[
                    //   {
                    //     required: true,
                    //     message: "Please input Brand hotline!",
                    //   },
                    // ]}
                  >
                    <Input
                      prefix={<PhoneIcon />}
                      placeholder="Brand hotline"
                      className="form-input"
                      onChange={(e) => {
                        form.setFieldValue(
                          "brandHotline",
                          e.target.value.replace(/[^0-9-]/g, "")
                        );
                      }}
                    />
                  </Form.Item>

                  <Form.Item
                    name="csEmail"
                    rules={[
                      { required: true, message: "Please input CS Email!" },
                      {
                        type: "email",
                        message: "Please enter a valid email address",
                      },
                    ]}
                  >
                    <Input
                      prefix={<EmailIcon />}
                      placeholder="CS Email"
                      className="form-input"
                    />
                  </Form.Item>
                </S.FieldPair>
                <S.BrandLocation>
                  <h3>매장 주소</h3>
                  {/* <a href="#">
                    <CompassIcon />
                    <span>지도 찾기</span>
                  </a> */}
                </S.BrandLocation>
                <S.AddressContainer>
                  <Form.Item
                    name="brandAddress"
                    // rules={[
                    //   { required: true, message: "Please input address!" },
                    // ]}
                  >
                    <Input
                      prefix={<MapPinLineIcon />}
                      placeholder="Address"
                      className="form-input"
                      onFocus={() => setopenPopupAddress(true)}
                      // onBlur={()=>setopenPopupAddress(false)}
                    />
                  </Form.Item>
                  <S.OverLayout
                    onClick={() => setopenPopupAddress(false)}
                    className={openPopupAddress ? "active" : "sss"}
                  />
                  {openPopupAddress && (
                    <S.WrPopupAdress>
                      <div
                        className="close-popup-adress"
                        onClick={() => setopenPopupAddress(false)}
                      >
                        <CloseOutlined />
                      </div>
                      <DaumPostcode
                        onComplete={handleCompleteAdress}
                        onClose={() => setopenPopupAddress(false)}
                      />
                    </S.WrPopupAdress>
                  )}
                </S.AddressContainer>
                <Form.Item>
                  <Form.Item
                    name="zoneCode"
                    style={{
                      width: "calc(50% - 7.5px)",
                      marginRight: "7.5px",
                      float: "left",
                    }}
                    // rules={[
                    //   { required: true, message: "Please input post code!" },
                    // ]}
                  >
                    <Input
                      prefix={<MapPinLineIcon />}
                      placeholder="Post code"
                      className="form-input"
                    />
                  </Form.Item>
                  <Form.Item
                    name="moreAddress"
                    style={{
                      width: "calc(50% - 7.5px)",
                      marginLeft: "7.5px",
                      float: "right",
                    }}
                  >
                    <Input
                      prefix={<MapPinLineIcon />}
                      placeholder="More address"
                      className="form-input"
                    />
                  </Form.Item>
                </Form.Item>
              </S.FieldContainer>

              <S.FieldContainer>
                <h2>회사 정보</h2>
                <h3>회사 상세 정보</h3>
                <Form.Item
                  name="companyName"
                  rules={[
                    // { required: true, message: "Please input company name!" },
                    {
                      min: 2,
                      message: "Please enter at least 2 characters",
                    },
                  ]}
                >
                  <Input
                    prefix={<FileTextIcon />}
                    placeholder="Company name"
                    className="form-input"
                    onBlur={(e) =>
                      form.setFieldsValue({
                        companyName: e.target.value.trim().replace(/\s+/g, " "),
                      })
                    }
                  />
                </Form.Item>

                <Form.Item
                  name="representative"
                  rules={[
                    // {
                    //   required: true,
                    //   message: "Please input representative!",
                    // },
                    {
                      min: 2,
                      message: "Please enter at least 2 characters",
                    },
                  ]}
                >
                  <Input
                    prefix={<UserIcon />}
                    placeholder="Representative"
                    className="form-input"
                    onBlur={(e) =>
                      form.setFieldsValue({
                        representative: e.target.value
                          .trim()
                          .replace(/\s+/g, " "),
                      })
                    }
                  />
                </Form.Item>

                <S.AddressContainer>
                  <Form.Item
                    name="companyAddress"
                    // rules={[
                    //   {
                    //     required: true,
                    //     message: "Please input company address!",
                    //   },
                    // ]}
                  >
                    <Input
                      prefix={<MapPinLineIcon />}
                      placeholder="Company address"
                      className="form-input"
                      onFocus={() => setOpenCompanyAddress(true)}
                    />
                  </Form.Item>
                  <S.OverLayout
                    onClick={() => setOpenCompanyAddress(false)}
                    className={openCompanyAddress ? "active" : "sss"}
                  />
                  {openCompanyAddress && (
                    <S.WrPopupAdress>
                      <div
                        className="close-popup-adress"
                        onClick={() => setOpenCompanyAddress(false)}
                      >
                        <CloseOutlined />
                      </div>
                      <DaumPostcode
                        onComplete={handleCompleteCompanyAddress}
                        onClose={() => setOpenCompanyAddress(false)}
                      />
                    </S.WrPopupAdress>
                  )}
                </S.AddressContainer>

                <S.FieldPair>
                  <Form.Item
                    name="companyPhoneNumber"
                    // rules={[
                    //   {
                    //     required: true,
                    //     message: "Please input Brand hotline!",
                    //   },
                    // ]}
                  >
                    <Input
                      prefix={<PhoneIcon />}
                      placeholder="Phone number"
                      className="form-input"
                      onChange={(e) => {
                        form.setFieldValue(
                          "companyPhoneNumber",
                          e.target.value.replace(/[^0-9-]/g, "")
                        );
                      }}
                    />
                  </Form.Item>

                  <Form.Item
                    name="companyEmail"
                    rules={[
                      // { required: true, message: "Please input email!" },
                      {
                        type: "email",
                        message: "Please enter a valid email address",
                      },
                    ]}
                  >
                    <Input
                      prefix={<EmailIcon />}
                      placeholder="Email"
                      className="form-input"
                    />
                  </Form.Item>
                </S.FieldPair>

                <h3>담당자 정보</h3>
                <Form.Item
                  name="email"
                  rules={[
                    // {
                    //   required: true,
                    //   message: "메일 주소를 입력해 주세요",
                    // },
                    {
                      type: "email",
                      message:
                        "유효한 이메일 주소를 입력해주세요 ex) 123@gmail.com",
                    },
                  ]}
                >
                  <Input
                    disabled
                    prefix={<EmailIcon />}
                    placeholder="담당자 메일 주소"
                    className="form-input"
                  />
                </Form.Item>

                <Form.Item
                  name="manager"
                  rules={[
                    // { required: true, message: "담당자 이름 입력해 주세요" },
                    {
                      min: 3,
                      message: "3글자 이상이어야 합니다",
                    },
                  ]}
                >
                  <Input
                    prefix={<UserIcon />}
                    placeholder="담당자 이름"
                    className="form-input"
                    onBlur={(e) =>
                      form.setFieldsValue({
                        manager: e.target.value.trim(),
                      })
                    }
                  />
                </Form.Item>

                <Form.Item
                  name="phoneNumber"
                  rules={[
                    // { required: true, message: "휴대폰 번호를 입력해 주세요" },
                    {
                      len: 13,
                      message: "휴대폰 번호 11자리를 입력해주세요",
                    },
                  ]}
                >
                  <Input
                    prefix={<PhoneIcon />}
                    placeholder="담당자 휴대폰 번호"
                    className="form-input"
                    maxLength={13}
                    onChange={(e) => {
                      const phoneNumber = e.target.value.replace(/\D/g, "");
                      let formattedPhoneValue = "";
                      for (let i = 0; i < phoneNumber.length; i++) {
                        if (i === 3 || i === 7) {
                          formattedPhoneValue += "-";
                        }
                        if (i < 11) {
                          formattedPhoneValue += phoneNumber[i];
                        }
                      }
                      form.setFieldValue("phoneNumber", formattedPhoneValue);
                    }}
                  />
                </Form.Item>

                <h3>정산 정보</h3>
                <S.FieldPair>
                  <Form.Item name="bankName">
                    <SelectCmp
                      options={dataBankName}
                      placeholder="Bank name"
                      className="form-input form-select"
                      prefix={<BankIcon />}
                      style={{ height: "46px", borderColor: "#d9d9d9" }}
                    />
                  </Form.Item>

                  <Form.Item
                    name="bankAccountName"
                    rules={[
                      {
                        min: 2,
                        message: "Please enter at least 2 characters",
                      },
                    ]}
                  >
                    <Input
                      prefix={<UserIcon />}
                      placeholder="Account name"
                      className="form-input"
                      onBlur={(e) =>
                        form.setFieldsValue({
                          bankAccountName: e.target.value
                            .trim()
                            .replace(/\s+/g, " "),
                        })
                      }
                    />
                  </Form.Item>
                </S.FieldPair>

                <Form.Item
                  name="bankAccountNumber"
                  rules={[
                    {
                      pattern: /^[0-9]*$/,
                      message: "Only enter numbers",
                    },
                  ]}
                >
                  <Input
                    prefix={<UserListIcon />}
                    placeholder="Account number"
                    className="form-input"
                  />
                </Form.Item>
                <Form.Item name="businessLicense">
                  <SingleFileUpload
                    defaultName={form.getFieldValue("businessLicense")}
                    buttonLabel="사업자등록증 업로드"
                  />
                </Form.Item>
                <Form.Item name="bankImage">
                  <SingleFileUpload
                    defaultName={form.getFieldValue("bankImage")}
                    buttonLabel="통장사본 업로드"
                  />
                </Form.Item>
              </S.FieldContainer>

              <S.ButtonContainer>
                <ButtonComp onClick={handleCancel} status="noBackground">
                  수정 취소
                </ButtonComp>
                <ButtonComp htmlType="submit">수정 완료</ButtonComp>
              </S.ButtonContainer>
            </Form>
          </S.ContentBody>
        </S.Content>
      </S.Container>
    </S.Wrapper>
  );
};

export default EditBrandAccount;
