import { ReactComponent as Campaign } from "assets/icons/Campaign.svg";
import dayjs from "dayjs";
import MainHeader from "layouts/components/Header";
import React, { useEffect, useState } from "react";
import * as S from "./style";
import { getDetailInquiryApi } from "api/inquiry";
import { useParams } from "react-router-dom";
import { typeMappings } from "pages/CustomerService/FAQ/Client";

const InquiryDetail: React.FC = () => {
  const [dataDetail, setDataDetail] = useState<any>({});
  const { id } = useParams();

  const getDetailInquiry = async () => {
    try {
      if (id) {
        const { data }: any = await getDetailInquiryApi({ id: +id });
        if (data) {
          setDataDetail(data);
        }
      }
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    getDetailInquiry();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <S.Wrapper>
      <MainHeader
        backLink="/customer-service/inquiry"
        title="1:1 문의"
        breadCrumb={[
          {
            title: "고객서비스",
            link: "/customer-service/inquiry",
            icon: <Campaign />,
          },
          {
            title: "1:1 답변하기",
          },
        ]}
      />
      <S.Content>
        <S.BlockTop>
          <div className="line">
            <h3>질문 작성자</h3>
            <div className="user-info">
              <span className="strong">{dataDetail?.displayName}</span>
              <span className="vertical-line" />
              <span>{dataDetail?.verifiedPhoneNumber}</span>
            </div>
          </div>
          <div className="line">
            <span>질문 작성일</span>
            <span>
              {dayjs(dataDetail.createdTime).format("YYYY/MM/DD HH:mm")}
            </span>
          </div>
          <div className="line">
            <span>답변 작성일</span>
            <span>
              {dayjs(dataDetail.updatedTime).format("YYYY/MM/DD HH:mm")}
            </span>
          </div>
        </S.BlockTop>
        <S.MainContent>
          <S.Question>
            <h4>
              문의 유형: {typeMappings[dataDetail.type] || dataDetail.type}
            </h4>
          </S.Question>
          <S.Question>
            <h4>질문 내용</h4>
            <h4>{dataDetail.subject}</h4>
            <p className="desc">{dataDetail.description}</p>
          </S.Question>
          <S.Answer>
            <h4>답변 내용</h4>
            <div
              dangerouslySetInnerHTML={{ __html: dataDetail.answer ?? "" }}
            />
          </S.Answer>
        </S.MainContent>
      </S.Content>
    </S.Wrapper>
  );
};

export default InquiryDetail;
