import { useEffect, useState } from "react";
import * as S from "../style";
import { DatePicker } from "antd";
import { CloseCircleOutlined } from "@ant-design/icons";
import dayjs from "dayjs";

export type TimeType = "WEEK" | "1M" | "DATE";
type DateType = {
  start?: string;
  end?: string;
};
type TimeItem = {
  key: TimeType;
  label: string;
};

interface Props {
  onChange?: (timeType: TimeType, date?: DateType) => void;
  defaultTimeType?: TimeType;
}

const timeList: TimeItem[] = [
  {
    key: "WEEK",
    label: "최근 7일",
  },
  {
    key: "1M",
    label: "이번 달",
  },
  {
    key: "DATE",
    label: "직접설정",
  },
];

const PickTime: React.FC<Props> = ({ onChange, defaultTimeType }) => {
  const [timeType, setTimeType] = useState<TimeType>();
  // const [showClear, setShowClear] = useState<boolean>(false);
  const [date, setDate] = useState<DateType>({
    start: "",
    end: "",
  });

  const handleSelectTimeType = (timeTypeItem: TimeItem) => {
    setTimeType(timeTypeItem.key);
    onChange?.(timeTypeItem.key);
    if (timeTypeItem.key !== "DATE") {
      setDate({});
    }
  };

  useEffect(() => {
    setTimeType(defaultTimeType);
  }, [defaultTimeType]);

  // useEffect(() => {
  //   if (date.start && date.end) {
  //     const dateFull: DateType = {
  //       start: date.start,
  //       end: date.end,
  //     };
  //     setShowClear(true);
  //     timeType && onChange?.(timeType, dateFull);
  //   } else {
  //     setShowClear(false);
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [date]);

  return (
    <S.PickTime>
      <div className="wr-time-type">
        {timeList.map((item: TimeItem) => (
          <button
            className={`time-btn ${item.key === timeType ? "active" : ""}`}
            onClick={() => handleSelectTimeType(item)}
            key={item.key}
          >
            {item.label}
          </button>
        ))}
      </div>
      {timeType === "DATE" && (
        <div className="wr-date">
          <DatePicker
            className="start-date"
            placeholder="Start date"
            onChange={(_, dateString) => {
              setDate((prev: DateType) => ({ ...prev, start: dateString }));
              onChange?.(timeType, { start: dateString, end: date.end });
            }}
            value={date.start ? dayjs(date.start) : null}
          />
          {date.start || date.end ? (
            <div
              onClick={() => {
                onChange?.(timeType);
                // setShowClear(false);
                setDate({});
              }}
              style={{ cursor: "pointer", width: 30, textAlign: "center" }}
            >
              <CloseCircleOutlined />
            </div>
          ) : (
            <div style={{ width: 30, textAlign: "center" }}>-</div>
          )}
          <DatePicker
            className="end-date"
            placeholder="End date"
            onChange={(_, dateString) => {
              setDate((prev: DateType) => ({ ...prev, end: dateString }));
              onChange?.(timeType, { start: date.start, end: dateString });
            }}
            value={date.end ? dayjs(date.end) : null}
          />
        </div>
      )}
    </S.PickTime>
  );
};
export default PickTime;
