import { useEffect, useState } from "react";
import * as S from "./style";
import { DatePicker } from "antd";
import { CloseCircleOutlined } from "@ant-design/icons";
import dayjs, { Dayjs } from "dayjs";

type DateType = {
  start?: Dayjs | null;
  end?: Dayjs | null;
};

export type TimeType = "WEEK" | "MONTH" | "DATE";

interface Props {
  handleAddTime?: (startTime: Dayjs, endTime: Dayjs) => void;
}

const SelectTime: React.FC<Props> = ({ handleAddTime }) => {
  const [showClear, setShowClear] = useState<boolean>(false);
  const [date, setDate] = useState<DateType>({});

  useEffect(() => {
    if (date.start && date.end) {
      // const dateFull: DateType = {
      //   start: date.start,
      //   end: date.end,
      // };
      setShowClear(true);
    } else {
      setShowClear(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [date]);

  const addTime = () => {
    if (date.start && date.end) {
      handleAddTime?.(date.start, date.end);
    }
  };

  return (
    <S.Wrapper>
      <div className="wr-date">
        <DatePicker
          className="start-date"
          placeholder="시작날짜"
          onChange={(v) => setDate((prev: DateType) => ({ ...prev, start: v }))}
          value={date.start ? dayjs(date.start) : null}
        />
        {showClear ? (
          <div
            onClick={() => {
              // onChange?.(timeType);
              setShowClear(false);
              setDate({});
            }}
            style={{ cursor: "pointer", width: "69px", textAlign: "center" }}
          >
            <CloseCircleOutlined />
          </div>
        ) : (
          <div style={{ width: 69, textAlign: "center" }}>~</div>
        )}
        <DatePicker
          className="end-date"
          placeholder="종료날짜"
          onChange={(v) => setDate((prev: DateType) => ({ ...prev, end: v }))}
          value={date.end ? dayjs(date.end) : null}
        />
      </div>
      <S.AddButton>
        <button
          disabled={!date.start || !date.end}
          onClick={addTime}
          style={{
            backgroundColor: !date.start || !date.end ? "#F1F1F1" : "#526557",
          }}
        >
          Add
        </button>
      </S.AddButton>
    </S.Wrapper>
  );
};
export default SelectTime;
