import { Tabs, TabsProps } from "antd";
import { getListOrderApi, getOrderCountApi } from "api/groupBuying";
import { ReactComponent as SearchIcon } from "assets/icons/Search.svg";
import { ReactComponent as IconSearch14 } from "assets/icons/search14.svg";
import DateRangePicker from "components/DateRangePicker";
import CsInput from "components/Input";
import ListBrandSelectSearch from "components/ListBrandSelectSearch";
import { FetListOrderApi, StatusType } from "model/groupBuying";
import { useCallback, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { debounce } from "utils/debounce";
import OrderTable from "./OrderTable";
import * as S from "./style";
import SelectCmp from "components/SelectCmp";

const OrderHistory: React.FC = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [dataTable, setDataTable] = useState<any>([]);
  const [totalElement, setTotalElement] = useState<number>(0);
  const [filter, setFilter] = useState<FetListOrderApi>({});
  const [searchValue, setSearchValue] = useState<string>("");
  const [count, setCount] = useState<number>(0);
  const [clearFilterDate, setClearFilterDate] = useState<boolean>(false);
  const [clearFilterBrand, setClearFilterBrand] = useState<boolean>(false);

  const getListOrder = async () => {
    try {
      const { data, error }: any = await getListOrderApi({
        querySearch: filter?.querySearch?.trim(),
        startDate: filter.startDate,
        endDate: filter.endDate,
        brandId: filter.brandId ? filter.brandId : undefined,
        status: searchParams.get("status") as StatusType,
        page: Number(searchParams.get("page") || 1),
        size: 10,
        orderDateSort: searchParams.get("orderDateSort") as "asc" | "desc",
        totalValueSort: searchParams.get("totalValueSort") as "asc" | "desc",
        type: filter.type,
      });

      if (error) {
        setDataTable([]);
        setTotalElement(0);
      } else if (data) {
        setDataTable(data.datas);
        setTotalElement(+data.pageable.totalElement);
      }
    } catch (err) {
      console.error(err);
    }
  };

  const getOrderCount = async (params?: {
    querySearch?: string;
    brandId?: number;
    startDate?: string;
    endDate?: string;
  }) => {
    try {
      const { data }: any = await getOrderCountApi(params || {});
      if (data) {
        setCount(data);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getListOrder();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter, searchParams]);

  useEffect(() => {
    // getAllBrand();
    getOrderCount();
    // reset filter, sort
    searchParams.delete("orderDateSort");
    searchParams.delete("totalValueSort");
    searchParams.set("page", "1");
    setSearchParams(searchParams);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // const setTextSearch = debounce((value: string) => {
  //   setFilter((prev: FetListOrderApi) => ({ ...prev, querySearch: value }));
  // }, 500);

  const handleSearch = useCallback(
    debounce((value: string) => {
      setFilter((prev: FetListOrderApi) => {
        return { ...prev, querySearch: value };
      });
      const params = {
        querySearch: value.trim(),
        startDate: filter.startDate,
        endDate: filter.endDate,
        brandId: filter.brandId ? filter.brandId : undefined,
        type: filter.type,
      };
      getOrderCount(params);
      searchParams.set("page", "1");
      setSearchParams(searchParams);
    }, 1000),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const onChangeTab = (key: any) => {
    setFilter({});
    setClearFilterDate(true);
    setClearFilterBrand(true);
    setSearchValue("");
    getOrderCount({});
    searchParams.set("page", "1");
    searchParams.set("status", key);
    setSearchParams(searchParams);
  };

  const handleChangeDate = (_: any, dateString: [string, string] | string) => {
    setFilter((prev: FetListOrderApi) => ({
      ...prev,
      startDate: dateString[0],
      endDate: dateString[1],
    }));
    const params = {
      querySearch: filter?.querySearch?.trim(),
      startDate: dateString[0],
      endDate: dateString[1],
      brandId: filter.brandId,
      type: filter.type,
    };
    getOrderCount(params);
    searchParams.set("page", "1");
    setSearchParams(searchParams);
  };

  // const refundRequest = 16;
  const items: TabsProps["items"] = [
    {
      key: "",
      label: `전체`,
      children: <OrderTable data={dataTable} totalElement={totalElement} />,
    },
    {
      key: "WAITING",
      label: `입금대기`,
      children: <OrderTable data={dataTable} totalElement={totalElement} />,
    },
    {
      key: "PAID",
      label: `결제완료`,
      children: <OrderTable data={dataTable} totalElement={totalElement} />,
    },
    {
      key: "OWNED",
      label: `보유중`,
      children: <OrderTable data={dataTable} totalElement={totalElement} />,
    },
    {
      key: "REQUESTED",
      label: (
        <div className="has-number">
          환불 요청
          <p>{count}</p>
        </div>
      ),
      children: <OrderTable data={dataTable} totalElement={totalElement} />,
    },
    {
      key: "REFUNDED",
      label: `환불 완료`,
      children: <OrderTable data={dataTable} totalElement={totalElement} />,
    },
  ];
  // console.log("filter :>> ", filter);
  return (
    <S.Wrapper>
      <div className="headings">
        <h1 className="main-headings">주문 내역</h1>
        <p className="sub-heading">프리미엄 멤버십 주문 내역</p>
      </div>
      <div className="filter-container">
        <CsInput
          placeholder="발급명, 회원명으로 검색"
          onChange={(e) => {
            const inputValue: string = e.target.value;
            const cleanedValue = inputValue.replace(/\s+/g, " ");
            if (cleanedValue !== " ") {
              setSearchValue(cleanedValue);
              handleSearch(cleanedValue);
            }
          }}
          value={searchValue}
          suffix={<SearchIcon />}
        />
        <ListBrandSelectSearch
          // defaultValue="inactive"
          iconWhenOpen={<IconSearch14 />}
          type="filter"
          style={{
            width: 180,
          }}
          // options={listBrand}
          // placeholder="Brand"
          onChange={(value) => {
            setFilter((prev: FetListOrderApi) => ({
              ...prev,
              brandId: value !== "clear" ? Number(value) : undefined,
            }));
            const params = {
              querySearch: filter?.querySearch?.trim(),
              startDate: filter.startDate,
              endDate: filter.endDate,
              brandId: value !== "clear" ? Number(value) : undefined,
              type: filter.type,
            };
            getOrderCount(params);
            searchParams.set("page", "1");
            setSearchParams(searchParams);
          }}
          clearValue={clearFilterBrand}
          onClearFilter={() => setClearFilterBrand(false)}
        />
        <SelectCmp
          // defaultValue="inactive"
          value={filter.type}
          style={{
            width: 180,
          }}
          options={[
            {
              label: "선물하기",
              value: "GIFT",
            },
            {
              label: "구매하기",
              value: "GB",
            },
          ]}
          placeholder="카테고리 분류"
          onChange={(value) => {
            setFilter((prev: FetListOrderApi) => ({
              ...prev,
              type: value,
            }));
            searchParams.set("page", "1");
            setSearchParams(searchParams);
          }}
        />
        <DateRangePicker
          type="range"
          placeholder={["검색 시작일", "검색 종료일"]}
          onChange={handleChangeDate}
          clearValue={clearFilterDate}
          onClearFilter={() => setClearFilterDate(false)}
          // value={[dayjs(filter.startDate), dayjs(filter.endDate)]}
        />
      </div>
      <div className="order-tabs">
        <Tabs
          defaultActiveKey={searchParams.get("status") || ""}
          items={items}
          onChange={onChangeTab}
        />
      </div>
    </S.Wrapper>
  );
};

export default OrderHistory;
