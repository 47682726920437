import { ColumnsType } from "antd/es/table";
import CsTable from "components/Table";
import { useSearchParams } from "react-router-dom";
import { MoneyConvert } from "utils/format";
import * as S from "./style";
import { formatTimeZone } from "utils/formatTimeZone";
import dayjs from "dayjs";

interface Props {
  data: any;
  totalItem: number;
}

const CanceledTable: React.FC<Props> = ({ data, totalItem }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const columns: ColumnsType<any> = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
      render: (_, record) => <S.GpId>{record.orderId}</S.GpId>,
    },
    {
      title: "취소 날짜",
      dataIndex: "purchaseTime",
      key: "purchaseTime",
      render: (_, record) => (
        <S.GpInfo>
          <p className="top">
            {/* {formatTimeZone(record.createdTime, "YYYY/MM/DD")} */}
            {/* {dayjs(record.updatedTime).format("HH:mm:ss")} */}
            {dayjs(record.updatedTime).format("YYYY/MM/DD")}
          </p>
          <p className="bottom">
            {/* {formatTimeZone(record.createdTime, "HH:mm:ss")} */}
            {dayjs(record.updatedTime).format("HH:mm:ss")}
          </p>
        </S.GpInfo>
      ),
    },
    {
      title: "회원명",
      dataIndex: "useId",
      key: "useId",
      render: (_, record) => (
        <S.GpInfo>
          <p className="top">{record.displayName}</p>
          <p className="bottom">{record.userName}</p>
        </S.GpInfo>
      ),
    },
    {
      title: "연락처/이메일",
      dataIndex: "phone",
      key: "phone",
      render: (_, record) => (
        <S.GpInfo>
          <p className="top">{record.verifiedPhoneNumber}</p>
          <p className="bottom">{record.email}</p>
        </S.GpInfo>
      ),
    },
    {
      title: "구매 정보",
      dataIndex: "quantity",
      key: "quantity",
      render: (_, record) => (
        <S.GpInfo>
          <p className="top">{MoneyConvert(record.quantity) || 0}개</p>
          <p className="bottom price">
            {MoneyConvert(record.totalValue) || 0}원
          </p>
        </S.GpInfo>
      ),
    },
  ];

  return (
    <S.TableWraper>
      <CsTable
        columns={columns}
        dataSource={data}
        pagination={
          totalItem > 10
            ? {
                current: Number(searchParams.get("page") || 1),
                total: totalItem,
                onChange: (page) => {
                  searchParams.set("page", page.toString());
                  setSearchParams(searchParams);
                },
              }
            : false
        }
      />
    </S.TableWraper>
  );
};

export default CanceledTable;
