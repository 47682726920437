import { Button, ButtonProps } from "antd";
import React from "react";
import * as S from "./style";

interface ButtonStatus {
  status?:
    | "initial"
    | "waiting"
    | "active"
    | "inactive"
    | "reject"
    | "noBackground";
  style?: any;
}

const ButtonComp: React.FC<ButtonStatus & ButtonProps> = (props) => {
  const { icon, children, style, className, status, ...rest } = props;

  const buttonStyle: React.CSSProperties = {};

  switch (status) {
    case "waiting":
      buttonStyle.backgroundColor = "#FF900E";
      buttonStyle.borderColor = "#FF900E";
      break;
    case "reject":
      buttonStyle.backgroundColor = "#000";
      buttonStyle.borderColor = "#000";
      break;
    case "active":
      buttonStyle.backgroundColor = "#4AA159";
      buttonStyle.borderColor = "#4AA159";
      break;
    case "inactive":
      buttonStyle.backgroundColor = "#BDBDBD";
      buttonStyle.borderColor = "#BDBDBD";
      break;
    case "initial":
      buttonStyle.backgroundColor = "#1F8EFF";
      buttonStyle.borderColor = "#1F8EFF";
      break;
    case "noBackground":
      buttonStyle.backgroundColor = "#fff";
      buttonStyle.borderColor = "#526557";
      buttonStyle.borderWidth = "2px";
      buttonStyle.color = "#526557";
      buttonStyle.height = "52px";
      break;
    default:
      break;
  }

  const buttonClasses = `${className} ${status ? "status-btn" : ""}`;

  return (
    <S.Wrapper>
      <Button
        className={buttonClasses}
        style={{ ...buttonStyle, ...style }}
        icon={icon ? icon : false}
        {...rest}
      >
        {children}
      </Button>
    </S.Wrapper>
  );
};

export default ButtonComp;
