import type { MenuProps } from "antd";
import { Menu } from "antd";
import { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { ReactComponent as CaretDown } from "assets/icons/CaretDown.svg";
import { ReactComponent as CaretRight } from "assets/icons/CaretRight.svg";
import { ReactComponent as Announcement } from "assets/icons/Announcement.svg";
import { ReactComponent as BrandUser } from "assets/icons/BrandUser.svg";
import { ReactComponent as Campaign } from "assets/icons/Campaign.svg";
import { ReactComponent as ClientUser } from "assets/icons/ClientUser.svg";
import { ReactComponent as CustomerService } from "assets/icons/CustomerService.svg";
import { ReactComponent as FAQ } from "assets/icons/FAQ.svg";
import { ReactComponent as Group } from "assets/icons/Group.svg";
import { ReactComponent as Inquiry } from "assets/icons/Inquiry.svg";
import { ReactComponent as Transaction } from "assets/icons/Transaction.svg";
import { ReactComponent as Users } from "assets/icons/Users.svg";
import { ReactComponent as SignOut } from "assets/icons/SignOut.svg";
import logo from "assets/images/admin-logo.png";
import Settlement from "assets/images/deal.png";
import Membership from "assets/images/membership.png";
import BrandImg from "assets/images/brand-image.png";

import * as S from "./style";
import { useAppDispatch } from "redux/hook";
import { logout } from "redux/user/userSlice";
// import Announcement from "pages/CustomerService/Announcement";

function Sidebar() {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const [pathName, setPathName] = useState<string[]>([
    window.location.href.split("/")[3],
  ]);

  useEffect(() => {
    const temp = window.location.href.split("?")[0];
    const href = temp.split("/")[3];
    const href2 = temp.split("/")[4];
    setPathName([href, href + "/" + href2]);
  }, [location?.pathname]);
  const routes: MenuProps["items"] = [
    {
      key: "users",
      icon: <Users />,
      label: "회원 관리",
      children: [
        {
          key: "users/client-user",
          icon: <ClientUser />,
          label: <Link to="/users/client-user">일반 회원</Link>,
        },
        {
          key: "users/brand-user",
          icon: <BrandUser />,
          label: <Link to="/users/brand-user">브랜드 회원</Link>,
        },
      ],
    },
    {
      key: "group-buying",
      icon: <Group />,
      label: "프리미엄 멤버십",
      children: [
        {
          key: "group-buying/campaign",
          icon: <Campaign />,
          label: <Link to="/group-buying/campaign">발급 관리</Link>,
        },
        {
          key: "group-buying/order",
          icon: <Transaction />,
          label: <Link to="/group-buying/order">주문 내역</Link>,
        },
        {
          key: "group-buying/transaction",
          icon: <Transaction />,
          label: <Link to="/group-buying/transaction">거래 내역</Link>,
        },
      ],
    },
    {
      key: "membership",
      icon: <img width={20} height={20} src={Membership} alt="img" />,
      label: "일반 멤버십",
      children: [
        {
          key: "membership/order-history",
          icon: <Transaction />,
          label: <Link to="/membership/order-history">멤버십 주문 내역</Link>,
        },
        {
          key: "membership/member-manager",
          icon: <Transaction />,
          label: <Link to="/membership/member-manager">멤버십 회원 관리</Link>,
        },
      ],
    },
    {
      key: "settlement",
      icon: <img width={20} height={20} src={Settlement} alt="img" />,
      label: (
        <Link style={{ fontSize: 16 }} to="/settlement">
          정산내역
        </Link>
      ),
    },
    {
      key: "brand",
      icon: <img width={20} height={20} src={BrandImg} alt="img" />,
      label: "브랜드 관리",
      children: [
        {
          key: "brand/slide-management",
          icon: <Transaction />,
          label: <Link to="/brand/slide-management">상단 배너 관리</Link>,
        },
        {
          key: "brand/create-slide",
          icon: <Transaction />,
          label: <Link to="/brand/recommend">추천 브랜드 관리</Link>,
        },
        {
          key: "brand/set-delivery-delay",
          icon: <Transaction />,
          label: <Link to="/brand/set-delivery-delay">배송지연 날짜설정</Link>,
        },
      ],
    },

    {
      key: "customer-service",
      icon: <CustomerService />,
      label: "고객 서비스",
      children: [
        {
          key: "customer-service/announcement",
          icon: <Announcement />,
          label: <Link to="/customer-service/announcement">공지사항</Link>,
        },
        {
          key: "customer-service/faq",
          icon: <FAQ />,
          label: <Link to="/customer-service/faq">FAQ</Link>,
        },
        {
          key: "customer-service/inquiry",
          icon: <Inquiry />,
          label: <Link to="/customer-service/inquiry">1:1 문의</Link>,
        },
      ],
    },
  ];
  return (
    <S.Sidebar width={280}>
      <div className="logo-container">
        <img src={logo} alt="logo" />
      </div>
      <Menu
        mode="inline"
        selectedKeys={pathName}
        defaultOpenKeys={pathName}
        style={{ height: "calc(100% - 160px)" }}
        items={routes}
        // expandIcon={<CaretRight />}

        expandIcon={({ isOpen }) => (isOpen ? <CaretDown /> : <CaretRight />)}
      />
      <S.LogoutButton onClick={() => dispatch(logout())}>
        <SignOut /> <span>로그아웃</span>
      </S.LogoutButton>
    </S.Sidebar>
  );
}

export default Sidebar;
