import { Tabs, TabsProps } from "antd";
import AccountList from "./AccountList";
import DeleteAccountList from "./DeleteAccountList";
import * as S from "./style";
import { useSearchParams } from "react-router-dom";
import { getListUserDeleteCountApi } from "api/user";
import { useEffect, useState } from "react";

function ClientUser() {
  const [searchPrams, setSearchParams] = useSearchParams();
  const [deleteRequestCount, setDeleteRequestCount] = useState<string>("0");

  const getCountDeleteRequest = async (params?: {
    querySearch?: string;
    isActive?: 1 | 0;
  }) => {
    try {
      const { data }: any = await getListUserDeleteCountApi(params || {});
      if (data) {
        setDeleteRequestCount(data);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const items: TabsProps["items"] = [
    {
      key: "account",
      label: `계정 목록`,
      children: <AccountList />,
    },
    {
      key: "delete_account",
      label: (
        <div className="has-number">
          계정 삭제 요청 <p>{deleteRequestCount}</p>
        </div>
      ),
      children: (
        <DeleteAccountList getCountDeleteRequest={getCountDeleteRequest} />
      ),
    },
  ];

  const onChange = (key: string) => {
    searchPrams.set("tab", key);
    setSearchParams(searchPrams);
  };

  useEffect(() => {
    getCountDeleteRequest();
  }, []);

  return (
    <S.Wrapper>
      <div className="headings">
        <h1 className="main-headings">계정 관리</h1>
      </div>
      <div className="tabs-wrapper">
        <Tabs
          defaultActiveKey={searchPrams.get("tab") || "account"}
          items={items}
          onChange={onChange}
        />
      </div>
    </S.Wrapper>
  );
}

export default ClientUser;
