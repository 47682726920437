import { Tabs, TabsProps } from "antd";
import React from "react";
import * as S from "./style";

import Client from "./Client";
// import Brand from "./Brand";

const FAQ: React.FC = () => {
  const onChange = (key: string) => {
    // console.log(key);
  };
  // const items: TabsProps["items"] = [
  //   {
  //     key: "client",
  //     label: `회원 용`,
  //     children: <Client />,
  //   },
  //   {
  //     key: "brand",
  //     label: `브랜드 용`,
  //     children: <></>,
  //   },
  // ];
  return (
    <S.Wrapper>
      <div className="headings">
        <h1 className="main-headings">FAQ</h1>
        <p className="sub-headings">
          자주 묻는 질문과 답변을 작성 할 수 있습니다.
        </p>
      </div>
      <div className="inquiry-tabs">
        {/* <Tabs defaultActiveKey="3" items={items} onChange={onChange} /> */}
        <Client />
      </div>
    </S.Wrapper>
  );
};

export default FAQ;
