import MainHeader from "layouts/components/Header";
import React, { useEffect, useState } from "react";
import * as S from "./style";

import { getDetailUserBrandApi } from "api/user";
import { ReactComponent as BankIcon } from "assets/icons/Bank.svg";
import { ReactComponent as EmailIcon } from "assets/icons/Email.svg";
import { ReactComponent as FileTextIcon } from "assets/icons/FileText.svg";
import { ReactComponent as MapPinLineIcon } from "assets/icons/MapPinLine.svg";
import { ReactComponent as PencilIcon } from "assets/icons/Pencil.svg";
import { ReactComponent as PhoneIcon } from "assets/icons/Phone.svg";
import { ReactComponent as UserListIcon } from "assets/icons/UserList.svg";
import { ReactComponent as UserActive } from "assets/icons/User_active.svg";
import { ReactComponent as UserIcon } from "assets/icons/Users-1.svg";
import { ReactComponent as WarningCircleIcon } from "assets/icons/WarningCircle.svg";
import { ReactComponent as CategoryIcon } from "assets/icons/CategoryIcon.svg";
import { ReactComponent as BrandIdIcon } from "assets/icons/BrandIDIcon.svg";
import { ReactComponent as LicenseIcon } from "assets/icons/License.svg";

import AccountStatus from "components/AccountStatus";
import ButtonComp from "components/ButtonCmp";
import { ACCOUNT_STATUS } from "constants/index";
import dayjs from "dayjs";
import { useNavigate, useParams } from "react-router-dom";
import { changeApproveStatus } from "api/brand";
import brandImgDemo from "assets/images/avatar-demo.png";
import { Form, Image, Input, Modal, message } from "antd";
import { NoticeType } from "antd/es/message/interface";
import { ChangeApproveStatus, StatusAccount } from "model/brand";
import { useForm } from "antd/es/form/Form";
import { formatPhoneNumber } from "utils/format";
import downloadImg from "assets/images/download_btn.png";

type ViewDetailType = {
  bankImage: boolean;
  businessLicense: boolean;
};

const BranUserDetail: React.FC = () => {
  const navigate = useNavigate();
  const [form] = useForm();
  const [messageApi, contextHolder] = message.useMessage({ maxCount: 2 });
  const { brandId, userId } = useParams<string>();
  const [detailData, setDetailData] = useState<any>([]);
  const [status, setStatus] = useState<ACCOUNT_STATUS>();
  const [confirmVisible, setConfirmVisible] = useState<boolean>(false);
  const [selectedStatus, setSelectedStatus] = useState<StatusAccount>();
  const [reason, setReason] = useState<string>("");
  const [viewDetail, setViewDetail] = useState<ViewDetailType>({
    bankImage: false,
    businessLicense: false,
  });
  // const [render, setRender] = useState<boolean>(false);

  const setReasonValue = (e: React.ChangeEvent<HTMLInputElement>) =>
    setReason(e.target.value);

  const showMess = (type: NoticeType, message: string) => {
    messageApi.open({
      type: type,
      content: message,
    });
  };
  const handleChangeStatus = async (_: any, btnKey: string) => {
    // setRender(!render);
    const statusMap: Record<string, StatusAccount> = {
      approve: "ACTIVE",
      active: "ACTIVE",
      reject: "REJECT",
      deactive: "INACTIVE",
    };

    const status = statusMap[btnKey];

    if (["reject", "deactive"].includes(btnKey)) {
      setSelectedStatus(status);
      setConfirmVisible(true);
    } else {
      // console.log("status2", status);
      await updateStatus(status, false);
    }
  };
  // console.log('detailData', detailData);

  const updateStatus = async (
    status: StatusAccount,
    shouldUpdateReason: boolean = false
  ) => {
    if (!brandId) {
      console.error("Brand ID is missing.");
      return;
    }

    const requestData: ChangeApproveStatus = {
      id: +brandId,
      status: status,
    };

    if (shouldUpdateReason) {
      requestData.message = reason;
    }

    try {
      const { data, error }: any = await changeApproveStatus(requestData);
      if (data) {
        getDetailUserBrand();
        showMess("success", "Update status successfully");
      }
      if (error) {
        showMess("error", "Update status failed!");
      }
      form.resetFields();
    } catch (error) {
      console.error(error);
      showMess("error", "Update status failed!");
      form.resetFields();
    }
  };

  const handleConfirm = async () => {
    setConfirmVisible(false);
    if (selectedStatus) {
      await updateStatus(selectedStatus, true);
    }
  };

  const getDetailUserBrand = async () => {
    if (!userId || !brandId) {
      console.error("Brand ID and userId is missing.");
      return;
    }

    try {
      const { data, error }: any = await getDetailUserBrandApi({
        userId: +userId,
        brandId: +brandId,
      });

      if (error) {
        console.error(error);
        return;
      }

      const statusMap: Record<string, ACCOUNT_STATUS> = {
        INITIAL: ACCOUNT_STATUS.Initial,
        WAITING: ACCOUNT_STATUS.Waiting,
        REJECT: ACCOUNT_STATUS.Reject,
        INACTIVE: ACCOUNT_STATUS.Inactive,
        ACTIVE: ACCOUNT_STATUS.Active,
      };

      const mappedStatus = statusMap[data?.status];

      if (mappedStatus) {
        setStatus(mappedStatus);
      } else {
        console.log("status type wrong");
      }
      setDetailData(data);
    } catch (error) {
      console.error(error);
    }
  };

  const handleDownload = async (url: string) => {
    const originalImage = url;
    const image = await fetch(originalImage);

    // Split image name
    const nameSplit = originalImage.split("/");
    const duplicateName = nameSplit.pop();

    const imageBlog = await image.blob();
    const imageURL = URL.createObjectURL(imageBlog);
    const link = document.createElement("a");
    link.href = imageURL;
    link.download = "" + duplicateName + "";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  useEffect(() => {
    getDetailUserBrand();
  }, []);

  return (
    <S.Wrapper>
      {contextHolder}
      <MainHeader
        backLink="/users/brand-user"
        breadCrumb={[
          {
            title: "브랜드 계정",
            link: "/users/brand-user",
            icon: <UserActive />,
          },
          { title: "세부 정보" },
        ]}
        title="브랜드 계정 세부 정보"
        rightBtn={
          <ButtonComp
            onClick={() =>
              navigate(`/users/brand-user/edit/${brandId}/${userId}`)
            }
            style={{ padding: "0 30px" }}
            icon={<PencilIcon />}
          >
            계정 정보 수정
          </ButtonComp>
        }
      />
      <S.Container>
        <S.ContentTop>
          <AccountStatus
            onClick={handleChangeStatus}
            status={status}
            time={
              status
                ? dayjs(detailData?.changeStatusAt).format(
                    "YYYY-MM-DD HH:mm:ss"
                  )
                : dayjs(detailData?.createdTime).format("YYYY-MM-DD HH:mm:ss")
            }
            reason={status === 2 || status === 4 ? detailData?.message : ""}
          />
        </S.ContentTop>
        <S.Content>
          <S.BlockItem className="avatar">
            <img
              style={{
                width: "128px",
                height: "128px",
                objectFit: "contain",
                borderRadius: "50%",
              }}
              src={detailData?.logoImage || brandImgDemo}
              alt="avatar"
            />
            <h2>{detailData?.brandName}</h2>
            <p>
              개설 날짜: {dayjs(detailData?.createdTime).format("YYYY/MM/DD")}
            </p>
          </S.BlockItem>
          <S.BlockItem className="user-account ">
            <div className="text-top">
              <h3>계정 정보</h3>
            </div>
            <div className="line">
              <div className="label">
                <BrandIdIcon />
                <span>브랜드 ID</span>
              </div>
              <div className="value">
                <span>{detailData.userName}</span>
              </div>
            </div>

            <div className="line">
              <div className="label">
                <UserIcon />
                <span>담당자 이름</span>
              </div>
              <div className="value">
                <span>{detailData?.manager}</span>
              </div>
            </div>

            <div className="line">
              <div className="label">
                <PhoneIcon />
                <span>담당자 연락처</span>
              </div>
              <div className="value">
                <span>{formatPhoneNumber(detailData?.phoneNumber)}</span>
              </div>
            </div>

            <div className="line">
              <div className="label">
                <EmailIcon />
                <span>담당자 이메일</span>
              </div>
              <div className="value">
                <span>{detailData?.email}</span>
              </div>
            </div>
            <div className="line">
              <div className="label">
                <UserListIcon />
                <span>역할</span>
              </div>
              <div className="value">
                <span>
                  {detailData?.branchRole === "HEAD_ADMIN" ? "브랜드 대표" : ""}
                </span>
              </div>
            </div>
            <div className="line">
              <div className="label">
                <CategoryIcon />
                <span>카테고리 분류</span>
              </div>
              <div className="value">
                <span>{detailData?.korName}</span>
              </div>
            </div>
          </S.BlockItem>
          <S.BlockItem>
            <div className="text-top">
              <h3>매장 정보</h3>
            </div>
            <div className="line">
              <span>{detailData?.address}</span>
            </div>
            <div className="line">
              <span>우편번호: {detailData?.postCode}</span>
            </div>
            <div className="line">
              <span>{detailData?.addressDescription}</span>
            </div>
          </S.BlockItem>
          <S.BlockItem>
            <div className="text-top">
              <h3>회사 정보</h3>
            </div>
            <div className="line">
              <div className="label">
                <FileTextIcon />
                <span>회사명</span>
              </div>
              <div className="value">
                <span className="strong">{detailData?.companyName}</span>
              </div>
            </div>
            <div className="line">
              <div className="label">
                <UserIcon />
                <span>대표자</span>
              </div>
              <div className="value">
                <span>{detailData?.representative}</span>
              </div>
            </div>
            <div className="line">
              <div className="label">
                <MapPinLineIcon />
                <span>회사주소</span>
              </div>
              <div className="value">
                <span>{detailData?.companyAddress}</span>
              </div>
            </div>
            <div className="line">
              <div className="label">
                <PhoneIcon />
                <span>회사 연락처</span>
              </div>
              <div className="value">
                <span>{formatPhoneNumber(detailData?.companyPhone)}</span>
              </div>
            </div>
            <div className="line">
              <div className="label">
                <EmailIcon />
                <span>회사 이메일</span>
              </div>
              <div className="value">
                <span>{detailData?.companyEmail}</span>
              </div>
            </div>
            <div className="underline" />
            <div className="text-top">
              <h3>정산 정보</h3>
            </div>
            <div className="line">
              <div className="label">
                <BankIcon />
                <span>은행명</span>
              </div>
              <div className="value">
                <span className="strong">{detailData?.bankName}</span>
              </div>
            </div>
            <div className="line">
              <div className="label">
                <UserIcon />
                <span>예금주</span>
              </div>
              <div className="value">
                <span className="strong">{detailData?.bankAccountName}</span>
              </div>
            </div>
            <div className="line">
              <div className="label">
                <UserListIcon />
                <span>계좌번호</span>
              </div>
              <div className="value">
                <span>{detailData?.bankAccountNumber}</span>
              </div>
            </div>
            <div className="line" style={{ marginBottom: 5 }}>
              <div className="label">
                <LicenseIcon />
                <span>사업자증록증</span>
              </div>
              <div className="value">
                {detailData?.businessLicense && (
                  <>
                    <S.ViewDetail>
                      <div
                        onClick={() =>
                          setViewDetail((prev: ViewDetailType) => ({
                            ...prev,
                            businessLicense: !prev.businessLicense,
                          }))
                        }
                      >
                        View Detail
                      </div>
                      <button
                        type="button"
                        onClick={() =>
                          handleDownload(detailData?.businessLicense)
                        }
                      >
                        <img src={downloadImg} alt="download" />
                      </button>
                    </S.ViewDetail>
                    <Image
                      preview={{
                        visible: viewDetail.businessLicense,
                        onVisibleChange: (visible) =>
                          setViewDetail((prev: ViewDetailType) => ({
                            ...prev,
                            businessLicense: visible,
                          })),
                      }}
                      src={detailData?.businessLicense}
                    />
                  </>
                )}
              </div>
            </div>
            <div className="line">
              <div className="label">
                <LicenseIcon />
                <span>통장사본</span>
              </div>
              <div className="value">
                {detailData?.bankImage && (
                  <>
                    <S.ViewDetail>
                      <div
                        onClick={() =>
                          setViewDetail((prev: ViewDetailType) => ({
                            ...prev,
                            bankImage: !prev.bankImage,
                          }))
                        }
                      >
                        View Detail
                      </div>
                      <button
                        type="button"
                        onClick={() => handleDownload(detailData?.bankImage)}
                      >
                        <img src={downloadImg} alt="download" />
                      </button>
                    </S.ViewDetail>
                    <Image
                      preview={{
                        visible: viewDetail.bankImage,
                        onVisibleChange: (visible) =>
                          setViewDetail((prev: ViewDetailType) => ({
                            ...prev,
                            bankImage: visible,
                          })),
                      }}
                      src={detailData?.bankImage}
                    />
                  </>
                )}
              </div>
            </div>
          </S.BlockItem>
          <S.BlockItem>
            <div className="text-top">
              <h3>고객센터 정보</h3>
            </div>
            <div className="line">
              <div className="label">
                <span>연락처</span>
              </div>
              <div className="value">
                <span>{formatPhoneNumber(detailData?.hotline)}</span>
              </div>
            </div>
            <div className="line">
              <div className="label">
                <span>E-mail</span>
              </div>
              <div className="value">
                <span>{detailData?.customerServiceEmail}</span>
              </div>
            </div>
          </S.BlockItem>
        </S.Content>
      </S.Container>
      <Modal
        open={confirmVisible}
        onCancel={() => setConfirmVisible(false)}
        footer={false}
        width={460}
      >
        <S.ModalContent>
          <WarningCircleIcon />
          <h3>
            {/* Are You {selectedStatus === "REJECT" ? "Reject" : "Deactivate"}{" "}
            Account? */}

            {selectedStatus === "REJECT"
              ? "거절 사유를 입력하세요"
              : "비활성화 사유를 입력하세요"}
          </h3>
          <p>{detailData?.brandName}</p>
          <Form form={form} onFinish={handleConfirm} name="updateStatusMess">
            <Form.Item
              name="reason"
              rules={[
                { required: true, message: "사유를 입력하세요" },
                {
                  min: 3,
                  message: "세글자 이상 입력해 주세요",
                },
              ]}
            >
              <Input
                placeholder="입력하세요"
                onChange={setReasonValue}
                onBlur={(e: React.FocusEvent<HTMLInputElement, Element>) =>
                  form.setFieldsValue({
                    reason: e.target.value.trim().replace(/\s+/g, " "),
                  })
                }
              />
            </Form.Item>
            <S.ModalBtn>
              <ButtonComp
                status="noBackground"
                className="btn-cancel"
                onClick={() => setConfirmVisible(false)}
              >
                취소
              </ButtonComp>
              <ButtonComp className="btn-submit" htmlType="submit">
                {selectedStatus === "REJECT" ? "거절" : "확인"}
              </ButtonComp>
            </S.ModalBtn>
          </Form>
        </S.ModalContent>
      </Modal>
    </S.Wrapper>
  );
};

export default BranUserDetail;
