import { styled } from "styled-components";

export const Wrapper = styled.div`
  height: 100%;
  background-color: var(--sub-background);
`;

export const Content = styled.div`
  padding: 34px 115px 19px;
`;

export const Block = styled.div`
  background-color: var(--light-color);
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  padding: 30px 20px;
  &.no-pd {
    padding: 0;
  }
  &.flex-center {
    align-items: center;
  }
  &.flex {
    display: flex;
  }
  &.space-between {
    justify-content: space-between;
  }
  &.flex-row {
    flex-direction: row;
  }
  &.justify-between {
    justify-content: space-between;
  }
  .info-left {
    display: flex;
    align-items: center;
  }
  .info-user-bank {
    display: flex;
    align-items: flex-end;
    gap: 40px;
    > a {
      background-color: #526557;
      display: block;
      color: #fff;
      padding: 0 16px;
      height: 66px;
      border-radius: 8px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    h4 {
      font-size: 16px;
    }
    .line {
      display: flex;
      align-items: center;
      margin-top: 4px;
    }
    label {
      font-size: 14px;
      width: 110px;
      display: block;
    }
  }
  .brand-name,
  .voucher-name {
    font-size: 16px;
    line-height: 22.4px;
    font-family: var(--font-600);
    margin-bottom: 9px;
  }
  img {
    border-radius: 5px;
  }
  .brand-category {
    margin-bottom: 36px;
    .brand-category-name {
      padding: 3px;
      border-radius: 3px;
      font-size: 12px;
      display: inline-block;
      background-color: var(--hint-color);
      line-height: 16.8px;
    }
  }
  .voucher-logo {
    margin-bottom: 6px;
  }
  .status {
    font-size: 10px;
    line-height: 16px;
    background-color: var(--hint-color);
    padding: 3px;
    border-radius: 3px;
  }
  .filter-container {
    display: flex;
    gap: 12px;
    height: 60px;
    margin: 30px 0 20px 0;
  }
  .order-info {
    .order-no {
      font-size: 18px;
      font-family: var(--font-600);
      margin-bottom: 16px;
    }
    .create-at {
      font-size: 16px;
      margin-top: 12px;
    }
  }
  .grp-btn {
    display: flex;
    align-items: center;
    gap: 16px;
    &.has-text {
      flex-direction: column;
      align-items: end;
      > p {
        text-align: right;
        font-size: 16px;
      }
    }
    .btn-approve {
      width: 160px;
    }
    .btn-reject {
      width: 160px;
    }
  }
  .voucher-info {
    margin-left: 25px;
  }
  .user-info {
    display: flex;
    align-items: center;
    gap: 8px;
    .avt {
      border-radius: 50%;
    }
  }
  .user-name,
  .user-phone,
  .user-email {
    font-size: 16px;
    line-height: 22.4px;
  }
  .user-name {
    font-family: var(--font-600);
  }
  .user-phone,
  .user-email {
    margin-top: 4px;
  }
  .list {
    padding: 30px 20px 10px;
  }
  .total {
    padding: 30px 20px;
    border-top: 0.5px solid var(--hint-color);
  }
`;

export const TableWraper = styled.div``;
export const RefundSummary = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 30px 20px;
  height: 136px;
  border-radius: 20px;
  background: linear-gradient(93deg, #36a3ea 1.18%, #20629f 75.9%);
  .summary-info {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .label {
      font-size: 16px;
      color: var(--light-color);
    }
    .value {
      font-size: 20px;
      font-family: "SpaceMonoBold";
      color: var(--light-color);
    }
  }
`;

export const RefundDetail = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 30px 20px;
  border-radius: 20px;
  .summary-info {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .label {
      font-size: 16px;
      color: var(--light-color);
    }
    .value {
      font-size: 20px;
      font-family: "SpaceMonoBold";
      color: var(--light-color);
    }
  }
`;

export const FLexLine = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
  .color-blue {
    color: #3478f6;
  }
  .color-red {
    color: #dd6b59;
  }
  p {
    font-size: 16px;
    line-height: 22.4px;
  }
  &.has-border {
    border-top: 0.5px #f2f2eb solid;
    border-bottom: 0.5px #f2f2eb solid;
    padding: 16px 0;
    margin: 8px 0;
  }
  &.has-border-top {
    border-top: 0.5px #f2f2eb solid;
    padding-top: 16px;
  }
  .value {
    font-family: "SpaceMonoRegular";
  }
  &.total-info {
    .value {
      font-family: "SpaceMonoBold";
      font-size: 20px;
    }
  }
`;

export const Title = styled.div`
  font-size: 20px;
  font-family: var(--font-600);
  margin-bottom: 16px;
  &.white {
    color: var(--light-color);
  }
`;

export const GpId = styled.div`
  font-size: 16px;
`;

export const GpInfo = styled.div`
  .top {
    font-size: 16px;
    margin-bottom: 10px;
  }
  .bottom {
    font-size: 12px;
  }
  .price {
    font-size: 16px;
    line-height: normal;
    font-family: "SpaceMonoBold";
  }
`;

export const Status = styled.div`
  height: 28px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  color: var(--light-color);
  padding: 0 5px;
  font-size: 16px;
`;

export const ModalContent = styled.div`
  display: flex;
  gap: 20px;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin: auto;
  > p {
    font-size: 16px;
    text-transform: capitalize;
    font-family: var(--font-600);
  }
`;

export const ModalBtn = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
  &.one {
    justify-content: flex-end;
  }
  .approve {
    width: 160px;
  }
  .cancel {
    width: 220px;
  }
`;
