import { DatePicker } from "antd";
import type { DatePickerProps, RangePickerProps } from "antd/es/date-picker";
import { ReactComponent as Calendar } from "assets/icons/Calendar.svg";
import dayjs, { Dayjs } from "dayjs";
import React, { useEffect, useState } from "react";
import * as S from "./style";

const { RangePicker } = DatePicker;

type DateTimeFormat = "YYYY-MM-DD HH:mm:ss" | "YYYY-MM-DD";

interface DateRangePickerProps {
  disabledDate?: (currentDate: Dayjs, dateRange?: [Dayjs, Dayjs]) => boolean;
  disabled?: boolean;
  type?: "single" | "range";
  placeholder?: string | string[];
  format?: DateTimeFormat;
  defaultValue?: Dayjs | [Dayjs, Dayjs];
  clearValue?: boolean;
  onClearFilter?: () => void;
  showTime?:
    | {
        format: string;
      }
    | boolean;
  onChange?: (
    value: DatePickerProps["value"] | RangePickerProps["value"],
    dateString: [string, string] | string
  ) => void;
  onOk?: (value: DatePickerProps["value"] | RangePickerProps["value"]) => void;
}

const DateRangePicker: React.FC<DateRangePickerProps> = (props) => {
  const {
    disabledDate,
    disabled,
    type,
    placeholder,
    showTime,
    format,
    defaultValue,
    onChange,
    onOk,
    clearValue,
    onClearFilter = () => {},
  } = props;

  const [value, setValue] = useState<Dayjs | [Dayjs, Dayjs] | null>();

  useEffect(() => {
    setValue(defaultValue);
  }, [defaultValue]);

  useEffect(() => {
    if (clearValue) {
      setValue(null);
      onClearFilter();
    }
  }, [clearValue, onClearFilter]);

  const handleChange = (
    v: DatePickerProps["value"] | RangePickerProps["value"],
    d: [string, string] | string
  ) => {
    if (onChange) {
      onChange(v, d);
      setValue(
        v === null || v === undefined
          ? null
          : Array.isArray(v)
          ? [dayjs(d[0]), dayjs(d[1])]
          : dayjs(v)
      );
    }
  };

  if (type === "range") {
    return (
      <S.DateRangePickerWrapper>
        <RangePicker
          showTime={showTime}
          format={format ? format : "YYYY-MM-DD"}
          // defaultValue={defaultValue as [Dayjs, Dayjs]}
          onChange={handleChange}
          onOk={onOk}
          suffixIcon={<Calendar />}
          disabled={disabled}
          disabledDate={disabledDate}
          value={value as [Dayjs, Dayjs]}
          placeholder={
            Array.isArray(placeholder)
              ? [placeholder[0], placeholder[1]]
              : undefined
          }
        />
      </S.DateRangePickerWrapper>
    );
  }
  return (
    <S.DateRangePickerWrapper>
      <DatePicker
        showTime={showTime}
        format={format ? format : "YYYY-MM-DD"}
        value={value as Dayjs}
        onChange={handleChange}
        suffixIcon={<Calendar />}
        placeholder={placeholder as string}
        disabled={disabled}
        disabledDate={disabledDate}
      />
    </S.DateRangePickerWrapper>
  );
};

export default DateRangePicker;
