import dayjs, { Dayjs } from "dayjs";
import SelectTime from "./SelectTime";
import * as S from "./style";
import { useEffect, useState } from "react";
import {
  createDelayTimeApi,
  deleteDelayTimeApi,
  getListDelayTimeApi,
} from "api/brand";
import { formatTimeZone } from "utils/formatTimeZone";
import { message } from "antd";

interface TimeItem {
  id: number;
  startTime: Dayjs;
  endTime: Dayjs;
}

const DeliveryDelay: React.FC = () => {
  const [listTime, setListTime] = useState<TimeItem[]>([]);
  const getListTime = async () => {
    const { data, error }: any = await getListDelayTimeApi();
    if (error) {
      console.error(error);
      return;
    }

    const results: TimeItem[] = data?.map((el: any) => {
      return {
        startTime: el.startTime,
        endTime: el.endTime,
        id: el.id,
      };
    });
    setListTime(results);
  };

  const handleAddTime = async (startTime: Dayjs, endTime: Dayjs) => {
    const { data, error }: any = await createDelayTimeApi({
      startTime: formatTimeZone(startTime, "YYYY-MM-DD"),
      endTime: formatTimeZone(endTime, "YYYY-MM-DD"),
    });
    if (error) {
      console.error(error);
      return;
    }
    getListTime();
  };

  const handleDeleteTime = async (id: number) => {
    const { data, error }: any = await deleteDelayTimeApi({
      id,
    });
    if (error) {
      console.error(error);
      message.error("Delete failed");
      return;
    }
    message.success("Delete successfully");
    getListTime();
  };

  useEffect(() => {
    getListTime();
  }, []);

  return (
    <S.Wrapper>
      <S.Heading>
        <S.Title>배송지연 날짜설정</S.Title>
        <S.SubTitle>
          배송 지연할 날짜를 설정해주세요.
          <br />
          설정된 기간 동안 배송이 된 상품의 배송완료 날짜가 설정된 종료날짜 기준
          +5일 0시에 지정됩니다.
        </S.SubTitle>
      </S.Heading>
      <S.Content>
        <h3>Add time</h3>
        <S.ListTime>
          {listTime.map((item, i) => (
            <div key={item.id}>
              <h4>Time {i + 1}</h4>
              <div className="time-box">
                <span>
                  {dayjs(item.startTime).format("YYYY-MM-DD")} ~{" "}
                  {dayjs(item.endTime).format("YYYY-MM-DD")}
                </span>
                <button onClick={() => handleDeleteTime(item.id)}>삭제</button>
              </div>
            </div>
          ))}
        </S.ListTime>
        <SelectTime handleAddTime={handleAddTime} />
      </S.Content>
    </S.Wrapper>
  );
};

export default DeliveryDelay;
