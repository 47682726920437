import { styled } from "styled-components";

export const Wrapper = styled.div`
  .ant-radio-group {
    display: flex;
    width: 100%;
  }
  .ant-radio-wrapper {
    width: fit-content;
    flex: none;
  }
  .ant-radio-wrapper .ant-radio-checked .ant-radio-inner {
    border-color: #526557;
    background-color: #526557;
  }
  .ant-radio-wrapper:hover .ant-radio-inner,
  .ant-radio-wrapper .ant-radio-checked::after {
    border-color: #526557;
  }
`;
