import Table, { ColumnsType } from "antd/es/table";
import { getPostHistoryApi } from "api/membership";
import CsTable from "components/Table";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { useAppSelecter } from "redux/hook";
import { tokenSelect } from "redux/user/userSlice";
import styled from "styled-components";

const ViewBtn = styled.a`
  height: 28px;
  line-height: 28px;
  background-color: #000;
  color: #fff;
  border-radius: 4px;
  padding: 0 8px;
  cursor: pointer;
  display: inline-block;
`;

const ContentTable: React.FC = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { userId } = useParams();
  const token = useAppSelecter(tokenSelect);

  const [dataTable, setDataTable] = useState<{
    data: any;
    totalElement: number;
  }>({ data: [], totalElement: 0 });

  const getPostHistory = async () => {
    if (!userId) return;
    const { data, error }: any = await getPostHistoryApi({
      userId: +userId,
      page: Number(searchParams.get("page")) || 1,
      size: 10,
    });
    if (error) {
      console.error(error);
      return;
    }
    if (data.datas) {
      setDataTable({
        data: data.datas,
        totalElement: data?.pageable.totalElement,
      });
    }
  };
  // console.log(
  //   "process.env.REACT_APP_CLIENT_URL",
  //   process.env.REACT_APP_CLIENT_URL
  // );
  useEffect(() => {
    getPostHistory();
  }, [searchParams.get("page")]);
  const columns: ColumnsType<any> = [
    {
      title: "원문보기",
      dataIndex: "cl1",
      key: "cl1",
      width: "18.19%",
      render: (_, record) => (
        <ViewBtn
          target="_blank"
          href={`${process.env.REACT_APP_CLIENT_URL}/${record?.targetBrandId}?accessToken=${token}&tab=community&postID=${record?.id}`}
        >
          확인
        </ViewBtn>
      ),
    },
    {
      title: "방문일시",
      dataIndex: "cl2",
      key: "cl2",
      width: "24.79%",
      render: (_, record) => (
        <div>
          {dayjs(record?.createdTime).format("YYYY-MM-DD")}
          <br />
          {dayjs(record?.createdTime).format("HH:mm:ss")}
        </div>
      ),
    },
    {
      title: "내용",
      dataIndex: "cl3",
      key: "cl3",
      width: "75.21%",
      render: (_, record) => (
        <div
          style={{
            overflow: "hidden",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
            maxWidth: 900,
          }}
        >
          {record?.content}
        </div>
      ),
    },
  ];
  return (
    <div>
      <Table
        bordered
        // onChange={handleTableChange}
        className="center-table"
        columns={columns}
        dataSource={dataTable.data}
        // onRow={(record, rowIndex) => {
        //   return {
        //     onClick: () => handleRowClick(record), // Click vào hàng
        //   };
        // }}
        pagination={
          dataTable.totalElement >= 10
            ? {
                current: Number(searchParams.get("page")) || 1,
                total: dataTable.totalElement,
                pageSize: 10,
                showSizeChanger: false,
                onChange: (page) => {
                  searchParams.set("page", page.toString());
                  setSearchParams(searchParams);
                },
              }
            : false
        }
      />
    </div>
  );
};
export default ContentTable;
