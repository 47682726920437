import { ColumnsType } from "antd/es/table";
import CsTable from "components/Table";
import dayjs from "dayjs";
import { useNavigate, useSearchParams } from "react-router-dom";
import { MoneyConvert } from "utils/format";

interface Props {
  data: any;
  totalElement: number;
}

const PremiumTable: React.FC<Props> = ({ data, totalElement }) => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const columns: ColumnsType<any> = [
    {
      title: "브랜드명",
      dataIndex: "cl1",
      key: "cl1",
      // width: "250px",
      fixed: "left",
      render: (_, record) => <div>{record?.brandName}</div>,
    },
    {
      title: "멤버십 No.",
      dataIndex: "cl2",
      key: "cl2",
      // width: "250px",
      fixed: "left",
      render: (_, record) => <div>{record.membershipNo}</div>,
    },
    {
      title: "이메일 계정",
      dataIndex: "cl3",
      key: "cl3",
      // width: "250px",
      fixed: "left",
      render: (_, record) => <div>{record.email}</div>,
    },
    {
      title: "이름",
      dataIndex: "cl4",
      key: "cl4",
      fixed: "left",
      // width: "250px",
      render: (_, record) => <div>{record.displayName}</div>,
    },
    {
      title: "전화번호",
      dataIndex: "cl5",
      key: "cl5",
      // width: "250px",
      render: (_, record) => <div>{record.phoneNumber}</div>,
    },
    {
      title: "가입 상태",
      dataIndex: "cl6",
      key: "cl6",
      // width: "250px",
      render: (_, record) => (
        <div>
          {record?.registedStatus === "RENEWAL" ? "멤버십 유지" : "만료"}
        </div>
      ),
    },
    {
      title: "최초가입일",
      dataIndex: "cl7",
      key: "cl7",
      // width: "250px",
      render: (_, record) => (
        <div>{dayjs(record?.firstRegistedDate).format("YYYY-MM-DD")}</div>
      ),
    },
    {
      title: "최근결제일",
      dataIndex: "cl8",
      key: "cl8",
      // width: "250px",
      render: (_, record) => (
        <div>{dayjs(record?.lastPay).format("YYYY-MM-DD")}</div>
      ),
    },
    {
      title: "멤버십 만료일",
      dataIndex: "cl9",
      key: "cl9",
      // width: "250px",
      render: (_, record) => (
        <div>{dayjs(record?.expiredDate).format("YYYY-MM-DD")}</div>
      ),
    },
    {
      title: "진행회차",
      dataIndex: "cl10",
      key: "cl10",
      // width: "250px",
      render: (_, record) => <div>{MoneyConvert(record?.totalPay, true)}</div>,
    },
    {
      title: "자동결제 상태",
      dataIndex: "cl11",
      key: "cl11",
      // width: "250px",
      render: (_, record) => <div> {record?.status ? "유지" : "해지"}</div>,
    },
    {
      title: "최근방문일",
      dataIndex: "cl12",
      key: "cl12",
      // width: "250px",
      render: (_, record) => (
        <div>{dayjs(record?.lastUsedDate).format("YYYY-MM-DD")}</div>
      ),
    },
  ];

  const handleRowClick = (record: any) => {
    console.log("record", record);
    navigate(`/membership/premium-detail/${record.id}/${record.brandId}`);
  };

  return (
    <div>
      <CsTable
        // onChange={handleTableChange}
        className="no-margin-x center-table"
        columns={columns}
        dataSource={data}
        onRow={(record, rowIndex) => {
          return {
            onClick: () => handleRowClick(record), // Click vào hàng
          };
        }}
        pagination={
          totalElement >= 50
            ? {
                current: Number(searchParams.get("page")) || 1,
                total: totalElement,
                pageSize: 50,
                showSizeChanger: false,
                onChange: (page) => {
                  searchParams.set("page", page.toString());
                  setSearchParams(searchParams);
                },
              }
            : false
        }
      />
    </div>
  );
};
export default PremiumTable;
