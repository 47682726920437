import { Modal, ModalProps } from "antd";
import { ReactComponent as CloseIcon } from "assets/icons/membership/close.svg";

interface Props extends ModalProps {
  handleClose: () => void;
}

const UsageHistoryInfoModal: React.FC<Props> = ({ open, handleClose }) => {
  return (
    <Modal
      onCancel={handleClose}
      width={450}
      closeIcon={null}
      open={open}
      footer={null}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          cursor: "pointer",
        }}
        onClick={handleClose}
      >
        <h2>멤버십 이용 기록</h2>
        <CloseIcon />
      </div>
      <br />
      <hr />
      <br />
      <p>
        - Y: 이용 기록 있음 <br />- N: 이용 기록 없음
      </p>

      <br />
    </Modal>
  );
};
export default UsageHistoryInfoModal;
