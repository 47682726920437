import MainHeader from "layouts/components/Header";
import { ReactComponent as PencilIcon } from "assets/icons/Pencil.svg";
import * as S from "./style";
import ButtonComp from "components/ButtonCmp";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import DetailInfo from "./components/DetailInfo";
import BrandJoin from "./components/BrandJoin";
import { useEffect, useState } from "react";
import { getAllDetailAccountApi } from "api/user";
import OrderHistory from "./components/OrderHistory";
import { MoneyConvert } from "utils/format";
import TabSimple, { TabItem } from "components/TabSimple";
import WalletTable from "pages/Membership/MemberManager/Premium/Detail/WalletTable";
import ContentTable from "pages/Membership/MemberManager/Shares/ContentTable";
import PostTable from "pages/Membership/MemberManager/Shares/PostTable";
import CommentTable from "pages/Membership/MemberManager/Shares/CommentTable";

const tabItems: TabItem[] = [
  {
    id: "wallet",
    title: "MY월렛 이용내역",
    content: <WalletTable />,
  },
  {
    id: "content",
    title: "멤버십 콘텐츠 조회",
    content: <ContentTable />,
  },
  {
    id: "post",
    title: "포스트 작성",
    content: <PostTable />,
  },
  {
    id: "comment",
    title: "댓글 작성",
    content: <CommentTable />,
  },
];

const ClientUserDetail: React.FC = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const { userId } = useParams<{ userId: string }>();
  const [info, setInfo] = useState<any>();

  const getAllDetailAccount = async () => {
    if (!userId) return;
    const { data, error }: any = await getAllDetailAccountApi({
      userId: +userId,
    });
    if (error) {
      console.error(error);
      return;
    }
    setInfo(data);
  };

  useEffect(() => {
    getAllDetailAccount();
    // set active tab params
    searchParams.set("joinTab", "unExpired");
    searchParams.set("orderTab", "normal");
    searchParams.set("historyTab", "wallet");
    setSearchParams(searchParams);
  }, []);

  // console.log('info?.totalPayment?.payAmount', info?.totalPayment);

  return (
    <S.Wrapper>
      <MainHeader
        backLink="/users/client-user"
        title="일반 회원 세부 정보"
        breadCrumb={[
          {
            title: "일반 회원",
            link: "/users/client-user",
            // icon: <Campaign />,
          },
          {
            title: "계정 세부 정보",
          },
        ]}
        // rightBtn={
        //   <ButtonComp
        //     onClick={() => navigate(`/users/client-user/edit/${userId}`)}
        //     icon={<PencilIcon />}
        //   >
        //     회원 정보 수정
        //   </ButtonComp>
        // }
      />
      <S.Body>
        <S.Card>
          <h3>일반 정보</h3>
          <DetailInfo data={info} getAllDetailAccount={getAllDetailAccount} />
        </S.Card>
        <S.Card>
          <h3>가입 브랜드</h3>
          <BrandJoin />
        </S.Card>
        <S.Card>
          <h3>구독 브랜드</h3>
          <S.Subscribed>
            {info?.followingBrand?.map((el: any) => (
              <div key={el.id} className="subscribed-item">
                <img src={el.logo_image} alt="img" />
                <h2 className="brand-name">{el.brand_name}</h2>
              </div>
            ))}
          </S.Subscribed>
        </S.Card>
        <S.Card>
          <h3>멤버십 주문내역</h3>
          <div style={{ marginBottom: 20 }}>
            총 결제 완료 금액 {MoneyConvert(info?.totalPayment?.payAmount)}원
          </div>
          <OrderHistory />
        </S.Card>
        <S.Card>
          <h3>활동 내역</h3>
          <TabSimple
            paramName="historyTab"
            noActiveParams
            defaultActive="wallet"
            items={tabItems}
          />
        </S.Card>
      </S.Body>
    </S.Wrapper>
  );
};

export default ClientUserDetail;
