import { CloseOutlined } from "@ant-design/icons";
import { Form, Input, message } from "antd";
import { NoticeType } from "antd/es/message/interface";
import { uploadSingleFile } from "api/uploadSingleFile";
import { getClientUserDetailApi, updateUserApi } from "api/user";
import { ReactComponent as CakeIcon } from "assets/icons/Cake.svg";
import { ReactComponent as EmailIcon } from "assets/icons/Email.svg";
import { ReactComponent as GenderIcon } from "assets/icons/GenderIntersex.svg";
import { ReactComponent as MapPinLineIcon } from "assets/icons/MapPinLine.svg";
import { ReactComponent as PhoneIcon } from "assets/icons/Phone.svg";
import { ReactComponent as UserFocusIcon } from "assets/icons/UserFocus.svg";
import { ReactComponent as UserIcon } from "assets/icons/Users-1.svg";
import ButtonComp from "components/ButtonCmp";
import UploadImageCrop from "components/UploadImageCrop";
import MainHeader from "layouts/components/Header";
import { UpdateUserApi } from "model/user";
import { useEffect, useState } from "react";
import DaumPostcode from "react-daum-postcode";
import { useNavigate, useParams } from "react-router-dom";
import * as S from "./style";

function ClientUserEdit() {
  const { userId } = useParams();
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [messageApi, contextHolder] = message.useMessage({ maxCount: 2 });
  const [initData, setInitData] = useState<any>({});
  const [openPopupAddress, setopenPopupAddress] = useState<boolean>(false);

  const MessageFunc = (type: NoticeType, message: string) => {
    messageApi.open({
      type: type,
      content: message,
    });
  };

  const onImageChange = (file: File) => {
    // console.log(file);
    form.setFieldsValue({
      avatar: file,
    });
  };

  const onFinish = async (values: any) => {
    let updatedValues: Omit<UpdateUserApi, "id"> = {
      avatar: values.avatar,
      email: values.email,
      address: values.address,
    };

    if (typeof values.avatar !== "string") {
      const formData = new FormData();
      formData.append("file", values.avatar);

      try {
        const resp: any = await uploadSingleFile(formData);
        if (resp.error && resp.error.message !== "file not found") {
          MessageFunc("error", "Upload image failed");
          return;
        }
        if (resp?.imageUrl) {
          updatedValues.avatar = resp.imageUrl;
        }
      } catch (error) {
        console.log(error);
      }
    }

    if (userId) {
      try {
        const { data, error }: any = await updateUserApi({
          id: +userId,
          ...updatedValues,
        });
        if (error) {
          if (error?.errorCode === "EXISTED_EMAIL") {
            message.error("Email is already existed!", 2);
          } else {
            MessageFunc("error", "Update failed");
          }
          return;
        }
        if (data) {
          // console.log(data);
          navigate(`/users/client-user/${userId}`);
        }
      } catch (error) {
        console.error(error);
        MessageFunc("error", "Update failed");
      }
    }
  };

  const onFinishFailed = (value: any) => {};

  const handleResetForm = () => {
    form.resetFields();
    navigate(`/users/client-user/${userId}`);
  };

  const handleCompleteAdress = (data: any) => {
    // console.log(data);
    let fullAddress = data.address;
    let extraAddress = "";

    if (data.addressType === "R") {
      if (data.bname !== "") {
        extraAddress += data.bname;
      }
      if (data.buildingName !== "") {
        extraAddress +=
          extraAddress !== "" ? `, ${data.buildingName}` : data.buildingName;
      }
      fullAddress += extraAddress !== "" ? ` (${extraAddress})` : "";
    }

    form.setFieldsValue({
      address: fullAddress,
    });
    // setAddressValue(fullAddress);
  };

  useEffect(() => {
    const getClientUserDetail = async () => {
      if (userId) {
        const { data }: any = await getClientUserDetailApi({ id: +userId });
        // console.log(data);
        if (data) {
          form.setFieldsValue({
            avatar: data?.avatar,
            displayName: data?.displayName,
            gender: data?.gender === "female" ? "Female" : "Male",
            dateOfBirth: data?.birthDate,
            phoneNumber: data?.verifiedPhoneNumber,
            email: data?.email,
            address: data?.address,
          });
          setInitData(data);
        }
      }
    };
    getClientUserDetail();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId]);

  return (
    <S.Wrapper>
      {contextHolder}
      <MainHeader
        backLink={`/users/client-user/${userId}`}
        title="회원 정보 수정"
      />
      <S.Container>
        <S.Content>
          <Form
            form={form}
            name="basic"
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
          >
            <S.FormField>
              <h3>프로필 사진</h3>
              <Form.Item name="avatar">
                <UploadImageCrop
                  initImage={initData?.avatar}
                  onImageChange={onImageChange}
                />
              </Form.Item>
              <h3>회원 정보</h3>
              <Form.Item name="displayName">
                <Input
                  prefix={<UserIcon />}
                  placeholder="Chae Song Hwa"
                  className="form-input"
                  disabled={true}
                />
              </Form.Item>
              <S.ThreeField>
                <Form.Item name="gender">
                  <Input
                    prefix={<GenderIcon />}
                    placeholder="Female"
                    className="form-input"
                    disabled={true}
                  />
                </Form.Item>
                <Form.Item name="dateOfBirth">
                  <Input
                    prefix={<CakeIcon />}
                    placeholder="1990/09/11"
                    className="form-input"
                    disabled={true}
                  />
                </Form.Item>
                <Form.Item name="phoneNumber">
                  <Input
                    prefix={<PhoneIcon />}
                    placeholder="010-0022-324"
                    className="form-input"
                    disabled={true}
                  />
                </Form.Item>
              </S.ThreeField>
              {/* <Form.Item name="userName">
                <Input
                  prefix={<UserFocusIcon />}
                  placeholder="Username"
                  className="form-input"
                  disabled={true}
                />
              </Form.Item> */}
              <Form.Item
                name="email"
                rules={[
                  { required: true, message: "필수값입니다" },
                  {
                    type: "email",
                    message:
                      "유효한 이메일 주소를 입력해주세요 ex) 123@gmail.com",
                  },
                ]}
              >
                <Input
                  disabled={true}
                  prefix={<EmailIcon />}
                  placeholder="dr.songhwa@gmail.com"
                  className="form-input"
                />
              </Form.Item>
              <S.AddressContainer>
                <Form.Item name="address">
                  <Input
                    // value={addressValue}
                    prefix={<MapPinLineIcon />}
                    placeholder="주소"
                    className="form-input"
                    onFocus={() => setopenPopupAddress(true)}
                    // onBlur={()=>setopenPopupAddress(false)}
                  />
                </Form.Item>
                <S.OverLayout
                  onClick={() => setopenPopupAddress(false)}
                  className={openPopupAddress ? "active" : "sss"}
                />
                {openPopupAddress && (
                  <S.WrPopupAdress>
                    <div
                      className="close-popup-adress"
                      onClick={() => setopenPopupAddress(false)}
                    >
                      <CloseOutlined />
                    </div>
                    <DaumPostcode
                      onComplete={handleCompleteAdress}
                      onClose={() => setopenPopupAddress(false)}
                    />
                  </S.WrPopupAdress>
                )}
              </S.AddressContainer>
            </S.FormField>
            <S.FormBtn>
              <ButtonComp
                onClick={handleResetForm}
                className="cancel"
                status="noBackground"
              >
                취소
              </ButtonComp>
              <ButtonComp htmlType="submit" className="update">
                수정하기
              </ButtonComp>
            </S.FormBtn>
          </Form>
        </S.Content>
      </S.Container>
    </S.Wrapper>
  );
}

export default ClientUserEdit;
