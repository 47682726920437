export enum ACCOUNT_STATUS {
  Initial,
  Waiting,
  Reject,
  Active,
  Inactive,
}

export enum GB_STATUS {
  Active,
  Inactive,
}
export const TIME_FORMAT = "YYYY/MM/DD HH:mm";

export enum API_URL {
  LOGIN = "/auth/sign-in",
  UPLOAD_SINGLE_FILE = "/upload/single-file",
  UPLOAD_SINGLE_VIDEO = "upload/single-file-video",

  LIST_CATEGORY = "/categories/get-all",
  CREATE_CATEGORY = "/categories/create-category",

  LIST_USER = "/user/list",
  LIST_USER_DELETE_COUNT = "/user/request-count",
  LIST_USER_DELETE = "/user/request-list",
  UPDATE_REQUEST_DELETE = "/user/update-request-leave",
  CLIENT_USER_DETAIL = "/user/details",
  UPDATE_ACCOUNT_STATUS = "/user/update-active",
  UPDATE_USER = "/user/update",
  CHECK_AUTH = "/brand/check-auth-info",

  CHANGE_APPROVE_STATUS = "/brand/change-approve-status",
  UPDATE_GB_STATUS = "/brand/update-gb-status",
  LIST_USER_BRAND = "/brand/list-brand",
  DETAIL_USER_BRAND = "/brand/brand-user",
  CREATE_BRAND = "/brand/create-brand",
  UPDATE_BRAND = "/brand/edit-brand",
  CAMPAIGN_LIST = "/brand/list-gb",
  LIST_BRAND = "/brand/list-brand-select",
  CREATE_GROUP_BUYING = "/brand/create-gb",
  UPDATE_GB = "/brand/update-gb",
  UPDATE_GB_INCOMING = "/brand/update-incoming-gb",
  GROUP_BUYING_DETAIL = "/brand/gb-details",
  LIST_BRANCH = "/brand/list-branch-select",
  LIST_BRAND_HAS_PURCHASE = "/brand/brand-has-purchase",

  LIST_ORDER = "/order/order-list",
  ORDER_COUNT = "/order/request-count",
  ORDER_DETAIL = "/order/details",
  UPDATE_STATUS_ORDER = "/order/update",
  LIST_TRANSACTION_HISTORY = "/transaction-history/list",

  CREATE_ANNOUNCEMENT = "/announcement/create",
  UPDATE_ANNOUNCEMENT = "/announcement/update",
  GET_ANNOUNCEMENT_CATEGORIES = "/announcement/type-list",
  CREATE_ANNOUNCEMENT_CATEGORIES = "/announcement/create-type",
  GET_LIST_ANNOUNCEMENT = "/announcement/list",
  GET_ANNOUNCEMENT_DETAIL = "/announcement/details",
  DELETE_ANNOUNCEMENT = "/announcement/delete",

  CREATE_FAQ = "/faq/create",
  UPDATE_FAQ = "/faq/update",
  GET_FAQ_CATEGORIES = "/faq/type-list",
  CREATE_FAQ_CATEGORIES = "/faq/create-type",
  GET_LIST_FAQ = "/faq/list",
  GET_FAQ_DETAIL = "/faq/details",
  DELETE_FAQ = "/faq/delete",

  LIST_INQUIRY = "/inquiry/list",
  INQUIRY_DETAIL = "/inquiry/details",
  INQUIRY_REPLY = "/inquiry/reply",

  ORDER_MEMBERSHIP_HISTORY = "/transaction-history/normal-membership-history",
  MEMBERSHIP_PRE_REFUND = "/membership/pre-refund",
  MEMBERSHIP_REFUND_HISTORY = "/membership/refund-history",
  MEMBERSHIP_REFUND = "/membership/refund",
  DOWNLOAD_USED_LOG = "/membership/download-used-excel",
  GET_SETTLEMENT = "/settlement/by-month",
  COMPLETE_SETTLEMENT = "/settlement/done-settlement",
  COMPLETE_RELEASE_TAX = "/settlement/release-tax",
  DOWNLOAD_SETTLEMENT_LOG = "/settlement/download-settlement-excel",

  GET_NORMAL_LIST = "/membership/membership-user",
  GET_NORMAL_DETAIL = "/membership/membership-user-detail",
  DOWNLOAD_NORMAL = "/membership/download-membership-user",
  UPDATE_EMAIL_RECEIVE = "/membership/update-email-receive",
  GET_CONTENT_HISTORY = "/membership/view-content-history-by-user",
  GET_POST_HISTORY = "/membership/post-history-by-user",
  GET_CONTENT_OF_POST = "/membership/comment-of-post",
  GET_PREMIUM_LIST = "/premium-membership/list",
  GET_PREMIUM_DETAIL = "/premium-membership/details",
  DOWNLOAD_PREMIUM = "/premium-membership/download-premium-user-exce",
  GET_WALLET_HISTORY = "/u-b-payment/transaction-history",
  GET_LIST_SLIDE = "/banner/list",
  CREATE_SLIDE = "/banner/create",
  EDIT_SLIDE = "/banner/update",
  SLIDE_POSITION = "/banner/swap-position",
  DELETE_SLIDE = "/banner/delete",
  GET_DETAIL_SLIDE = "/banner/details",
  GET_ALL_ACCOUNT = "/membership/all-membership",
  UPDATE_CLIENT_APP = "/brand/update-brand-user",
  DUPLICATE_GB = "/brand/duplicate-gb",
  GET_UNEXPIRED_MEMBERSHIP = "/membership/unexpired-membership",
  GET_EXPIRED_MEMBERSHIP = "/membership/expired-membership",
  GET_ALL_DETAIL_ACCOUNT = "/membership/all-details",
  GET_PREMIUM_ORDER_SINGLE_API = "/membership/premium-order-single",
  GET_NORMAL_ORDER_SINGLE_API = "/membership/normal-order-single",
  DOWNLOAD_ALL_ACCOUNT = "/membership/download-all-membership",
  GET_LIST_DELAY_TIME = "/delay-date/list",
  CREATE_DELAY_TIME = "/delay-date/create",
  DELETE_DELAY_TIME = "/delay-date/delete",
  ADD_PREMIUM_HISTORY = "/premium-membership/add-premium-history",
  GET_PREMIUM_LOG = "/premium-membership/premium-log",
}
