import { ColumnsType } from "antd/es/table";
import * as S from "./style";
import CsTable from "components/Table";
import { useSearchParams } from "react-router-dom";
import { MoneyConvert } from "utils/format";
import {
  handleCompleteReleaseTaxApi,
  handleCompleteSettlementApi,
} from "api/settlement";
import { message } from "antd";

interface Props {
  data: any;
  totalElement?: number;
  getSettlement?: (payload?: any) => Promise<void>;
}

const SettlementTable: React.FC<Props> = ({
  data,
  totalElement,
  getSettlement,
}) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const handleCompleteSettlement = async (id: number) => {
    const { data, error }: any = await handleCompleteSettlementApi({ id });
    if (error) {
      console.error(error);
      return;
    }
    message.success("Successfully");
    getSettlement?.()
  };

  const handleCompleteReleaseTax = async (id: number) => {
    const { data, error }: any = await handleCompleteReleaseTaxApi({ id });
    if (error) {
      console.error(error);
      return;
    }
    message.success("Successfully");
    getSettlement?.()
  };

  const columns: ColumnsType<any> = [
    {
      title: "브랜드명",
      dataIndex: "cl1",
      width: "200px",
      render: (_, record) => <div>{record.brandName}</div>,
    },
    {
      title: "정산확정금액",
      dataIndex: "cl2",
      width: "200px",
      render: (_, record) => (
        <div>{MoneyConvert(record.settlementAmount, true)}</div>
      ),
    },
    {
      title: "결제 완료 금액",
      dataIndex: "cl3",
      width: "200px",
      render: (_, record) => (
        <div>{MoneyConvert(record.paymentAmount, true)}</div>
      ),
    },
    {
      title: "플랫폼 이용 수수료",
      dataIndex: "cl4",
      width: "200px",
      render: (_, record) => (
        <div>{MoneyConvert(record.platformFee, true)}</div>
      ),
    },
    {
      title: "결제 대행 수수료",
      dataIndex: "cl5",
      width: "200px",
      render: (_, record) => <div>{MoneyConvert(record.paymentFee, true)}</div>,
    },
    {
      title: "정산 지급",
      dataIndex: "cl6",
      width: "200px",
      render: (_, record) => (
        <div>
          {!record.status ? (
            <S.ActiveBtn onClick={() => handleCompleteSettlement(record.id)}>
              완료
            </S.ActiveBtn>
          ) : (
            <S.InActiveBtn>완료</S.InActiveBtn>
          )}
        </div>
      ),
    },
    {
      title: "세금계산서 발행",
      dataIndex: "cl1",
      width: "200px",
      render: (_, record) => (
        <div>
          {!record.taxRelease ? (
            <S.ActiveBtn onClick={() => handleCompleteReleaseTax(record.id)}>
              완료
            </S.ActiveBtn>
          ) : (
            <S.InActiveBtn>완료</S.InActiveBtn>
          )}
        </div>
      ),
    },
  ];

  return (
    <CsTable
      columns={columns}
      dataSource={data}
      pagination={
        totalElement && totalElement > 10
          ? {
              current: Number(searchParams.get("page")) || 1,
              total: totalElement,
              pageSize: 10,
              showSizeChanger: false,
              onChange: (page) => {
                searchParams.set("page", page.toString());
                setSearchParams(searchParams);
                getSettlement?.({ page });
              },
            }
          : false
      }
    />
  );
};
export default SettlementTable;
