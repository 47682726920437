import { styled } from "styled-components";

export const Wrapper = styled.div`
  height: 100%;
  background-color: var(--sub-background);
  .filter-container {
    padding: 0 30px;
    display: flex;
    gap: 12px;
    height: 60px;
    margin: 30px 0 20px 0;
  }
  .ant-input-affix-wrapper,
  .ant-picker,
  .ant-input {
    background-color: transparent;
  }
`;

export const Heading = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  .btn-create {
    border-radius: 12px;
    background: var(--brand-color);
    color: var(--light-color) !important;
    width: 220px;
    height: 52px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
  }
`;

export const TdTextInner = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  p {
    margin: 0;
  }
  .big-text {
    font-size: 16px;
    line-height: 25.6px;
  }
  .small-text {
    font-size: 14px;
    line-height: 22.4px;
  }
`;

export const ViewDetailBtn = styled.div`
  cursor: pointer;
  display: flex;
`;

export const BrandInfo = styled.div`
  display: flex;
  gap: 6px;
  align-items: center;
  p {
    margin: 0;
  }
  img {
    border-radius: 5px;
    object-fit: cover;
  }
  .brand-name {
    font-family: var(--font-600);
    line-height: 19.6px;
  }
  .brand-category {
    font-size: 10px;
    line-height: 14px;
    padding: 3px;
    background-color: var(--hint-color);
    border-radius: 3px;
    display: inline-block;
  }
`;

export const VoucherInfo = styled.div`
  display: flex;
  gap: 6px;
  align-items: center;
  p {
    margin: 0;
  }
  img {
    border-radius: 5px;
    object-fit: cover;
  }
  .voucher-name {
    font-family: var(--font-600);
    line-height: 19.6px;
    margin-bottom: 5px;
  }
  .voucher-detail {
    font-size: 12px;
    line-height: 19.2px;
  }
`;

export const TargetAmount = styled.div`
  display: flex;
  gap: 6px;
  flex-direction: column;
  p {
    margin: 0;
  }
  .total {
    line-height: 22.4px;
    font-family: "SpaceMonoBold";
  }
  .per-unit {
    font-size: 12px;
    line-height: 19.2px;
  }
`;

export const StatusBtn = styled.div`
  height: 22px;
  padding: 0 5px;
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  color: #fff;
  display: inline-block;
  line-height: 22px;
  text-align: center;
  border-radius: 5px;
  min-width: 50px;
`;

export const Progress = styled.div`
  display: inline-flex;
  flex-direction: column;
  p {
    margin: 0;
  }
  .progress {
    line-height: 22.4px;
  }
  .status {
    font-size: 10px;
    line-height: 16px;
    border-radius: 3px;
    background: var(--hint-color);
    padding: 3px;
    min-width: 50px;
    text-align: center;
  }
`;

export const RangeTime = styled.div`
  display: flex;
  flex-direction: column;
  gap: 7px;
  p {
    margin: 0;
    font-size: 12px;
  }
`;

export const TableWraper = styled.div``;
