import { Select, SelectProps } from "antd";
import * as S from "./style";

import { ReactComponent as IconDownClose } from "assets/icons/ArrowDown14.svg";
import { ReactComponent as IconDownOpen } from "assets/icons/ArrowUp14.svg";

import { ReactNode, useState } from "react";

interface Props extends SelectProps<any> {
  iconWhenClose?: ReactNode;
  iconWhenOpen?: ReactNode;
  className?: string;
  prefix?: ReactNode;
}

function SelectCmp({
  options,
  suffixIcon,
  iconWhenClose,
  iconWhenOpen,
  className,
  prefix,
  ...props
}: Props) {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  return (
    <S.Wrapper className={prefix ? "has-prefix" : ""}>
      <S.PrefixIcon>{prefix}</S.PrefixIcon>
      <Select
        allowClear={prefix ? false : true}
        className={className}
        options={options}
        suffixIcon={
          iconWhenClose || iconWhenOpen ? (
            isOpen ? (
              iconWhenOpen
            ) : (
              iconWhenClose
            )
          ) : isOpen ? (
            <IconDownOpen />
          ) : (
            <IconDownClose />
          )
        }
        // suffixIcon={<span style={{ marginRight: 8 }}>Prefix</span>}
        onDropdownVisibleChange={(open: boolean) => setIsOpen(open)}
        {...props}
      />
    </S.Wrapper>
  );
}

export default SelectCmp;
