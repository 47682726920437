import React, { CSSProperties } from "react";
import { ButtonStyle } from "./styles";
import { ButtonProps } from "antd";

function ButtonMain({
  children,
  type = "default",
  style,
  ...rest
}: ButtonProps) {
  const buttonType: Record<string, CSSProperties> = {
    primary: {
      backgroundColor: "var(--brand-color)",
      color: "var(--light-color)",
    },
    default: {
      backgroundColor: "transparent",
      color: "var(--brand-color)",
      borderColor: "var(--brand-color)",
    },
  };
  return (
    <ButtonStyle
      type={type}
      {...rest}
      style={buttonType[type] ? { ...buttonType[type], ...style } : style}
    >
      {children}
    </ButtonStyle>
  );
}

export default ButtonMain;
