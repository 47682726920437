import styled from "styled-components";

export const Wrapper = styled.div`
  position: relative;
  .clearBtn {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 35px;
    cursor: pointer;
    z-index: 11;
    opacity: 0;
    visibility: hidden;
    transition: all 0.3s;
    &:hover {
      svg {
        color: #8c8c8c !important;
      }
    }
  }
  &:hover {
    .clearBtn {
      opacity: 1;
      visibility: visible;
    }
  }
  &.normal {
    .ant-input-affix-wrapper {
      font-size: 16px;
      color: var(--brand-color);
      border-color: var(--brand-color);
      height: 100%;
      width: 100%;
      border-radius: 20px;
      padding: 0 20px;
      height: 48px;
    }
    .ant-select {
      .ant-select-selector {
        font-size: 16px;
        color: var(--brand-color);
        border-color: var(--brand-color);
        height: 100%;
        width: 100%;
        border-radius: 20px;
        padding: 0 20px;
        height: 48px;
      }
      .ant-select-selection-search-input {
        height: 48px !important;
        align-items: center;
        display: flex;
        font-size: 16px;
        color: var(--brand-color);
        border-color: var(--brand-color);
        height: 100%;
        width: 100%;
        border-radius: 20px;
        padding-left: 10px !important;
      }
      .ant-select-selection-placeholder,
      .ant-select-selection-item {
        display: flex;
        align-items: center;
        height: 48px !important;
      }
    }
  }
  &.filter {
    .ant-input-affix-wrapper {
      background-color: transparent;
    }
    .ant-select-selection-search-input {
      height: 58px !important;
      padding-left: 6px !important;
    }
    .ant-select {
      .ant-select-selector {
        background-color: transparent;
        padding: 0 16px;
        height: 58px;
        display: flex;
        align-items: center;
        border-radius: 20px;
        border: 1px solid var(--main-border);
      }
    }
  }
  
  /* .brand-option {
    position: absolute;
    left: 0;
    right: 0;
    padding-inline-end: 30px;
  } */
  
`;
// export const PrefixIcon = styled.div`
//   position: absolute;
//   top: 50%;
//   transform: translateY(-50%);
//   left: 11px;
//   display: flex;
//   align-items: center;
// `;
