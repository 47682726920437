import utc from "dayjs/plugin/utc";
import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone"

dayjs.extend(utc);
dayjs.extend(timezone)

export const formatTimeZone = (time: any, format: string): string => {
  return dayjs.utc(time).format(format);
};

export const formatTimeZoneExpiredTime = (time: dayjs.Dayjs): string => {
  const originalTime = dayjs(time, "DD/MM/YYYY");
  const newTime = originalTime.add(1, "day");
  return newTime.format("YYYY-MM-DD 00:00:00");
};
