import React from "react";
import * as S from "../style";
import { MoneyConvert } from "utils/format";

interface Props {
  data?: any;
}

const Amount: React.FC<Props> = ({ data }) => {
  return (
    <React.Fragment>
      <S.FlexContainer>
        <S.Card>
          <h3>합계</h3>
          <div className="grid">
            <span>결제완료-결제취소건</span>
            <span>결제금액-취소금액</span>
            <span>{MoneyConvert(data?.totalCnt)}건</span>
            <span>{MoneyConvert(data?.totalAmount, true)}원</span>
          </div>
        </S.Card>
        <S.Card>
          <h3>결제</h3>
          <div className="grid">
            <span>건</span>
            <span>금액</span>
            <span>{MoneyConvert(data?.totalCnt - data?.cancelCnt)}건</span>
            <span>
              {MoneyConvert(data?.totalAmount - data?.totalCancelAmount, true)}
              원
            </span>
          </div>
        </S.Card>
        <S.Card>
          <h3>취소</h3>
          <div className="grid">
            <span>건</span>
            <span>금액</span>
            <span>{MoneyConvert(data?.cancelCnt)}건</span>
            <span>{MoneyConvert(data?.totalCancelAmount, true)}원</span>
          </div>
        </S.Card>
      </S.FlexContainer>
      <S.TextBottom>
        <S.Dot />
        <S.Text>결제실패 건은 포함되지 않습니다.</S.Text>
      </S.TextBottom>
    </React.Fragment>
  );
};
export default Amount;
