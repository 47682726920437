import { ColumnsType } from "antd/es/table";
import CsTable from "components/Table";
import dayjs from "dayjs";
import { useState } from "react";
import { useSearchParams } from "react-router-dom";
import { MoneyConvert } from "utils/format";
import * as S from "./style";

interface Props {
  data: any;
  totalElement: number;
}

const TransactionTable: React.FC<Props> = ({ data, totalElement }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [sortString, setSortString] = useState<{
    order: "ascend" | "descend" | undefined;
    field: string | undefined;
  }>();
  const columns: ColumnsType<any> = [
    {
      title: "날짜",
      dataIndex: "transactionId",
      key: "transactionId",
      render: (_, record) => (
        <S.DateInfo>
          <p className="create-date">
            {dayjs(record.createdTime).format("YYYY/MM/DD HH:mm")}
          </p>
          <p className="transaction-id">{record.transactionId}</p>
        </S.DateInfo>
      ),
      sorter: true,
    },
    {
      title: "회원명",
      dataIndex: "bankHolder",
      key: "bankHolder",
      render: (_, record) => (
        <S.CustomerInfo>
          <p className="bank-holder">{record.displayName}</p>
          <p className="bank-account">{record.phoneNumber}</p>
        </S.CustomerInfo>
      ),
    },
    {
      title: "브랜드",
      dataIndex: "brandName",
      key: "brandName",
      render: (_, record) => (
        <S.BrandInfo>
          <img src={record.logoImage} alt="brandLogo" width={24} height={24} />
          <p className="brand-name">{record.brandName}</p>
        </S.BrandInfo>
      ),
    },
    {
      title: "브랜드 검색",
      dataIndex: "bankBranch",
      key: "bankBranch",
      render: (_, record) => <S.BranchInfo>{record.branchName}</S.BranchInfo>,
    },
    {
      title: "사용 금액",
      dataIndex: "amount",
      key: "amount",
      render: (_, record) => (
        <S.Amount>{MoneyConvert(record.amount) || 0}원</S.Amount>
      ),
      sorter: true,
    },
    {
      title: "메모",
      dataIndex: "message",
      key: "message",
      render: (_, record) => <S.Message>{record.message}</S.Message>,
      width: 400,
    },
  ];

  const handleChangeTable = (_: any, __: any, sorter: any) => {
    // console.log(sorter);

    if (
      sorter?.order !== sortString?.order ||
      sorter?.field !== sortString?.field
    ) {
      // console.log("vao day");

      setSortString({
        order: sorter.order,
        field: sorter.field,
      });
      const sortFieldMap: any = {
        transactionId: "transactionDateSort",
        amount: "amountSort",
      };

      const sortOrderMap: any = {
        ascend: "asc",
        descend: "desc",
      };

      for (const key in sortFieldMap) {
        if (searchParams.has(sortFieldMap[key])) {
          searchParams.delete(sortFieldMap[key]);
        }
      }

      const field = sortFieldMap[sorter.field];
      const order = sortOrderMap[sorter.order];

      if (field && order) {
        searchParams.set(field, order);
      }

      searchParams.set("page", "1");
      setSearchParams(searchParams);
    }
  };

  return (
    <S.TableWraper>
      <CsTable
        onChange={handleChangeTable}
        columns={columns}
        dataSource={data}
        pagination={{
          current: Number(searchParams.get("page")) || 1,
          total: totalElement,
          pageSize: 10,
          showSizeChanger: false,
          onChange: (page) => {
            searchParams.set("page", page.toString());
            setSearchParams(searchParams);
          },
        }}
      />
    </S.TableWraper>
  );
};

export default TransactionTable;
