import React, { useContext, useEffect, useMemo, useState } from "react";
import { HolderOutlined } from "@ant-design/icons";
import type { DragEndEvent } from "@dnd-kit/core";
import { DndContext } from "@dnd-kit/core";
import type { SyntheticListenerMap } from "@dnd-kit/core/dist/hooks/utilities";
import { restrictToVerticalAxis } from "@dnd-kit/modifiers";
import { ReactComponent as Plus } from "assets/icons/Plus.svg";
import {
  arrayMove,
  SortableContext,
  useSortable,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { Button, message, Modal, Table } from "antd";
import type { ColumnsType } from "antd/es/table";
import * as S from "./style";
import { Navigate, useNavigate } from "react-router-dom";
import {
  deleteSlideApi,
  editSlideApi,
  getListSlideApi,
  positionSlideApi,
} from "api/brand";
import dayjs from "dayjs";

interface DataType {
  key: string;
  // no: string;
  id: number;
  banner: string;
  brand: string;
  createTime: string;
  status: string;
}

interface RowContextProps {
  setActivatorNodeRef?: (element: HTMLElement | null) => void;
  listeners?: SyntheticListenerMap;
}

const RowContext = React.createContext<RowContextProps>({});

const DragHandle: React.FC = () => {
  const { setActivatorNodeRef, listeners } = useContext(RowContext);
  return (
    <Button
      type="text"
      size="small"
      icon={<HolderOutlined />}
      style={{ cursor: "move" }}
      ref={setActivatorNodeRef}
      {...listeners}
    />
  );
};

interface RowProps extends React.HTMLAttributes<HTMLTableRowElement> {
  "data-row-key": string;
}

const Row: React.FC<RowProps> = (props) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    setActivatorNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({ id: props["data-row-key"] });

  const style: React.CSSProperties = {
    ...props.style,
    transform: CSS.Translate.toString(transform),
    transition,
    ...(isDragging ? { position: "relative", zIndex: 9999 } : {}),
  };

  const contextValue = useMemo<RowContextProps>(
    () => ({ setActivatorNodeRef, listeners }),
    [setActivatorNodeRef, listeners]
  );

  return (
    <RowContext.Provider value={contextValue}>
      <tr {...props} ref={setNodeRef} style={style} {...attributes} />
    </RowContext.Provider>
  );
};

const SlideList: React.FC = () => {
  const navigate = useNavigate();
  const [idSlide, setIdSlide] = useState<number>();
  const [dataSource, setDataSource] = React.useState<DataType[]>([]);
  const [openModalDelete, setOpenModalDelete] = React.useState<{
    isOpen: boolean;
    id?: number;
  }>({
    isOpen: false,
  });
  // const [bannersInput, setBannersInput] = useState<any>();

  const onDragEnd = async ({ active, over }: DragEndEvent) => {
    // console.log("active", active);
    // console.log("over", over);
    let bannersInput: any;
    if (active.id !== over?.id) {
      setDataSource((prevState) => {
        const activeIndex = prevState.findIndex(
          (record) => record.key === active?.id
        );
        const overIndex = prevState.findIndex(
          (record) => record.key === over?.id
        );
        const arrMove = arrayMove(prevState, activeIndex, overIndex);
        const bnInput = arrMove.map((el: any, i: number) => {
          return {
            id: el.key,
            orderNumber: i + 1,
          };
        });
        bannersInput = bnInput;
        return arrMove;
      });
    }
    if (!active.id || !over?.id) {
      return;
    }
    const { data, error }: any = await positionSlideApi({
      bannersInput,
    });
    if (error) {
      console.error(error);
      message.error("Position failed");
      return;
    }
    message.success("Position successfully");
  };

  const getListSlide = async () => {
    const { data, error }: any = await getListSlideApi();
    if (error) {
      console.error(error);
      return;
    }
    const dataFormat: DataType[] = data.map((el: any, i: number) => ({
      key: el.id.toString(),
      id: el.id,
      banner: el.image,
      brand: el.brandName,
      createTime: el.createTime,
      status: el.status,
    }));
    setDataSource(dataFormat);
  };

  const handleDelete = async () => {
    if (!openModalDelete.id) {
      return;
    }
    const { data, error }: any = await deleteSlideApi({
      id: openModalDelete.id,
    });
    if (error) {
      console.error(error);
      message.error("Error deleting slide");
      return;
    }
    message.success("Slide deleted successfully");
    setOpenModalDelete({ isOpen: false });
    getListSlide();
  };

  const handleUpdateStatus = async (id: number, status: boolean) => {
    const { data, error }: any = await editSlideApi({
      id,
      status,
    });

    if (error) {
      console.error(error);
      message.error("Update Status failed");
      return;
    }
    message.success("Update Status successfully");
    getListSlide();
  };

  useEffect(() => {
    getListSlide();
  }, []);

  const columns: ColumnsType<DataType> = [
    {
      key: "sort",
      title: "순서 변경",
      align: "center",
      width: 110,
      render: () => <DragHandle />,
    },
    { title: "No", dataIndex: "key", width: 110 },
    {
      title: "배너 이미지",
      dataIndex: "banner",
      render: (_, record) => (
        <div>
          <img
            style={{
              margin: "auto",
              objectFit: "cover",
              width: 100,
              height: 56,
            }}
            title="banner"
            src={record.banner}
          />
        </div>
      ),
    },
    { title: "연결 브랜드", width: 300, dataIndex: "brand" },
    {
      title: "등록일",
      width: 300,
      dataIndex: "createTime",
      render: (_, record) => (
        <>{dayjs(record.createTime).format("YYYY-MM-DD HH:mm")}</>
      ),
    },
    {
      title: "공개여부",
      dataIndex: "status",
      render: (_, record) =>
        record.status ? (
          <Button
            size="small"
            type="primary"
            style={{ backgroundColor: "#4aa159", minWidth: 63 }}
            onClick={() => {
              handleUpdateStatus(record.id, !record.status);
            }}
          >
            Show
          </Button>
        ) : (
          <Button
            size="small"
            style={{ backgroundColor: "#bdbdbd", minWidth: 63 }}
            type="primary"
            onClick={() => {
              handleUpdateStatus(record.id, !record.status);
            }}
          >
            Hidden
          </Button>
        ),
    },
    {
      title: "Action",
      dataIndex: "action",
      render: (_, record) => (
        <S.ActionBtn>
          <Button onClick={() => navigate(`/brand/edit-slide/${record.key}`)}>
            Edit
          </Button>
          <Button
            onClick={() =>
              setOpenModalDelete({ isOpen: true, id: +record.key })
            }
            type="primary"
            danger
          >
            Delete
          </Button>
        </S.ActionBtn>
      ),
    },
  ];

  return (
    <S.Wrapper>
      <div className="headings">
        <h1 className="main-headings">브랜드 상단 배너 등록</h1>
        <p>
          브랜드 페이지에 상단으로 들어갈 배너 이미지를 업로드할 수 있습니다.
          (16:9 비율 권장)
        </p>
      </div>
      <S.Body>
        <S.WrapCreate>
          <Button
            icon={<Plus />}
            size="large"
            onClick={() => navigate("/brand/create-slide")}
          >
            배너 등록
          </Button>
        </S.WrapCreate>
        <DndContext modifiers={[restrictToVerticalAxis]} onDragEnd={onDragEnd}>
          <SortableContext
            items={dataSource.map((i) => i.key)}
            strategy={verticalListSortingStrategy}
          >
            <Table
              bordered
              rowKey="key"
              components={{ body: { row: Row } }}
              columns={columns}
              dataSource={dataSource}
              pagination={false}
            />
          </SortableContext>
        </DndContext>
      </S.Body>
      <Modal
        footer={false}
        open={openModalDelete.isOpen}
        onCancel={() => setOpenModalDelete({ isOpen: false })}
      >
        <S.ContentModal>
          Are you sure you want to delete this slide?
        </S.ContentModal>
        <S.FooterModal>
          <Button onClick={() => setOpenModalDelete({ isOpen: false })}>
            Cancel
          </Button>
          <Button onClick={handleDelete} type="primary" danger>
            Ok
          </Button>
        </S.FooterModal>
      </Modal>
    </S.Wrapper>
  );
};

export default SlideList;
