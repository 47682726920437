import { styled } from "styled-components";

export const Wrapper = styled.div`
  .ant-table-wrapper .ant-table-thead > tr > th {
    text-align: center;
  }
  .ant-table-wrapper .ant-table-tbody > tr > td {
    text-align: center;
  }
`;

export const Body = styled.div`
  padding: 30px 30px 0 30px;
  .ant-table {
    border-radius: 0;
    .ant-table-thead {
      > tr > th,
      td {
        &:before {
          display: none;
        }
      }
    }
  }
`;

export const Status = styled.div``;
export const WrapCreate = styled.div`
  margin-bottom: 30px;
  display: flex;
  justify-content: flex-end;
  .ant-btn {
    display: flex;
    align-items: center;
    background-color: var(--brand-color);
    color: #fff;
    border-color: var(--brand-color);
    &:hover {
      background-color: var(--brand-color) !important;
      color: #fff !important;
      border-color: var(--brand-color) !important;
    }
  }
`;

export const ActionBtn = styled.div`
  display: flex;
  justify-content: center;
  gap: 8px;
`;
export const ContentModal = styled.div`
  padding-top: 30px;
  font-size: 18px;
`;

export const FooterModal = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 8px;
  margin-top: 30px;
`;
