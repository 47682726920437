import * as S from "./style";

interface Props {
  currentStep: number;
}

function ProgressBar({ currentStep }: Props) {
  return (
    <S.ProgressContainer>
      <S.ProgressBar className={`progress-bar step-${currentStep}`} />
    </S.ProgressContainer>
  );
}

export default ProgressBar;
