import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import styled from "styled-components";

export interface TabItem {
  id: string;
  title: string;
  content: React.ReactNode;
}

interface TabProps {
  items: TabItem[];
  onChange?: (id: string) => void;
  defaultActive?: string;
  noActiveParams?: boolean;
  paramName?: string;
}

const Container = styled.div`
  width: 100%;
`;

const TabList = styled.div`
  display: flex;
  max-width: 595px;
  overflow-x: auto;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  &::-webkit-scrollbar {
    display: none; /* Chrome, Safari, Opera*/
  }
  .item-tab {
    display: flex;
    align-items: center;
  }
`;

const TabButton = styled.button<{ $active: boolean }>`
  padding: 8px 0;
  margin-bottom: -1px;
  font-size: 18px;
  line-height: 25.2px;
  outline: none;
  white-space: nowrap;
  color: black;
  font-family: ${(props) =>
    props.$active ? "var(--font-700)" : "var(--font-400)"};
  background-color: transparent;
  border: 0;
  cursor: pointer;
`;

const Divider = styled.div`
  width: 1px;
  height: 18px;
  background-color: #d9d9d9;
  margin: 0 16px;
`;

const TabContent = styled.div`
  padding-top: 16px;
  color: #4a4a4a;
`;

const TabSimple: React.FC<TabProps> = ({
  items,
  defaultActive,
  noActiveParams,
  paramName = "tab",
  onChange,
}) => {
  const [activeTab, setActiveTab] = useState<string>(items[0].id);
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    if (defaultActive) {
      setActiveTab(defaultActive);
      if (!noActiveParams) {
        searchParams.set("tab", defaultActive);
        setSearchParams(searchParams);
      }
    }
  }, [defaultActive]);

  const handleTabClick = (id: string) => {
    // console.log("id", id);

    setActiveTab(id);
    onChange?.(id);
    searchParams.set(paramName, id);
    searchParams.set("page", "1");
    setSearchParams(searchParams);
  };

  return (
    <Container>
      <TabList>
        {items.map((tab, i) => (
          <div key={tab.id} className="item-tab">
            <TabButton
              $active={activeTab === tab.id}
              onClick={() => handleTabClick(tab.id)}
            >
              {tab.title}
            </TabButton>
            {i < items.length - 1 && <Divider />}
          </div>
        ))}
      </TabList>
      <TabContent>
        {items.map((tab) =>
          activeTab === tab.id ? <div key={tab.id}>{tab.content}</div> : null
        )}
      </TabContent>
    </Container>
  );
};

export default TabSimple;
