import TabSimple, { TabItem } from "components/TabSimple";
import * as S from "../../style";
import UnExpired from "./UnExpired";
import Expired from "./Expired";

const tabItems: TabItem[] = [
  {
    id: "unExpired",
    title: "멤버십유지",
    content: <UnExpired />,
  },
  {
    id: "expired",
    title: "만료",
    content: <Expired />,
  },
];

const BrandJoin: React.FC = () => {
  return (
    <S.BrandJoin>
      <TabSimple
        paramName="joinTab"
        noActiveParams
        items={tabItems}
        defaultActive="unExpired"
      />
    </S.BrandJoin>
  );
};

export default BrandJoin;
