import { Modal, ModalProps } from "antd";
import { ReactComponent as CloseIcon } from "assets/icons/membership/close.svg";

interface Props extends ModalProps {
  handleClose: () => void;
}

const RefundInfoModal: React.FC<Props> = ({ open, handleClose }) => {
  return (
    <Modal
      onCancel={handleClose}
      width={450}
      closeIcon={null}
      open={open}
      footer={null}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          cursor: "pointer",
        }}
        onClick={handleClose}
      >
        <h2>관리자 환불</h2>
        <CloseIcon />
      </div>
      <br />
      <hr />
      <br />
      <p>
        플랫폼 정책상 환불 기준을 충족하지 않아 판매자 환불이 불가능한 경우에
        관리자 환불에서 환불을 진행할 수 있습니다.
      </p>
      <p
        style={{
          color: "#D80000",
          fontFamily: "Pretendard-600",
        }}
      >
        반드시 브랜드의 요청에 의해서만 환불을 진행해주세요.
      </p>

      <br />
    </Modal>
  );
};
export default RefundInfoModal;
