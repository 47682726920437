// Trong components/Table.tsx
import { Form, Input, InputRef, message } from "antd";
import DaumPostcode from "react-daum-postcode";
import { useNavigate, useSearchParams } from "react-router-dom";
import * as S from "./style";

import { ReactComponent as BankIcon } from "assets/icons/Bank.svg";
import { ReactComponent as CompassIcon } from "assets/icons/Compass.svg";
import { ReactComponent as EmailIcon } from "assets/icons/Email.svg";
import { ReactComponent as FileTextIcon } from "assets/icons/FileText.svg";
import { ReactComponent as MapPinLineIcon } from "assets/icons/MapPinLine.svg";
import { ReactComponent as PhoneIcon } from "assets/icons/Phone.svg";
import { ReactComponent as StorefrontIcon } from "assets/icons/Storefront.svg";
import { ReactComponent as TreeStructureIcon } from "assets/icons/TreeStructure.svg";
import { ReactComponent as UserListIcon } from "assets/icons/UserList.svg";
import { ReactComponent as UserIcon } from "assets/icons/Users-1.svg";

import ButtonComp from "components/ButtonCmp";

import { CloseOutlined, LoadingOutlined } from "@ant-design/icons";
import { DefaultOptionType } from "antd/es/select";
import { createBrandApi } from "api/brand";
import { fetchCategories } from "api/category";
import { uploadSingleFile } from "api/uploadSingleFile";
import SelectCmp from "components/SelectCmp";
import UploadImage from "components/UploadImage";
import { useEffect, useRef, useState } from "react";
import dataBankName from "../bankList";
import { AccountInfoStorage } from "./Step1";
import SingleFileUpload from "components/SingleFileUpload";

interface Props {}

const CreateBrandAccountStep2: React.FC<Props> = () => {
  const brandNameRef = useRef<InputRef>(null);
  const [searchParams, setSearchParams] = useSearchParams();
  const [messageApi, contextHolder] = message.useMessage({ maxCount: 2 });
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [listCategory, setListCategory] = useState<DefaultOptionType[]>([]);
  const [openPopupAddress, setopenPopupAddress] = useState<boolean>(false);
  const [openCompanyAddress, setOpenCompanyAddress] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const errorMess = (mess: string) => {
    messageApi.open({
      type: "error",
      content: mess,
    });
  };

  const getAllCategory = async () => {
    try {
      const { data }: any = await fetchCategories();
      if (data) {
        const formatData = data.map((item: any) => {
          const newItem: DefaultOptionType = {
            label: item.korName,
            value: item.id,
          };
          return newItem;
        });
        setListCategory(formatData);
      } else {
        setListCategory([]);
      }
    } catch (error) {
      console.log(error);
      setListCategory([]);
    }
  };

  const handleCompleteAddress = (data: any) => {
    // console.log(data);
    let fullAddress = data.address;
    let extraAddress = "";

    if (data.addressType === "R") {
      if (data.bname !== "") {
        extraAddress += data.bname;
      }
      if (data.buildingName !== "") {
        extraAddress +=
          extraAddress !== "" ? `, ${data.buildingName}` : data.buildingName;
      }
      fullAddress += extraAddress !== "" ? ` (${extraAddress})` : "";
    }

    form.setFieldsValue({
      brandAddress: fullAddress,
      zoneCode: data.zonecode,
    });
    // setAddressValue(fullAddress);
  };
  const handleCompleteCompanyAddress = (data: any) => {
    // console.log(data);
    let fullAddress = data.address;
    let extraAddress = "";

    if (data.addressType === "R") {
      if (data.bname !== "") {
        extraAddress += data.bname;
      }
      if (data.buildingName !== "") {
        extraAddress +=
          extraAddress !== "" ? `, ${data.buildingName}` : data.buildingName;
      }
      fullAddress += extraAddress !== "" ? ` (${extraAddress})` : "";
    }

    form.setFieldsValue({
      companyAddress: fullAddress,
    });
    // setAddressValue(fullAddress);
  };
  const handleCancel = () => {
    form.resetFields();
    searchParams.set("step", "1");
    setSearchParams(searchParams);
  };
  const onFinish = async (values: any) => {
    console.log("Success:", values);
    let imgUrl: string =
      "https://personal-membership.s3.ap-northeast-2.amazonaws.com/1718352781417_brand-logo.jpg";
    let businessLicenseUrl: string = "";
    let bankImageUrl: string = "";
    const userInfoStorage: AccountInfoStorage | null = JSON.parse(
      localStorage.getItem("accountInfo") || "null"
    );

    if (values.avatar) {
      const formData = new FormData();
      formData.append("file", values.avatar);
      try {
        const resp: any = await uploadSingleFile(formData);
        if (resp?.imageUrl) {
          imgUrl = resp.imageUrl;
        }
        if (resp.error && resp.error.message !== "file not found") {
          errorMess("Upload image failed");
          return;
        }
      } catch (error) {
        console.log(error);
        errorMess("Upload image failed");
        return;
      }
    }

    if (values.businessLicense && typeof values.businessLicense !== "string") {
      const businessLicenseFormData = new FormData();
      businessLicenseFormData.append("file", values?.businessLicense);
      const businessLicenseResp: any = await uploadSingleFile(
        businessLicenseFormData
      );

      businessLicenseUrl = businessLicenseResp?.imageUrl || undefined;
    }
    if (values.businessLicense && typeof values.bankImage !== "string") {
      const bankImageFormData = new FormData();
      bankImageFormData.append("file", values?.bankImage);
      const bankImageResp: any = await uploadSingleFile(bankImageFormData);
      bankImageUrl = bankImageResp?.imageUrl || undefined;
    }

    if (userInfoStorage) {
      try {
        const { data, error }: any = await createBrandApi({
          logoImage: imgUrl,
          userName: userInfoStorage?.userName,
          email: userInfoStorage?.email,
          manager: userInfoStorage?.manager,
          password: userInfoStorage?.password,
          phoneNumber: userInfoStorage?.phone.replace(/-/g, ""),
          brandName: values.brandName,
          categoryId: +values.categoryId,
          brandHotline: values.brandHotline.replace(/-/g, ""),
          cSEmail: values.csEmail,
          brandAddress: values.brandAddress,
          postCode: values.zoneCode,
          brandAddressDescription: values.moreAddress,
          companyName: values.companyName,
          representative: values.representative,
          companyAddress: values.companyAddress,
          companyPhoneNumber: values.companyPhoneNumber.replace(/-/g, ""),
          companyEmail: values.companyEmail,
          bankName: values.bankName,
          bankAccountName: values.bankAccountName,
          bankAccountNumber: values.bankAccountNumber,
          businessLicense: businessLicenseUrl,
          bankImage: bankImageUrl,
        });
        if (data) {
          navigate("/users/brand-user");
        }
        if (error) {
          errorMess("Create Failed!");
          console.error(error);
        }
        setLoading(false);
      } catch (error) {
        errorMess("Create Failed!");
        setLoading(false);
        console.error(error);
      }
    }

    searchParams.set("step", "3");
    setSearchParams(searchParams);
  };

  const onFinishFailed = (errorInfo: any) => {
    // console.log("Failed:", errorInfo);
    brandNameRef.current!.focus({
      cursor: "end",
    });
  };

  useEffect(() => {
    if (!localStorage.getItem("accountInfo")) {
      searchParams.set("step", "1");
      setSearchParams(searchParams);
    }
    getAllCategory();
  }, []);

  return (
    <>
      {contextHolder}
      <S.ContentHeader>
        <h1>브랜드 계정 생성 (2/2)</h1>
        <p>회원들에게 어필할 정보를 작성하세요</p>
      </S.ContentHeader>
      <S.ContentBody>
        <Form
          form={form}
          name="create"
          onFinish={(e) => {
            setLoading(true);
            onFinish(e);
          }}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          <S.FieldContainer>
            <h2>브랜드 정보</h2>
            <h3>브랜드 로고 및 브랜드명</h3>

            <Form.Item
              name="avatar"
              // rules={[{ required: true, message: "Please choose avatar!" }]}
            >
              <UploadImage
                onImageChange={(file: File) => {
                  form.setFieldsValue({ avatar: file });
                }}
              />
            </Form.Item>

            <Form.Item
              name="brandName"
              rules={[
                { required: true, message: "Please input brand name!" },
                {
                  min: 2,
                  message: "Please enter at least 2 characters",
                },
                {
                  pattern: /^[a-zA-Z0-9\s-_]+$/,
                  message:
                    "ID 생성은 알파벳, 숫자, -(하이픈), _(언더바)으로 가능합니다.",
                },
              ]}
            >
              <Input
                ref={brandNameRef}
                prefix={<StorefrontIcon />}
                placeholder="브랜드명"
                className="form-input"
                onBlur={(e) =>
                  form.setFieldsValue({
                    brandName: e.target.value.trim().replace(/\s+/g, " "),
                  })
                }
              />
            </Form.Item>

            <Form.Item
              name="categoryId"
              rules={[
                {
                  required: true,
                  message: "Please select category!",
                },
              ]}
            >
              <SelectCmp
                options={listCategory}
                placeholder="카테고리 선택"
                className="form-input form-select"
                prefix={<TreeStructureIcon />}
                style={{ height: "46px", borderColor: "#d9d9d9" }}
              />
            </Form.Item>

            <S.FieldPair>
              <Form.Item
                name="brandHotline"
                rules={[
                  {
                    required: true,
                    message: "Please input Brand hotline!",
                  },
                ]}
              >
                <Input
                  prefix={<PhoneIcon />}
                  placeholder="고객센터 연락처"
                  className="form-input"
                  onChange={(e) => {
                    // const phoneNumber = e.target.value.replace(/\D/g, "");
                    // let formattedPhoneValue = "";
                    // for (let i = 0; i < phoneNumber.length; i++) {
                    //   if (i === 3 || i === 7) {
                    //     formattedPhoneValue += "-";
                    //   }
                    //   if (i < 11) {
                    //     formattedPhoneValue += phoneNumber[i];
                    //   }
                    // }
                    form.setFieldValue(
                      "brandHotline",
                      e.target.value.replace(/[^0-9-]/g, "")
                    );
                  }}
                />
              </Form.Item>

              <Form.Item
                name="csEmail"
                rules={[
                  { required: true, message: "Please input CS Email!" },
                  {
                    type: "email",
                    message:
                      "Please enter a valid email address. Ex: abc@xyz.kr",
                  },
                ]}
              >
                <Input
                  prefix={<EmailIcon />}
                  placeholder="고객센터 이메일"
                  className="form-input"
                />
              </Form.Item>
            </S.FieldPair>
            <S.BrandLocation>
              <h3>매장 주소 정보</h3>
              {/* <a
                href="#"
                onClick={(e) => {
                  e.preventDefault();
                  setopenPopupAddress(true);
                }}
              >
                <CompassIcon />
                <span>Search on map</span>
              </a> */}
            </S.BrandLocation>
            <S.AddressContainer>
              <Form.Item
                name="brandAddress"
                // rules={[{ required: true, message: "Please input address!" }]}
              >
                <Input
                  // value={addressValue}
                  prefix={<MapPinLineIcon />}
                  placeholder="매장 주소"
                  className="form-input"
                  onFocus={() => setopenPopupAddress(true)}
                  // onBlur={()=>setopenPopupAddress(false)}
                />
              </Form.Item>
              <S.OverLayout
                onClick={() => setopenPopupAddress(false)}
                className={openPopupAddress ? "active" : "sss"}
              />
              {openPopupAddress && (
                <S.WrPopupAdress>
                  <div
                    className="close-popup-adress"
                    onClick={() => setopenPopupAddress(false)}
                  >
                    <CloseOutlined />
                  </div>
                  <DaumPostcode
                    onComplete={handleCompleteAddress}
                    onClose={() => setopenPopupAddress(false)}
                  />
                </S.WrPopupAdress>
              )}
            </S.AddressContainer>
            <Form.Item>
              <Form.Item
                name="zoneCode"
                style={{
                  width: "calc(50% - 7.5px)",
                  marginRight: "7.5px",
                  float: "left",
                }}
                // rules={[{ required: true, message: "Please input post code!" }]}
              >
                <Input
                  disabled={true}
                  prefix={<MapPinLineIcon />}
                  placeholder="우편번호"
                  className="form-input"
                />
              </Form.Item>
              <Form.Item
                name="moreAddress"
                style={{
                  width: "calc(50% - 7.5px)",
                  marginLeft: "7.5px",
                  float: "right",
                }}
              >
                <Input
                  prefix={<MapPinLineIcon />}
                  placeholder="상세 주소"
                  className="form-input"
                  onBlur={(e) =>
                    form.setFieldsValue({
                      moreAddress: e.target.value.trim().replace(/\s+/g, " "),
                    })
                  }
                />
              </Form.Item>
            </Form.Item>
          </S.FieldContainer>

          <S.FieldContainer>
            <h2>회사 정보</h2>
            <h3>회사 상세 정보</h3>
            <Form.Item
              name="companyName"
              rules={[
                { required: true, message: "Please input company name!" },
                {
                  min: 2,
                  message: "Please enter at least 2 characters",
                },
              ]}
            >
              <Input
                prefix={<FileTextIcon />}
                placeholder="회사명"
                className="form-input"
                onBlur={(e) =>
                  form.setFieldsValue({
                    companyName: e.target.value.trim().replace(/\s+/g, " "),
                  })
                }
              />
            </Form.Item>

            <Form.Item
              name="representative"
              rules={[
                {
                  required: true,
                  message: "Please input representative!",
                },
                {
                  min: 2,
                  message: "Please enter at least 2 characters",
                },
              ]}
            >
              <Input
                prefix={<UserIcon />}
                placeholder="대표자"
                className="form-input"
                onBlur={(e) =>
                  form.setFieldsValue({
                    representative: e.target.value.trim().replace(/\s+/g, " "),
                  })
                }
              />
            </Form.Item>

            <S.AddressContainer>
              <Form.Item
                name="companyAddress"
                rules={[
                  {
                    required: true,
                    message: "Please input company address!",
                  },
                ]}
              >
                <Input
                  prefix={<MapPinLineIcon />}
                  placeholder="회사 주소"
                  className="form-input"
                  onFocus={() => setOpenCompanyAddress(true)}
                />
              </Form.Item>
              <S.OverLayout
                onClick={() => setOpenCompanyAddress(false)}
                className={openCompanyAddress ? "active" : "sss"}
              />
              {openCompanyAddress && (
                <S.WrPopupAdress>
                  <div
                    className="close-popup-adress"
                    onClick={() => setOpenCompanyAddress(false)}
                  >
                    <CloseOutlined />
                  </div>
                  <DaumPostcode
                    onComplete={handleCompleteCompanyAddress}
                    onClose={() => setOpenCompanyAddress(false)}
                  />
                </S.WrPopupAdress>
              )}
            </S.AddressContainer>

            <S.FieldPair>
              <Form.Item
                name="companyPhoneNumber"
                rules={[
                  { required: true, message: "Please input phone number!" },
                ]}
              >
                <Input
                  prefix={<PhoneIcon />}
                  placeholder="회사 연락처"
                  className="form-input"
                  onChange={(e) => {
                    form.setFieldValue(
                      "companyPhoneNumber",
                      e.target.value.replace(/[^0-9-]/g, "")
                    );
                  }}
                />
              </Form.Item>

              <Form.Item
                name="companyEmail"
                rules={[
                  { required: true, message: "Please input email!" },
                  {
                    type: "email",
                    message:
                      "Please enter a valid email address. Ex: abc@xyz.kr",
                  },
                ]}
              >
                <Input
                  prefix={<EmailIcon />}
                  placeholder="회사 이메일"
                  className="form-input"
                />
              </Form.Item>
            </S.FieldPair>

            <h3>정산 정보</h3>

            <S.FieldPair>
              <Form.Item name="bankName">
                <SelectCmp
                  options={dataBankName}
                  placeholder="은행명"
                  className="form-input form-select"
                  prefix={<BankIcon />}
                  style={{ height: "46px", borderColor: "#d9d9d9" }}
                />
              </Form.Item>

              <Form.Item
                name="bankAccountName"
                rules={[
                  {
                    min: 2,
                    message: "Please enter at least 2 characters",
                  },
                ]}
              >
                <Input
                  prefix={<UserIcon />}
                  placeholder="예금주"
                  className="form-input"
                  onBlur={(e) =>
                    form.setFieldsValue({
                      bankAccountName: e.target.value
                        .trim()
                        .replace(/\s+/g, " "),
                    })
                  }
                />
              </Form.Item>
            </S.FieldPair>

            <Form.Item
              name="bankAccountNumber"
              rules={[
                {
                  pattern: /^[0-9]*$/,
                  message: "Only enter numbers",
                },
              ]}
            >
              <Input
                prefix={<UserListIcon />}
                placeholder="계좌번호"
                className="form-input"
                onChange={(e) => {
                  const formatValue = e.target.value.replace(/\D/g, "");
                  form.setFieldValue("bankAccountNumber", formatValue);
                }}
              />
            </Form.Item>

            <Form.Item name="businessLicense">
              <SingleFileUpload buttonLabel="사업자등록증 업로드" />
            </Form.Item>
            <Form.Item name="bankImage">
              <SingleFileUpload buttonLabel="통장사본 업로드" />
            </Form.Item>
          </S.FieldContainer>

          <S.ButtonContainer>
            <ButtonComp onClick={handleCancel} status="noBackground">
              이전 단계
            </ButtonComp>
            {loading ? (
              <ButtonComp>
                <LoadingOutlined />
              </ButtonComp>
            ) : (
              <ButtonComp disabled={loading} htmlType="submit">
                브랜드 생성
              </ButtonComp>
            )}
          </S.ButtonContainer>
        </Form>
      </S.ContentBody>
    </>
  );
};

export default CreateBrandAccountStep2;
