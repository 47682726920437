import React, { useEffect, useState } from "react";
import { PlusOutlined } from "@ant-design/icons";
import { Modal, Upload } from "antd";
import type { RcFile, UploadProps } from "antd/es/upload";
import type { UploadFile } from "antd/es/upload/interface";
import { v4 as uuidv4 } from "uuid";

interface Props {
  onChange?: (files: File[]) => void;
  initData?: string[];
}

const getBase64 = (file: RcFile): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = (error) => reject(error);
  });

const UploadMultiImage: React.FC<Props> = ({ onChange, initData }) => {
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  // const [previewTitle, setPreviewTitle] = useState("");
  const [fileList, setFileList] = useState<UploadFile[]>([]);

  useEffect(() => {
    if (initData) {
      const results: UploadFile[] = initData?.map((item: string) => {
        if (typeof item === "string") {
          return {
            uid: uuidv4(),
            name: item,
            status: "done",
            url: item,
          };
        } else {
          return item;
        }
      });
      setFileList(results);
    }
  }, [initData]);

  const handleCancel = () => setPreviewOpen(false);

  const handleChange: UploadProps["onChange"] = ({ fileList: newFileList }) => {
    if (onChange) {
      const results = newFileList?.map((item: any) => {
        return item;
      });
      onChange(results);
      setFileList(results);
    }
  };

  const handlePreview = async (file: UploadFile) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj as RcFile);
    }

    setPreviewImage(file.url || file.preview || "");
    // setPreviewTitle(
    //   file.name || file.url?.substring(file.url.lastIndexOf("/") + 1) || ""
    // );
    setPreviewOpen(true);
  };

  const uploadButton = (
    <div>
      <PlusOutlined />
      <div style={{ marginTop: 8 }}>이미지 업로드</div>
    </div>
  );
  return (
    <>
      <Upload
        accept="image/png, image/jpeg"
        customRequest={() => false}
        listType="picture-card"
        fileList={fileList}
        beforeUpload={(file) => {
          const type = file.type;
          if (type.includes("image")) {
            setFileList([...fileList, file]);
          }
          return false;
        }}
        onChange={handleChange}
        onPreview={handlePreview}
      >
        {fileList.length >= 8 ? null : uploadButton}
      </Upload>
      <Modal
        open={previewOpen}
        title="Preview"
        footer={null}
        onCancel={handleCancel}
      >
        <img alt="example" style={{ width: "100%" }} src={previewImage} />
      </Modal>
    </>
  );
};

export default UploadMultiImage;
