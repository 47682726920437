import TabSimple, { TabItem } from "components/TabSimple";
import React from "react";
import * as S from "../../style";
import NormalTable from "./NormalTable";
import PremiumTable from "./PremiumTable";
// import PremiumLog from "./PremiumLog";

const OrderHistory: React.FC = () => {
  const tabItems: TabItem[] = [
    {
      id: "normal",
      title: "Normal",
      content: <NormalTable />,
    },
    {
      id: "premium",
      title: "Premium",
      content: <PremiumTable />,
    },
    // {
    //   id: "premium_log",
    //   title: "프리미엄 추가",
    //   content: <PremiumLog />,
    // },
  ];

  return (
    <S.OrderHistory>
      <TabSimple
        paramName="orderTab"
        noActiveParams
        items={tabItems}
        defaultActive="normal"
      />
    </S.OrderHistory>
  );
};

export default OrderHistory;
