import React, { useEffect, useState } from "react";
import * as S from "./style";
import PickTime, { TimeType } from "./components/PickTime";
import MultiCheckbox from "components/MultiCheckbox";
import { CloseOutlined } from "@ant-design/icons";
import { Select } from "antd";
import Amount from "./components/Amount";
import OrderHistoryTable from "./components/OrderHistoryTable";
import {
  GetOrderMembershipHistoryApi,
  getOrderMembershipHistoryApi,
  PaymentStatusType,
} from "api/membership";
import { useSearchParams } from "react-router-dom";

type Option = {
  label: string;
  value: PaymentStatusType;
};

type DataTableType = {
  summary: any;
  data: any[];
  totalElement: number;
};

const paymentStatusOptions: Option[] = [
  { label: "전체", value: "ALL" },
  {
    label: "결제완료",
    value: "PAID",
  },
  {
    label: "결제최소",
    value: "CANCEL",
  },
  {
    label: "구매확정",
    value: "CLOSE",
  },
  {
    label: "맴버십 이용",
    value: "USED",
  },
  {
    label: "환불요청",
    value: "REQUESTED",
  },
  {
    label: "환불완료",
    value: "REFUNDED",
  },
];

const MembershipOrderHistory: React.FC = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [filter, setFilter] = useState<GetOrderMembershipHistoryApi>({
    timeFilter: "WEEK",
    queryType: "email",
    querySearch: "",
    paymentStatus: paymentStatusOptions.map((el: Option) => el.value),
  });
  const [isFilterChanged, setIsFilterChanged] = useState<boolean>(false);
  const [sort, setSort] = useState<"asc" | "desc">("desc");
  const [dataTable, setDataTable] = useState<DataTableType>({
    summary: {},
    data: [],
    totalElement: 0,
  });
  const [filterDate, setFilterDate] = useState<{
    startDate?: string;
    endDate?: string;
  }>({
    startDate: "",
    endDate: "",
  });

  const getOrderMembershipHistory = async (payload?: any) => {
    const paymentStatus = filter.paymentStatus?.filter(
      (s: PaymentStatusType) => s !== "ALL"
    );

    const { error, data }: any = await getOrderMembershipHistoryApi({
      size: 10,
      page: Number(searchParams.get("page")) || 1,
      timeFilter: filter.timeFilter,
      queryType: filter.queryType,
      querySearch: filter.querySearch,
      startDate: filter.startDate,
      endDate: filter.endDate,
      transactionDateSort: sort,
      paymentStatus,
      ...payload,
    });
    if (error) {
      console.error(error);
      if (error.errorCode === "gb_404") {
        setDataTable({
          summary: {},
          data: [],
          totalElement: 0,
        });
      }
      return;
    }
    setDataTable({
      summary: data?.datas?.summary,
      data: data?.datas?.historyList,
      totalElement: data?.pageable?.totalElement,
    });
  };

  const defaultFilter = () => {
    const defaultPayload: GetOrderMembershipHistoryApi = {
      timeFilter: "WEEK",
      queryType: "email",
      querySearch: "",
      paymentStatus: paymentStatusOptions.map((el: Option) => el.value),
    };
    searchParams.set("page", "1");
    setSearchParams(searchParams);
    setFilter(defaultPayload);
    setFilterDate({ startDate: "", endDate: "" });
    setIsFilterChanged(false);
    getOrderMembershipHistory(defaultPayload);
  };

  useEffect(() => {
    getOrderMembershipHistory();
  }, [searchParams]);

  useEffect(() => {
    if (filterDate.startDate && filterDate.endDate) {
      setFilter((prev: GetOrderMembershipHistoryApi) => ({
        ...prev,
        startDate: filterDate.startDate,
        endDate: filterDate.endDate,
      }));
    } else {
      setFilter((prev: GetOrderMembershipHistoryApi) => ({
        ...prev,
        startDate: undefined,
        endDate: undefined,
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterDate]);

  useEffect(() => {
    const isPaymentStatusDefault = paymentStatusOptions.every((option) =>
      filter?.paymentStatus?.includes(option.value)
    );

    const isChanged: boolean =
      filter.timeFilter !== "WEEK" ||
      !isPaymentStatusDefault ||
      filter.queryType !== "email" ||
      filter.querySearch !== "";

    setIsFilterChanged(isChanged);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter]);

  return (
    <S.Wrapper>
      <div className="headings">
        <h1 className="main-headings">멤버십 주문 내역</h1>
        <p className="sub-heading">멤버십 주문 내역을 조회할 수 있습니다.</p>
      </div>
      <S.Body>
        <S.Filter>
          <div className="line mb">
            <div className="block-left">결제일</div>
            <div className="block-right">
              <PickTime
                defaultTimeType={filter.timeFilter}
                onChange={(t, d) => {
                  setFilter((prev: GetOrderMembershipHistoryApi) => ({
                    ...prev,
                    timeFilter: t,
                    startDate: d?.start,
                    endDate: d?.end,
                  }));
                }}
              />
            </div>
          </div>
          <div className="line mb">
            <div className="block-left">결제상태</div>
            <div className="block-right">
              <MultiCheckbox
                defaultValues={filter.paymentStatus}
                options={paymentStatusOptions}
                onChange={(v) =>
                  setFilter((prev: GetOrderMembershipHistoryApi) => ({
                    ...prev,
                    paymentStatus: v as PaymentStatusType[],
                  }))
                }
              />
            </div>
          </div>
          {/* <div className="line mb">
            <div className="block-left">구매상태</div>
            <div className="block-right">
              <MultiCheckbox
                defaultValues={filter.orderStatus}
                options={orderStatusOptions}
                onChange={(v) =>
                  setFilter((prev: GetOrderMembershipHistoryApi) => ({
                    ...prev,
                    orderStatus: v,
                  }))
                }
              />
            </div>
          </div> */}
          <div className="line">
            <div className="block-left">상세 조건</div>
            <div className="block-right flex">
              <div className="wr-select">
                <Select
                  popupClassName="custom-select-dropdown"
                  defaultValue="email"
                  value={filter.queryType}
                  style={{ width: 125 }}
                  onChange={(value) =>
                    setFilter((prev: GetOrderMembershipHistoryApi) => ({
                      ...prev,
                      queryType: value as "email" | "id",
                    }))
                  }
                  options={[
                    { value: "email", label: "이메일계정" },
                    { value: "id", label: "주문번호" },
                  ]}
                />
              </div>
              <div className="wr-input">
                <input
                  value={filter.querySearch}
                  onChange={(e) => {
                    setFilter((prev: GetOrderMembershipHistoryApi) => ({
                      ...prev,
                      querySearch: e.target.value.trim(),
                    }));
                  }}
                />
                {filter?.querySearch && (
                  <button
                    onClick={() =>
                      setFilter((prev: GetOrderMembershipHistoryApi) => ({
                        ...prev,
                        querySearch: "",
                      }))
                    }
                  >
                    <CloseOutlined />
                  </button>
                )}
              </div>
            </div>
          </div>
          <div className="wr-btn">
            {isFilterChanged && (
              <button
                onClick={() => {
                  // setIsFilterChanged(false);
                  defaultFilter();
                }}
                className="clear-btn"
              >
                Clear
              </button>
            )}
            <button
              onClick={() => getOrderMembershipHistory()}
              className="filter-btn"
            >
              검색
            </button>
          </div>
        </S.Filter>
        {/* Amount ---------------------------------- */}
        <Amount data={dataTable.summary} />
        {/* Sort ---------------------------------- */}
        <S.Sort>
          <Select
            // popupClassName="custom-select-dropdown"
            // defaultValue="email"
            value={sort}
            style={{ width: 180 }}
            onChange={(value: "asc" | "desc") => setSort(value)}
            options={[
              { label: "결제일 최신순", value: "desc" },
              { label: "결제일 오래된순", value: "asc" },
            ]}
          />
        </S.Sort>
        <S.WrapTable>
          <OrderHistoryTable
            data={dataTable?.data || []}
            totalElement={dataTable?.totalElement || 0}
            getOrderMembershipHistory={getOrderMembershipHistory}
          />
        </S.WrapTable>
      </S.Body>
    </S.Wrapper>
  );
};

export default MembershipOrderHistory;
