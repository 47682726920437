import { Select } from "antd";
import { getBrandHasPurchaseApi } from "api/brand";
import { useEffect, useMemo, useState } from "react";
import * as S from "./style";

interface Props {
  getBrand?: (brand: any) => void;
  required?: boolean;
  setIsRequired: React.Dispatch<React.SetStateAction<boolean>>;
}

const BrandHasPurchase: React.FC<Props> = ({
  getBrand,
  required,
  setIsRequired,
}) => {
  const [listBrand, setListBrand] = useState<any>([]);
  const getBrandHasPurchase = async (q?: string) => {
    const { data, error }: any = await getBrandHasPurchaseApi({
      size: 999999999,
      page: 1,
      querySearch: q,
    });
    if (error) {
      console.error(error);
      return;
    }
    setListBrand(data?.datas);
  };

  const brandFormat = useMemo(() => {
    return listBrand.map((el: any) => ({
      label: (
        <div style={{ color: el.progress === "INCOMING" ? "red" : undefined }}>
          {el.brandName}
        </div>
      ),
      value: el.brandId,
    }));
  }, [listBrand]);

  const onChange = (value: string) => {
    // console.log(`selected ${value}`);
    value && setIsRequired(false);
    getBrand?.(value);
    const brand = listBrand.find((el: any) => el.brandId === value);
    getBrand?.(brand);
  };

  const onSearch = (value: string) => {
    console.log("search:", value);
  };

  useEffect(() => {
    getBrandHasPurchase();
  }, []);

  return (
    <S.Wrapper className={required ? "required" : undefined}>
      <Select
        showSearch
        placeholder="Select brand"
        optionFilterProp="label"
        onChange={onChange}
        onSearch={onSearch}
        options={brandFormat}
        style={{
          width: 150,
          textAlign: "left",
        }}
      />
    </S.Wrapper>
  );
};
export default BrandHasPurchase;
