import type { ColumnsType } from "antd/es/table";
import { ReactComponent as CaretRightIcon } from "assets/icons/CaretRight.svg";
import ButtonComp from "components/ButtonCmp";
import CsTable from "components/Table";
import { useNavigate, useSearchParams } from "react-router-dom";
import * as S from "./style";
import dayjs from "dayjs";
import CsInput from "components/Input";
import { useCallback, useEffect, useState } from "react";
import { debounce } from "utils/debounce";
import { DefaultOptionType } from "antd/es/select";
import { getListUserDeleteApi } from "api/user";
import SelectCmp from "components/SelectCmp";

interface TableProps {
  getCountDeleteRequest: (querySearch?: any, isActive?: 1 | 0) => Promise<void>;
}

const DeleteAccountList: React.FC<TableProps> = ({ getCountDeleteRequest }) => {
  const navigate = useNavigate();
  const [searchParam, setSearchParam] = useSearchParams();
  const [searchParams, setSearchParams] = useSearchParams();
  // const [textSearch, setTextSearch] = useState<string>("");
  // const debounceSearch = useDebounce<string>(textSearch, 500);
  const [dataTable, setDataTable] = useState<any>([]);
  const [totalItem, setTotalItem] = useState<number>(0);
  const [searchValue, setSearchValue] = useState<string>("");
  const [filter, setFilter] = useState<{
    querySearch?: any;
    isActive?: 1 | 0;
  }>({});
  // console.log(status);

  const getListUser = async () => {
    try {
      const { data }: any = await getListUserDeleteApi({
        querySearch: filter?.querySearch?.trim(),
        isActive: filter.isActive,
        size: 10,
        page: Number(searchParams.get("page")) || 1,
      });
      if (data) {
        setDataTable(data.datas || []);
        if (data.datas) {
          setTotalItem(+data?.pageable?.totalElement);
        } else {
          setTotalItem(0);
        }
      }
    } catch (error) {
      console.error(error);
    }
  };
  // console.log('searchValue', searchValue);

  const handleSearch = useCallback(
    debounce((value: string) => {
      setFilter((prev: any) => {
        return { ...prev, querySearch: value };
      });
      const params = {
        isActive: filter.isActive,
        querySearch: value,
      };

      getCountDeleteRequest(params);
      searchParams.set("page", "1");
      setSearchParams(searchParams);
    }, 1000),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  useEffect(() => {
    if (searchParam.get("tab") === "delete_account") {
      getListUser();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter, searchParam]);

  const statusOptions: DefaultOptionType[] | undefined = [
    {
      label: "비활성",
      value: "inactive",
    },
    {
      label: "활성",
      value: "active",
    },
  ];

  const columns: ColumnsType<any> = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "회원명",
      dataIndex: "accName",
      key: "accName",
      render: (_, record) => (
        <S.TdTextInner>
          <p className="big-text">{record.displayName}</p>
          <p className="small-text">{record.userName}</p>
        </S.TdTextInner>
      ),
    },
    {
      title: "연락처/이메일",
      dataIndex: "accInfo",
      key: "accInfo",
      render: (_, record) => (
        <S.TdTextInner>
          <p className="big-text">{record?.verifiedPhoneNumber}</p>
          <p className="small-text">{record?.email}</p>
        </S.TdTextInner>
      ),
    },
    {
      title: "상태",
      dataIndex: "status",
      key: "status",
      render: (_, record) => (
        <ButtonComp status={record.active ? "active" : "inactive"}>
          {record.active ? "활성" : "비활성"}
        </ButtonComp>
      ),
    },
    {
      title: "접속 이력",
      dataIndex: "history",
      key: "history",
      render: (_, record) => (
        <S.TdTextInner>
          <p className="big-text">
            마지막 로그인:{" "}
            {record?.lastLogin === null
              ? "-"
              : dayjs(record?.lastLogin).format("YYYY/MM/DD")}
          </p>
          <p className="small-text">
            총 로그인 횟수: {record?.loginCount} times
          </p>
        </S.TdTextInner>
      ),
    },
    {
      title: "가입 정보",
      dataIndex: "requestDate",
      key: "requestDate",
      render: (_, record) => (
        <S.TdTextInner>
          <p className="big-text">
            {dayjs(record.requestLeaveAt).format("YYYY/MM/DD")}
          </p>
          <p className="small-text">
            {dayjs(record.requestLeaveAt).format("HH:mm:ss")}
          </p>
        </S.TdTextInner>
      ),
    },
    {
      title: <></>,
      dataIndex: "viewDetail",
      key: "viewDetail",
      render: (_, record) => (
        <S.ViewDetailBtn onClick={() => navigate(`list-delete/${record.id}`)}>
          <CaretRightIcon />
        </S.ViewDetailBtn>
      ),
    },
  ];

  // if (!dataTable.length) {
  //   return <S.NoData>There is no delete request</S.NoData>;
  // }

  return (
    <>
      <div className="filter-container">
        <CsInput
          placeholder="회원명/연락처/이메일 검색"
          // onChange={(e) => setTextSearch(e.target.value)}
          onChange={(e) => {
            const inputValue: string = e.target.value;
            const cleanedValue = inputValue.replace(/\s+/g, " ");
            if (cleanedValue !== " ") {
              setSearchValue(cleanedValue);
              handleSearch(cleanedValue);
            }
          }}
          value={searchValue}
        />
        <SelectCmp
          style={{
            width: 200,
          }}
          options={statusOptions}
          placeholder="계정 상태"
          onChange={(value: any) => {
            setFilter((prev: any) => ({
              ...prev,
              isActive:
                value === undefined ? undefined : value === "active" ? 1 : 0,
            }));
            const params = {
              querySearch: filter.querySearch,
              isActive: value === "active" ? 1 : 0,
            };
            getCountDeleteRequest(params);
            searchParams.set("page", "1");
            setSearchParams(searchParams);
          }}
        />
      </div>
      {dataTable.length ? (
        <S.TableContainer>
          <CsTable
            columns={columns}
            dataSource={dataTable}
            pagination={
              totalItem >= 10
                ? {
                    current: Number(searchParam.get("page")) || 1,
                    total: totalItem,
                    showSizeChanger: false,
                    onChange: (page) => {
                      searchParam.set("page", page.toString());
                      setSearchParam(searchParam);
                    },
                  }
                : false
            }
          />
        </S.TableContainer>
      ) : (
        <S.NoData>There is no delete request</S.NoData>
      )}
    </>
  );
};

export default DeleteAccountList;
