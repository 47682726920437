import type { ColumnsType, TableProps } from "antd/es/table";
import type { AnyObject } from "antd/es/_util/type";
import * as S from "./style";

type Props = {
  columns: ColumnsType<any>;
  dataSource: any;
} & TableProps<AnyObject>;

const CsTable: React.FC<Props> = (props) => {
  const { columns, dataSource } = props;
  return (
    <S.TableStyle
      {...props}
      columns={columns}
      dataSource={dataSource.map((x: any, i: number) => ({
        ...x,
        key: x.key ? x.key : i,
      }))}
    />
  );
};

export default CsTable;
