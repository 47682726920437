import { styled } from "styled-components";

export const Wrapper = styled.div`
  height: 100%;
  background-color: var(--sub-background);
  .total {
    margin-top: 60px;
    margin-bottom: 20px;
  }
`;

export const Heading = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  padding: 0 30px;
  gap: 10px;
  .sub-heading {
    font-size: 16px;
    line-height: normal;
    font-family: var(--font-500);
  }
`;

export const Body = styled.div`
  margin-top: 50px;
  padding: 0 30px;
`;

export const MonthPicker = styled.div`
  display: flex;
  gap: 28px;
  > button {
    border: 0;
    cursor: pointer;
    background-color: transparent;
  }
  > div {
    display: flex;
    gap: 5px;
    align-items: center;
    > span {
      font-size: 13px;
      font-family: var(--font-500);
      color: #15a0a1;
      display: block;
      height: 17px;
      padding-left: 4px;
      padding-right: 4px;
      line-height: 17px;
      text-align: center;
      background-color: #eafcfc;
    }
  }
  .ant-picker-clear {
    display: none;
  }
  .ant-picker {
    background-color: transparent;
    border: 0;
    padding-left: 0;
    padding-right: 0;
    width: 115px;
    .ant-picker-input > input {
      font-size: 24px;
      text-align: center;
    }
  }
  .ant-picker:hover,
  :where(.css-dev-only-do-not-override-fpg3f5).ant-picker-focused {
    border: 0;
    box-shadow: none;
  }
`;

export const MonthPickerContent = styled.div`
  margin-top: 14px;
  border: 1px solid #2d2d2d;
  box-shadow: 0px 8px 24px rgba(0, 0, 0, 0.08);
  border-radius: 20px;
  padding: 24px;
  display: flex;
  flex-direction: column;
  max-width: 343px;

  @media (min-width: 768px) {
    flex-direction: row;
    max-width: 810px;
  }
  .def {
    display: grid;
    gap: 16px;
    flex: 1;

    @media (min-width: 768px) {
      &:not(.no-border) {
        border-right: 1px solid #e3e2e2;
      }
      padding-bottom: 0;
      margin-bottom: 0;
    }
    &.center {
      justify-content: center;
    }
  }

  .ghi {
    font-size: 16px;
    line-height: 22.4px;
    color: #666666;
    font-family: var(--font-500);
  }

  .jkl {
    display: flex;
    align-items: baseline;
    gap: 4px;
  }

  .mno {
    display: block;
    font-size: 23px;
    line-height: 32.2px;
    color: #000000;
    font-family: var(--font-600);
  }

  .pqr {
    display: block;
    font-size: 16px;
    line-height: 22.4px;
    color: #000000;
    font-family: var(--font-600);
  }

  .stu {
    display: flex;
    align-items: baseline;
    gap: 4px;
  }

  .vwx {
    display: block;
    font-size: 16px;
    line-height: 22.4px;
    color: #15a0a1;
    font-family: var(--font-600);
    &.down {
      color: #c70039;
    }
  }

  .yza {
    display: block;
    font-size: 13px;
    line-height: 18.2px;
    color: #666666;
    font-family: var(--font-500);
  }
`;
export const Filter = styled.div`
  margin-top: 43px;
  .line {
    gap: 16px;
    display: flex;
    align-items: center;
    &.mb {
      margin-bottom: 40px;
    }
    .left {
      width: 120px;
    }
    .right {
      /* .wr-input {
        position: relative;
        input {
          width: 280px;
          height: 40px;
          background-color: transparent;
          border: 1px solid;
          padding: 0 10px;
          font-size: 16px;
          border-radius: 20px;
          padding-right: 45px;
          &:focus {
            outline: none;
          }
        }
        button {
          width: 30px;
          height: 30px;
          border: 0;
          position: absolute;
          right: 7px;
          top: 50%;
          transform: translateY(-50%);
          cursor: pointer;
          background-color: transparent;
        }
      } */
      .ant-select {
        width: 280px;
        .ant-select-selector {
          background-color: transparent;
        }
      }
    }
  }
  .wr-btn {
    margin-top: 40px;
    margin-left: 136px;
    display: flex;
    gap: 16px;
    max-width: 280px;
    > button {
      height: 48px;
      border: 0;
      padding: 0 16px;
      flex: 1;
      border-radius: 50px;
      cursor: pointer;
      &.filter-btn {
        background: #526557;
        color: #fff;
      }
      &.clear-btn {
        border: 1px solid #526557;
        color: #526557;
      }
    }
  }
`;

export const Sort = styled.div`
  display: flex;
  justify-content: space-between;
  .ant-select-single {
    max-width: 200px;
    .ant-select-selector {
      height: 40px;
      border-radius: 50px;
      background-color: transparent;
      display: flex;
      align-items: center;
    }
  }
  > button {
    height: 40px;
    border-radius: 50px;
    border: 0;
    background-color: #526557;
    padding: 0 16px;
    color: #fff;
    min-width: 200px;
    cursor: pointer;
  }
`;

export const Table = styled.div`
  margin-top: 30px;
  margin-left: -30px;
  margin-right: -30px;
  .ant-table-wrapper .ant-table-thead > tr > th {
    text-align: center;
  }
  .ant-table-wrapper .ant-table-tbody > tr > td {
    text-align: center;
  }
`;
export const ActiveBtn = styled.button`
  height: 30px;
  background-color: #000;
  color: #fff;
  border: 0;
  border-radius: 4px;
  padding: 0 10px;
  cursor: pointer;
`;

export const InActiveBtn = styled.button`
  height: 30px;
  background-color: #e5e5e5;
  color: #a3a3a3;
  border: 0;
  border-radius: 4px;
  padding: 0 10px;
`;

export const NoData = styled.div`
  padding: 150px 50px;
  text-align: center;
  font-size: 30px;
  margin-top: 50px;
  border: 1px solid;
  border-radius: 20px;
`;
