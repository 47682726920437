import { Tabs, TabsProps } from "antd";
import React from "react";
import * as S from "./style";
import NormalMember from "./Normal";
import PremiumMember from "./Premium";
const MemberManager: React.FC = () => {
  const onChange = (key: string) => {
    // console.log(key);
  };
  const items: TabsProps["items"] = [
    {
      key: "normal",
      label: `회원 용`,
      children: <NormalMember />,
    },
    {
      key: "premium",
      label: `브랜드 용`,
      children: <PremiumMember />,
    },
  ];
  return (
    <S.Wrapper>
      <div className="headings">
        <h1 className="main-headings">멤버십 회원 관리</h1>
        <p className="sub-heading">멤버십 회원정보를 조회할 수 있습니다.</p>
      </div>
      <div className="inquiry-tabs">
        <Tabs defaultActiveKey="3" items={items} onChange={onChange} />
      </div>
    </S.Wrapper>
  );
};

export default MemberManager;
