import React, { useState } from "react";
import styled from "styled-components";
import CheckedIcon from "assets/images/checkbox/check_box.png";
import UnCheckedIcon from "assets/images/checkbox/check_box_outline_blank.png";

type CheckboxProps = {
  label: string;
  onChange?: (checked: boolean) => void;
  defaultChecked?: boolean;
  size?: number;
};

const Wrapper = styled.label`
  position: relative;
  display: flex;
  align-items: center;
  cursor: pointer;
`;

const CheckboxText = styled.span`
  font-size: 15px;
  font-family: Pretendard-500;
  line-height: 20px;
  color: #2d2d2d;
  margin-left: 10px;
`;

const CheckboxIcon = styled.img``;

const Checkbox: React.FC<CheckboxProps> = ({
  label,
  onChange,
  defaultChecked = false,
  size = 20,
}) => {
  const [checked, setChecked] = useState(defaultChecked);

  const handleChange = () => {
    const newChecked = !checked;
    setChecked(newChecked);
    onChange && onChange(newChecked);
  };

  return (
    <Wrapper>
      <input
        type="checkbox"
        checked={checked}
        onChange={handleChange}
        style={{ display: "none" }}
      />
      <CheckboxIcon
        src={checked ? CheckedIcon : UnCheckedIcon}
        alt={checked ? "Checked" : "UnChecked"}
        // size={size}
      />
      <CheckboxText>{label}</CheckboxText>
    </Wrapper>
  );
};

export default Checkbox;
