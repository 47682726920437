import { createSlice } from "@reduxjs/toolkit"
import { RootState } from "../store"

export interface UserState {
  loading: boolean
  currentUser: any
  errorCode: string
}

const initialState: UserState = {
  loading: false,
  currentUser: null,
  errorCode: "",
}

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    login: (state) => {
      state.loading = true
    },
    loginSuccess: (state, action) => {
      state.loading = false
      state.currentUser = action.payload
      state.errorCode = ""
    },
    loginFailed: (state) => {
      state.loading = false
      state.currentUser = null
    },
    logout: (state) => {
      state.loading = false
      state.currentUser = null
      state.errorCode = ""
    },
  },
})

export const { login, loginSuccess, loginFailed, logout } = userSlice.actions

export const loginSelect = (state: RootState) => state.user;
export const tokenSelect = (state: RootState) => state.user.currentUser?.accessToken;

export default userSlice.reducer
