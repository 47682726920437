import {
  Action,
  ThunkAction,
  configureStore
} from "@reduxjs/toolkit"
// import counterReducer from "../features/counter/counterSlice"
import { combineReducers } from "redux"
import {
  PERSIST,
  persistReducer,
  persistStore
} from "redux-persist"
import storage from "redux-persist/lib/storage"
import userSlice from "./user/userSlice"
// import { PERSIST } from "@reduxjs/toolkit/constants/actionTypes";


const rootPersistConfig = {
  key: "root",
  version: 1,
  storage,
  whitelist: ['auth']
}

const rootReducer = combineReducers({
  user: persistReducer({ key: "user", storage }, userSlice),
})
const persistedReducer = persistReducer(rootPersistConfig, rootReducer)

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [PERSIST],
      },
    }),
})

export type AppDispatch = typeof store.dispatch
export type RootState = ReturnType<typeof store.getState>
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>

export const persistor = persistStore(store)
