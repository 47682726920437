import { Tabs, TabsProps } from "antd";
import Client from "./Client";
import * as S from "./style";
import { Wrap } from "./style";

function Inquiry() {
  const onChange = (key: string) => {
    // console.log(key);
  };
  // const items: TabsProps["items"] = [
  //   {
  //     key: "client",
  //     label: `고객 용`,
  //     children: <Client />,
  //   },
  //   {
  //     key: "brand",
  //     label: `브랜드 용`,
  //     // children: <Brand />,
  //     children: <></>,
  //   },
  // ];
  return (
    <Wrap>
      <div className="headings">
        <h1 className="main-headings">1:1 문의하기</h1>
        <p className="sub-headings">
          1:1 문의가 들어온 게시글을 답변 관리 할 수 있습니다.
        </p>
      </div>
      <div className="inquiry-tabs">
        {/* <Tabs defaultActiveKey="3" items={items} onChange={onChange} /> */}
        <Client />
      </div>
    </Wrap>
  );
}

export default Inquiry;
