import { message, Select } from "antd";
// import ArrowDownIcon from "@assets/icons/community/expand_more.svg";
import * as S from "./style";
import useWindowSize from "hooks/useWindowSize";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getPremiumDetailApi, updateEmailReceiveApi } from "api/membership";
import { ReactComponent as ArrowUp48Icon } from "assets/icons/membership/ArrowUp48.svg";
import { ReactComponent as ArrowDown48Icon } from "assets/icons/membership/ArrowDown48.svg";
import dayjs from "dayjs";
import { MoneyConvert } from "utils/format";

interface DetailInfoProps {
  data?: any;
}

const DetailInfo: React.FC<DetailInfoProps> = () => {
  const { width } = useWindowSize();
  const { userId, brandId } = useParams();
  const [info, setInfo] = useState<any>();
  const [showFull, setShowFull] = useState<boolean>(false);
  const [isShowBtn, setIsShowBtn] = useState<boolean>(false);

  const getPremiumDetail = async () => {
    if (!userId || !brandId) return;
    const { data, error }: any = await getPremiumDetailApi({
      userId: +userId,
      brandId: +brandId,
    });
    if (error) {
      console.error(error);
      return;
    }
    setInfo(data);
    // if (itemsToShow.length >= 4) {
    //   setIsShowBtn(true);
    // } else {
    //   setIsShowBtn(false);
    // }
    if (data.membershipNoList.length >= 4) {
      setIsShowBtn(true);
    } else {
      setIsShowBtn(false);
    }
  };

  const handleChangeEmaiLReceive = async (v: string) => {
    // console.log("v", v)
    if (!userId) return;
    const emailReceived: boolean = v === "1" ? true : false;
    const { data, error }: any = await updateEmailReceiveApi({
      userId: +userId,
      emailReceived,
    });
    if (error) {
      console.error(error);
      message.error("Error updating email");
      return;
    }
    message.success("Successfully updated");
    getPremiumDetail();
  };

  const itemsToShow = showFull
    ? info?.membershipNoList
    : info?.membershipNoList.slice(0, 4);

  // var itemsToShow = showFull
  //   ? [
  //       "STEN06M210",
  //       "STEN06M210",
  //       "STEN06M210",
  //       "STEN06M210",
  //       "STEN06M210",
  //       "STEN06M210",
  //       "STEN06M210",
  //       "STEN06M210",
  //     ]
  //   : [
  //       "STEN06M210",
  //       "STEN06M210",
  //       "STEN06M210",
  //       "STEN06M210",
  //       "STEN06M210",
  //       "STEN06M210",
  //       "STEN06M210",
  //       "STEN06M210",
  //     ].slice(0, 4);

  useEffect(() => {
    getPremiumDetail();
  }, []);

  return (
    <S.WrapField>
      <div className="field-item">
        <label>브랜드명</label>
        <div>{info?.brandName}</div>
      </div>
      <div className="field-item">
        <label>멤버십 No.</label>
        <div className="has-label multi">
          <div
            className="no-list"
            style={{
              height: showFull ? "auto" : "48px",
              borderRadius: showFull ? "15px" : "50px",
            }}
          >
            {itemsToShow?.map((item: string) => (
              <div className="no-item">
                {item} <span>프리미엄</span>
              </div>
            ))}
          </div>
          {isShowBtn && (
            <div
              className="btn w-[48px] h-[48px] block ml-[15px] cursor-pointer"
              onClick={() => setShowFull(!showFull)}
            >
              {showFull ? <ArrowUp48Icon /> : <ArrowDown48Icon />}
            </div>
          )}
        </div>
      </div>
      <div className="field-item">
        <label>가입일</label>
        <div>{dayjs(info?.registedDate).format("YYYY-MM-DD")}</div>
      </div>
      <div className="field-item">
        <label>만료일</label>
        <div>{dayjs(info?.expiredDate).format("YYYY-MM-DD")}</div>
      </div>
      <div className="field-item">
        <label>가입 상태</label>
        <div>{info?.registedStatus === "RENEWAL" ? "멤버십 유지" : "만료"}</div>
      </div>
      <div className="field-item">
        <label>최근사용일</label>
        <div>{dayjs(info?.lastUsedDate).format("YYYY-MM-DD")}</div>
      </div>

      <div className="field-item">
        <label>잔여금액</label>
        <div>{MoneyConvert(info?.balanceAmount, true)}</div>
      </div>

      <div className="field-item">
        <label>사용금액</label>
        <div>{MoneyConvert(info?.usedAmount, true)}</div>
      </div>

      <div className="field-item">
        <label>전체금액</label>
        <div>{MoneyConvert(info?.usedAmount + info?.balanceAmount, true)}</div>
      </div>

      <div className="field-item">
        <label>결제건수</label>
        <div>{MoneyConvert(info?.totalSuccessPayment, true)}</div>
      </div>

      <div className="field-item">
        <label>이름</label>
        <div>{info?.displayName}</div>
      </div>

      <div className="field-item">
        <label>생년월일</label>
        <div>{info?.birthDate}</div>
      </div>

      <div className="field-item">
        <label>성별</label>
        <div>{info?.gender === "MALE" ? "남성" : "여성"}</div>
      </div>

      <div className="field-item">
        <label>연락처</label>
        <div>{info?.phoneNumber}</div>
      </div>

      <div className="field-item">
        <label>이메일</label>
        <div>{info?.email}</div>
      </div>

      <div className="field-item">
        <label>마케팅 수신 여부</label>
        <div className="wr-select">
          <Select
            popupClassName="custom-select-dropdown"
            defaultValue={"1"}
            // value={sort}
            style={{
              width: "100%",
              maxWidth: width && width > 767 ? "220px" : "100%",
              height: 48,
            }}
            onChange={handleChangeEmaiLReceive}
            // suffixIcon={
            //   <div>
            //     <ArrowDownIcon />
            //   </div>
            // }
            options={[
              { value: "1", label: "동의" },
              { value: "0", label: "비동의" },
            ]}
          />
        </div>
      </div>
    </S.WrapField>
  );
};

export default DetailInfo;
