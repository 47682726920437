import { styled } from "styled-components";

export const Wrapper = styled.div``;

export const Container = styled.div`
  width: 100%;
  max-width: 732px;
  margin: auto;
  padding: 0 16px;
`;

export const Content = styled.div`
  padding-top: 30px;
  padding-bottom: 100px;
`;

export const ThreeField = styled.div`
  display: flex;
  gap: 15px;
`;
export const FormBtn = styled.div`
  display: flex;
  gap: 20px;
  margin-top: 30px;
  > div {
    button {
      width: 100%;
    }
    &:nth-child(1) {
      width: 260px;
    }
    &:nth-child(2) {
      flex: 1;
    }
  }
`;

export const FormField = styled.div`
  padding: 30px 25px;
  background-color: #fff;
  border-radius: 20px;
  > h3 {
    font-family: var(--font-600);
    font-size: 22px;
    font-style: normal;
    font-weight: 600;
    line-height: 110%;
    text-transform: capitalize;
    margin-bottom: 20px;
  }
  .ant-form-item {
    margin-bottom: 20px;
  }
  .image-upload-container {
    gap: 40px;
    .upload-label {
      border-radius: 50%;
    }
  }
`;
export const AddressContainer = styled.div`
  margin-bottom: 25px;
  position: relative;
`;

export const OverLayout = styled.div`
  &.active {
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: 11;
    display: block;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }
`;
export const WrPopupAdress = styled.div`
  position: absolute;
  top: 0;
  background: #fff;
  width: 100%;
  z-index: 111;
  border: 1px solid;
  .close-popup-adress {
    position: absolute;
    top: -27px;
    width: 25px;
    height: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    right: 0;
    background: #878787;
    color: #fff;
    cursor: pointer;
  }
`;
