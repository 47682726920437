import { styled } from "styled-components";

export const Wrapper = styled.div`
  padding-bottom: 30px;
  height: 100%;
  /* background-color: var(--sub-background); */
  .main-title {
    margin-bottom: 10px;
  }
  .ant-input {
    background-color: transparent;
  }
  .wr-select {
    .ant-select-single {
      .ant-select-selector {
        height: 40px;
        border-radius: 50px;
        background-color: transparent;
        display: flex;
        align-items: center;
        border: 1px solid;
      }
    }
  }

  .filter-container {
    padding: 0 30px;
    display: flex;
    gap: 12px;
    height: 60px;
    margin: 30px 0 20px 0;
  }
  .tabs-wrapper {
    margin-top: 20px;
    background-color: var(--light-color);
    .has-number {
      display: flex;
      gap: 10px;
      align-items: center;
      p {
        background-color: #dd6b59;
        color: var(--light-color);
        padding: 5px 10px;
        border-radius: 20px;
        font-size: 16px;
        font-family: "SpaceMonoRegular";
      }
    }
    .ant-tabs-nav {
      margin-bottom: 0;
    }
    .ant-tabs-nav-wrap {
      padding: 0 24px;
      .ant-tabs-nav-list {
        width: 100%;
        display: flex;
        justify-content: center;
        .ant-tabs-tab {
          flex-grow: 1;
          display: flex;
          justify-content: center;
          padding: 28px 0;
        }
        .ant-tabs-tab-active.ant-tabs-tab-active .ant-tabs-tab-btn {
          color: var(--brand-color);
          font-family: var(--font-600);
        }
        .ant-tabs-ink-bar {
          background-color: var(--brand-color);
        }
      }
    }
    .ant-tabs-tab-btn {
      font-size: 16px;
      color: var(--brand-color);
    }
  }
`;

export const TdTextInner = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  p {
    margin: 0;
  }
  .big-text {
    font-size: 16px;
    line-height: 25.6px;
  }
  .small-text {
    font-size: 14px;
    line-height: 22.4px;
  }
`;

export const ViewDetailBtn = styled.div`
  cursor: pointer;
`;

export const TableContainer = styled.div`
  td {
    vertical-align: middle;
  }
  th {
    font-size: 16px;
  }
`;
export const NoData = styled.div`
  padding: 224px 0;
  text-align: center;
  background-color: var(--sub-background);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: capitalize;
`;
