import { styled } from "styled-components";

export const Wrapper = styled.div`
  height: 100%;
  .sort-download {
    display: flex;
    justify-content: space-between;
    gap: 16px;
    > button {
      height: 40px;
      border-radius: 50px;
      border: 0;
      background: #526557;
      color: #fff;
      padding: 0 24px;
      cursor: pointer;
      display: flex;
      align-items: center;
      gap: 5px;
    }
  }
  .ant-select-single {
    .ant-select-selector {
      height: 40px;
      border-radius: 50px;
      background-color: transparent;
      display: flex;
      align-items: center;
      border: 1px solid;
    }
  }
  .filter-container {
    display: flex;
    gap: 12px;
    padding: 20px 30px 52px;
    background-color: var(--sub-background);
    .btn-create {
      border-radius: 20px;
      background: var(--brand-color);
      height: 60px;
      width: 180px;
      display: flex;
      align-items: center;
      color: var(--light-color) !important;
      border: none !important;
    }
  }
  .inquiry-tabs {
    margin-top: 20px;
    background-color: var(--light-color);
    .ant-tabs-nav {
      margin-bottom: 0;
    }
    .ant-tabs-nav-wrap {
      padding: 0 24px;
      .ant-tabs-nav-list {
        width: 100%;
        display: flex;
        justify-content: center;
        .ant-tabs-tab {
          flex-grow: 1;
          display: flex;
          justify-content: center;
          padding: 28px 0;
        }
        .ant-tabs-tab-active.ant-tabs-tab-active .ant-tabs-tab-btn {
          color: var(--brand-color);
          font-family: var(--font-600);
        }
        .ant-tabs-ink-bar {
          background-color: var(--brand-color);
        }
      }
    }
    .ant-tabs-tab-btn {
      font-size: 16px;
      color: var(--brand-color);
    }
  }
`;

export const Heading = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  padding: 100px 30px 0 30px;
  gap: 10px;
  background-color: var(--light-color);
  .sub-heading {
    font-size: 16px;
    line-height: normal;
    font-family: var(--font-500);
  }
  .btn-create {
    border-radius: 12px;
    background: var(--brand-color);
    color: var(--light-color) !important;
    width: 220px;
    height: 52px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
  }
`;

export const TableWraper = styled.div`
  .ant-input-affix-wrapper,
  .ant-picker,
  .ant-input {
    background-color: transparent;
  }
  .ant-table thead tr th {
    font-size: 20px;
  }
  .create-date {
    font-size: 16px;
  }
  .status {
    width: 54px;
    height: 32px;
    font-size: 16px;
    line-height: 22.4px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--light-color);
    &.active {
      background-color: #04ae01;
    }
    &.inactive {
      background-color: #858584;
    }
  }
`;

export const Filter = styled.div`
  margin-top: 50px;
  .ant-picker {
    border: 1px solid;
  }
  .line {
    display: flex;
    align-items: center;
    gap: 16px;
    &.mb {
      margin-bottom: 30px;
    }
    .block-left {
      width: 100px;
    }
    .block-right {
      flex: 1;
      &.flex {
        display: flex;
        gap: 16px;
      }
      /* .wr-select {
        .ant-select-single {
          .ant-select-selector {
            height: 40px;
            border-radius: 50px;
            background-color: transparent;
            display: flex;
            align-items: center;
            border: 1px solid;
          }
        }
      } */
      .wr-input {
        position: relative;
        width: 280px;
        input {
          width: 100%;
          height: 40px;
          background-color: transparent;
          border: 1px solid;
          padding: 0 10px;
          font-size: 16px;
          border-radius: 20px;
          padding-right: 45px;
          &:focus {
            outline: none;
          }
        }
        button {
          width: 30px;
          height: 30px;
          border: 0;
          position: absolute;
          right: 7px;
          top: 50%;
          transform: translateY(-50%);
          cursor: pointer;
          background-color: transparent;
        }
      }
    }
  }
  .wr-btn {
    margin-top: 40px;
    margin-left: 116px;
    display: flex;
    gap: 16px;
    max-width: 420px;
    > button {
      height: 48px;
      border: 0;
      padding: 0 16px;
      flex: 1;
      border-radius: 50px;
      cursor: pointer;
      &.filter-btn {
        background: #526557;
        color: #fff;
      }
      &.clear-btn {
        border: 1px solid #526557;
        color: #526557;
      }
    }
  }
`;
export const PickTime = styled.div`
  display: flex;
  gap: 16px;
  .wr-time-type {
    display: flex;
    border: 1px solid var(--main-border);
    border-radius: 50px;
  }
  .time-btn {
    height: 40px;
    background-color: transparent;
    border: 0;
    padding: 0 12px;
    cursor: pointer;
    &:first-child {
      border-right: 1px solid var(--main-border);
    }
    &:last-child {
      border-left: 1px solid var(--main-border);
    }
    &.active {
      font-family: var(--font-700);
    }
  }
  .wr-date {
    display: flex;
    align-items: center;
  }
  .ant-picker {
    height: 40px;
    background-color: transparent;
    border-radius: 50px;
    .ant-picker-input:hover .ant-picker-clear {
      display: none;
    }
  }
`;

export const Normal = styled.div`
  padding: 0 30px;
`;

export const Sort = styled.div`
  margin: 50px 0;
  > h3 {
    margin-bottom: 16px;
  }
  .sort-download {
    display: flex;
    justify-content: space-between;
    gap: 16px;
    > button {
      height: 40px;
      border-radius: 50px;
      border: 0;
      background: #526557;
      color: #fff;
      padding: 0 24px;
      cursor: pointer;
      display: flex;
      align-items: center;
      gap: 5px;
    }
  }
`;
