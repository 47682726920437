import { styled } from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 30px;
  .wr-date {
    display: flex;
    align-items: center;
  }
  .ant-picker {
    width: 235px;
    height: 48px;
    border-radius: 20px;
    border-color: #858584;
  }
`;

export const AddButton = styled.div`
  display: flex;
  width: 100%;
  button {
    width: 100%;
    color: #fff;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 20px;
    background-color: #526557;
    font-family: var(--font-500);
  }
`;
