import Table, { ColumnsType } from "antd/es/table";
import { getNormalOrderSingleApi } from "api/user";
import dayjs from "dayjs";
import { paymentStatusMapping } from "pages/Membership/OrderHistory/components/OrderHistoryTable";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { MoneyConvert } from "utils/format";

const NormalTable: React.FC = () => {
  // const [searchParams, setSearchParams] = useSearchParams();
  const [pageNumber, setPageNumber] = useState<number>(1);
  const { userId } = useParams<{ userId: string }>();
  const [dataTable, setDataTable] = useState<{
    data: any;
    totalElement: number;
  }>({
    data: [],
    totalElement: 0,
  });

  const getNormalOrderSingle = async (p?: any) => {
    if (!userId) {
      console.error("Couldn't find userId");
      return;
    }
    const { data, error }: any = await getNormalOrderSingleApi({
      userId: +userId,
      // page: Number(searchParams.get("page")) || 1,
      size: 10,
      ...p,
    });
    if (error) {
      console.error(error);
      return;
    }
    if (data?.datas) {
      setDataTable({
        data: data.datas,
        totalElement: data.pageable.totalElement,
      });
    }
  };

  useEffect(() => {
    // if (searchParams.get("orderTab") === "normal") {
    getNormalOrderSingle();
    // }
  }, []);
  // console.log("dataTable", dataTable);

  const columns: ColumnsType<any> = [
    {
      title: "결제일시",
      dataIndex: "cl1",
      key: "cl1",
      width: "20%",
      fixed: "left",
      render: (_, record) => (
        <div>{dayjs(record?.createdTime).format("YYYY-MM-DD")}</div>
      ),
    },
    {
      title: "브랜드명",
      dataIndex: "cl2",
      key: "cl2",
      width: "20%",
      fixed: "left",
      render: (_, record) => <div>{record?.brandName}</div>,
    },
    {
      title: "결제금액",
      dataIndex: "cl3",
      key: "cl3",
      width: "20%",
      fixed: "left",
      render: (_, record) => (
        <div>{MoneyConvert(record?.totalPayment, true)}</div>
      ),
    },
    {
      title: "멤버십유형",
      dataIndex: "cl4",
      key: "cl4",
      fixed: "left",
      width: "20%",
      render: (_, record) => <div>Premium</div>,
    },
    {
      title: "결제상태",
      dataIndex: "cl5",
      key: "cl5",
      width: "20%",
      render: (_, record) => (
        <div>{paymentStatusMapping[record?.paymentStatus]}</div>
      ),
    },
  ];
  return (
    <div>
      <Table
        // onChange={handleTableChange}
        bordered
        className="no-margin-x center-table"
        columns={columns}
        dataSource={dataTable?.data}
        pagination={
          dataTable.totalElement >= 10
            ? {
                current: pageNumber,
                total: dataTable.totalElement,
                pageSize: 10,
                showSizeChanger: false,
                onChange: (page) => {
                  // searchParams.set("page", page.toString());
                  // setSearchParams(searchParams);
                  getNormalOrderSingle({ page: +page });
                  setPageNumber(+page);
                },
              }
            : false
        }
      />
    </div>
  );
};

export default NormalTable;
