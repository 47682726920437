import styled from "styled-components";

export const DateRangePickerWrapper = styled.div`
  .ant-picker {
    width: 200px;
    border: 1px solid var(--main-border);
    border-radius: 20px;
    height: 100%;
    padding-left: 20px;
    padding-right: 20px;
    .ant-picker-active-bar {
      display: none;
    }
  }
  .ant-picker-range {
    width: 280px;
  }
  .ant-picker-clear {
    right: 35px;
  }
`;
