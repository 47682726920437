import { Button } from "antd";
import { ColumnsType } from "antd/es/table";
import { getListInquiryApi } from "api/inquiry";
import { ReactComponent as CaretRight } from "assets/icons/CaretRight.svg";
import { ReactComponent as BigRight } from "assets/icons/PaperPlaneRight.svg";
import { ReactComponent as SearchIcon } from "assets/icons/Search.svg";
import CsInput from "components/Input";
import CsTable from "components/Table";
import dayjs from "dayjs";
import { useCallback, useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { debounce } from "utils/debounce";
import * as S from "./style";
import { categories, typeMappings } from "../FAQ/Client";
import SelectCmp from "components/SelectCmp";
import { DefaultOptionType } from "antd/es/select";
import { formatPhoneNumber } from "utils/format";

type Props = {};

const inquiryStatus: DefaultOptionType[] = [
  {
    label: "답변대기",
    value: 0,
  },
  {
    label: "답변완료",
    value: 1,
  },
];

function Client({}: Props) {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [dataTable, setDateTable] = useState<any>([]);
  const [totalElement, setTotalElement] = useState<number>(0);
  const [searchValue, setSearchValue] = useState("");
  const [dateSort, setDateSort] = useState<"asc" | "desc">();
  const [dateSortString, setDateSortString] = useState<
    "ascend" | "descend" | undefined
  >();

  const handleSearch = useCallback(
    debounce((value: string) => {
      setSearchValue(value);
      searchParams.set("page", "1");
      setSearchParams(searchParams);
    }, 1000),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );
  // console.log('searchParams.get("isReply")', searchParams.get("isReply"));

  const getListInquiry = async (payload?: any) => {
    try {
      const { data }: any = await getListInquiryApi({
        querySearch: searchValue.trim(),
        page: Number(searchParams.get("page") || 1),
        size: 10,
        createDateSort: dateSort,
        type: searchParams.get("type") || undefined,
        isReply: searchParams.has("isReply")
          ? Number(searchParams.get("isReply"))
          : undefined,
        ...payload,
      });
      if (data) {
        setDateTable(data.datas || data);
      }
      if (data.datas) {
        setTotalElement(+data.pageable.totalElement);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (!searchParams.has("isReply")) {
      searchParams.set("isReply", "0");
      setSearchParams(searchParams);
    }
    getListInquiry();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateSort, searchParams, searchValue]);

  const columns: ColumnsType<any> = [
    {
      title: "회원명 / 연락처",
      dataIndex: "userInfo",
      key: "userInfo",
      render: (_: any, record: any) => {
        return (
          <S.UserInfo>
            <h3>{record?.displayName}</h3>
            <p>{formatPhoneNumber(record?.verifiedPhoneNumber)}</p>
          </S.UserInfo>
        );
      },
    },
    {
      title: "문의 유형",
      dataIndex: "type",
      key: "type",
      render: (_, record) => (
        <p className="category">{typeMappings[record.type] || record.type}</p>
      ),
    },
    {
      title: "문의 제목",
      dataIndex: "subject",
      key: "subject",
      width: "40%",
    },
    {
      title: "질문 작성일",
      dataIndex: "createdTime",
      key: "createdTime",
      render: (_: any, record: any) => {
        return <>{dayjs(record.createdTime).format("YYYY/MM/DD HH:mm")}</>;
      },
      sorter: true,
    },
    {
      title: "답변 상태",
      dataIndex: "status",
      key: "status",
      render: (_: any, record: any) => {
        return (
          <Button
            type="primary"
            style={{
              width: "63px",
              height: "32px",
              padding: "5px",
              backgroundColor: `${record.isReply ? "#858584" : "#04AE01"}`,
            }}
            // onClick={() => {
            //   navigate("id");
            // }}
          >
            {record.isReply ? "답변완료" : "답변대기"}
          </Button>
        );
      },
    },
    {
      title: "",
      dataIndex: "btnDetail",
      key: "btnDetail",
      render: (_: any, record: any) => {
        return (
          <S.ViewDetailBtn>
            {record.isReply ? (
              <div
                onClick={() =>
                  navigate(`/customer-service/inquiry/${record.id}`)
                }
              >
                <CaretRight />
              </div>
            ) : (
              <div
                onClick={() =>
                  navigate(`/customer-service/inquiry/reply/${record.id}`)
                }
              >
                <BigRight />
              </div>
            )}
          </S.ViewDetailBtn>
        );
      },
    },
  ];
  // console.log("page", searchParams.get("page"));

  const handleTableChange = (_: any, __: any, sorter: any) => {
    const { order } = sorter;

    if (order !== dateSortString) {
      setDateSortString(order);
      setDateSort(
        order === "ascend" ? "asc" : order === "descend" ? "desc" : undefined
      );
      searchParams.set("page", "1");
      setSearchParams(searchParams);
    }
  };

  return (
    <S.Wrapper>
      <div
        style={{ padding: "0 30px 30px 30px", display: "flex", gap: "12px" }}
      >
        <CsInput
          name="textSearch"
          placeholder="Search user info, content"
          style={{ height: "60px" }}
          suffix={<SearchIcon />}
          onChange={(e) => {
            const inputValue: string = e.target.value;
            const cleanedValue = inputValue.replace(/\s+/g, " ");
            if (cleanedValue !== " ") {
              setSearchValue(cleanedValue);
              handleSearch(cleanedValue);
            }
          }}
          value={searchValue}
        />
        <SelectCmp
          // defaultValue="inactive"
          style={{
            width: 180,
            height: 60,
          }}
          options={categories}
          placeholder="문의 유형"
          onChange={(value) => {
            if (value !== undefined) {
              searchParams.set("type", value);
              searchParams.set("page", "1");
              setSearchParams(searchParams);
            } else {
              searchParams.delete("type");
              setSearchParams(searchParams);
            }
          }}
        />
        <SelectCmp
          // defaultValue={0}
          style={{
            width: 180,
            height: 60,
          }}
          options={inquiryStatus}
          placeholder="답변 상태"
          onChange={(value) => {
            if (value !== undefined) {
              searchParams.set("isReply", value);
              searchParams.set("page", "1");
              setSearchParams(searchParams);
            } else {
              searchParams.delete("isReply");
              setSearchParams(searchParams);
            }
          }}
        />
      </div>
      <CsTable
        onChange={handleTableChange}
        columns={columns}
        dataSource={dataTable}
        pagination={
          totalElement > 10
            ? {
                current: Number(searchParams.get("page")) || 1,
                total: totalElement,
                showSizeChanger: false,
                onChange: (page) => {
                  searchParams.set("page", page.toString());
                  setSearchParams(searchParams);
                },
              }
            : false
        }
      />
    </S.Wrapper>
  );
}

export default Client;
