import { CloseOutlined } from "@ant-design/icons";
import type { FormInstance, InputRef, TableProps } from "antd";
import { Form, Input } from "antd";
import Table from "antd/es/table";
import { addPremiumHistoryApi } from "api/brand";
import { getPremiumOrderSingleApi } from "api/user";
import BrandHasPurchase from "components/BrandHasPurchase";
import dayjs from "dayjs";
import React, {
  ReactNode,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { formatNumberWithCommas, MoneyConvert } from "utils/format";

const formatCurrency = (value: ReactNode): string => {
  const valueAsString = typeof value === "string" ? value : String(value);

  // Xóa dấu phẩy nếu có
  const cleanedValue = valueAsString.replace(/,/g, "");
  const parsedValue = parseFloat(cleanedValue);

  if (!isNaN(parsedValue)) {
    return new Intl.NumberFormat("ko-KR", {
      style: "decimal",
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    }).format(parsedValue);
  }

  return valueAsString;
};
interface Props {}
const EditableContext = React.createContext<FormInstance<any> | null>(null);
interface Item {
  createdTime?: string;
  brandName?: string;
  rootPrice?: string;
  totalPayment?: string;
  type?: string;
  membershipNo?: string;
  timeUsed?: {
    endTime?: string;
    expiredTime?: string;
  };
  id?: number;
  key: React.Key;
  isNew?: boolean;
  cancelAmount?: boolean;
  gbProgress?: string;
}

interface EditableRowProps {
  index: number;
}

// const handleAddAndUpdate = async () => {};

const EditableRow: React.FC<EditableRowProps> = ({ index, ...props }) => {
  const [form] = Form.useForm();
  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  );
};

interface EditableCellProps {
  title: React.ReactNode;
  editable: boolean;
  dataIndex: keyof Item;
  record: Item;
  handleSave: (record: Item) => void;
  getPremiumOrderSingle: () => Promise<void>;
}

const EditableCell: React.FC<React.PropsWithChildren<EditableCellProps>> = ({
  title,
  editable,
  children,
  dataIndex,
  record,
  handleSave,
  getPremiumOrderSingle,
  ...restProps
}) => {
  const [editing, setEditing] = useState(record?.isNew);
  const inputRef = useRef<InputRef>(null);
  const form = useContext(EditableContext)!;

  useEffect(() => {
    if (editing) {
      inputRef.current?.focus();
    }
  }, [editing]);

  // useEffect(() => {
  //   record?.totalPayment === "0" ? setEditing(true) : setEditing(false);
  // }, []);

  const toggleEdit = () => {
    setEditing(!editing);
    form.setFieldsValue({
      [dataIndex]: formatNumberWithCommas(`${record[dataIndex]}`),
    });
  };

  const save = async () => {
    try {
      const values = await form.validateFields();

      toggleEdit();
      handleSave({ ...record, ...values });
    } catch (errInfo) {
      console.log("Save failed:", errInfo);
    }
  };

  const close = async () => {
    try {
      const { data, error }: any = await addPremiumHistoryApi({
        id: record.id,
        cancelAmount: true,
      });
      if (error) {
        console.error(error);
        return;
      }
      // Gọi lại dữ liệu sau khi cập nhật
      await getPremiumOrderSingle(); // Gọi hàm này
    } catch (err) {
      console.error("Error while closing:", err);
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = formatNumberWithCommas(e.target.value); // Xóa dấu phẩy
    // setInputValue(value); // Cập nhật giá trị trong state
    form.setFieldsValue({ [dataIndex]: value }); // Cập nhật giá trị trong form
  };
  let childNode = children;

  // console.log("record.totalPayment", record?.totalPayment);
  // record?.totalPayment === "0" ||
  // console.log('childNode', childNode);
  // console.log('editing', editing);

  if (editable) {
    childNode = editing ? (
      <Form.Item
        style={{ margin: 0 }}
        name={dataIndex}
        rules={[{ required: true, message: `` }]}
      >
        <Input
          suffix="원"
          ref={inputRef}
          onPressEnter={save}
          onBlur={save}
          onChange={handleChange}
        />
      </Form.Item>
    ) : (
      <div>
        <div
          // disabled={true}
          style={{
            height: 18,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {record?.cancelAmount ? (
            <div style={{ color: "red", fontSize: 12 }}>무효됨</div>
          ) : (
            record?.totalPayment === "0" && (
              <CloseOutlined
                onClick={close}
                style={{
                  color: "red",
                  cursor: "pointer",
                  fontSize: 16,
                }}
              />
            )
          )}
        </div>

        <div
          className={`editable-cell-value-wrap ${
            record?.cancelAmount ||
            Number(record?.totalPayment) > 0 ||
            record?.gbProgress !== "FINISHED"
              ? "disabled"
              : ""
          }`}
          style={{
            paddingInlineEnd: 24,
            textDecoration: record?.cancelAmount ? "line-through" : undefined,
          }}
          onClick={() => {
            !record?.cancelAmount &&
              record?.totalPayment === "0" &&
              record?.gbProgress === "FINISHED" &&
              toggleEdit();
          }}
        >
          {formatCurrency(children)}
        </div>
      </div>
    );
  }

  return <td {...restProps}>{childNode}</td>;
};

export interface DataType {
  createdTime?: string;
  brandName?: string;
  rootPrice?: string;
  totalPayment?: string;
  type?: string;
  membershipNo?: string;
  timeUsed?: {
    endTime?: string;
    expiredTime?: string;
  };
  id?: number;
  key: React.Key;
  isNew?: boolean;
  cancelAmount?: boolean;
  gbProgress?: string;
}

type ColumnTypes = Exclude<TableProps<DataType>["columns"], undefined>;

const PremiumTable: React.FC<Props> = ({}) => {
  const [searchParams] = useSearchParams();
  const { userId } = useParams<{ userId: string }>();
  const [dataSource, setDataSource] = useState<DataType[] | null>(null);
  const [totalElement, setTotalElement] = useState<number>(0);
  const [canAdd, setCanAdd] = useState<boolean>(true);
  const [brandSelected, setBrandSelected] = useState<any>();
  const [isRequired, setIsRequired] = useState<boolean>(false);
  const [pageNumber, setPageNumber] = useState<number>(1);
  // const [count, setCount] = useState(2);
  // console.log('canAdd', canAdd);

  const getPremiumOrderSingle = async (p?: any) => {
    if (!userId) {
      console.error("Couldn't find userId");
      return;
    }
    const { data, error }: any = await getPremiumOrderSingleApi({
      userId: +userId,
      size: 10,
      ...p,
    });
    if (error) {
      console.error(error);
      return;
    }
    if (data?.datas) {
      const formatData: DataType[] = data?.datas.map((el: any) => {
        return {
          id: el?.id,
          createdTime: el?.createdTime,
          brandName: el?.brandName,
          rootPrice: el?.usageValue,
          totalPayment: el?.totalPayment,
          membershipNo: el?.membershipNoList[0],
          timeUsed: {
            endTime: el?.endTime,
            expiredTime: el?.expiredTime,
          },
          cancelAmount: el?.cancelAmount,
        };
      });
      setDataSource(formatData);
      setTotalElement(data?.pageable?.totalElement);
    }
  };

  const handleAdd = async () => {
    if (!dataSource) return;
    if (!userId) {
      console.error("Couldn't find userId");
      return;
    }
    const { data, error }: any = await getPremiumOrderSingleApi({
      userId: +userId,
      size: 10,
      page: 1,
    });
    if (error) {
      console.error(error);
      return;
    }
    if (data?.datas) {
      const formatData: DataType[] = data?.datas.map((el: any) => {
        return {
          id: el?.id,
          createdTime: el?.createdTime,
          brandName: el?.brandName,
          rootPrice: el?.usageValue,
          totalPayment: el?.totalPayment,
          membershipNo: el?.membershipNoList[0],
          timeUsed: {
            endTime: el?.endTime,
            expiredTime: el?.expiredTime,
          },
          cancelAmount: el?.cancelAmount,
        };
      });

      const newData: DataType = {
        key: formatData.length + 1,
        createdTime: dayjs().format("YYYY-MM-DD"),
        brandName: "",
        rootPrice: "0",
        totalPayment: "totalPayment",
        type: "type",
        membershipNo: "",
        timeUsed: {
          endTime: "",
          expiredTime: "",
        },
        isNew: true,
      };
      setCanAdd(false);
      const dataConvert = formatData.filter((_, i: number) => i < 9);
      setDataSource([newData, ...dataConvert]);
      setPageNumber(1);
      setTotalElement(data?.pageable?.totalElement);
    }
  };

  const handleSave = async (row: DataType) => {
    if (!row.id && !brandSelected?.gbId) {
      setIsRequired(true);
    }
    if (brandSelected?.gbId) {
      setIsRequired(false);
    }

    if (row.isNew) {
      if (!userId || !brandSelected?.gbId) return;
      const { data, error }: any = await addPremiumHistoryApi({
        // id: row.id,
        amount: Number(row.rootPrice?.replace(/,/g, "")),
        userId: +userId,
        gbId: brandSelected.gbId,
      });
      if (error) {
        console.error(error);
        return;
      }

      getPremiumOrderSingle();
      row.isNew = false;
    } else {
      const { data, error }: any = await addPremiumHistoryApi({
        id: row.id,
        amount: Number(row.rootPrice?.replace(/,/g, "")),
      });
      if (error) {
        console.error(error);
        return;
      }
      getPremiumOrderSingle();
    }
    setCanAdd(true);
  };

  const components = {
    body: {
      row: EditableRow,
      cell: EditableCell,
    },
  };

  const defaultColumns: (ColumnTypes[number] & {
    editable?: boolean;
    dataIndex: string;
  })[] = [
    {
      title: "결제일시",
      dataIndex: "cl1",
      key: "cl1",
      width: 124,
      fixed: "left",
      render: (_, record) => (
        <div>{dayjs(record?.createdTime).format("YYYY-MM-DD")}</div>
      ),
    },
    {
      title: "브랜드명",
      dataIndex: "cl2",
      key: "cl2",
      width: 160,
      fixed: "left",
      render: (_, record) => {
        if (!record.brandName) {
          return (
            <BrandHasPurchase
              getBrand={(brand) => {
                setBrandSelected(brand);
              }}
              required={isRequired}
              setIsRequired={setIsRequired}
            />
          );
        } else {
          return <div>{record.brandName}</div>;
        }
      },
    },
    {
      title: "혜택가(원가)",
      dataIndex: "rootPrice",
      key: "cl21",
      width: 160,
      fixed: "left",
      editable: true,
    },
    {
      title: "결제금액",
      dataIndex: "cl3",
      key: "cl3",
      width: 100,
      fixed: "left",
      render: (_, record) => {
        // console.log("record?.totalPayment", record?.totalPayment);

        return (
          <div>
            {record?.totalPayment === "0"
              ? "ADMIN"
              : MoneyConvert(record?.totalPayment)}
          </div>
        );
      },
    },
    {
      title: "멤버십유형",
      dataIndex: "cl4",
      key: "cl4",
      fixed: "left",
      width: 120,
      render: (_, record) => <div>Premium</div>,
    },
    {
      title: "멤버십 No",
      dataIndex: "cl41",
      key: "cl41",
      fixed: "left",
      width: 170,
      render: (_, record) => <div>{record.membershipNo || "-"}</div>,
    },
    {
      title: "사용(유효)기간",
      dataIndex: "cl5",
      key: "cl5",
      width: 170,
      render: (_, record) => {
        if (record?.timeUsed?.endTime) {
          return (
            <div>
              {dayjs(record?.timeUsed?.endTime).format("YYYY-MM-DD HH:mm")}{" "}
              <br /> ~
              {dayjs(record?.timeUsed?.expiredTime)
                .subtract(1, "minute")
                .format("YYYY-MM-DD HH:mm")}
            </div>
          );
        } else {
          return "-";
        }
      },
    },
  ];

  const columns = defaultColumns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record: DataType) => ({
        // key: record.key,
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
        handleSave,
        getPremiumOrderSingle,
      }),
    };
  });

  useEffect(() => {
    if (searchParams.get("orderTab") === "premium") {
      getPremiumOrderSingle();
    }
  }, []);
  // console.log("totalElement", totalElement);

  return (
    <div>
      <Table<DataType>
        // onChange={handleTableChange}
        // rowKey="key"
        components={components}
        bordered
        rowClassName={() => "editable-row"}
        className="no-margin-x antd-center-table"
        columns={columns as ColumnTypes}
        dataSource={dataSource?.map((x: any, i: number) => ({
          ...x,
          key: x.key ? x.key : i,
        }))}
        pagination={
          totalElement >= 10
            ? {
                current: pageNumber,
                total: totalElement,
                pageSize: 10,
                showSizeChanger: false,
                onChange: (page) => {
                  // searchParams.set("pageA", page.toString());
                  // setSearchParams(searchParams);

                  getPremiumOrderSingle({ page: +page });
                  setPageNumber(+page);
                  setCanAdd(true);
                },
              }
            : false
        }
      />
      <button
        className="btn-add"
        onClick={handleAdd}
        disabled={!canAdd}
        style={{ opacity: canAdd ? "1" : "0.5" }}
      >
        + 추가
      </button>
    </div>
  );
};

export default PremiumTable;
