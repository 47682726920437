import { Form, message } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { ReactComponent as LockKey } from "assets/icons/LockKey.svg";
import { ReactComponent as UserLogin } from "assets/icons/UserLogin.svg";
import loginImage from "assets/images/login.png";
import logoImage from "assets/images/admin-logo.png";
import ButtonMain from "components/ButtonMain";
import CsInput from "components/Input";
import { useNavigate } from "react-router";
import { useAppDispatch, useAppSelecter } from "redux/hook";
import * as S from "./style";
import {
  loginFailed,
  loginSelect,
  loginSuccess,
  login as loginAction,
} from "redux/user/userSlice";
import { routesPath } from "routes";
import { useEffect } from "react";
import { loginApi } from "api/user";
import { loginInfo } from "model/user";
type Props = {};

function Login({}: Props) {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const login = useAppSelecter(loginSelect);
  const { loading, currentUser } = login;
  const [messageApi, contextHolder] = message.useMessage({
    maxCount: 2,
  });

  const error = (mess: string) => {
    messageApi.open({
      type: "error",
      content: mess,
    });
  };

  const loginAsync = async (loginInfo: loginInfo) => {
    dispatch(loginAction());
    const { data }: any = await loginApi(loginInfo);

    if (data) {
      dispatch(loginSuccess(data));
    } else {
      dispatch(loginFailed());
      error(
        "Invalid Username or Password. Please make sure your username and password are entered correctly."
      );
    }
  };

  const handleLogin = async (value: any) => {
    const loginInfo: loginInfo = {
      userName: value.username,
      password: value.password,
    };
    loginAsync(loginInfo);
  };

  useEffect(() => {
    if (currentUser) {
      navigate(routesPath.CLIENT_USER);
    }
  }, [navigate, currentUser]);
  return (
    <S.Wrap>
      {contextHolder}
      <div className="login-container">
        <div>
          <img
            style={{
              width: "100%",
            }}
            src={loginImage}
            alt="login"
          />
        </div>
        <div className="form-login">
          <div className="title-container">
            <img src={logoImage} alt="Logo" />
          </div>
          <h2>모아프렌즈 어드민 로그인</h2>
          <Form onFinish={handleLogin}>
            <Form.Item
              name="username"
              rules={[{ required: true, message: "아이디를 입력해 주세요" }]}
            >
              <CsInput
                placeholder="ID를 입력하세요"
                prefix={<UserLogin />}
                style={{
                  padding: "10px 12px",
                }}
              />
            </Form.Item>
            <Form.Item
              name="password"
              rules={[{ required: true, message: "비밀번호를 입력해 주세요" }]}
            >
              <CsInput
                placeholder="패스워드"
                type="password"
                prefix={<LockKey />}
                style={{
                  padding: "10px 12px",
                }}
              />
            </Form.Item>

            <ButtonMain
              htmlType="submit"
              type="primary"
              style={{
                marginTop: "24px",
                height: "56px",
                borderRadius: "20px",
              }}
            >
              {loading ? <LoadingOutlined /> : "로그인"}
            </ButtonMain>
          </Form>
        </div>
      </div>
    </S.Wrap>
  );
}

export default Login;
